import React, { useEffect, useState } from 'react'
import { BorderedTable } from '../../Components/Tables/BorderedTable'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Popover from '../../Components/Common/Popover'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { Link, useNavigate } from 'react-router-dom'
import { deleteUser, getUser } from '../../Api/Admin/AllAPIs'
import { SearchInput } from '../../Components/Inputs/SearchInput'
import { getActionsPermission, searchDataWithKeys } from '../../utils'
import DeleteButton from '../../Components/Buttons/DeleteButton'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { TableSkelton } from '../../Components/Common/TableSkelton'
import Pagination from '../../Components/Common/Pagination/Pagination'
import "./userManagement.css"
import DeleteDialog from '../../Components/Common/DeleteDialog'
export default function UserListing() {

  let [allUser, setAllUsers] = useState([])
  let [deleteDialog, setDeleteDialog] = useState(false)
  let [deletedUserId, setDeletedUserId] = useState("")
  let [loader, setLoader] = useState(false)
  let [allData, setAllData] = useState([])
  let navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const perPageData = 10;
  const [totalRecords, setTotalRecords] = useState(0);


  const [isDeleteAccess, setIsDeleteAccess] = useState(false);
  let deleteAccess = getActionsPermission("Delete User")

  useEffect(() => {
    setIsDeleteAccess(deleteAccess)
  }, [deleteAccess])

  async function getAllUsers() {
    try {
      setLoader(true)

      let params = {
        page: page,
        perPageData: perPageData
      }
      setSearch("")
      let response = await getUser(params)

      let data = response?.data

      setTotalRecords(data?.totalCount || 0)
      setAllUsers(data?.data || [])
      setAllData(data?.data || [])
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {

    getAllUsers()
  }, [page])

  async function deleteuser() {
    try {

      console.log(deletedUserId, "dddddddddddddddd")
      return
      let response = await deleteUser(deletedUserId)
      setDeleteDialog(false)
      setAllUsers(pre => pre.filter(user => user._id !== deletedUserId))
      setAllData(pre => pre.filter(user => user._id !== deletedUserId))
    }
    catch (err) {
      console.log(err)
    }
    console.log(deletedUserId)
  }



  return (
    <div>

        <div className="page_heading">User Management</div>

      <div className="d-flex mb-20 gap-3 flex-sm-row flex-column-reverse justify-content-sm-between  align-items-sm-center align-items-end mt-3">
        <div>
          <SearchInput
            value={search}
            onCancel={() => {
              setAllUsers(allData)
              setSearch("")
            }}
            onChange={(e) => {
              setSearch(e.target.value)
              setAllUsers(searchDataWithKeys(["first_name", "email_address"], allData, e.target.value))

            }}
          />
        </div>
        <div>
          <Link to={"createUser"}>
            <PrimaryButton addIcon text={"Create User"} />
          </Link>
        </div>

      </div>
      <br />
      <div className='userListing_table_scroll'>
        {loader ? <TableSkelton header={["Full Name", "Phone", "Email", "Address", "UserType", ""]} /> :
          <BorderedTable>
            <DataTable
              value={allUser}
            >
              <Column field="first_name" header="Full Name"></Column>
              <Column field="business_phone" header="Phone"></Column>
              <Column field="email_address" header="Email"></Column>
              <Column field="address" header="Address"></Column>
              <Column field="user_type" header="User Type"></Column>
              <Column header="Action"
                body={(rowData) => (
                  <div className="`d-flex align-items-center gap-2">
                    <Popover
                      onSelect={(index) => {

                        if (index === 0) {
                          console.log("Edit")
                          navigate(`editUser/${rowData?._id}`)
                        }
                        if (index === 1) {
                          console.log("Delete")
                          setDeleteDialog(true)
                          setDeletedUserId(rowData._id)
                        }
                      }}
                      options={["Edit", deleteAccess ? "Delete" : null]}
                    />
                  </div>
                )}
              >
              </Column>
            </DataTable>
          </BorderedTable>
        }
      </div>
      <br />
      <Pagination
        currentPage={page}
        totalCount={totalRecords}
        pageSize={perPageData}
        onPageChange={page => {
          console.log(page)
          setPage(page)
        }}
      />



      <DeleteDialog
        visible={deleteDialog}
        setVisible={setDeleteDialog}
        onCancel={() => setDeletedUserId(null)}
        onConfirm={deleteuser}
        header={""}
      />




      {/* <ConfirmDialog
        style={{
          width: "30%",
          minWidth: "25rem",
          padding: "0.8rem",
          background: "#fff",
        }}
        draggable={false}
        visible={deleteDialog}
        onHide={() => setDeleteDialog(false)}
        header={<div style={{ fontSize: "22px" }}> Delete User</div>}
        message={
          <div className="mt-2" style={{ fontSize: "18px" }}>
            Are you sure you want to delete ?
          </div>
        }
        acceptClassName="p-button-danger"
        acceptLabel="Yes"
        rejectLabel="No"
        footer={
          <div className="d-flex gap-3 justify-content-end mt-4">
            <button className="filter_btn  " onClick={() => setDeleteDialog(false)}>
              {" "}
              Cancel
            </button>
            <button onClick={deleteuser} className="filter_btn_apply ">
              {" "}
              Delete
            </button>
          </div>
        }
      /> */}

    </div>
  )
}
