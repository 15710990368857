import React, { useEffect, useState } from 'react'
import TextInput from '../../Components/Inputs/TextInput'
import AutoCompleteSelect from '../../Components/Common/AutoCompleteSelect'
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Common/Loader';
import { DataTable } from 'primereact/datatable';
import { TableSkelton } from '../../Components/Common/TableSkelton';
import { Column } from 'primereact/column';
import style from './style.module.css'
import ViewDrawings from './ViewDrawings';
import { getAllCategoriesByPhase, getAllDocType, getAllPhase, getAllSubCategoriesByPhase, getCategories, getDocTypes, getGlobalData, getSubCategories } from '../../Api/Admin/AllAPIs';
import { toast } from '../../utils';
import reactCsv from 'react-csv';



export default function ImportFronGlobal({ role }) {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [btnloader, setBtnLoader] = useState(false)
    const [openViewModal, setOpenViewModal] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [noData, setNoData] = useState(true)
    const [callData, setCalldata] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [allPhase, setAllPhase] = useState([])
    const [allCategory, setAllCategory] = useState([])
    const [allSubCategory, setAllSubCategory] = useState([])
    const [allDocTypeData, setAllDocTypeData] = useState([])


    const [formData, setFormData] = useState({
        phase_id: null,
        category_id: null,
        sub_category_id: null,
        doctype_id: null,
        title: null
    })


    const [Validation, setValidation] = useState({
        phase_id: false,
        category_id: false,
        sub_category_id: false,
        doctype_id: false,
    });


    async function getAllPhaseData() {
        try {
            let res = await getAllPhase()
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            // console.log(finalData)
            setAllPhase(finalData)

        } catch (err) {
            console.log(err)
        }
    }

    async function getAllCategoriesData() {
        try {
            let res = await getCategories()
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData, "categories---")
            setAllCategory(finalData)

        } catch (err) {
            console.log(err)
        }

    }

    async function getAllSubCategoriesData() {
        try {
            let res = await getSubCategories()
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllSubCategory(finalData)

        } catch (err) {
            console.log(err)
        }

    }

    async function getAllDocTypeData() {
        try {
            let res = await getDocTypes()
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllDocTypeData(finalData)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllPhaseData();
        getAllCategoriesData();
        getAllSubCategoriesData();
        getAllDocTypeData();
    }, [])



    async function handleChange(e, key) {
        setFormData(pre => {
            return {
                ...pre,
                [key]: e
            }
        })
    }



    console.log(formData.phase_id, "phase--");
    console.log(formData.category_id, "category_id");
    console.log(formData.sub_category_id, "subCategory_id")
    console.log(formData.doctype_id, "doc_id")



    function pageFor() {
        switch (role) {
            case 'drawings':
                return 'drawing'
                break;
            case 'photos':
                return 'photo'
                break;
            case 'models':
                return 'model'
                break;
            case 'documents':
                return 'document'
                break;
            default:
                break;
        }
    }
    function forApi() {
        switch (role) {
            case 'drawings':
                return 2
                break;
            case 'photos':
                return 1
                break;
            case 'models':
                return 3
                break;
            case 'documents':
                return 4
                break;

            default:
                break;
        }
    }


    // const checkValidation = () => {
    //     if (formData?.phase_id == null) {
    //         setValidation((prevState) => ({
    //             ...prevState,
    //             phase_id: true,
    //         }));
    //     }


    //     if (formData?.phase_id == null) {
    //         toast.info("Please Fill atleast one Field");
    //         return true;
    //     }
    //     return false;
    // }




    async function getDataList() {
        if (!formData.phase_id && !formData.category_id && !formData.sub_category_id && !formData.doctype_id && !formData.title) {
            toast.info("Please select at least one field");
            return;
        }
        try {
            setBtnLoader(true)
            let payload = { ...formData, role: forApi() }
            let res = await getGlobalData(payload)

            console.log(res, "import from global---")
            let data = res?.data?.data || []
            console.log(data, "datalength is zeroo----")
            if (data?.length == 0) {
                setCalldata(true)
                setNoData(true)
            } else {
                setCalldata(true)
                setNoData(false)
                setTableData(data)
            }

        } catch (err) {
            console.log(err)
        } finally {
            setBtnLoader(false)
        }
    }



    const handleSelected = (e) => {
        setSelectedProducts(e.value);

        console.log(e.value);
        const selectedData = e?.value?.map((item) => ({
            title: item?.title,
            files: Array.isArray(item?.files) ? item?.files : [],
            // _id: Array.isArray(item?.files) ? item?.files?._id : ""
        }))

        console.log(selectedData, "selectedFiles---")
        setSelectedFiles(selectedData);
    }






    return (
        <>
            <ViewDrawings
                open={openViewModal}
                setOpenViewModal={setOpenViewModal}
                data={selectedFiles}
            />
            <div className=" main-container">
                <div>
                    <div className="row m-0">
                        <div className="col-md-12 py-3">
                            <span
                                className="open_Rfi_text text-capitalize"
                                onClick={() => {
                                    if (pageFor() === "document")
                                        navigate("/documents");
                                    else {
                                        navigate(`/projectManagement/${pageFor()}s`)
                                    }
                                }}
                            >
                                {pageFor()}s
                            </span>
                            <span className="px-1 open_Rfi_text">/</span>
                            <span className="createNew_text">Import from Global</span>
                        </div>
                    </div>

                    <div className="page_heading">IMPORT FROM GLOBAL</div>

                    {/* Top Create New Rfi End */}
                    {loader ? (
                        <Loader />
                    ) : (
                        <div className="row m-0 p-0 mt-4 mb-5">
                            <div className="row m-0 p-0">

                                <div className="col-md-6 col-12 mt-3">
                                    <label className="labels mb-2">Phase:</label>
                                    <div className="card flex justify-content-center">
                                        <AutoCompleteSelect
                                            options={allPhase}
                                            value={formData?.phase_id}
                                            placeholder="Select Phase"
                                            optionLabel="label"
                                            className={`p-0 ${Validation.phase_id ? "Validation" : ""}`}
                                            onChange={(e) => {
                                                handleChange(e, 'phase_id')
                                                // handleChangeData('set', e)
                                                setValidation({ ...Validation, phase_id: false })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-3">
                                    <label className="labels mb-2">Category:</label>
                                    <div className="card flex justify-content-center">
                                        <AutoCompleteSelect
                                            // disabled={formData?.phase_id == null}
                                            options={allCategory}
                                            value={formData?.category_id}
                                            className={`p-0 ${Validation.category_id ? "Validation" : ""}`}
                                            placeholder="Select Category"
                                            optionLabel="label"
                                            onChange={(e) => {
                                                handleChange(e, 'category_id')
                                                // handleChangeData('set', e)
                                                setValidation({ ...Validation, category_id: false })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-3">
                                    <label className="labels mb-2">Sub-Category:</label>
                                    <div className="card flex justify-content-center">
                                        <AutoCompleteSelect
                                            // disabled={formData?.phase_id == null || formData?.category_id == null}
                                            options={allSubCategory}
                                            value={formData?.sub_category_id}
                                            className={`p-0 ${Validation.sub_category_id ? "Validation" : ""}`}
                                            placeholder="Select Sub-Category"
                                            optionLabel="label"
                                            onChange={(e) => {
                                                handleChange(e, 'sub_category_id')
                                                // handleChangeData('set', e)
                                                setValidation({ ...Validation, sub_category_id: false })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-3">
                                    <label className="labels mb-2">Document Type:</label>
                                    <div className="card flex justify-content-center">
                                        <AutoCompleteSelect
                                            // disabled={formData?.phase_id == null || formData?.category_id == null}
                                            options={allDocTypeData}
                                            value={formData?.doctype_id}
                                            placeholder="Select Document Type"
                                            optionLabel="label"
                                            className={`p-0 ${Validation.doctype_id ? "Validation" : ""}`}
                                            onChange={(e) => {
                                                handleChange(e, 'doctype_id')
                                                // handleChangeData('set', e)
                                                setValidation({ ...Validation, doctype_id: false })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <label htmlFor="" className="pb-2 labelCss text-capitalize">
                                        {pageFor()} Title:
                                    </label>
                                    <TextInput
                                        placeholder="Enter title"
                                        onChange={(e) => handleChange(e.target.value, 'title')}
                                        className={Validation.subject ? "Validation" : ""}
                                    >
                                    </TextInput>

                                </div>
                            </div>


                            {/* {!callData && noData ?  */}
                            <div className="d-flex justify-content-end gap-3 mt-4 ">
                                <button
                                    className="cancelBtn  px-2 py-2"
                                    onClick={() => {
                                        navigate("/documents")
                                        setNoData(true)
                                        setCalldata(false)
                                        setFormData({
                                            phase_id: null,
                                            category_id: null,
                                            sub_category_id: null,
                                            doctype_id: null,
                                            title: null
                                        })
                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    className="btn btn-primary px-3 py-2 text-capitalize"
                                    // disabled={btnloader}
                                    onClick={getDataList} >
                                    {btnloader ? <div class="spinner-border text-light spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div > : (
                                        <>
                                            {`View ${pageFor() ? pageFor().trim().charAt(0).toUpperCase() + pageFor().trim().slice(1) : ""}`}
                                        </>
                                    )}
                                </button>
                            </div>
                            {/* : ''} */}


                            {console.log(tableData, "import table data---")}



                            {callData ?
                                <div className='w-100 mt-4'>

                                    {noData ?
                                        <React.Fragment>
                                            <div className='text-center fs-5'>No Data found</div>
                                            <div className="d-flex justify-content-end gap-3 mt-4">
                                                <span
                                                    className="cancelBtn  px-2 py-2"
                                                    onClick={() => {
                                                        setSelectedProducts(null)
                                                        setSelectedFiles([])
                                                        setNoData(true)
                                                        setCalldata(false)
                                                        setFormData({
                                                            phase_id: null,
                                                            category_id: null,
                                                            sub_category_id: null,
                                                            doctype_id: null,
                                                            title: null
                                                        })
                                                    }}
                                                >
                                                    Clear
                                                </span>
                                            </div>
                                        </React.Fragment>
                                        : <>
                                            <h4> {`View ${pageFor() ? pageFor().trim().charAt(0).toUpperCase() + pageFor().trim().slice(1) : ""}s`}</h4>
                                            <div className={`${style.tableBox} mt-4`}>
                                                <div style={{ width: '100%', minWidth: '1200px' }}>
                                                    {skeletonLoader ? (
                                                        <TableSkelton
                                                            header={[
                                                                "",
                                                                "",
                                                                "Phases",
                                                                "Categories",
                                                                "Sub - Categories",
                                                                "Document Type",
                                                                "Template",
                                                                ""
                                                            ]}
                                                        />
                                                    ) : (
                                                        <DataTable value={tableData}
                                                            selectionMode={rowClick ? null : 'checkbox'}
                                                            selection={selectedProducts}
                                                            onSelectionChange={handleSelected}
                                                            dataKey="_id"
                                                            tableStyle={{ minWidth: '50rem' }}>
                                                            <Column headerStyle={{ width: '70px' }} selectionMode="multiple"  ></Column>
                                                            <Column headerStyle={{ width: '70px' }} field="code" header="S.No." body={(rowData, e) => e?.rowIndex + 1} ></Column>
                                                            <Column header={`${pageFor()?.charAt(0)?.toUpperCase() + pageFor()?.slice(1)} Title`} field='title' ></Column>
                                                            <Column field="" header="Files" body={(rowData) => Array.isArray(rowData?.files) ? rowData?.files?.length : 0} ></Column>
                                                            <Column field="doctype_details.name" header="Document Type" ></Column>
                                                            <Column field="subcategory_details.name" header="Sub-Category" ></Column>
                                                            <Column field="category_details.name" header="Category" ></Column>
                                                            <Column field="phase_details.name" header="Phases" ></Column>
                                                            <Column field="" header="Projects" body={(rowData, e) => 'test project'} ></Column>
                                                        </DataTable>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end gap-3 mt-4">
                                                <span
                                                    className="cancelBtn  px-2 py-2"
                                                    onClick={() => {
                                                        setSelectedProducts(null)
                                                        setSelectedFiles([])
                                                        setNoData(true)
                                                        setCalldata(false)
                                                        setFormData({
                                                            phase_id: null,
                                                            category_id: null,
                                                            sub_category_id: null,
                                                            doctype_id: null,
                                                            title: null
                                                        })
                                                    }}
                                                >
                                                    Clear
                                                </span>

                                                <span
                                                    className="CreateRfi px-3 py-2"
                                                    style={{
                                                        cursor: selectedFiles?.length === 0 ? "not-allowed" : "pointer",
                                                        opacity: selectedFiles?.length === 0 ? 0.5 : 1
                                                    }}
                                                    onClick={() => {
                                                        if (selectedFiles?.length !== 0) {
                                                            setOpenViewModal(true);
                                                        }
                                                    }}
                                                >
                                                    View Files
                                                </span>

                                            </div>
                                        </>}
                                </div>

                                : ''}
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}
