import React from 'react'

export const CrossIcon = (props) => {
    return (
        <svg {...props} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9998 7.06689C11.5145 7.06689 7.06738 11.5146 7.06738 16.9993C7.06738 22.485 11.5145 26.933 16.9998 26.933C22.485 26.933 26.9321 22.4853 26.9321 16.9993C26.9319 11.5145 22.485 7.06689 16.9998 7.06689ZM21.1932 19.5639L19.5722 21.1843L17.0075 18.6207L14.4268 21.202L12.8053 19.5805L15.386 16.9992L12.8058 14.4179L14.4268 12.7975L17.0075 15.3777L19.5717 12.8141L21.1932 14.4356L18.629 16.9992L21.1932 19.5639Z" fill="#A4A1A1" />
        </svg>

    )
}
