import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import React, { useState } from 'react';

const SendEmail = ({ sendEmail, open, setOpen }) => {

    let [value, setValue] = useState("");
    let [to, setTo] = useState("");
    let [cc, setCc] = useState([]);
    let [bcc, setBcc] = useState([]);

    const handleCcChange = (e) => {
        const emails = e.target.value.split(',').map(email => email.trim());
        setCc(emails);
    }

    const handleBccChange = (e) => {
        const emails = e.target.value.split(',').map(email => email.trim());
        setBcc(emails);
    }

    const payload = {
        to: to,
        cc: cc,
        bcc: bcc,
        text: value
    }

    return (
        <div>
            {true ?
                <Dialog visible={open} onHide={() => setOpen(false)}>
                    <div>
                        <div className='row mb-3'>
                            <div className='col-md-12'>
                                <label>To:</label>
                                <input type='text' className='form-control w-50' onChange={(e) => setTo(e.target.value)} />
                            </div>
                            <div className='col-md-12 mt-3'>
                                <label>CC:</label>
                                <input type='text' className='form-control w-50' onChange={handleCcChange} placeholder="CC" />
                            </div>
                            <div className='col-md-12 mt-3'>
                                <label>BCC:</label>
                                <input type='text' className='form-control w-50' onChange={handleBccChange} placeholder="BCC" />
                            </div>
                        </div>

                        <Editor style={{ minHeight: "100px" }} value={value} onTextChange={(e) => setValue(e.htmlValue)} />
                        <div className='mt-4 text-end '> <button className='btn btn-primary' onClick={() => {
                            console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
                            sendEmail(payload);
                            setOpen(false);
                        }}
                        >Submit</button></div>
                    </div>
                </Dialog>
                :
                <div>
                    <div className='row mb-3'>
                        <div className='col-md-12'>
                            <label>To:</label>
                            <input type='text' className='form-control w-50' onChange={(e) => setTo(e.target.value)} />
                        </div>
                        <div className='col-md-12 mt-3'>
                            <label>CC:</label>
                            <input type='text' className='form-control w-50' onChange={handleCcChange} placeholder="CC" />
                        </div>
                        <div className='col-md-12 mt-3'>
                            <label>BCC:</label>
                            <input type='text' className='form-control w-50' onChange={handleBccChange} placeholder="BCC" />
                        </div>
                    </div>

                    <Editor style={{ minHeight: "100px" }} value={value} onTextChange={(e) => setValue(e.htmlValue)} />
                    <div className='mt-4 text-end '> <button className='btn btn-primary' onClick={() => {
                        sendEmail(payload);
                        setOpen(false);
                    }}
                    >Submit</button></div>
                </div>

            }

        </div>
    );
}

export default SendEmail;
