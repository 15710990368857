import React, { useState } from 'react';


import TextInput from '../../../../Components/Inputs/TextInput';
import SingleSelect from '../../../../Components/Common/SingleSelect';
import { Editor } from 'primereact/editor';
import { useNavigate } from 'react-router-dom';

export const ModifyBudget = () => {

    const navigate=useNavigate()
    
    return(

        <>
<div className=" main-container">
        <div>
          <div className=" m-0">
            <div className="col-md-12 py-3">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/financialManagement/budget")}
              >
              financialManagement/budget
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> Modify Budget</span>
            </div>
            <div className="col-md-12 ">
                <span className="newRfi_text">  MODIFY BUDGET LINE ITEMS </span>
              </div>

              <div className="mt-3 row">
              <div className="col-6 mt-3">
              <label htmlFor="" className="py-2 labelCss">
              To: 
                    <span className="text-danger">*</span>
                  </label>
                <SingleSelect 
             
                placeholder={"  Enter To"}
                option={["add","add"]}
                
                />
             </div>
             <div className="col-6 mt-3">
             <label htmlFor="" className="py-2 labelCss">
             From: 
                    <span className="text-danger">*</span>
                  </label>
                <SingleSelect 
             
                placeholder={"  Enter From"}
                option={["add","add"]}
                
                />
             </div>

             <div className="col-12 mt-3">
             <label htmlFor="" className="py-2 labelCss">
             Transfer Amount: 
                    <span className="text-danger">*</span>
                  </label>
                  <TextInput placeholder={" Enter Transfer Amount"}/>
             </div>
      

             <div className="col-md-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                  Reason: 
                  </label>
                  <div className="card">
                 
                    <Editor
                      value={""}
                      onTextChange={""}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>


                <div className="btn_main my-5 ">
                <span
                  className="cancelBtn  px-2 py-2"
                  onClick={() => navigate("/financialManagement/budget")}
                >
                  {" "}
                  Cancel
                </span>

                <span className="CreateRfi px-2 py-2" >
                Create Modification
                </span>
              </div>
              </div>

          </div>
          </div>
          </div>


        </>
    )
};
