import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { MultiSelect } from "primereact/multiselect";
import { getCompanyByProjectId, getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { getProjectId } from "../../utils";

const TaskFilter = ({ filterData, setFilterData, onFilter }) => {

  const [visible, setVisible] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allCompanies, setAllCompanies] = useState([])
const project_id=getProjectId()

  function handleApplyFilter() {
    onFilter()
    setVisible(false)
  }



  useEffect(() => {
    getAllUsers()
    getAllCompany()
  }, [])

  const getAllUsers = async () => {
    try {
      let res = await getUserByProjectId(project_id)
      setAllUsers(res?.data?.data?.[0].combined_users || [])
    }
    catch (err) {
      console.log(err)
    }
  }

  const getAllCompany = async () => {
    try {
      let res = await getCompanyByProjectId(project_id)
      setAllCompanies(res?.data?.data?.[0]?.companynames || [])
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="card flex justify-content-center">
      <button
        className="filter_btn"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>
      <Dialog
        draggable={false}
        header="Filter Tasks"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row ">
          <div className="col-md-6">
            <label className="labels py-2">Responsible Person:</label>

            <div className="card flex justify-content-center  ">
              <MultiSelect
                filter={true}
                value={allUsers.filter(e => filterData?.responsiblePerson?.includes(e._id))}
                onChange={(e) => {
                  setFilterData((pre) => ({ ...pre, responsiblePerson: e.value.map(e => e._id) }))
                }}
                optionLabel='first_name'
                placeholder="Responsible Person"
                options={allUsers}

              />
            </div>

          </div>
          <div className="col-md-6">

            <label className="labels py-2">Responsible Contractor Company</label>
            <div className="card flex justify-content-center">
              <MultiSelect
                filter={true}
                value={allCompanies.filter(e => filterData?.responsibleContractor?.includes(e._id))}
                placeholder="Responsible Contractor Company"
                optionLabel='company_name'
                onChange={(e) => {
                  setFilterData((pre) => ({ ...pre, responsibleContractor: e.value.map(e => e._id) }))
                }}
                options={allCompanies}
              />
            </div>
          </div>


          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default TaskFilter;
