import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getTransmittalbyid } from "../../Api/Admin/AllAPIs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toPng } from "html-to-image";
import { HtmlIntoString } from "../../Components/Common/HtmlIntoText";
import ViewVersion from "../../Components/Common/ViewVersion";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";

export default function ViewTransmittals({
  usedAsComponent,
  includeVersionHistory,
}) {
  const pdfRef = useRef(null); // Initialize ref with null

  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents",
        files: transMittals?.attachments?.map((e) => e.url),
      },
    ]);
  }

  const navigate = useNavigate();
  const [transMittals, setTransmittals] = useState({});
  const { id } = useParams();
  const [attachedData, setAttachedData] = useState({
    drawing: [],
    files: [],
  });

  const getTransMitalByIdData = async () => {
    try {
      const res = await getTransmittalbyid(id);
      console.log(res);
      setTransmittals(res.data.data);
      setAttachedData({
        drawing: [],
        files: [
          ...res?.data?.data?.attachFile.map((e) => e.url),
          ...res?.data?.data?.documentList
            ?.map((e) => e.attachFile.map((d) => d.fileUrl))
            .flat(Infinity),
        ],
      });

      console.log(
        [
          ...res?.data?.data?.attachFile.map((e) => e.url),
          ...res?.data?.data?.documentList
            ?.map((e) => e.attachFile.map((d) => d.fileUrl))
            .flat(Infinity),
        ],
        ";;;;;;;"
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTransMitalByIdData();
  }, []);

//   const downloadPdf2 = () => {


// let urlLinks = [
//   ...transMittals?.attachFile?.map((e) => e.fileUrl) || [],
//   ...transMittals?.documentList
//     ?.map((e) => e.attachFile.map((d) => d.fileUrl))
//     .flat(Infinity) || [],
// ];

// downloadPdf(pdfRef.current, [
//   {
//     heading: "Attached Documents",
//     files: urlLinks,
//   },
// ], "Transmittal");
// return
 
//   };


const downloadPdf2 = () => {
  let urlLinks = [
    ...transMittals?.attachFile?.map((e) => e.url).filter(Boolean) || [],
    ...transMittals?.documentList
      ?.map((e) => e.attachFile.map((d) => d.fileUrl).filter(Boolean))
      .flat(Infinity) || [],
  ];

  // if (urlLinks.length === 0) {
  //   console.error("No valid URLs found to include in the PDF.");
  //   return;
  // }

  try {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents",
        files: urlLinks,
      },
    ], "Transmittal");
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

  return (
    <>
      <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
      {!usedAsComponent && 
        <div className="w-100">
          <span
            className="open_Rfi_text"
            onClick={() => navigate("/projectManagement/transmittals")}
          >
            Transmittals
          </span>
          <span className="px-1 open_Rfi_text ">/</span>
          <span className="createNew_text">View Transmittal</span>
        </div>}
        {/* <div style={{ width: "120px" }}>
          <EmailTemplateWithPdf sendEmail={okok} isDialog={true} />
        </div> */}
      </div>
      <div className="rfibody d-flex flex-column gap-4">
        <div className="d-flex justify-content-between">
          {/* <h1 className="headdingRFI" style={{textTransform:"capitalize"}}> {transMittals?.Title}</h1> */}
          <div className="newRfi_text">
                  Transmittal
                </div>
          <div className="d-flex align-items-center gap-2">
          {!usedAsComponent &&
            <div
              className="d-flex gap-2 align-items-center"
              style={{ cursor: "pointer" }}
              onClick={downloadPdf2}
            >
              <AiOutlineExport />
              Export
              <MdOutlineKeyboardArrowDown />
            </div>}
            <div className="d-flex flex-column gap-3"></div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <h5 className="mb-3">Sender Detail</h5>
            <ViewPageTable
              tableData={[
                {
                  "Sender Name":
                    transMittals?.senderData
                      ?.map((e) => e?.first_name)
                      ?.join(", ") || "--",
                },
                {
                  //
                  "Sendor contractor company":
                    transMittals?.companySenderData?.[0]?.company_name || "---",
                },
                {
                  "Sender Phone":
                    transMittals?.senderData
                      ?.map((e) => e?.cell_phone)
                      ?.join(", ") || "--",
                },
                {
                  "Sender Email":
                    transMittals?.senderData
                      ?.map((e) => e?.email_address)
                      ?.join(", ") || "--",
                },
                {
                  "Sender Address":
                    transMittals?.senderData
                      ?.map((e) => e?.address)
                      ?.join(", ") || "--",
                },
              ]}
            />
          </div>
          <div className="col-6">
            <h5 className="mb-3">Receiver Detail</h5>

            <ViewPageTable
              tableData={[
                {
                  "Reciever Name":
                    transMittals?.recipientData
                      ?.map((e) => e?.first_name)
                      ?.join(", ") || "--",
                },
                {
                  "Recipient’s contractor company":
                    transMittals?.companyRecipientData?.[0]?.company_name ||
                    "---",
                },
                {
                  "Reciever Phone":
                    transMittals?.recipientData
                      ?.map((e) => e?.cell_phone)
                      ?.join(", ") || "--",
                },
                {
                  "Reciever Email":
                    transMittals?.recipientData
                      ?.map((e) => e?.email_address)
                      ?.join(", ") || "--",
                },
                {
                  "Reciever Address":
                    transMittals?.recipientData
                      ?.map((e) => e?.address)
                      ?.join(", ") || "--",
                },
              ]}
            />
          </div>
        </div>

        <div className="row mt-3">
          <h5>Other Details</h5>
          <div className="col-6">
            <ViewPageTable
              tableData={[
                { "Transmittals Number": transMittals?.ID },
                {
                  "Recipients Acknowledgment": moment(
                    transMittals?.recipientAcknowledgmentDate
                  ).format("DD-MM-YYYY"),
                },
                {
                  Reviewer:<div style={{textTransform:"capitalize"}}>{
                    transMittals?.reviewer
                      ?.map((e) => e?.first_name)
                      ?.join(", ") || "--"}</div>,
                },
                // {
                //   "Created By": "--",
                // },
                {
                  "Issue Date": moment(transMittals?.created_at).format(
                    "DD-MM-YYYY"
                  ),
                },
              ]}
            />
          </div>
          <div className="col-6">
            <ViewPageTable
              tableData={[
                {
                  "Workflow Status": transMittals?.status_data,
                },
                {
                  Approver:<div style={{textTransform:"capitalize"}}>{
                    transMittals?.approver
                      ?.map((e) => e?.first_name)
                      ?.join(", ") || "--"}</div>,
                },
                { "Subject": <div style={{textTransform:"capitalize"}}>{transMittals?.Title}</div> },
                {
                  "Hours Spent": 
                  // data?.hoursSpent || "--"
                  `${transMittals?.hoursSpent?.split("-")[0]} Hr ${transMittals?.hoursSpent?.split("-")[1]} Min` 
                  
                  ,
                },
                { "Approver Comment": <div style={{textTransform:"capitalize"}}>{transMittals?.approver_comment || "--"}</div> },
                { "Reviewer Comment": <div style={{textTransform:"capitalize"}}>{transMittals?.reviewer_comment || "--"}</div> }
              ]}
            />
          </div>
        </div>
        <div className="mt-4">
          <h5>Recipients Action:</h5>
        </div>
        <div style={{textTransform:"capitalize"}}>{transMittals?.recipientAction}</div>
        <div className="mt-4">
          <h5>Purpose of Transmittals:</h5>
        </div>
        <div>{HtmlIntoString({ html: transMittals?.question })}</div>
        <div className="mt-4">
          <h5>Attached Document:</h5>
        </div>
        <div>
          {/* {transMittals?.attachFile?.map((res) => {
            return <>{res?.fileName}</>;
          })} */}
          {transMittals?.attachFile?.map((file, index) => (
            <ViewPageDocumentName
              docUrl={file?.url}
              key={index}
              name={file?.fileName}
            />
          ))}
        </div>
        <div className="mt-5">
          <BorderedTable>
            <DataTable value={transMittals?.documentList}>
              <Column
                header="Document Number"
                style={{ minWidth: "4rem" }}
                body={(rowData, meta) => (
                  <div className="d-flex justify-content-center">
                    {rowData?.documentNumber}
                  </div>
                )}
              />
              <Column
                header="Document Title"
                body={(rowData, meta) => (
                  <div className="d-flex justify-content-center">
                    {rowData?.documentTitle}
                  </div>
                )}
                style={{ minWidth: "4rem" }}
              />
              <Column
                header="Document version"
                body={(rowData, meta) => (
                  <div className="d-flex justify-content-center">
                    {rowData?.documentVersion}
                  </div>
                )}
                style={{ minWidth: "4rem" }}
              />
              <Column
                header="Remark"
                body={(rowData, meta) => (
                  <div className="d-flex justify-content-center">
                    {rowData?.remarks}
                  </div>
                )}
                style={{ minWidth: "4rem" }}
              />
              <Column
                header="Attachment"
                body={(rowData, meta) => (
                  <div>
                    {/* {rowData?.attachFile?.map((res) => {
                      return <>{res?.fileName}</>;
                    })} */}
                    {rowData?.attachFile?.map((file, index) => (
                      <ViewPageDocumentName
                        docUrl={file?.url}
                        key={index}
                        name={file?.fileName}
                      />
                    ))}
                  </div>
                )}
                style={{ minWidth: "4rem" }}
              />
            </DataTable>
          </BorderedTable>
        </div>

        {(!usedAsComponent || includeVersionHistory) && (
          <>
            <hr />
            <ViewVersion isView={true} />
            <br />
            <br />
          </>
        )}

        <div style={{ height: "0px", overflow: "hidden" }}>
          <div ref={pdfRef} style={{width:"2000px"}}>
            <div className="card p-5">
            {/* <h1 className="headdingRFI" style={{textTransform:"capitalize"}}> {transMittals?.Title}</h1> */}
            <div className="newRfi_text">
                  Transmittal
                </div>
              <div className="row mt-3">
                <div className="col-6">
                  <h5 className="mb-3">Sender Detail</h5>
                  <ViewPageTable
                    tableData={[
                      {
                        "Sender Name":
                          transMittals?.senderData
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      {
                        //
                        "Sendor contractor company":
                          transMittals?.companySenderData?.[0]?.company_name ||
                          "---",
                      },
                      {
                        "Sender Phone":
                          transMittals?.senderData
                            ?.map((e) => e?.cell_phone)
                            ?.join(", ") || "--",
                      },
                      {
                        "Sender Email":
                          transMittals?.senderData
                            ?.map((e) => e?.email_address)
                            ?.join(", ") || "--",
                      },
                      {
                        "Sender Address":
                          transMittals?.senderData
                            ?.map((e) => e?.address)
                            ?.join(", ") || "--",
                      },
                    ]}
                  />
                </div>
                <div className="col-6">
                  <h5 className="mb-3">Receiver Detail</h5>

                  <ViewPageTable
                    tableData={[
                      {
                        "Reciever Name":
                          transMittals?.recipientData
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      {
                        "Recipient’s contractor company":
                          transMittals?.companyRecipientData?.[0]
                            ?.company_name || "---",
                      },
                      {
                        "Reciever Phone":
                          transMittals?.recipientData
                            ?.map((e) => e?.cell_phone)
                            ?.join(", ") || "--",
                      },
                      {
                        "Reciever Email":
                          transMittals?.recipientData
                            ?.map((e) => e?.email_address)
                            ?.join(", ") || "--",
                      },
                      {
                        "Reciever Address":
                          transMittals?.recipientData
                            ?.map((e) => e?.address)
                            ?.join(", ") || "--",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <h5>Other Details</h5>
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      { "Transmittals Number": transMittals?.ID },
                      {
                        "Recipients Acknowledgment": moment(
                          transMittals?.recipientAcknowledgmentDate
                        ).format("DD-MM-YYYY"),
                      },
                      {
                        Reviewer:<div style={{textTransform:"capitalize"}}>{
                          transMittals?.reviewer
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--"}</div>,
                      },
                      // {
                      //   "Created By": "--",
                      // },
                      {
                        "Issue Date": moment(transMittals?.created_at).format(
                          "DD-MM-YYYY"
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      {
                        "Workflow Status": transMittals?.status_data,
                      },
                      {
                        Approver:<div style={{textTransform:"capitalize"}}>{
                          transMittals?.approver
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--"}</div>,
                      },
                      { "Subject": <div style={{textTransform:"capitalize"}}>{transMittals?.Title}</div> },
                      {
                        "Hours Spent": 
                        // data?.hoursSpent || "--"
                        `${transMittals?.hoursSpent?.split("-")[0]} Hr ${transMittals?.hoursSpent?.split("-")[1]} Min` 
                        
                        ,
                      },
                      { "Approver Comment": <div style={{textTransform:"capitalize"}}>{transMittals?.approver_comment || "--"}</div> },
                      { "Reviewer Comment": <div style={{textTransform:"capitalize"}}>{transMittals?.reviewer_comment || "--"}</div> }
                    ]}
                  />
                </div>
              </div>
              <div className="mt-4">
                <h5>Recipients Action:</h5>
              </div>
              <div style={{textTransform:"capitalize"}}>{transMittals?.recipientAction}</div>
              <div className="mt-4">
                <h5>Purpose of Transmittals:</h5>
              </div>
              <div>{HtmlIntoString({ html: transMittals?.question })}</div>
              <div className="mt-4">
          <h5>Attached Document:</h5>
        </div>
        <div>
          
          {transMittals?.attachFile?.map((file, index) => (
            <ViewPageDocumentName
              docUrl={file?.url}
              key={index}
              name={file?.fileName}
            />
          ))}
        </div>
              <div className="mt-5 ">
                <BorderedTable>
                  <DataTable value={transMittals?.documentList}>
                    <Column
                      header="Document Number"
                      body={(rowData, meta) => (
                        <div className="">
                          {rowData?.documentNumber}
                        </div>
                      )}
                    />
                    <Column
                      header="Document Title"
                      body={(rowData, meta) => (
                        <div className="">
                          {rowData?.documentTitle}
                        </div>
                      )}
                    />
                    <Column
                      header="Document version"
                      body={(rowData, meta) => (
                        <div className="">
                          {rowData?.documentVersion}
                        </div>
                      )}
                    />
                    <Column
                      header="Remark"
                      body={(rowData, meta) => (
                        <div className="">
                          {rowData?.remarks}
                        </div>
                      )}
                    />
                   <Column
                header="Attachment"
                body={(rowData, meta) => (
                  <div>
                    {/* {rowData?.attachFile?.map((res) => {
                      return <>{res?.fileName}</>;
                    })} */}
                    {rowData?.attachFile?.map((file, index) => (
                      <ViewPageDocumentName
                        docUrl={file?.url}
                        key={index}
                        name={file?.fileName}
                      />
                    ))}
                  </div>
                )}
                style={{ minWidth: "4rem" }}
              />
                  </DataTable>
                </BorderedTable>
              </div>
              {(!usedAsComponent || includeVersionHistory) && (
                <>
                  <hr />
                  <ViewVersion isView={true} />
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!usedAsComponent &&
      <div className="d-flex justify-content-end ">
        <button
          className="btn border"
          onClick={() => navigate("/projectManagement/transmittals")}
        >
          Back
        </button>
      </div>}
      <br />
    </>
  );
}
