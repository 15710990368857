import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import TextInput from '../../Components/Inputs/TextInput';
import { InputNumber } from 'primereact/inputnumber';
import SingleSelect from '../../Components/Common/SingleSelect';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';



function AddUser({ visible2, setVisible2 }) {

    const data=[
        {
            option:'Yes'
        },
        {
            option:'No'
        }
    ]

    const navigate = useNavigate();

    return (
        <>
            <Dialog header="Add User" draggable={false} visible={visible2} style={{ width: '45vw' }} onHide={() => setVisible2(false)}>

{/* <div>heegegjehr</div> */}
            <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Name:
                        </label>

                        <TextInput placeholder="Name"

                        > </TextInput>
                    </div>
                </div>
                <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Last Name: 
                        </label>

                        <TextInput placeholder="Last Name"

                        > </TextInput>
                    </div>
                </div>
                <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Email:
                        </label>

                        <TextInput placeholder="Email"

                        > </TextInput>
                    </div>
                </div>
                <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Project Permission Template:
                        </label>

                      <SingleSelect placeholder="Project Permission Template" 
                       options={[]}
               />
                    </div>
                </div>
                <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Company Permission Template:
                        </label>

                      <SingleSelect placeholder="Company Permission Template"
                       options={[]} 
               />
                    </div>
                </div>
                <div className="row mt-3  ">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Is Employee of Procore Certification:
                        </label>

                      <SingleSelect placeholder="Is Employee of Procore Certification" 
                      options={data.map((item)=>(
                        {
                            label:item.option,
                            value:item.option
                        }
                      ))}
               />
                    </div>
                </div>

                <div className="end mt-5">
                    <button
                        className="btn border me-3"
                        onClick={() => setVisible2(false)}
                    >
                        Clear
                    </button>
                    <PrimaryButton text={"Create"} onClick={() => navigate("/addUserInfo")} />
                </div>

            </Dialog>
        </>
    )
}

export default AddUser