import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css'; // import theme CSS
import 'primereact/resources/primereact.min.css'; // core CSS
import 'primeicons/primeicons.css'; // icons
import TextInput from '../../../../Components/Inputs/TextInput';

export const AddSnapshots = (props) => {
    const { visible, setVisible } = props;

    const footer = (
        <div className='mt-2'>
            <Button label="Cancel" onClick={() => setVisible(false)} className="filter_btn" />
            <Button label="Save Snapshot" onClick={() => setVisible(false)} className='filter_btn_apply' />
        </div>
    );



    return (
        <>
          <div className='budgetDialog'> 
          
          <Dialog
                header="Budget Snapshot"
                headerClassName='CustomDialog_header'
                visible={visible}
                style={{ width: '40rem'}} 
                footer={footer}
                onHide={() => setVisible(false)}
            >
                <div className='container'>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            Budget snapshot has been captured. Enter the details below to save the snapshot.
                        </div>
                        <div className='col-12 mt-4'>
                            <label htmlFor="" className="py-2 labelCss">Snapshot Name:</label>
                            <TextInput placeholder={" Enter Snapshot Name"} />
                        </div>
                        <div className='col-12 mt-2'>
                            <label htmlFor="" className="py-2 labelCss">Description:</label>
                            <TextInput placeholder={" Enter Description"} />
                        </div>
                    </div>
                </div>
            </Dialog>
          
          </div>
        </>
    );
};
