import { toJpeg, toPng } from "html-to-image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getProjectData, getProjectId, getProjectName } from "../../../utils";

export async function downloadPdf(content, fileLinks, pdfName, noDownload) {
    try {
        // const dataUrl = await toJpeg(content, { quality: 0.3 });
        let dataUrl = await html2canvas(content, { scale: 0.7 })

        const pdf = new jsPDF('p', 'mm', 'a4');

        const imgData = 'https://ott-fast-data.s3.ap-southeast-1.amazonaws.com/Group+(1).png';
        const imgW = 20;  // Width of the image in mm
        const imgH = 10;   // Height of the image in mm

        // Add the image to the PDF at a specific position
        const imageX = 5;  // X coordinate for image
        const imageY = 5;  // Y coordinate for image

        pdf.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);

        // Get the project name
        const projectName = `${getProjectName()} (Project Id: ${getProjectData()?.project_code || "-"})`;
        const textX = imageX + imgW + 6;  // X coordinate for text (image width + margin)
        const textY = imageY + 7.5;         // Y coordinate for text (adjust if necessary)

        // Add project name text to the PDF
        pdf.setFontSize(16);
        pdf.text(projectName, textX, textY);




        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        let imgWidth = pdfWidth;
        let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        let startY = 18;
        let availableHeight = pageHeight - startY;

        // Check if the image height exceeds the available height on the current page
        if (imgHeight > availableHeight) {
            // Scale the image to fit the available height
            imgHeight = availableHeight;
            imgWidth = (imgProps.width * imgHeight) / imgProps.height;
        }


        pdf.addImage(dataUrl, 'PNG', 0, startY, imgWidth, imgHeight, undefined, 'FAST');
        startY = imgHeight + 20;


        const addLinksWithHeading = (heading, links) => {
            pdf.setFontSize(14);
            pdf.setTextColor(71, 83, 103);
            pdf.text(heading, 10, (startY));
            startY += 10;

            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 255);
            pdf.setFont("helvetica", "normal");

            links.forEach((link) => {
                const text = link;
                const textX = 10;
                const textWidth = pdf.getTextWidth(text);
                if (textWidth > pdfWidth - 20) {
                    const splittedText = pdf.splitTextToSize(text, pdfWidth - 20);
                    let textY = (startY);
                    splittedText.forEach(line => {
                        pdf.text(line, textX, textY);
                        textY += 7;
                    });
                    startY = textY;
                } else {
                    pdf.text(text, textX, startY);
                    startY += 10;
                }
                const linkHeight = 10;
                pdf.link(textX, startY - linkHeight, textWidth, linkHeight, { url: link });
                startY += 5;
            });
            startY += 5;
        };

        fileLinks.forEach((res) => {
            if (res?.files?.length) {
                addLinksWithHeading(res?.heading, res.files);
            }
        });

        if (noDownload) {
            const pdfBlob = pdf.output('blob');
            return pdfBlob;
        } else {
            pdf.save(pdfName || 'download.pdf');
            return null;
        }
    } catch (err) {
        console.error('Error generating PDF', err);
        // alert('Error generating PDF');
        return err;
    }
}


