import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const CustomDialog = ({ visible,setVisible, title, content, onConfirm, label ,onSubmit,lock}) => {
    return (
        <Dialog header={title}  headerClassName='CustomDialog_header'  visible={visible} style={{ width: '40rem' }}  draggable={false}      onHide={() => setVisible(false)} modal>
            <div className="dialog-content px-2 mt-2 font-weight-normal">
                {content}
            </div>
            <div className="dialog-footer mt-4 d-flex justify-content-end ">
                <Button label="Cancel"     onClick={() => {
                  setVisible(false);
                }} className="filter_btn" />
                 <div className='d-flex gap-3' >
     
                 {lock ?           <Button label={label}  className='filter_btn_apply' onClick={onSubmit} autoFocus /> :  <>
                    <Button label={"Delete & Unlock Budget"}  className='filter_btn_apply' onClick={onSubmit} autoFocus />
                 <Button label={"Preserve & Unlock Budget"}  className='filter_btn_apply' onClick={onSubmit} autoFocus /> 
                 </>}
                                 </div>
            </div>
        </Dialog>
    );
};

export default CustomDialog;
