import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { CiCalendar } from "react-icons/ci";

export default function Calender({ label, nobuttonBar, ...rest }) {
  const [visible, setVisible] = useState(false);
  const CalendarTag = () => {
    if (nobuttonBar) {
      return (
        <Calendar
          // id="buttondisplay"

          {...rest}
          readOnlyInput
          showIcon
          todayButtonClassName="calendarTodayButton"
          dateFormat="dd/mm/yy"
        />
      );
    } else {
      return (
        <Calendar
          // id="buttondisplay"
          showButtonBar
          {...rest}
          readOnlyInput
          showIcon
          todayButtonClassName="calendarTodayButton"
          dateFormat="dd/mm/yy"
        />
      );
    }
  };
  return (
    <>
      <div className="calender_container flex flex-column flex-auto py-0 position-relative">
        <label>{label}</label>
        {CalendarTag()}
        {/* <span
          className="position-absolute"
          style={{ right: "0.8rem", bottom: "0.8rem", fontSize: "1.3rem" }}
          onClick={() => setVisible(!visible)}
        > */}
        {/* <CiCalendar /> */}
        {/* </span> */}
      </div>
    </>
  );
}

{
  /* <Calendar
className="w-100"
key={key}
value={value} // Use value if provided, otherwise use currentDateTime
onChange={handleDateTimeChange}
inputId={forTime ? "time" : "date"} // Set different input IDs for date and time fields
showIcon
{...rest}
showTime={forTime} // Show time picker if forTime is true
timeOnly={forTime}
/> */
}
