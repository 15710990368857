import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextInput from "../../../../../Components/Inputs/TextInput";
import { Editor } from "primereact/editor";
import { DragAndDrop } from "../../../../../Components/Inputs/DragAndDrop";
import Calender from "../../../../../Components/Common/Calender";
import { PrimaryButton } from "../../../../../Components/Buttons/PrimaryButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popover from "../../../../../Components/Common/Popover";

const AddSubContractor = () => {
  
  const fields = [
    {
      CostCode: "01-023 Project Executive",
      Description: "Project Executive Salary",
      Tax_Code: "GST",
      Amount: "$ 180,000.00",
      Billed_to_Date: "$ 0.00",
      Amount_Remaining: "$ 180,000.00",
    },
    {
      CostCode: "01-023 Project Executive",
      Description: "Project Executive Salary",
      Tax_Code: "GST",
      Amount: "$ 180,000.00",
      Billed_to_Date: "$ 0.00",
      Amount_Remaining: "$ 180,000.00",
    },
  ];
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: { ...formData }
  });
  const { field, append } = useFieldArray({
    control,
    name: "cost_line_item",
  });
  return (
    <div>
      <div className="page_heading">CREATE SUBCONTRACT</div>

      <h5 className="mt-3">General Information</h5>
      <div className="row">
        <div className="col-md-12">
          <label className="py-3 labelCss">
            Title: <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_cost_name"
            // rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  // className={errors?.direct_cost_name && "Validation"}
                  // value={value}
                  // onChange={onChange}
                  placeholder={"Name"}
                />
              );
            }}
          />
        </div>
        <div className="col-md-6">
          <label className="py-3 labelCss">
            Contract Company: <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_cost_name"
            // rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  // className={errors?.direct_cost_name && "Validation"}
                  // value={value}
                  // onChange={onChange}
                  placeholder={"Name"}
                />
              );
            }}
          />
        </div>
        <div className="col-md-6">
          <label className="py-3 labelCss">
            Default Retainage : <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_cost_name"
            // rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  // className={errors?.direct_cost_name && "Validation"}
                  // value={value}
                  // onChange={onChange}
                  placeholder={"Name"}
                />
              );
            }}
          />
        </div>
        <div className="col-md-6">
          <label className="py-3 labelCss">
          Status: <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_cost_name"
            // rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  // className={errors?.direct_cost_name && "Validation"}
                  // value={value}
                  // onChange={onChange}
                  placeholder={"Name"}
                />
              );
            }}
          />
        </div>
        <div className="col-md-6">
          <label className="py-3 labelCss">
          Execution Status: <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_cost_name"
            // rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  // className={errors?.direct_cost_name && "Validation"}
                  // value={value}
                  // onChange={onChange}
                  placeholder={"Name"}
                />
              );
            }}
          />
        </div>
   
      
     
      
        <div className="col-12 mt-3">
          <label className="py-2 labelCss">
            Description: <span className="text-danger">*</span>
          </label>
          <Controller
            name="direct_description"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Editor
                  style={{ minHeight: "100px" }}
                  value={value}
                  // onTextChange={(e) => onChange(e.htmlValue)}
                />
              );
            }}
          />
        </div>
        <div className="col-md-12 mt-3">
              <DragAndDrop
              // setRemovePhoto={setRemovePhoto}
              // value={fileName}
              // setFiles={setFileUpload}
              // onChange={handleFileSubmit}
              // setValue={setFileName}
              // setPreviousFiles={setPreviousFile}
              />
            </div>
           <div className="col-md-12 mt-3">
           <hr />
           </div>
            <h5 className="mt-3">Contract Dates</h5>
            <div className="col-md-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Contract Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Start Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Delivery Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Start Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Signed Purchase Order Recieve Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Start Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Issue On Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Start Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
          <div className="col-md-12 mt-3">
           <hr />
           </div>
           <h5 className="mt-3">Schedule Of Value</h5>
   
        <DataTable value={fields} className="mb-3">
          <Column
            header="#"
            body={(rowData, rowIndex) => (
              <div>
                {rowIndex.rowIndex < 9 ? "0" : ""}
                {rowIndex.rowIndex + 1}
              </div>
            )}
          />
          <Column header="Cost Code" field="CostCode" />
          <Column header="Description" field="Description" />
          <Column header="Tax Code" field="Tax_Code" />
          <Column header="Amount" field="Amount" />
          <Column header="Billed to Date" field="Billed_to_Date" />
          <Column header="Amount Remaining" field="Amount_Remaining" />
          <Column
            header=""
            field=""
            body={(rowData, meta) => (
              <div className="flex justify-content-center">
                <Popover
                  options={["Edit", "delete"]}
                  onSelect={(index, event) => {
                    if (index === 0)
                      // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                      console.log(index);
                  }}
                />
              </div>
            )}
          />
        </DataTable>
        <br />
        {/* <AddSovItem onSubmit={append} /> */}
      </div>
      <div className="end mt-5 gap-2 mb-3">
        <button
          className="btn border"
          // onClick={() => setVisible(false)}
          type="button"
        >
          Cancel
        </button>
        <PrimaryButton  type="submit" text={"Create Purchase Order"} />
  
        
      </div>
    </div>
  );
};

export default AddSubContractor;
