import React, { useState } from "react";
import "./ProjectDirectory.css";
import { BorderedTable } from "../../../Tables/BorderedTable";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import TreeSelectComp from "../../../Common/TreeSelectComp";
import SingleSelect from "../../../Common/SingleSelect";
import profile from "../../../../assets/profile.png";
import { IoMdTrash } from "react-icons/io";
import {
  getProjectDirectory,
  addAssignPersonCompany,
  getProjectAssignData,
  getallrole,
} from "../../../../Api/Admin/AllAPIs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableSkelton } from "../../../Common/TableSkelton";
import SevedModal from "../../../Common/SavedModal";
import { MultiSelect } from "primereact/multiselect";
import { TreeTable } from "primereact/treetable";
import { getProjectCode, getProjectName, getUserName, toast } from "../../../../utils";


export default function ProjectDirectory({ mode }) {
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userName = userObj?.name;
  const [isVersionHistory, setIsVersionHistory] = useState(true);
  const [visible, setVisible] = useState(false)
  const [skeletonLoader, setSkeletonLoader] = useState(
    mode == "Edit" ? true : false
  );
  const [btnLoader, setBtnLoader] = useState(false)
  const navigate = useNavigate();
  const [contractors, setContractors] = useState([])
  const [subContractors, setSubContractors] = useState([])
  const [employees, setEmployees] = useState([])
  const [selectedContractor, setSelectedContractor] = useState([]);
  const [selectedSubContractor, setSelectedSubContractor] = useState([]);
  const [selectEmployees, setSelectEmployees] = useState([])
  const [saveVisible, setSavedVisible] = useState(false)
  const [saveVisible2, setSavedVisible2] = useState(false)
  const [cancelVisible, setCancelVisible] = useState(false)
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [allRole, setRoleAll] = useState([]);
  const [allUsers, setAllUsers] = useState([])
  const [prevData, setPrevData] = useState([])
  const domain = window.location.origin;


  const [allPreviousUsers, setAllPreviousUsers] = useState([])
  const [allDBUsers, setAllDBUsers] = useState([])

  const getAllRoleData = async () => {
    try {
      const res = await getallrole();
      const response = res.data.data;
      let data = response.map((e) => {
        return {
          label: e?.role,
          value: e?._id
        }
      })
      // console.log(data);
      setRoleAll(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllRoleData()
  }, []);

  function formator(data) {
    let dd = [];

    Object.keys(data).forEach(key => {
      if (key.includes('-company')) {
        let companyId = key.split('-')[0];
        let companyObj = {
          company: companyId,
          persons: []
        };

        Object.keys(data).forEach(subKey => {
          if (subKey.startsWith(companyId) && subKey.includes('-person')) {
            let personId = subKey.split('-')[1];
            companyObj.persons.push(personId);
          }
        });

        dd.push(companyObj);
      }
    });
    return dd
  }


  const handleChange = (e, type) => {
    if (type == 'contractor') {
      setSelectedContractor(e.value);
      let contractorIds = formator(e.value)
      let arr = contractorIds?.map(res => {
        const companyData = contractors?.find((ss) => ss.key?.includes(res?.company))
        const userData = companyData?.children?.filter((person) => res?.persons?.includes(person?.value))
        return {
          ...companyData,
          company: companyData?.label,
          children: userData
        }
      })
      setTableData1(arr)
    }
    else if (type == 'subcontractor') {
      setSelectedSubContractor(e.value);
      let contractorIds = formator(e.value)
      let arr = contractorIds?.map(res => {
        const companyData = subContractors?.find((ss) => ss.key?.includes(res?.company))
        const userData = companyData?.children?.filter((person) => res?.persons?.includes(person?.value));
        return {
          ...companyData,
          company: companyData?.label,
          children: userData
        }
      })
      setTableData2(arr)
    }
    else if (type == 'employees') {
      let arr = e?.value
      setSelectEmployees(arr)
      let filterUsers = employees?.filter(res => arr.includes(res.value)) || []

      let object = {
        key: 'internalEmployees-company',
        label: 'Internal Employees',
        company_type: 'internalEmployees',
        companyId: 'internalEmployees',
        company: 'Internal Employees',
        children: filterUsers
      }
      setTableData3([object])

    }

  };
  const addProjectAssign = async (btnName) => {
    // if (validation()) {
    let data = [...tableData]
    let payload = ''
    let projectId = localStorage.getItem("projectId")
    let uu = [...allUsers]
    let emailObj = []
    data?.forEach(res => {
      res?.children?.forEach(user => {
        if (!prevData?.includes(user?.value)) {
          let roleName = allRole?.find(item => item.value == user?.project_role)
          let userInfo = uu?.find(item => item?._id == user?.value)
          // console.log(userInfo)
          emailObj?.push({
            currentUser: userInfo?.first_name,
            to: userInfo?.email_address || '',
            project_role: roleName,
            created_by: userName,
            template_type: ''
          })
          // users?.push(user?.value)
        }
      })
    })

    // console.log(emailObj)


    // let mailUsers = uu?.filter(res => users?.includes(res?._id))
    // console.log(mailUsers)
    // return
    try {
      payload = transformData(data, projectId)
    } catch (err) {
      toast.error(err?.message)
      return
    }

    try {

      let companyUsers = [
        ...payload.assigned_to?.flatMap(e => e?.person || [])?.filter(d => d) || [],
        ...payload.individual_users
      ]

      let selectedUsers = companyUsers.filter(d => !allPreviousUsers?.some(e => e._id == d.user))
      let email_data = selectedUsers.map((res) => {
        let user = allDBUsers?.find(d => d._id == res.user) || {}
        return ({
          name: user?.first_name || "",
          email: user?.email_address || "",
          role: allRole?.find(d => d?.value == res?.project_role)?.label || "",
          url: domain
        })
      })
      setBtnLoader(true)
      await addAssignPersonCompany({ ...payload, email_data, url: domain }, projectId);
      setSavedVisible(false)
      setSavedVisible2(false)
      setBtnLoader(false)

      if (btnName !== "save") {
        navigate(
          mode === "Edit"
            ? "/coretools/admin/projectform/step-3"
            : "/portfolio/projectform/step-3"
        );
      } else {
        // navigate("/projects")
      }

      setSavedVisible(false)

    }
    catch (err) {
      console.log(err)
    }

    // }
  };


  function transformData(data, projectId) {

    let transformedData = {
      assigned_to: [],
      individual_users: [],
      isVersionHistory: isVersionHistory,
      user_name: userName,
      project_id: projectId
    };
    data?.forEach(item => {
      if (item.company_type === "contractor" || item.company_type === "subcontractor") {
        let companyObject = {
          company: item.companyId,
          company_type: item.company_type,
          person: []
        };

        item.children.forEach(child => {
          if (child?.project_role === null) {
            throw new Error(`Project Role not selected for user ${child?.label} in ${item?.company}`);
            return
          }

          let personObject = {
            user: child?.value,
            permission_template: child?.permission_template,
            project_role: child?.project_role
          };
          companyObject.person.push(personObject);
        });

        transformedData.assigned_to.push(companyObject);
      } else if (item.company_type === "internalEmployees") {
        item?.children?.forEach(child => {
          if (child?.project_role === null) {
            throw new Error(`Error: Project Role not selected for user ${child?.label} in ${item?.company}`);
            return
          }

          let individualUserObject = {
            user: child?.value,
            project_role: child?.project_role
          };
          transformedData.individual_users.push(individualUserObject);
        });
      }
    });

    return transformedData;
  }







  const [roleErr, setRoleErr] = useState([]);


  const fetchProjectData = async () => {
    try {
      let allPerson = []
      setSkeletonLoader(true);
      const res = await getProjectDirectory();
      const response = res.data.data;

      // this variable is only user for store all database users (contractor users, subcontractor users and individual users)
      let DBUser = []
      let newUserArr = response?.users?.map(user => {
        allPerson.push(user)
        DBUser.push(user)
        return {
          key: 'internalEmployees' + "-" + user._id + "-" + "person",
          label: user?.first_name + " " + user?.last_name,
          project_role: null,
          permission_template: '',
          value: user?._id,
          person: user?.first_name + " " + user?.last_name,
          company_type: 'internalEmployees',
        };
      })
      setEmployees(newUserArr)

      let contractors = []
      let subcontractors = []
      const projectData = [
        ...response.contractor,
        ...response.subcontractor,
      ];

      projectData?.forEach((company) => {
        DBUser.push(company.person)
        if (company?.company_type == "contractor") {

          contractors.push({
            key: company?._id + "-" + "company",
            label: company?.company_name,
            companyId: company?._id,
            company_type: company?.company_type,
            children: company?.person?.map((user) => {
              allPerson.push(user)
              // console.log(user)
              return {
                key: company?._id + "-" + user._id + "-" + "person",
                label: user?.first_name + " " + user?.last_name,
                project_role: null,
                permission_template: '',
                value: user?._id,
                person: user?.first_name + " " + user?.last_name,
                company_type: company?.company_type,
              };
            }),
          })
        } else {
          subcontractors.push({
            key: company?._id + "-" + "company",
            label: company?.company_name,
            companyId: company?._id,
            company_type: company?.company_type,
            children: company?.person?.map((user) => {
              allPerson.push(user)
              return {
                key: company?._id + "-" + user?._id + "-" + "person",
                label: user?.first_name + " " + user?.last_name,
                value: user?._id,
                project_role: null,
                permission_template: '',
                person: user?.first_name + " " + user?.last_name,
                company_type: company?.company_type,
              };
            }),
          })
        }
      });
      // allDBUsers used for fetch all selected users details for email data 
      setAllDBUsers(DBUser.flat(Infinity))

      setAllUsers(allPerson)
      setContractors(contractors?.filter(res => res?.children?.length !== 0));
      setSubContractors(subcontractors?.filter(res => res?.children?.length !== 0))
      await getProjectData(contractors?.filter(res => res?.children?.length !== 0), subcontractors?.filter(res => res?.children?.length !== 0), newUserArr)

    } catch (err) {
      console.error(err);
    } finally {
      setSkeletonLoader(false);
    }
  };



  function convertToDD(dataArray) {
    let dd = {};
    dataArray.forEach((entry) => {
      let companyId = entry.company?._id || null;
      if (companyId) {
        dd[`${companyId}-company`] = { checked: true, partialChecked: false };
        entry.person.forEach((person) => {
          let personId = person.user._id;
          dd[`${companyId}-${personId}-person`] = { checked: true, partialChecked: false };
        });
      }
    });
    return dd;
  }


  function processData(data) {
    let contractor = [];
    let subcontractor = [];
    let internalEmployees = [];

    data?.forEach(item => {
      const companyInfo = item.company || {};
      const people = item?.person || [];

      let companyData = {
        key: companyInfo?.company_type !== 'individual' ? companyInfo?._id : 'internalEmployees' + '-company',
        label: companyInfo?.company_type !== 'individual' ? companyInfo?.company_name : 'Internal Employees',
        company: companyInfo?.company_type !== 'individual' ? companyInfo?.company_name : 'Internal Employees',
        companyId: companyInfo?.company_type !== 'individual' ? companyInfo?._id : 'internalEmployees',
        company_type: companyInfo?.company_type !== 'individual' ? companyInfo?.company_type : 'internalEmployees',
        children: people?.map(person => {
          return {
            key: companyInfo?.company_type !== 'individual' ? companyInfo?._id : 'internalEmployees' + person?.user?._id + '-person',
            label: `${person?.user?.first_name} ${person?.user?.last_name}`,
            person: `${person?.user?.first_name} ${person?.user?.last_name}`,
            project_role: person?.project_role,
            permission_template: person?.permission_template,
            value: person?.user?._id,
            company_type: companyInfo?.company_type !== 'individual' ? companyInfo?.company_type : 'internalEmployees'
          };
        })
      };

      switch (companyInfo?.company_type) {
        case "contractor":
          contractor.push(companyData);
          break;
        case "subcontractor":
          subcontractor.push(companyData);
          break;
        case "individual":
          internalEmployees.push(companyData);
          break;
        default:
          console.warn(`Unknown company type: ${companyInfo?.company_type}`);
          break;
      }
    });

    return { contractor, subcontractor, internalEmployees };
  }


  function compareDataAndAdd(data, data2) {
    let data1 = [...data];

    data1?.forEach(company1 => {
      const matchingCompany = data2?.find(company2 => company2?.companyId === company1?.companyId);

      if (matchingCompany) {
        company1?.children?.forEach(person1 => {
          const matchingPerson = matchingCompany?.children?.find(person2 => person2?.value === person1?.value);

          if (matchingPerson) {
            person1.project_role = matchingPerson?.project_role;
          } else {
            person1.project_role = null;
          }
        });
      }
    });

    return data1

  }



  function compareUsersData(data1, data2) {

    const updatedData = data1?.map(item1 => {
      const correspondingItem = data2?.find(item2 => item2?.value === item1?.value);

      if (correspondingItem) {
        item1.project_role = correspondingItem?.project_role;
      }

      return item1;
    });

    return updatedData

  }




  const getProjectData = async (con, sub, emp) => {
    try {
      const res = await getProjectAssignData(localStorage.getItem("projectId"));
      const respose = res?.data?.data;


      let ids = []
      respose?.forEach(res => {
        res?.person?.forEach(user => ids.push(user?._id))
      })
      // console.log(ids)
      setPrevData(ids)
      // if (false) {
      if (respose && respose.length !== 0) {

        setIsVersionHistory(true)
        let { contractor, subcontractor, internalEmployees } = processData(respose);
        let arr1 = compareDataAndAdd(con, contractor)
        let arr2 = compareDataAndAdd(sub, subcontractor)
        let arr3 = compareUsersData(emp, internalEmployees[0]?.children)

        setEmployees(arr3)
        setContractors(arr1)
        setSubContractors(arr2)
        console.log(respose, "rererererererer")
        let allUsersIds = respose?.map(e => e?.person?.map(d => d?.user))?.flat(Infinity)?.filter(e => e) || []

        setAllPreviousUsers(allUsersIds)

        // console.log([...contractor, ...subcontractor, ...internalEmployees])
        setTableData([...contractor, ...subcontractor, ...internalEmployees])
        setTableData1(contractor)
        setTableData2(subcontractor)
        setTableData3(internalEmployees)
        let contractorData = [];
        let subcontractorData = [];

        respose?.forEach((entry) => {
          let companyType = entry.company?.company_type;

          if (companyType === "contractor") {
            contractorData.push(entry);
          } else if (companyType === "subcontractor") {
            subcontractorData.push(entry);
          } else if (companyType === "individual") {
            let data2 = [];
            entry?.person?.forEach((person) => {
              data2?.push(person?._id)
            });
            setSelectEmployees(data2)

          }
        });
        let contractorDD = convertToDD(contractorData);
        let subcontractorDD = convertToDD(subcontractorData);
        setSelectedContractor(contractorDD)
        setSelectedSubContractor(subcontractorDD)
      }
      else {
        setIsVersionHistory(false)
      }
    } catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchProjectData();
  }, []);


  useEffect(() => {
    let arr = [...tableData1, ...tableData2, ...tableData3]
    setTableData(arr)
  }, [tableData1, tableData2, tableData3])


  function assignRole(rowData, e, type, index) {
    let parentIndex = index?.split('_')[0]
    let childIndex = index?.split('_')[1]

    // for table state
    let data = [...tableData]
    let interData = data[parentIndex].children
    interData[childIndex].project_role = e.target.value
    data[parentIndex].children = interData
    setTableData(data)
    // for table state
    if (type == "contractor") {
      // for contractors state 
      let companyId = rowData?.key?.split('-')[0]
      let personId = rowData?.key?.split('-')[1]
      let contractor = [...contractors]
      let index1 = contractor?.findIndex(res => res?.companyId == companyId)
      let childData = contractor[index1]?.children
      let index2 = childData?.findIndex(res => res?.value == personId)
      childData[index2].project_role = e.target.value
      contractor[index1].children = childData
      setContractors(contractor)
      // for contractors state 



    } else if (type == "subcontractor") {
      // for sub contractor state 

      let companyId = rowData?.key?.split('-')[0]
      let personId = rowData?.key?.split('-')[1]
      let subContractor = [...subContractors]
      let index1 = subContractor?.findIndex(res => res?.companyId == companyId)
      let childData = subContractor[index1]?.children
      let index2 = childData?.findIndex(res => res?.value == personId)
      childData[index2].project_role = e.target.value
      subContractor[index1].children = childData
      setSubContractors(subContractor)
      // for sub contractors state 


    } else {
      // let personId = rowData?.key?.split('-')[1]
      let personId = rowData?.value
      // console.log(rowData?.value)
      let employ = [...employees]
      let index1 = employ?.findIndex(res => res?.value == personId)
      // console.log(employ)
      // console.log(personId)
      employ[index1].project_role = e.target.value
      setEmployees(employ)
    }

  }

  return (
    <>
      <div className="outerBox">

        <div className="headingBox">
          <h1 className="">Project Directory</h1>
        </div>
        <div className="w-100 row justify-content-end p-0 m-0 dropdownBox">
          <div className="col-md-3 col-sm-4 col-6 pe-0">
            <TreeSelectComp
              placeholder="Add Contractor"
              options={contractors}
              onChange={(e) => handleChange(e, 'contractor')}
              value={selectedContractor}
            />
          </div>
          <div className="col-md-3 col-sm-4 col-6 pe-0">
            <TreeSelectComp
              placeholder="Add Sub Contractor"
              options={subContractors}
              onChange={(e) => handleChange(e, 'subcontractor')}
              value={selectedSubContractor}
            />
          </div>
          <div className="col-md-3 col-sm-4 col-12 pe-0">
            <MultiSelect
              options={employees}
              optionLabel="label"
              value={selectEmployees}
              filter
              onChange={(e) => handleChange(e, 'employees')}
              placeholder="Add Internal Employees"
              className={`p-0 w-100`}
            />
          </div>
        </div>
        <div className="tableBox" >
          {skeletonLoader ? (
            <TableSkelton
              header={[
                "",
                "",
                "Company Name",
                "",
                "Person Name",
                "User Role",
                "",
              ]}
            />
          ) : (
            <div style={{ minWidth: '1000px', width: '100%' }}>
              <TreeTable className="" value={tableData} tableStyle={{ minWidth: '50rem' }}>
                <Column field="company" header="Company name" expander body={(rowData => {
                  return (<span>{rowData?.company}</span>)
                })}></Column>
                <Column field="person" header="Person Name" body={(rowData => {
                  return (<span>{rowData?.person}</span>)
                })}></Column>
                <Column field="project_role" header="User Role" body={(rowData, index) => {
                  if (rowData?.project_role !== undefined) {
                    return (
                      <>
                        <SingleSelect
                          placeholder="Select Project Role"
                          value={rowData?.project_role}
                          onChange={(e) => {
                            assignRole(rowData, e, rowData?.company_type, index.rowIndex)
                          }}
                          options={allRole}
                          optionLabel="label"
                          className={`p-0`}
                        />
                        {roleErr.includes(index.rowIndex) && (
                          <>
                            <p className="text-danger mt-1">Please Select role</p>
                          </>
                        )}
                      </>
                    )
                  } else {
                    return ''
                  }
                }}></Column>
              </TreeTable>
            </div>
          )}
        </div>
        <div className="buttonBox">
          <div className="contoleButtons d-flex justify-content-between mt-3">
            <div className="d-flex gap-3">
              <PrimaryButton
                text="Cancel"
                onClick={() => setCancelVisible(true)}
              />
              <PrimaryButton
                text="Back"
                onClick={() => setVisible(true)}
              />

            </div>

            <div className="right d-flex gap-3">
              <PrimaryButton
                text={"Save"}
                onClick={() => setSavedVisible2(true)}
              />

              <PrimaryButton
                text={"Save & Continue"}
                onClick={() => setSavedVisible(true)}
              />
            </div>
          </div>
        </div>

      </div>




      <SevedModal
        yes={() => navigate("/projects")}
        visible={cancelVisible}
        setVisible={setCancelVisible}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />


      <SevedModal
        yes={() => addProjectAssign("Save and Continue")}
        visible={saveVisible}
        loader={btnLoader}
        setVisible={setSavedVisible}
        heading='Proceed to Next Step'
        type='info'
        headingText={`Are you sure you want to save your progress and continue?`}
      />
      <SevedModal
        yes={() => addProjectAssign("save")}
        loader={btnLoader}
        visible={saveVisible2}
        setVisible={setSavedVisible2}
        heading='Create the Project'
        type='info'
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-1")}
        visible={visible}
        setVisible={setVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />
    </>
  );
}
