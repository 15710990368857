import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from "react-redux";
import { error, success, info } from '../../Store/Slice/toast/toastSlice';
import './common.css'

export default function GlobalCustomSnakbar() {
    let data = useSelector(e => e.toast);
    const toast = useRef(null);
    const dispatch = useDispatch();
    let closeTime = 20000;
  
    const close = () => {
        if(data.severity === "success"){
            dispatch(success({show: false, detail: ""}));
        }
        else if(data.severity === "error"){
            dispatch(error({show: false, detail: ""}));
        }
        else{
            dispatch(info({show: false, detail: ""}));
        }
    }

    const show = (data) => {
        if (!data.show) { return; }
        toast.current.show({ severity: data.severity, detail: data.detail, life:3000});
        close()
    };

    // console.log(data);

    useEffect(() => {
        show(data);
    }, [data, closeTime]);

    return (
        <div className="snakbar card flex justify-content-center">
            <Toast ref={toast} />
        </div>
    )
}