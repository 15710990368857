

import React from 'react'

export const SearchIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5991 17.2981L15.5599 13.2589C15.4652 13.164 15.3365 13.1107 15.2023 13.1107C15.0683 13.1107 14.9396 13.164 14.8449 13.2589L14.5501 13.5549L12.7789 11.7826C14.2258 9.84905 14.5903 7.31253 13.7461 5.05005C12.9018 2.78752 10.9649 1.10934 8.60528 0.596179C6.24539 0.0830153 3.78631 0.804937 2.07865 2.51261C0.370986 4.22028 -0.350946 6.67915 0.162218 9.03924C0.675382 11.3989 2.35355 13.3358 4.61608 14.18C6.87881 15.0243 9.41533 14.6597 11.3486 13.2129L13.1207 14.9861L12.8248 15.2809H12.825C12.7301 15.3757 12.6767 15.5042 12.6767 15.6383C12.6767 15.7725 12.7301 15.901 12.825 15.9959L16.8641 20.0351V20.0349C17.3545 20.5131 18.0615 20.6961 18.7223 20.5161C19.3833 20.3359 19.8995 19.8197 20.0797 19.1587C20.2597 18.4979 20.0769 17.7909 19.5987 17.3005L19.5991 17.2981ZM2.0751 7.55769C2.0751 6.2185 2.60707 4.93439 3.55388 3.98753C4.50074 3.04067 5.78486 2.50874 7.12405 2.50874C8.46304 2.50874 9.74716 3.04072 10.6942 3.98753C11.6411 4.93439 12.173 6.2185 12.173 7.55769C12.173 8.89669 11.641 10.1808 10.6942 11.1279C9.74716 12.0747 8.46304 12.6066 7.12405 12.6066C5.78543 12.605 4.50223 12.0725 3.55557 11.126C2.60909 10.1795 2.07659 8.89616 2.0751 7.55769Z" fill="#A4A1A1" />
        </svg>

    )
}
