import React, { useEffect, useRef, useState } from 'react'
import { getSpecificationById } from '../../Api/Admin/AllAPIs'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlineExport } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable';
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';
import { ViewPagesPdfTemplate } from '../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate';
import { Statuscomp } from '../../Components/Common/StatusComp';
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf';
import moment from 'moment/moment';
import FormSkeleton from '../../Components/Common/FormSkeleton';
import ViewVersion from '../../Components/Common/ViewVersion';



export const ViewSpecification = ({ usedAsComponent, includeVersionHistory }) => {

  let { id } = useParams();
  let navigate = useNavigate();
  let [data, setData] = useState({})

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 1, height: "2.4rem" },
    { qty: 1, height: "2.4rem" },
    { qty: 1, height: "5rem" },

  ]


  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      setSkeletonLoader(true);
      let res = await getSpecificationById(id)
      console.log(res.data.data, "check data")
      setData(res?.data?.data?.[0] || {})
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSkeletonLoader(false);
    }
  }
  let pdfRef = useRef()
  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents", files: data?.attachFile.map(e => e.fileUrl)
      }
    ],"Specification")
  }

  const formatHourSpent = (hours) => {
    if (!hours || hours === "--") return "N/A";


    const parts = hours.split('-').map(Number);


    if (parts.some(isNaN)) return "Invalid";

    const [min, max] = parts;


    if (parts.length === 1) {
      if (min < 1) {
        return `<1 hour`;
      } else {
        return `${min} hours`;
      }
    } else if (max) {
      if (max >= 10) {
        return `${min} hours ${max} min`;
      } else {
        return `${min} hours ${max} min`;
      }
    } else {
      return `${min} hours`;
    }
  };


  // console.log(data?.set[0].name,"ttt")

  const hourSpentDisplay = formatHourSpent(data?.hourSpent)
  return (
    <>
      <div className="rfiView card">
        {
          skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
            <div className="rfiparent">
              <div className="w-100 breadcrum mb-2">
                <div className="w-100">
                  <span
                    className="open_Rfi_text"
                    onClick={() => navigate("/projectManagement/Specifications")}
                  >
                    Specifications
                  </span>
                  <span className="px-1 open_Rfi_text">/</span>
                  <span className="createNew_text"> View Specification</span>
                </div>
              </div>

              <div className="rfibody d-flex flex-column gap-4">
                <div className="d-flex justify-content-between">
                  <h1 className="headdingRFI"> {data?.set?.[0]?.name}</h1>
                  {!usedAsComponent &&

                  <div className="d-flex align-items-center gap-2" onClick={pdfDownload}>
                    <div
                      className="d-flex gap-2 align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <AiOutlineExport />
                      Export
                      <MdOutlineKeyboardArrowDown />
                    </div>

                    {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                <BsThreeDotsVertical />
              </div> */}
                  </div>}
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="row">
                    <div className="col-6">
                      <ViewPageTable tableData={[
                        { "Set Name": data?.set[0]?.name || "--" },
                        { "Responsible Contractor Company": data?.responsible_contractor_company?.map(e => e?.company_name).join(", ") || "--" },
                        { "Spec Manager": data?.spec_manager?.map(e => e?.first_name).join(", ") || "--" },
                        { "Spec ID": data?.spec_id || "--" },
                        {
                          "Hours Spent": data?.hours_spent
                              ? `${data?.hours_spent?.split("-")[0]} hour ${data?.hours_spent?.split("-")[1]
                              } Min`
                              : "--",
                      },
                       
                        { "Approval Status": data?.status || "--" },
                        { "Work Flow Status": data?.workflow_status || "--" },
                        { "Spec Received Date": moment(data?.receivedDate).format("DD/MM/YYYY") || "--" },
                        { "Reviewer": data?.reviewer?.[0]?.first_name || "--" },
                        { "Reviewer Comment":data?.reviewer_comment || "--" }
                      ]} />

                    </div>
                    <div className="col-6">
                      <ViewPageTable tableData={[
                        { "Format": data?.format?.[0]?.name || "--" },
                        { "Responsible Member": data?.responsible_member?.map(e => e?.first_name).join(", ") || "--" },
                        { "Spec Language": data?.specificationLanguage[0]?.name || "--" },
                        { "Spec version": data?.defaultRevision || "--" },
                        { "Responsible Person": data?.responsible_person?.map(e => e?.first_name).join(", ") || "--" },
                      ,
                        { "Approver": data?.approver?.[0]?.first_name || "--" },
                        { "Approver Comment":data?.approver_comment || "--" }
                      ]} />
                    </div>

                  </div>
                </div>
              </div>

              <div className="view_page_para_head mt-4 mb-2">Specification Description:</div>
              <div className="view_page_bold_text">
                <HtmlIntoText html={data?.description} />
              </div>
              <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
              <div className="d-flex flex-wrap">

                {data?.attachFile?.map((res, key) => {
                  return (
                    <ViewPageDocumentName docUrl={res?.fileUrl} name={res.fileName} />
                  )
                })}
              </div>


            </div>}

        {(!usedAsComponent || includeVersionHistory) && (
          <>
            <div className="mt-3">
              <ViewVersion isView={true} />
            </div>
          </>
        )}







        <div style={{ height: "0px", overflow: "hidden" }}>
          <div ref={pdfRef} >
            <div className=' card p-5'>
              <ViewPagesPdfTemplate
                header={`${data?.set?.[0].name}`}
                description={{
                  key: "Specification Description",
                  value: <HtmlIntoText html={data?.description} />
                }}
                tableData1={[
                  { "Set Name": data?.set?.[0]?.name || "--" },
                  { "Responsible Contractor Company": data?.responsible_contractor_company?.map(e => e?.company_name).join(", ") || "--" },
                  { "Spec Manager": data?.spec_manager?.map(e => e?.first_name).join(", ") || "--" },
                  { "Spec ID": data?.spec_id || "--" },
                  { "Hours Spent": data?.hourSpent || "--" },
                  { "Approval Status": data?.status || "--" },
                  { "Work Flow Status": data?.workflow_status || "--" },
                  { "Spec Received Date": moment(data?.receivedDate).format("DD/MM/YYYY") || "--" },
                  { "Reviewer": data?.reviewer?.[0]?.first_name || "--" },
                  { "Approver Comment": "" || "--" }
                ]}
                tableData2={[
                  { "Format": data?.format?.[0]?.name || "--" },
                  { "Responsible Member": data?.responsible_member?.map(e => e?.first_name).join(", ") || "--" },
                  { "Spec Language": data?.specificationLanguage?.[0]?.name || "--" },
                  { "Spec version": data?.defaultRevision || "--" },
                  { "Responsible Person": data?.responsible_person?.map(e => e?.first_name).join(", ") || "--" },
                  { "Spec Issue Date": moment(data?.issuedDate).format("DD/MM/YYYY") || "--" },
                  { "Approver": data?.approver?.[0]?.first_name || "--" },
                  { "Reviewer Comment": "" || "--" }
                ]}
              />



              {(!usedAsComponent || includeVersionHistory) && (
                <>
                  <div className="mt-3">
                    <ViewVersion isView={true} />
                  </div>
                </>
              )}

            </div>
          </div>
        </div>





     {!usedAsComponent &&

        <div className="d-flex justify-content-end mt-2">
          <button className='btn border' onClick={() => navigate("/projectManagement/Specifications")}>Back</button>
        </div>
     }
        <br />

      </div>
    </>

  )
}
