import React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import Portfolio from "./Components/Portfolio/Portfoli";
import TopBar from "./Components/TopBar/TopBar";
import ProjectHome from "./Components/ProjectHome/ProjectHome";
import Openrfis from "./Components/openrfis/Openrfis";
import DailyLog from "./pages/DailyLog/DailyLog";
import Submittals from "./pages/Submittals/Submittals";
import Schedule from "./pages/Schedule/Schedule";
import { PunchList } from "./pages/Punch/PunchList";
// import Drawing from "./Components/Drawing/Drawing";
// import DrawingFolder from "./Components/Drawing/DrawingFolder";
// import { CreateDrawing } from "./Components/Drawing/CreateDrawings";
// import { EditDrawing } from "./Components/Drawing/EditDrawings";
import PaintTouchup from "./pages/Punch/PaintTouchup";
import { CreateRfi } from "./Components/openrfis/CreateRfi";
import { CreatePunch } from "./pages/Punch/CreatePunch";
import Email from "./pages/email/Email";
import Incident from "./pages/Incidents/Incident";
import Inspections from "./pages/Inspections/Inspections";
import { Observation } from "./pages/Observation/Observation";
import { CreateObservation } from "./pages/Observation/CreateObservation";
import { ViewObservation } from "./pages/Observation/ViewObservation";
// import Models from "./pages/Models/Models";
import { Specifications } from "./pages/Specifications/Specifications";
import { Forms } from "./pages/Forms/Forms";
import Compose from "./pages/email/Compose";
import { CreateForm } from "./pages/Forms/CreateForm";
import { Meetings } from "./pages/Meetings/Meetings";
import { ViewMeeting } from "./pages/Meetings/ViewMeeting";
import { EditMeeting } from "./pages/Meetings/EditMeeting";
// import Photos from "./Components/Photos/Photos";
import CreateCCO from "./pages/FinancialManagement/InsideProject/ChangeOrder/CreateCCO";
import CreatePCCO from "./pages/FinancialManagement/InsideProject/ChangeOrder/CreatePCCO";
import IncidentList from "./pages/Incidents/IncidentList";
import { CreateIncident } from "./pages/Incidents/CreateIncident";
import { CoordinationIssues } from "./pages/CoordinationIssues/CoordinationIssues";
// import { EditMeeting } from "./pages/Meetings/EditMeeting";
import { Transmittals } from "./pages/Transmittals/Transmittals";
import { EditRfi } from "./Components/openrfis/EditRfi";
import { EditIncident } from "./pages/Incidents/EditIncident";
import { EditForm } from "./pages/Forms/EditForm";
import { EditObservation } from "./pages/Observation/EditObservation";
import { EditPunch } from "./pages/Punch/EditPunch";
import { ViewEvent } from "./pages/Schedule/ViewEvent";
import { CreateSubmittals } from "./pages/Submittals/CreateSubmittals";
import InspectionsTemplate from "./pages/Inspections/InspectionsTemplate";
import { EditSubmittals } from "./pages/Submittals/EditSubmittals";

import CreateTransmittals from "./pages/Transmittals/CreateTransmittals";
import { CreateIssue } from "./pages/CoordinationIssues/CreateIssue";
import { EditIssue } from "./pages/CoordinationIssues/EditIssue";
import EditTransmittals from "./pages/Transmittals/EditTransmittals";
import { CreateMeetings } from "./pages/Meetings/CreateMeetings";
// import CreatePhoto from "./Components/Photos/CreatePhoto";
// import EditPhoto from "./Components/Photos/EditPhoto";
// import ViewDrawing from "./Components/Drawing/ViewDrawing";
import Newinspectiontemplate from "./pages/Inspections/Newinspectiontemplate";
import { CreateSpecifications } from "./pages/Specifications/CreateSpecifications";
import { EditSpecification } from "./pages/Specifications/EditSpecification";
import { CreateTemplate } from "./pages/Meetings/CreateTemplate";
import { useSelector } from "react-redux";
import { EditTemplate } from "./pages/Meetings/EditTemplate";
import FormLogic from "./Components/ProjectHome/ProjectForm/FormLogic";
import FormLogic1 from "./Components/ProjectHome/updateProjectForm/FormLogic";

import { ProjectInformation } from "./Components/ProjectHome/ProjectForm/ProjectInfo/ProjectInfo";
import ProjectDirectory from "./Components/ProjectHome/ProjectForm/ProjectDirectory/ProjectDirectory";
import ProjectTool from "./Components/ProjectHome/ProjectForm/ProjectTool/ProjectTool";
import CostCodes from "./Components/ProjectHome/ProjectForm/CostCodes/CostCodes";
import { PROJECTSTAGES } from "./Components/ProjectHome/ProjectForm/ProjectStages/PROJECTSTAGES";
import { ProjectDrawing } from "./Components/ProjectHome/ProjectForm/ProjectDrawing/ProjectDrawing";
import { ProjectSpecifications } from "./Components/ProjectHome/ProjectForm/ProjectSpecifications/ProjectSpecifications";
import { Projectschedule } from "./Components/ProjectHome/ProjectForm/ProjectSchedule/Projectschedule";
// import CreateModels from "./pages/Models/CreateModels";
import ProjectDirectories from "./pages/ProjectDirectories/ProjectDirectories";
import Tasktable from "./pages/Task/Tasktable";
import ProjectList from "./pages/ProjectList/ProjectList";
import { ProjectInformation1 } from "./Components/ProjectHome/updateProjectForm/ProjectInfo/ProjectInfo";
import ProjectDirectory1 from "./Components/ProjectHome/updateProjectForm/ProjectDirectory/ProjectDirectory";
import CostCodes1 from "./Components/ProjectHome/updateProjectForm/CostCodes/CostCodes";
import ProjectTool1 from "./Components/ProjectHome/updateProjectForm/ProjectTool/ProjectTool";
import { ProjectDrawing1 } from "./Components/ProjectHome/updateProjectForm/ProjectDrawing/ProjectDrawing";
import { ProjectSpecifications1 } from "./Components/ProjectHome/updateProjectForm/ProjectSpecifications/ProjectSpecifications";
import { Projectschedule1 } from "./Components/ProjectHome/updateProjectForm/ProjectSchedule/Projectschedule";
import { PROJECTSTAGES1 } from "./Components/ProjectHome/updateProjectForm/ProjectStages/PROJECTSTAGES";
// import Documents from "./pages/Documents/Documents";

import Projects from "./pages/ProjectDirectories/Projects";
import AddCompany from "./pages/ProjectDirectories/AddCompany";
import AddUser from "./pages/ProjectDirectories/AddUser";
import AddUserInfo from "./pages/ProjectDirectories/AddUserInfo";
import ProjectSetting from "./pages/ProjectDirectories/ProjectSetting";

import Overview from "./pages/OverView/Overview";
import { Reports } from "./pages/Reports/Reports";
import { ViewRfi } from "./pages/Reports/ViewRfi";
import EditCompany from "./pages/ProjectDirectories/EditCompany";
import EditUserInfo from "./pages/ProjectDirectories/EditUserInfo";
import OverviewProject from "./pages/ProjectOverview/Overview";
import { Viewtask } from "./pages/Task/Viewtask";
import SignIn from "./pages/OnboardingSignIn/SignIn";
import ProjectHomeListing from "./Components/ProjectHome/ProjectHomeListing";
import RfiView from "./Components/openrfis/RfiView";
// import Drawings from "./Components/Drawing/Drawings";
// import { TopBar } from "./Components/Sidebar copy/Sidebar";
import { LogingKeeper } from "./Components/Auth/LogingKeeper";
import { Sidebar } from "./Components/Sidebar/Sidebar";
import FinancialManagement from "./pages/FinancialManagement/FinancialManagement";
import UserListing from "./pages/UserManagement/UserListing";
import ViewVersion from "./Components/Common/ViewVersion";
// import CreateEditDrawings from "./Components/Drawing/CreateEditDrawings";
import { ViewSubmittal } from "./pages/Submittals/ViewSubmittal";
import { ViewPuch, ViewPunch } from "./pages/Punch/ViewPunch";
import { ViewSpecification } from "./pages/Specifications/ViewSpecification";
import { ViewIssue } from "./pages/CoordinationIssues/ViewIssue";
import { ViewIncident } from "./pages/Incidents/ViewIncident";
import { ViewForm } from "./pages/Forms/ViewForm";
import Directory from "./pages/Directory/Directory";
// import ViewPhotos from "./Components/Photos/ViewPhotos";
// import ViewModels from "./pages/Models/ViewModels";
import { CreateUser } from "./pages/UserManagement/CreateUser";
import ResetPassword from "./pages/OnboardingSignIn/ResetPassword";
import ProjectView from "./pages/ProjectList/ProjectView";
import { RoleManagement } from "./pages/RbacManagement/RoleManagement";
import { CreateRole } from "./pages/RbacManagement/CreateRole";
import { ManageAccess } from "./pages/RbacManagement/ManageAccess";
import { RolesAndPermissionKeeper } from "./Components/Auth/RolesAndPermissionKeeper";
import { NoAccessPage } from "./pages/NoAcessPage/NoAccessPage";
import { OutsideProjectRoleAccessKeeper } from "./Components/Auth/OutsideProjectRoleAccessKeeper";
import { InsideProjectRoleAccessKeeper } from "./Components/Auth/InsideProjectRoleAccessKeeper";
import { CreateAddress } from "./pages/AddressManagement/CreateAddress";
import { AddressListing } from "./pages/AddressManagement/AddressListing";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { ResetForgotPassword } from "./pages/ForgotPassword/ResetForgotPassword";
import { Budget } from "./pages/FinancialManagement/InsideProject/Budget/Budget";
import { BudgetOutlet } from "./pages/FinancialManagement/InsideProject/Budget/BudgetOutlet";
import { BudgetChanges } from "./pages/FinancialManagement/InsideProject/Budget/BudgetChanges";
import { Modifications } from "./pages/FinancialManagement/InsideProject/Budget/Modifications";
import { SnapShots } from "./pages/FinancialManagement/InsideProject/Budget/SnapShots";
import { GeneralTable } from "./pages/FinancialManagement/InsideProject/DirectCost/GeneralTable";
import { SummaryByCostCode } from "./pages/FinancialManagement/InsideProject/DirectCost/SummaryByCostCode";
import { DirectCostOutlet } from "./pages/FinancialManagement/InsideProject/DirectCost/DirectCostOutlet";
import { CreateDirectCost } from "./pages/FinancialManagement/InsideProject/DirectCost/CreateDirectCost";
import { EditDirectCost } from "./pages/FinancialManagement/InsideProject/DirectCost/EditDirectCost";
import PrimeContract from "./pages/FinancialManagement/InsideProject/Prime Contract/PrimeContract";
// import PurchaseOrder from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder";
// import Subcontractor from "./pages/FinancialManagement/InsideProject/Commitments/Subcontractor";
import ChangeOrderOutlet from "./pages/FinancialManagement/InsideProject/ChangeOrder/ChangeOrderOutlet";
import PrimeContact from "./pages/FinancialManagement/InsideProject/ChangeOrder/PrimeContact";
import Commitments from "./pages/FinancialManagement/InsideProject/ChangeOrder/Commitments";
// import { CreatePurchaseOrder } from "./pages/FinancialManagement/InsideProject/Commitments/CreatePurchaseOrder";
import { CreateBudget } from "./pages/FinancialManagement/InsideProject/Budget/CreateBudget";
import CreateContract from "./pages/FinancialManagement/InsideProject/Prime Contract/CreateContract";
import { ModifyBudget } from "./pages/FinancialManagement/InsideProject/Budget/ModifyBudget";
import BudgetView from "./pages/FinancialManagement/InsideProject/Budget/BudgetView";
import ViewContract from "./pages/FinancialManagement/InsideProject/Prime Contract/ViewContract";
import GeneralDetails from "./pages/FinancialManagement/InsideProject/Prime Contract/GeneralDetails";
import PCCOView from "./pages/FinancialManagement/InsideProject/ChangeOrder/PCCOView";
import CCOView from "./pages/FinancialManagement/InsideProject/ChangeOrder/CCOView";
import ScheduleValue from "./pages/FinancialManagement/InsideProject/Prime Contract/ScheduleValue";
import Commitment from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/CommitmentOutlet";
import { CreatePurchaseOrder } from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/CreatePurchaseOrder";
import PurchaseOrder from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/PurchaseOrder";
import { CreateSubcontract } from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/CreateSubcontract";
import Subcontractor from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/Subcontractor";
import ChangeOrders from "./pages/FinancialManagement/InsideProject/Prime Contract/ChangeOrders";
import Invoices from "./pages/FinancialManagement/InsideProject/Prime Contract/Invoices";
import PaymentReceived from "./pages/FinancialManagement/InsideProject/Prime Contract/PaymentReceived";
import FinancialMarkups from "./pages/FinancialManagement/InsideProject/Prime Contract/FinancialMarkups";
import GeneralDetail_Purchase from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/GeneralDetail_Purchase";
import ViewPurchaseOrder from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/ViewPurchaseOrder";
import ChangeOrder_Purchase from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/ChangeOrder_Purchase";
import ScheduleValue_Purchase from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/ScheduleValue_Purchase";
import PaymentRecieve_Purchase from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/PaymentRecieve_Purchase";
import FinancialMarkup_Purchase from "./pages/FinancialManagement/InsideProject/Commitments/PurchaseOrder/View/FinancialMarkup_Purchase";
import ViewSubcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/ViewSubcontract";
import GeneralDetail_Subcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/GeneralDetail_Subcontract";
import ScheduleValue_Subcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/ScheduleValue_Subcontract";
import ChangeOrder_Subcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/ChangeOrder_Subcontract";
import PaymentRecieve_Subcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/PaymentRecieve_Subcontract";
import FinancialMarkup_Subcontract from "./pages/FinancialManagement/InsideProject/Commitments/SubContract/View/FinancialMarkup_Subcontract";
import { CreateUpdateDailyLog } from "./pages/DailyLog/CreateUpdateDailyLog";
import { ViewDailyLog } from "./pages/DailyLog/ViewDailyLog";
import { CreateUpdateTask } from "./pages/Task/CreateUpdateTask";
import ViewTransmittals from "./pages/Transmittals/ViewTransmittals";
import { EmailGroupList } from "./pages/EmailManagement/EmailGroupList";
import { CreateUpdateEmailGroup } from "./pages/EmailManagement/CreateUpdateEmailGroup";
import { ViewEmailGroup } from "./pages/EmailManagement/ViewEmailGroup";
import ProjectVersionHistory from "./pages/ProjectList/ProjectVersionHistory";
import PicklistManagement from "./pages/PicklistManagement/PicklistManagement";
import DrawingListing from "./pages/DrawingFolder/DrawingListing";
import { CreateUpdateDrawing } from "./pages/DrawingFolder/CreateUpdateDrawing";
import ImportFronGlobal from "./pages/DrawingFolder/ImportFronGlobal";
import ViewParticularDrawing from "./pages/DrawingFolder/ViewParticularDrawing";
import Notification from "./pages/NotificationFolder/Notification";
import { VerifyAccount } from "./pages/OnboardingSignIn/VerifyAccount";
import { CreateUpdateMeeting } from "./pages/Meetings/CreateUpdateMeeting";
import TestLogin from "./pages/OnboardingSignIn/TestLogin";



export const Router = () => {
  const location = useLocation();

  return (
    <>
      {/* Conditionally render the TopBar based on the current path */}
      {/* {location.pathname !== "/" && <TopBar />} */}
      <Routes>
        <Route exact path="/" element={<SignIn />}></Route>
        <Route exact path="/testLogin" element={<TestLogin />}></Route>

        <Route exact path="/resetPassword" element={<ResetPassword />}></Route>
        <Route exact path="/verifyAccount" element={<VerifyAccount />}></Route>
        <Route
          exact
          path="/forgotPassword"
          element={<ForgotPassword />}
        ></Route>
        <Route
          path="/forgotPassword/resetPassword"
          element={<ResetForgotPassword />}
        ></Route>
        {/* <Route path="" element={<RolesAndPermissionKeeper />}> */}
        <Route path="" element={<LogingKeeper layer={"whole"} />}>
          <Route element={<OutsideProjectRoleAccessKeeper />}>
            {/* outside project routes starts here  */}
            <Route path="/" element={<MainSidebar />}>
              <Route exact path="mainOverview" element={<Overview />}></Route>
              <Route exact path="projects" element={<ProjectHomeListing />} />
              <Route
                exact
                path="financialManagement"
                element={<FinancialManagement />}
              />
              <Route
                exact
                path="projectDirectories"
                element={<ProjectDirectories />}
              />
              {/* <Route exact path="projectDirectories/addUserInfo" element={<AddUserInfo condition={true} />} /> */}
              <Route
                exact
                path="projectDirectories/createContractor/:mode"
                element={<AddCompany />}
              />
              <Route
                exact
                path="projectDirectories/updateContractor/:id"
                element={<EditCompany />}
              />




              <Route exact path="picklistManagement">
                <Route index={true} element={<PicklistManagement />} />
                {/* <Route path="createUser" element={<CreateUser />} />
                <Route path="editUser/:id" element={<EditUserInfo />} /> */}
              </Route>
              <Route exact path="userManagement">
                <Route index={true} element={<UserListing />} />
                <Route path="createUser" element={<CreateUser />} />
                <Route path="editUser/:id" element={<EditUserInfo />} />
              </Route>

              <Route path="rbacManagement">
                <Route index={true} element={<RoleManagement />} />
                <Route path="manageAccess/:id" element={<ManageAccess />} />
              </Route>

              <Route path="emailManagement">
                <Route index={true} element={<EmailGroupList />} />
                <Route path="create" element={<CreateUpdateEmailGroup />} />
                <Route path="edit/:id" element={<CreateUpdateEmailGroup />} />
                <Route path="view/:id" element={<ViewEmailGroup />} />
              </Route>




              <Route
                path="portfolio/projectform"
                element={<FormLogic mode="Create" />}
              >
                <Route path="step-1" element={<ProjectInformation />} />
                <Route path="step-5" element={<PROJECTSTAGES />} />
                <Route path="step-3" element={<CostCodes />} />
                <Route path="step-4" element={<ProjectTool />} />
                <Route path="step-2" element={<ProjectDirectory />} />
                <Route path="step-6" element={<ProjectDrawing />} />
                <Route path="step-7" element={<ProjectSpecifications />} />
                <Route path="step-8" element={<Projectschedule />} />
              </Route>

              {/* <Route path="updateProjectForm/projectInfo/:id" element={<FormLogic1 />} >
            <Route path="step-1" element={<ProjectInformation1 />} />
            <Route path="step-2" element={<PROJECTSTAGES1 />} />
            <Route path="step-3" element={<ProjectTool1 />} />
            <Route path="step-4" element={<CostCodes1 />} />
            <Route path="step-5" element={<ProjectDirectory1 />} />
            <Route path="step-6" element={<ProjectDrawing1 />} />
            <Route path="step-7" element={<ProjectSpecifications1 />} />
            <Route path="step-8" element={<Projectschedule1 />} />
          </Route> */}
              <Route path="noAccess" element={<NoAccessPage />} />
            </Route>

            {/* outside project routes ends here  */}
          </Route>

          {/* inside project routes starts here  */}
          <Route path="/" element={<ProjectManagmentSidbar />}>
            <Route element={<InsideProjectRoleAccessKeeper />}>
              <Route
                exact
                path="overviewproject"
                element={<OverviewProject />}
              />
              <Route
                exact
                path="coretools/admin/projectView/:id"
                element={<ProjectView />}
              />
              <Route
                exact
                path="coretools/admin/projectView/versionHistory"
                element={<ProjectVersionHistory />}
              />
              <Route
                exact
                path="coretools/admin/projectView/AddressListing"
                element={<AddressListing useFor='project' />}
              ></Route>

              <Route
                path="coretools/admin/projectform"
                element={<FormLogic mode="Edit" />}
              >
                <Route
                  path="step-1"
                  element={<ProjectInformation mode="Edit" />}
                />
                <Route
                  path="step-2"
                  element={<ProjectDirectory mode="Edit" />}
                />
                <Route path="step-5" element={<PROJECTSTAGES mode="Edit" />} />
                <Route path="step-3" element={<CostCodes mode="Edit" />} />
                <Route path="step-4" element={<ProjectTool mode="Edit" />} />
                <Route path="step-6" element={<ProjectDrawing mode="Edit" />} />
                <Route
                  path="step-7"
                  element={<ProjectSpecifications mode="Edit" />}
                />
                <Route
                  path="step-8"
                  element={<Projectschedule mode="Edit" />}
                />
              </Route>

              <Route path="/projectManagement">
                <Route
                  exact
                  path="version-history/:heading/:name/:id"
                  element={<ViewVersion />}
                ></Route>
                <Route exact path="openrfis" element={<Openrfis />}></Route>
                <Route
                  exact
                  path="openrfis/createRfi"
                  element={<CreateRfi />}
                ></Route>
                <Route
                  exact
                  path="openrfis/view/:heading/:name/:id"
                  element={
                    <RfiView
                      includeVersionHistory={true}
                      usedAsComponent={false}
                    />
                  }
                ></Route>
                <Route
                  exact
                  path="openrfis/editRfi/:id"
                  element={<EditRfi />}
                ></Route>
                <Route exact path="submittals" element={<Submittals />}></Route>
                <Route
                  exact
                  path="submittals/view/:heading/:name/:id"
                  element={<ViewSubmittal />}
                ></Route>
                <Route
                  exact
                  path="submittals/createsubmittals"
                  element={<CreateSubmittals />}
                ></Route>
                <Route
                  exact
                  path="submittals/:submittalId"
                  element={<CreateSubmittals reqFor={"edit"} />}
                ></Route>
                <Route exact path="email" element={<Email />}></Route>
                <Route exact path="email/compose" element={<Compose />}></Route>
                <Route exact path="punch" element={<PunchList />}></Route>
                <Route
                  exact
                  path="notifications"
                  element={<Notification />}
                />
                <Route
                  exact
                  path="punch/view/:heading/:name/:id"
                  element={<ViewPunch />}
                ></Route>
                <Route
                  exact
                  path="punch/create"
                  element={<CreatePunch />}
                ></Route>
                <Route
                  exact
                  path="punch/edit/:id"
                  element={<EditPunch />}
                ></Route>
                <Route
                  exact
                  path="observation"
                  element={<Observation />}
                ></Route>
                <Route
                  exact
                  path="observation/:id"
                  element={<CreateObservation reqFor={"edit"} />}
                ></Route>
                <Route
                  exact
                  path="observation/create"
                  element={<CreateObservation />}
                ></Route>
                <Route
                  exact
                  path="observation/view/:heading/:name/:id"
                  element={<ViewObservation />}
                ></Route>
                {/* <Route exact path="observation/edit" element={<EditObservation />} ></Route> */}
                <Route
                  exact
                  path="specifications"
                  element={<Specifications />}
                ></Route>
                <Route
                  exact
                  path="specifications/view/:heading/:name/:id"
                  element={<ViewSpecification />}
                ></Route>
                <Route
                  exact
                  path="specifications/create"
                  element={<CreateSpecifications />}
                ></Route>
                <Route
                  exact
                  path="specifications/edit/:id"
                  element={<EditSpecification />}
                ></Route>

                <Route exact path="dailylog" element={<DailyLog />}></Route>
                <Route
                  exact
                  path="dailylog/create"
                  element={<CreateUpdateDailyLog />}
                ></Route>
                <Route
                  exact
                  path="dailylog/edit/:id"
                  element={<CreateUpdateDailyLog />}
                ></Route>
                <Route
                  exact
                  // path="dailylog/view/:id"


                  path="dailylog/view/:heading/:name/:id"


                  element={<ViewDailyLog />}
                ></Route>

                <Route
                  exact
                  path="coordinationIssues"
                  element={<CoordinationIssues />}
                ></Route>
                <Route
                  exact
                  path="coordinationIssues/edit/:id"
                  element={<EditIssue />}
                ></Route>
                <Route
                  exact
                  path="coordinationIssues/view/:heading/:name/:id"
                  element={<ViewIssue />}
                ></Route>
                <Route
                  exact
                  path="coordinationIssues/create"
                  element={<CreateIssue />}
                ></Route>
                <Route
                  exact
                  path="transmittals"
                  element={<Transmittals />}
                ></Route>
                <Route
                  exact
                  path="transmittals/create"
                  element={<CreateTransmittals />}
                ></Route>

                <Route
                  exact
                  path="transmittals/:id"
                  element={<CreateTransmittals reqFor={"edit"} />}
                ></Route>
                <Route
                  exact
                  // path="dailylog/view/:id"


                  path="transmittals/view/:heading/:name/:id"


                  element={<ViewTransmittals />}
                ></Route>
                <Route
                  exact
                  path="transmittals/edit/:id"
                  element={<EditTransmittals />}
                ></Route>
                <Route exact path="incident" element={<Incident />}></Route>
                <Route
                  exact
                  path="incident/view/:heading/:name/:id"
                  element={<ViewIncident />}
                ></Route>
                <Route
                  exact
                  path="incident/createincident"
                  element={<CreateIncident />}
                ></Route>
                <Route
                  exact
                  path="incident/editincident/:id"
                  element={<EditIncident />}
                ></Route>
                <Route
                  exact
                  path="inspection"
                  element={<Inspections />}
                ></Route>

                <Route
                  exact
                  path="createAddress/:toolName/:id"
                  element={<CreateAddress />}
                ></Route>
                <Route
                  exact
                  path="addressListing/:heading/:name/:id"
                  element={<AddressListing useFor='tools' />}
                ></Route>


                <Route
                  exact
                  path="inspection/inspectionsTemplate/:heading/:name/:id"
                  element={<InspectionsTemplate />}
                ></Route>

                <Route
                  exact
                  path="inspection/:id"
                  element={<Newinspectiontemplate reqFor={"edit"} />}
                ></Route>
                <Route
                  exact
                  path="inspection/newinspectiontemplate"
                  element={<Newinspectiontemplate reqFor={"create"} />}
                ></Route>
                <Route exact path="schedule" element={<Schedule />}></Route>
                <Route
                  exact
                  path="schedule/viewevent"
                  element={<ViewEvent />}
                ></Route>






                {/* drawing routes start  */}
                <Route exact path="drawings" element={<DrawingListing role='drawings' />}></Route>
                <Route exact path="drawings/createDrawings" element={<CreateUpdateDrawing role='drawings' />}></Route>
                <Route exact path="drawings/importFromGlobalDrawings" element={<ImportFronGlobal role='drawings' />}></Route>
                {/* <Route exact path="drawing/viewdrawing/:id" element={<ViewParticularDrawing role='drawings' />}></Route> */}


                <Route exact path=":heading/viewdrawing/:id" element={<ViewParticularDrawing role='drawings' includeVersionHistory={true}
                  usedAsComponent={false} />}></Route>
                <Route exact path="drawing/edit/:id" element={<CreateUpdateDrawing role='drawings' />}></Route>
                {/* drawing routes end */}


                {/* phatos routes start */}
                <Route exact path="photos" element={<DrawingListing role='photos' />}></Route>
                <Route exact path="photos/createPhotos" element={<CreateUpdateDrawing role='photos' />}></Route>
                <Route exact path="photos/importFromGlobalPhotos" element={<ImportFronGlobal role='photos' />}></Route>
                {/* <Route exact path="photo/viewphoto/:id" element={<ViewParticularDrawing role='photos' />}></Route> */}
                <Route exact path=":heading/viewphoto/:id" element={<ViewParticularDrawing role='photos' includeVersionHistory={true}
                  usedAsComponent={false} />}></Route>
                <Route exact path="photo/edit/:id" element={<CreateUpdateDrawing role='photos' />}></Route>
                {/* phatos routes end */}


                {/* models routes start */}
                <Route exact path="models" element={<DrawingListing role='models' />}></Route>
                <Route exact path="models/createModels" element={<CreateUpdateDrawing role='models' />}></Route>
                <Route exact path="models/importFromGlobalModels" element={<ImportFronGlobal role='models' />}></Route>
                {/* <Route exact path="model/viewmodel/:id" element={<ViewParticularDrawing role='models' />}></Route> */}
                <Route exact path=":heading/viewmodel/:id" element={<ViewParticularDrawing role='models' includeVersionHistory={true}
                  usedAsComponent={false} />}></Route>
                <Route exact path="model/edit/:id" element={<CreateUpdateDrawing role='models' />}></Route>
                {/* models routes end */}










                {/* <Route exact path="drawings" element={<Drawings />}></Route> */}
                {/* <Route
                  exact
                  path="drawings/create"
                  element={<CreateEditDrawings reqFor={"Create"} />}
                ></Route> */}
                {/* <Route
                  exact
                  path="drawings/edit/:id"
                  element={<CreateEditDrawings reqFor={"Update"} />}
                ></Route> */}
                {/* <Route
                  exact
                  path="Drawing/view"
                  element={<ViewDrawing />}
                ></Route> */}
                {/* <Route
                  exact
                  path="DrawingFolder/:type"
                  element={<DrawingFolder />}
                ></Route> */}
                {/* <Route
                  exact
                  path="Drawing/create"
                  element={<CreateDrawing />}
                ></Route> */}
                {/* <Route
                  exact
                  path="Drawing/:id"
                  element={<EditDrawing />}
                ></Route> */}
                {/* <Route exact path="photos" element={<Photos />}></Route> */}
                {/* <Route
                  exact
                  path="viewphotos/:id"
                  element={<ViewPhotos />}
                ></Route> */}
                {/* <Route
                  exact
                  path="photos/create"
                  element={<CreatePhoto />}
                ></Route> */}
                {/* <Route exact path="photos/:id" element={<EditPhoto />}></Route> */}
                {/* <Route exact path="models" element={<Models />}></Route> */}
                {/* <Route
                  exact
                  path="models/create"
                  element={<CreateModels reqFor={"Create"} />}
                ></Route>
                <Route
                  exact
                  path="models/edit/:id"
                  element={<CreateModels reqFor={"Edit"} />}
                ></Route>
                <Route
                  exact
                  path="models/view/:id"
                  element={<ViewModels reqFor={"View"} />}
                ></Route> */}
                <Route exact path="meetings" element={<Meetings />}></Route>
                <Route
                  exact
                  path="meetings/create"
                  element={<CreateUpdateMeeting />}
                ></Route>
                <Route
                  exact
                  path="meetings/edit/:id"
                  element={<CreateUpdateMeeting />}
                ></Route>



                <Route
                  exact
                  path="meetings/template"
                  element={<Meetings />}
                ></Route>



                <Route
                  path="meetings/view/:heading/:name/:id"
                  element={<ViewMeeting />}></Route>



                <Route
                  exact
                  path="meetings/:id"
                  element={<EditMeeting />}
                ></Route>
                <Route
                  exact
                  path="meetings/createTemplate"
                  element={<CreateTemplate />}
                ></Route>
                {/* <Route
                  exact
                  path="meetings/edit"
                  element={<EditMeeting />}
                ></Route> */}
                <Route exact path="forms" element={<Forms />}></Route>
                <Route
                  exact
                  path="forms/view/:heading/:name/:id"
                  element={<ViewForm />}
                ></Route>
                <Route
                  exact
                  path="forms/createForms"
                  element={<CreateForm />}
                ></Route>
                <Route
                  exact
                  path="forms/editForm/:id"
                  element={<EditForm />}
                ></Route>
              </Route>

              <Route path="/financialManagement">
                <Route path="budget" element={<BudgetOutlet />}>
                  <Route index element={<Budget />} />
                  <Route path="budgetChanges" element={<BudgetChanges />} />
                  <Route path="modifications" element={<Modifications />} />
                  <Route path="snapShots" element={<SnapShots />} />
                </Route>
                <Route path="budget/budgetCreate" element={<CreateBudget />} />
                <Route
                  path="budget/:id"
                  element={<CreateBudget reqFor={"edit"} />}
                />
                <Route path="budget/view" element={<BudgetView />} />
                <Route path="budget/modifybudget" element={<ModifyBudget />} />
                <Route path="changeOrder" element={<ChangeOrderOutlet />}>
                  <Route index element={<PrimeContact />} />
                  <Route path="commitments" element={<Commitments />} />
                </Route>

                <Route path="changeOrder/createPCCO" element={<CreatePCCO />} />
                <Route path="changeOrder/createCCO" element={<CreateCCO />} />
                <Route
                  path="changeOrder/PCCOView"
                  element={<PCCOView />}
                ></Route>
                <Route
                  path="changeOrder/commitments/CCOView"
                  element={<CCOView />}
                ></Route>

                <Route path="directCost" element={<DirectCostOutlet />}>
                  <Route index element={<GeneralTable />} />
                  <Route path="summaryByCode" element={<SummaryByCostCode />} />
                </Route>
                <Route
                  path="directCost/create"
                  element={<CreateDirectCost />}
                />
                <Route
                  path="directCost/edit/:id"
                  element={<EditDirectCost />}
                />

                <Route path="Primecontract" element={<PrimeContract />} />
                <Route
                  path="Primecontract/createcontract"
                  element={<CreateContract />}
                />
                <Route
                  path="Primecontract/ViewContract"
                  element={<ViewContract />}
                >
                  <Route index element={<GeneralDetails />} />
                  <Route path="ScheduleValue" element={<ScheduleValue />} />
                  <Route path="ChangeOrders" element={<ChangeOrders />} />
                  <Route path="Invoices" element={<Invoices />} />
                  <Route path="PaymentReceived" element={<PaymentReceived />} />
                  <Route
                    path="FinancialMarkups"
                    element={<FinancialMarkups />}
                  />
                </Route>

                <Route path="commitment" element={<Commitment />}>
                  {/* <Route index element={<Commitment />} /> */}
                  <Route index element={<PurchaseOrder />} />
                  <Route path="subcontractor" element={<Subcontractor />} />
                </Route>
                <Route
                  path="createPurchaseOrder"
                  element={<CreatePurchaseOrder />}
                />

                <Route
                  path="commitment/ViewPurchaseOrder"
                  element={<ViewPurchaseOrder />}
                >
                  <Route
                    path="scheduleValue"
                    element={<ScheduleValue_Purchase />}
                  />
                  <Route
                    path="changeOrder"
                    element={<ChangeOrder_Purchase />}
                  />
                  <Route
                    path="paymentRecieve"
                    element={<PaymentRecieve_Purchase />}
                  />
                  <Route
                    path="financialMarkup"
                    element={<FinancialMarkup_Purchase />}
                  />
                  <Route />
                  <Route
                    path="generalDetail"
                    element={<GeneralDetail_Purchase />}
                  />
                </Route>

                <Route
                  path="createSubcontract"
                  element={<CreateSubcontract />}
                />
                {/* ************* */}
                <Route
                  path="commitment/ViewSubcontract"
                  element={<ViewSubcontract />}
                >
                  <Route
                    path="subContract_generalDetail"
                    element={<GeneralDetail_Subcontract />}
                  />
                  <Route
                    path="subContract_scheduleValue"
                    element={<ScheduleValue_Subcontract />}
                  />
                  <Route
                    path="subContract_changeOrder"
                    element={<ChangeOrder_Subcontract />}
                  />
                  <Route
                    path="subContract_paymentRecieve"
                    element={<PaymentRecieve_Subcontract />}
                  />
                  <Route
                    path="subContract_financialMarkup"
                    element={<FinancialMarkup_Subcontract />}
                  />
                </Route>
                {/* ************* */}
              </Route>

              <Route exact path="portfolio" element={<Portfolio />}></Route>
              <Route exact path="home" element={<ProjectHome />}></Route>
              {/* <Route exact path="/documents" element={<Documents />}></Route> */}
              {/* document routes start */}
              <Route exact path="documents" element={<DrawingListing role='documents' />}></Route>
              <Route exact path="documents/createDocuments" element={<CreateUpdateDrawing role='documents' />}></Route>
              <Route exact path="documents/edit/:id" element={<CreateUpdateDrawing role='documents' />}></Route>

              <Route exact path="documents/importFromGlobalDocuments" element={<ImportFronGlobal role='documents' />}></Route>
              <Route exact path=":heading/viewdocument/:id" element={<ViewParticularDrawing role='documents' includeVersionHistory={true}
                usedAsComponent={false} />}></Route>
              {/* document routes end */}
              <Route exact path="paint/:id" element={<PaintTouchup />}></Route>
              <Route exact path="" element={<Portfolio />}></Route>
              <Route
                exact
                path="template/:id"
                element={<EditTemplate />}
              ></Route>
              {
                <Route
                  exact
                  path="createincident"
                  element={<CreateIncident />}
                ></Route>
              }
              <Route
                exact
                path="incidentlist"
                element={<IncidentList />}
              ></Route>

              <Route exact path="edit/:id" element={<EditIssue />}></Route>

              <Route path="coretools/tasktable">
                <Route index element={<Tasktable />} />
                <Route path="create" element={<CreateUpdateTask />}></Route>
                <Route path="edit/:id" element={<CreateUpdateTask />}></Route>
                <Route
                  path="view/:heading/:name/:id"
                  element={<Viewtask />}></Route>
              </Route>

              <Route path="coretools/directory">
                <Route index element={<Directory />} />
                {/* <Route path="create" element={<Createtask />}></Route> */}
              </Route>

              {/* <Route path="edittask/:id" element={<Edittask />}></Route> */}


              {/* <Route path="portfolio/projectform" element={<FormLogic />}>
            <Route path="step-1" element={<ProjectInformation />} />
            <Route path="step-2" element={<PROJECTSTAGES />} />

            <Route path="step-3" element={<ProjectTool />} />
            <Route path="step-4" element={<CostCodes />} />
            <Route path="step-5" element={<ProjectDirectory />} />
            <Route path="step-6" element={<ProjectDrawing />} />
            <Route path="step-7" element={<ProjectSpecifications />} />
            <Route path="step-8" element={<Projectschedule />} />
          </Route>
          <Route
            path="updateProjectForm/projectInfo/:id"
            element={<FormLogic1 />}
          >
            <Route path="step-1" element={<ProjectInformation1 />} />
            <Route path="step-2" element={<PROJECTSTAGES1 />} />

            <Route path="step-3" element={<ProjectTool1 />} />
            <Route path="step-4" element={<CostCodes1 />} />
            <Route path="step-5" element={<ProjectDirectory1 />} />
            <Route path="step-6" element={<ProjectDrawing1 />} />
            <Route path="step-7" element={<ProjectSpecifications1 />} />
            <Route path="step-8" element={<Projectschedule1 />} />
          </Route> */}
              {/* <Route
            exact
            path="projectDirectories"
            element={<ProjectDirectories />}
          ></Route> */}

              <Route
                exact
                path="editCompany/:id"
                element={<EditCompany />}
              ></Route>

              <Route exact path="project" element={<Projects />}></Route>
              <Route exact path="addUser" element={<AddUser />}></Route>

              <Route
                exact
                path="editUserInfo/:id"
                element={<EditUserInfo />}
              ></Route>
              <Route
                exact
                path="projectSetting"
                element={<ProjectSetting />}
              ></Route>
              <Route
                exact
                path="projectList/:id"
                element={<ProjectList />}
              ></Route>
              {/* <Route exact path="overview" element={<Overview />}></Route> */}

              <Route exact path="reports" element={<Reports />}></Route>
              <Route exact path="viewRfi" element={<ViewRfi />}></Route>
              <Route path="innernoAccess" element={<NoAccessPage />} />
            </Route>
          </Route>

          {/* inside project routes ends here  */}
        </Route>
        {/* </Route> */}
      </Routes>
    </>
  );
};

function ProjectManagmentSidbar() {
  return (
    <Sidebar layer="project">
      <Outlet></Outlet>
    </Sidebar>
  );
}

function MainSidebar() {
  return (
    <Sidebar layer="whole">
      <Outlet></Outlet>
    </Sidebar>
  );
}
