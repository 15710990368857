import React from 'react';
import { FaCheck } from "react-icons/fa6";
import Modal from './Modal';
import { IoWarningOutline } from "react-icons/io5";
import { PrimaryButton } from '../Buttons/PrimaryButton';
const SevedModal = ({
    visible,
    setVisible,
    width = "40vw",
    headingText,
    buttonOneText = "No",
    buttonTwoText = "Yes",
    yes,
    heading,
    type,
    loader
}) => {
    return (
        <Modal open={visible} setOpen={setVisible} width={width} draggable={false}>
            <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 py-2" style={{ minHeight: '150px' }}>
                <span
                    className="rounded-circle p-3 border border-secondary mb-3"
                    style={{ border: "1px solid gray" }}
                >
                    {type == 'warning' ? <IoWarningOutline className='text-danger' size={40} /> : <FaCheck className='text-success' size={40} />}
                </span>
                {/* <h4>{heading ? heading : ''}</h4> */}
                <div className="mb-2 fs-5">{headingText}</div>
            </div>

            <div className="d-flex gap-3 justify-content-end mt-3">
                <button
                    className="btn border"
                    onClick={() => setVisible(false)}
                >
                    {buttonOneText}
                </button>

                <PrimaryButton
                    loader={loader}
                    text={buttonTwoText}
                    onClick={yes}
                />
            </div>
        </Modal>
    );
};

export default SevedModal;
