import React, { useEffect, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { useNavigate } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { AddSpecificationFormat, AddSpecificationLanguage, AddSpecificationName, createSpecification, getCompanyByProjectId, getCountForSpecificationsByProjectId, getFormatByProjectId, getFormatByProjectIdtFormat, getFormByProjectId, getLanguageByProjectId, getNameByProjectId, getUserByProjectId, getUsersByCompanyId, uploadDocs } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectCode, getProjectId, getProjectName, getUserId, getUserName, toast } from "../../utils";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import AddSets from "../../Components/Common/AddSets";
import MultiSelects from "../../Components/Common/MultiSelects";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment/moment";
import TimeInput from "../../Components/Inputs/TimeSelector";
import { WORKFLOW_STATUS } from "../../constants";
import Loader from "../../Components/Common/Loader";


export const CreateSpecifications = () => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState();
  const [format, setFormat] = useState(null);
  const [revision, setRevision] = useState();
  const [noIgnore, setNoIgnore] = useState();
  const [language, setLanguage] = useState(null);
  const [issuedDate, setIssuedDate] = useState("");
  const [receivedDate, setReceivedDate] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [statusSelect, setStatusSelect] = useState(null);
  const [formatvisible, setFormatVisible] = useState(false)
  const [languagevisible, setLanguagevisible] = useState(false)
  const [visible, setVisible] = useState(false)
  // const [visible, setVisible] = useState(false)
  const [addName, setAddName] = useState()
  const [formatName, setFormatName] = useState()
  const [languageName, setLanguageName] = useState()
  const [languageData, setLanguageData] = useState()
  console.log(languageData)
  let set = useSelector(d => d.rolesAndPermissionData)
  const isApproverAccess = getActionsPermission("Specification Approver")
  const isReviewerAccess = getActionsPermission("Specification Reviewer")
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [approver, setApprover] = useState(null)
  const [reviewer, setReviewer] = useState(null)
  const [workflowStatus, setWorkflowStatus] = useState();
  const [name, setName] = useState()
  const [hourSpent, setHourSpent] = useState("")
  const [loading ,setLoading]=useState(false)
  const [specManager, setSpecManager] = useState(null);
  const [approvestatus, setApproveStatus] = useState();
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [users, setUsers] = useState([])
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [companies, setCompanies] = useState([])
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [companyId, setCompanyId] = useState()
  const [loader,setLoader]=useState(false)
  const [formats, setFormats] = useState();

  const domain = window.location.origin;
  const statusOptions = [
    { label: "Work In Progress", value: "work in progress" },
    { label: "Incomplete", value: "incomplete" },
    { label: "Completed", value: "completed" },
    { label: "Ready for Review", value: "ready for review" },
    { label: "Not Started", value: "not started" }
  ]

  const approvelOptions = [
    { label: "Draft", value: "draft" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Approved", value: "approved" },

  ]

  console.log(responsiblePerson, "yyy")
  // const status = [
  //   { name: "Draft" },
  //   { name: "Work Required" },
  //   { name: "Ready to close" },
  // ];
  const projectId = localStorage.getItem("projectId");
  const [specificationsCount, setSpecificationsCount] = useState(null);
  console.log(specificationsCount)
  useEffect(() => {

    const fetchSpecificationsCount = async () => {
      try {
        const count = await getCountForSpecificationsByProjectId(projectId); // Call the function
        setSpecificationsCount(count?.data?.data || ''); // Update the state with the result
      } catch (error) {
        console.error('Error fetching specifications count:', error); // Handle errors
      }
    }

    if (projectId) {
      fetchSpecificationsCount();
    }
  }, [projectId]);


  const [Validation, setValidation] = useState({
    title: false,
    issuedDate: false,
    format: false,
    revision: false,
    language: false,
    noIgnore: false,
    receivedDate: false,
  
    specManager: false,
    workflowStatus: false,
   

  });

  // check validation
  const checkValidation = () => {
    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
    }

   



    if (!issuedDate) {
      setValidation((prevState) => ({
        ...prevState,
        issuedDate: true,
      }));
    }
    if (!format) {
      setValidation((prevState) => ({
        ...prevState,
        format: true,
      }));
    }
    if (!responsibleContractor) {
      setValidation((prevState) => ({
        ...prevState,
        contractor: true,
      }));
    }
    if (!membersResponsible) {
      setValidation((prevState) => ({
        ...prevState,
        member: true,
      }));
    }
    if (!revision) {
      setValidation((prevState) => ({
        ...prevState,
        revision: true,
      }));
    }
    if (!language) {
      setValidation((prevState) => ({
        ...prevState,
        language: true,
      }));
    }
    if (!noIgnore) {
      setValidation((prevState) => ({
        ...prevState,
        noIgnore: true,
      }));
    }
    if (!receivedDate) {
      setValidation((prevState) => ({
        ...prevState,
        receivedDate: true,
      }));
    }

   

    if (!specManager) {
      setValidation((prevState) => ({
        ...prevState,
        specManager: true,
      }));
    }
  
    if (!workflowStatus) {
      setValidation((prevState) => ({
        ...prevState,
        workflowStatus: true,
      }));
    }

    if (
      !title ||
      !format ||
      !issuedDate ||
      !revision ||
      !language ||
      !membersResponsible ||
      !responsibleContractor ||
      !specManager || 
      
      !receivedDate ||
     
    

     
      !workflowStatus
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };


  console.log(Validation, "Validation")

  const handleFileInput = (event) => {
    setFileUpload(event);
  };
  // console.log(fileUpload);

  const handleSubmit = async () => {
    if (checkValidation()) {
      return;
    }
    // setLoader(true)
    
    try {
      let uploadResponse = {};
      if (fileUpload.length !== 0) {
        const formData = new FormData();
        for (const file of fileUpload) {
          // console.log(file, "test");
          formData.append("upload", file);
        }
        uploadResponse = await uploadDocs(formData);
      }

      let emailData = []
      if (reviewer) {
        let approverData = allUsersOfProject.find(e => e._id == reviewer)
  
        emailData.push({
          to: approverData?.email_address,
          name: approverData?.first_name,
          tool_number: specificationsCount,
          tool_name: title,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Specification",
          subject: `:FYI: Reviewer appointed created in ${getProjectName()}`,
          url: `${domain}/coretools/Specifications`,
          template_type: "createdToolEmailToReviewer",
        })
      }
      if (approver) {
        let reviewerData = allUsersOfProject.find(e => e._id == approver)
        emailData.push({
          to: reviewerData.email_address,
          name: reviewerData.first_name,
          tool_number: specificationsCount,
          tool_name: title,
          subject: `FYI: Approver appointed created in ${getProjectName()}`,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Specification",
          url: `${domain}/coretools/Specifications`,
          template_type: "createdToolEmailToApprover",
        })
      }
  
      

    specManager?.forEach((userId) => {
      let reviewerData = allUsersOfProject.find((e) => e._id == userId)
      if (reviewerData) {
        emailData?.push({
          to: reviewerData?.email_address,
          name: reviewerData?.first_name,
          tool_number: specificationsCount,
          tool_name: title,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Specification",
          subject: `FYI:New Specification created in ${getProjectName()}`,
          userType: "Responsible Person",
          url: `${domain}/coretools/Specifications`,
          template_type: "createdToolEmailToAssociatedUsers",
        })
      }
  })
     
  
      console.log(emailData)
// return
      const payload = {
        set: title,
        format: format,
        responsible_contractor_company: responsibleContractor,
        responsible_member: membersResponsible,
        issuedDate: issuedDate,
        receivedDate: receivedDate,
        numberToIgnore: noIgnore,
        specificationLanguage: language,
       
        description: description,
        defaultRevision: revision,
        responsible_person: responsiblePerson,
        spec_id: specificationsCount,
        workflow_status: workflowStatus,
        spec_manager: specManager,
        attachFile: uploadResponse ? uploadResponse?.data : [],
        project_id: projectId,
        reviewer_id: reviewer,
        approver_id: approver,
        email_data: emailData,
        hours_spent: hourSpent,
        creator_email: getUserId(),
      };
      // console.log(payload)
      // return
      if (payload.reviewer_id && payload?.approver_id) {
        payload.status = "inreview";
      }

      let res = await createSpecification(payload);
     
      console.log(res)
      if (res?.data?.message === "Spec ID Already Exist") {
        toast.error("Document number already exists. Please use a different number.");
      } else {
        toast.success("Submittal Created Successfully!");

        navigate("/projectManagement/Specifications");

      }
      setLoader(false)
    } catch (error) {
      if (error.response?.data?.message === "Spec ID Already Exist") {
        toast.error(error.response.data?.message);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error details:", error);
      }
    }
  };

  const navigate = useNavigate();
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
      // console.log(allUsers, "================================================")
    }
    catch (err) {
      console.log(err)
    }
  }

  const getTitileName = async (projectId) => {
    try {
      const res = await getNameByProjectId(projectId)
      console.log(res?.data?.data);
      // return
      const Name = res?.data?.data
      setName(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTitileName(projectId)
  }, [])
  const submitName = async () => {
    setLoading(true)
    try {
      const payload = {
        name: addName,
        project_id: projectId
      }
      let t = await AddSpecificationName(payload)
      console.log(t)
      setAddName('')
      getTitileName(projectId)
      setVisible(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getFormatename = async (projectId) => {
    try {
      const res = await getFormatByProjectId(projectId)
      console.log(res?.data?.data);
      // return
      const Name = res?.data?.data
      setFormats(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getFormatename(projectId)
  }, [])
  const submitFormat = async () => {
    setLoading(true)
    try {
      const payload = {
        name: formatName,
        project_id: projectId
      }
      let t = await AddSpecificationFormat(payload)
      console.log(t)
      setFormatName('')
      getFormatename(projectId)
      setFormatVisible(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getLanguagename = async (projectId) => {
    try {
      const res = await getLanguageByProjectId(projectId)
      console.log(res?.data?.data);
      // return
      const Name = res?.data?.data
      setLanguageData(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getLanguagename(projectId)
  }, [])
  const submitLanguage = async () => {
    setLoading(true)
    
    try {
      const payload = {
        name: languageName,
        project_id: projectId
      }
      let t = await AddSpecificationLanguage(payload)
      console.log(t)
      setLanguageName('')
      getLanguagename(projectId)
      setLanguagevisible(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }



  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value;
    setResponsibleContractor(selectedCompanyIds);
    console.log(selectedCompanyIds, "test");


    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(getProjectId(), { company_id: [companyId] });
          console.log(companyId, "id")
          console.log(res, "res")
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  console.log(users, "test")


  const companyUser = async (companyId) => {
    try {
      const res = await getUsersByCompanyId(projectId, companyId)

      console.log(res.data, "77777777777777777")
      if (res) {
        const userOptions = res?.data?.data?.map(user => ({
          label: user.name,
          value: user._id
        }));
        console.log(userOptions)
        setUsers(userOptions);
      } else {
        console.error("Response data is not in the expected format");
      }
    }
    catch (error) {
      console.log("Error fetching company data:", error);
    }
  }

  useEffect(() => {
    if (companyId)
      companyUser(companyId)
  }, [companyId])
  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      console.log("*********** Response:", res);
      if (res && res?.data) {

        const companyNames = res?.data?.data[0]?.companynames;
        console.log(companyNames, "companynames-------")

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id

        }));

        setCompanies(formattedCompanies);
        console.log(companies, "hhhhhhhhhhhhhhh")
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany()
  }, [])

  useEffect(() => {
    getAllUsersOfProject()
  }, [])


  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };



  function convertHourlySpentStringIntoObject(str) {

    if (typeof str === "string") {
      return {
        hours: parseInt(str.split("-")[0]) || "00",
        minutes: parseInt(str.split("-")[1]) || "00",
      };
    } else {
      return {
        hours: "0",
        minutes: "0",
      };
    }
  }


  return (
    <>

      <div className=" main-container">
        <div>
          <div className="row m-0 p-0">
            <div className="col-md-12  ">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/projectManagement/Specifications")}
              >
                {" "}
                Specifications
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> Create Specifications</span>
            </div>
          </div>
        
          {/* Top Create New Rfi End */}
{loader ? (
  <Loader/> 

  ):(  <div className="row m-0 p-0 mt-4">
            <div className="col-md-12 ">
              <span className="newRfi_text"> Create Specifications </span>
            </div>

        
            <div className="row m-0 p-0 mt-3 ">
             
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label className="labels mb-2"> Set Name :</label>{" "}
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center  ">


                    <AutoCompleteSelect
                      options={name?.map((item) => ({
                        label: capitalizeFirstLetter(item?.name),
                        value: item?._id,
                      })) || []}
                      value={title}
                      placeholder=" Set Name"
                      optionLabel="lebal"
                      onChange={(e) => {
                        setTitle(e)
                        setValidation({ ...Validation, title: false })
                      }}
                      className={Validation.title ? "Validation" : ""}
                      onClick={() => setValidation({ ...Validation, title: false })}
                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label className="labels mb-2"> Format :</label>{" "}
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center  ">


                    <AutoCompleteSelect
                      optionLabel="lebal"
                      options={formats?.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      })) || []} // Add fallback for options
                      placeholder="Format"
                      value={format}
                      onChange={(value) => {
                        setFormat(value)
                        setValidation({ ...Validation, format: false })
                      }} // Correctly set the selected format
                      className={Validation.format ? "Validation" : ""}
                      onClick={() => setValidation({ ...Validation, format: false })}
                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setFormatVisible(true)}
                  />
                </div>
              </div>


              <div className="row p-0 mt-3 m-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor Company (s):{" "}
                  </label>

                  <MultiSelect
                    value={responsibleContractor}
                    //  onChange={(e) => setResponsibleContractor(e.value)}  
                    onChange={handleCompanyChange}
                    options={companies}
                    optionLabel="label"
                    filter placeholder="Company"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""}`}
                    onClick={() =>
                      setValidation({ ...Validation, contractor: false })
                    }

                  />

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member (s):
                  </label>
                  <MultiSelect
                    value={membersResponsible}
                    onChange={(e) => setMembersResponsible(e.value)}
                    options={users}
                    optionLabel="label"
                    filter placeholder="Select Member"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`w-full md:w-20rem ${Validation.membersResponsible ? "Validation" : ""}`}
                    onClick={() =>
                      setValidation({ ...Validation, membersResponsible: false })
                    }
                  />


                </div>
              </div>
              <div className="row p-0 mt-3 m-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec Manager(s): <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                    value={specManager}
                    optionLabel="label"
                    onChange={(e) => setSpecManager(e?.value)}
                    options={allUsersOfProject?.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    filter
                    placeholder="Select Member"
                    style={{ width: "100%" }}
                  className={`p-0 ${Validation.specManager ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, specManager: false })
                  }
                  />
                </div>

                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec Language:<span className="text-danger">*</span>
                    </label>

                    <div className="card flex justify-content-center  ">


                      <AutoCompleteSelect
                        // className="p-0"
                        placeholder=" Specifications Language"
                        options={languageData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []} // Add fallback for options
                        optionLabel="lebal"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e)
                          setValidation({ ...Validation, language: false })
                        }}
                        className={Validation.language ? "Validation p-0" : ""}
                        onClick={() =>
                          setValidation({ ...Validation, language: false })
                        }
                      />

                    </div>
                  </div>
                  <div className="mt-3 d-flex align-items-end ">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLanguagevisible(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="row p-0 mt-3 m-0">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec ID
                  </label>
                  <TextInput

                    placeholder="Spec ID"
                    onChange={(e) => setSpecificationsCount(e?.target?.value)

                    }

                    value={specificationsCount}


                  />
                </div>

                <div className="col-md-6  col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec version:<span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="Spec version"
                    value={revision}
                    onChange={(e) => {
                      setRevision(e.target.value);
                    }}
                    className={Validation.revision ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, revision: false })
                    }
                  />
                </div>
              </div>



              <div className="col-md-6  mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec Issue Date:<span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  // className="p-0"
                  placeholder="dd/mm/yy"
                  selected={moment(issuedDate, "DD/MM/YY").format("YYYY-MM-DD")}
                  onChange={(e) => setIssuedDate(e.target.value)}

                  className={Validation.issuedDate ? "Validation p-2" : "form-control"}
                  onClick={() =>
                    setValidation({ ...Validation, issuedDate: false })
                  }
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec Received Date:<span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  // className="p-0"
                  placeholder="dd/mm/yy"
                  selected={receivedDate}
                  onChange={(e) => setReceivedDate(e.target.value)}
                  className={Validation.receivedDate ? "Validation p-2" : "form-control"}
                  onClick={() =>
                    setValidation({ ...Validation, receivedDate: false })
                  }
                />
              </div>



              <div className="row p-0 mt-3 m-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Hours Spent:
                  </label>


                  <TimeInput
                    value={convertHourlySpentStringIntoObject(hourSpent)}
                    onChange={(newValue) =>
                      setHourSpent(`${newValue.hours}-${newValue.minutes}`)
                    }
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person (s):
                  </label>
                  <MultiSelect
                    value={responsiblePerson}
                    onChange={(e) => setResponsiblePerson(e.value)}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))} optionLabel="label"
                    filter placeholder="Select Person"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""}`}

                  />

                </div>

              </div>



              <div className="row p-0 mt-3 m-0">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Workflow Status: <span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={workflowStatus}
                    options={WORKFLOW_STATUS}
                    placeholder="Status"
                    onChange={(e) => setWorkflowStatus(e.target.value)}
                    className={Validation.workflowStatus ? "Validation p-0" : "p-0"}
                    onClick={() =>
                      setValidation({ ...Validation, workflowStatus: false })
                    }
                  // disabled={
                  //   alldata.rfi_approval_status == "approved" ||
                  //   alldata.rfi_review_status == "approved"
                  // }
                  />
                </div>
                {/* <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Approval Status: <span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={approvestatus}

                    options={approvelOptions.map((item) => ({
                      label: item?.label,
                      value: item?.value,
                    }))}
                    placeholder="Approvel Status"
                    onChange={(e) => setApproveStatus(e.target.value)}
                    className={Validation.approvestatus ? "Validation p-0" : "p-0"}
                    onClick={() =>
                      setValidation({ ...Validation, approvestatus: false })
                    }
                  // disabled={
                  //   alldata.rfi_approval_status == "approved" ||
                  //   alldata.rfi_review_status == "approved"
                  // }
                  />
                </div> */}
              </div>


              <div className="row p-0 mt-3 m-0"></div>
              {isReviewerAccess &&
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <AutoCompleteSelect
                      options={allUsersOfProject?.map((item) => {
                        if (item?._id !== getUserId()) {
                          return {
                            label: item?.first_name,
                            value: item?._id,
                          }
                        }
                      })}
                      value={reviewer}
                      optionLabel="label"
                      onChange={(value) => {
                        setReviewer(value);
                      }}
                     
                   

                      disabled={reviewer === getUserId()}
                      placeholder="Select Reviewer"

                    />

                </div>}
              {isApproverAccess &&
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>
                  
                  <AutoCompleteSelect
                        options={allUsersOfProject?.map((item) => {
                          if (item?._id !== getUserId()) {
                            return {
                              label: item?.first_name,
                              value: item?._id,
                            }
                          }
                        })}
                        value={approver}
                        optionLabel="label"
                        onChange={(value) => {
                          setApprover(value);
                        }}
                       

                        disabled={approver === getUserId()}
                        placeholder="Select Approver"

                      />
                </div>
              }

            </div>


            <div className="col-md-12  pr-5 row mt-3 m-0 p-0">
              <label htmlFor="" className="py-2 labelCss">
                Description :
              </label>
              <div className="">
                <Editor
                  style={{ height: "200px" }}
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  className={`p-0 ${Validation.description ? "Validation" : ""
                    }`}
                  onClick={() =>
                    setValidation({ ...Validation, description: false })
                  }
                />
              </div>
            </div>

            <div className="col-md-12  row m-0 p-0">
              <DragAndDrop
                setRemovePhoto={setRemovePhoto}
                value={fileName}
                setFiles={fileUpload}
                onChange={handleFileInput}
                setValue={setFileName}
                setPreviousFiles={setPreviousFiles}
              />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <span
                className="cancelBtn py-2 px-2"
                onClick={() => navigate("/projectManagement/specifications")}
              >
                {" "}
                Cancel
              </span>
              &nbsp;&nbsp;
              <span className="CreateRfi px-2 py-2" onClick={handleSubmit}>
                Create
              </span>
            </div>
          </div>
  
  
)}
        </div>
      </div>
      <br />
      <AddSets
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Specification Set "}
        label={"Set"}
        reqFor={"create"}
        loading={loading}
        setLoading={setLoading}
        value={addName}
        setValue={setAddName}
        onSubmit={submitName}
      />
      <AddSets
       loading={loading}
       setLoading={setLoading}
        visible={formatvisible}
        setVisible={setFormatVisible}
        CategoryName={" Format "}
        label={"Format"}
        reqFor={"create"}
        value={formatName}
        setValue={setFormatName}
        onSubmit={submitFormat}
      />
      <AddSets
       loading={loading}
       setLoading={setLoading}
        visible={languagevisible}
        setVisible={setLanguagevisible}
        CategoryName={"Spec Language "}
        label={"language"}
        reqFor={"create"}
        value={languageName}
        setValue={setLanguageName}
        onSubmit={submitLanguage}
      />

    </>
  );
};
