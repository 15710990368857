import React, { useEffect, useState } from "react";
import style from "./input.module.css";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAttachment } from "react-icons/md";
import { toast } from "../../utils";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const DragAndDrop = ({
  value,
  onChange,
  acceptFiles,
  requiredMark,
  label,
  disabled,
  setValue,
  setRemovePhoto,
  setPreviousFiles,
  className,
  onClick,
  onRemoveChange,
  multiSelect,
  tooltipText
}) => {
  let randomId = Math.random();
  let [files, setFiles] = useState([]);

  // useEffect(() => {
  //   if (value !== undefined) {
  //     setFiles(value);
  //   }
  // }, [value]);

  const handleDrop = (event) => {
    event.preventDefault();
    onChangeSubmit(event.dataTransfer.files);
    console.log(event.dataTransfer.files[0]);
    setFiles((pre) => [...pre, ...event.dataTransfer.files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleRemove = (index) => {
    const ind = index - (value.length - files.length);
    console.log(value[index], files[ind]);
    const removedImg = value[index];
    const filteredData = value.filter((item) => item !== removedImg);
    setValue(filteredData);
    if (ind >= 0) {
      const data = files.filter((item) => item !== files[ind]);
      console.log(data);
      onRemoveSubmit(data, files[ind]);
    } else {
      setRemovePhoto((pre) => [...pre, removedImg.name]);
      setPreviousFiles((pre) =>
        pre.filter((item) => item._id != removedImg.id)
      );
      onRemoveSubmit([], removedImg);
    }
  };

  function onChangeSubmit(file) {
    let filArr = []
    file.forEach((data) => {
      filArr.push({ name: data.name, id: "012" + data.name })
    })

    // setFiles((pre) => [...pre, ...file]);

    setValue([...value, ...filArr]);

    onChange && onChange([...files, ...file]);
  }
  function onRemoveSubmit(data, file) {
    // console.log(file);
    setFiles(data, file);
    onChange && onChange(data);
    onRemoveChange && onRemoveChange(data, file);
  }

  return (

    <>
      {/* <Tooltip target=".multiple_select" content="thius klsjdf"  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}/> */}
      <div onClick={onClick} className="multiple_select">
        <label className="mb-1 add_class" htmlFor={randomId}>
          {/* {label || "attach files"} */}
          <Tooltip placement="top" title={tooltipText || "Choose a document to upload"}
            arrow
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: '0px',
                  },
                }
              }
            }}

          >
            <div className="d-flex gap-1">
              <span
                className="attText"
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                <div>
                  <MdOutlineAttachment size={22} /> {label ? label : "Attach File:"}{" "}
                </div>
              </span>
              <span className="text-danger">{` ${requiredMark ? "*" : ""}`}</span>
            </div>
          </Tooltip>
        </label>
        <label
          className="w-100 mt-1"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div
            className={`${style.dragAndDrop_main_con} ${className} w-100 d-flex`}
          >
            {!value?.length ? (
              <label>
                File(s)
                <br />
                <br />
              </label>
            ) : (
              <div className=" d-flex flex-wrap w-auto gap-4 px-2 py-2 ">
                {value.map((res, index) => (
                  <div className={style.file_main_con} key={index}>
                    <div
                      className={style.remove_icon}
                      onClick={() => handleRemove(index)}
                    >
                      <RxCross2 />
                    </div>
                    {res.name ? (
                      <>
                        {" "}
                        {res?.name?.length > 8
                          ? res?.name.substr(0, 8) + "..."
                          : res?.name}
                      </>
                    ) : (
                      <>
                        {" "}
                        {res?.fileName?.length > 8
                          ? res?.fileName.substr(0, 8) + "..."
                          : res?.fileName}
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </label>
        <input
          id={randomId}
          disabled={disabled}
          onChange={(e) => {
            onChangeSubmit(e.target.files);
            e.target.value = "";
          }}
          type="file"
          hidden
          multiple={multiSelect ? true : false}
        />
      </div >
    </>
  );
};
