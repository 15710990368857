import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";

export default function ScheduleCalender() {
  const navigate = useNavigate();

  const localizer = momentLocalizer(moment);
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: "some-color",
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
      padding: "2px",
    };
  };
  const data = [
    { title: "03 - Submittals: Lighting", date: "2/3/2024" },
    { title: "03 - Submittals: Lighting", date: "3/3/2024" },
    { title: "03 - Submittals: Lighting", date: "8/3/2024" },
  ];

  const events = data.map((item) => ({
    title: item.title,
    start: moment(item.date, "DD/MM/YYYY").toDate(),
    end: moment(item.date, "DD/MM/YYYY").toDate(),
  }));

  const handleSelectEvent = (event) => {
    navigate("/schedule/viewevent");
  };

  return (
    <>
      <div className="mt-4">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800 }}
          eventStyleGetter={eventStyleGetter}
          onSelectEvent={handleSelectEvent}
        />
      </div>
    </>
  );
}
