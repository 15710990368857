import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom'
import TextInput from '../../Components/Inputs/TextInput';
import SingleSelect from '../../Components/Common/SingleSelect';
import { addDailyLogData, getCountForDailyByProjectId, getDailyLogDataById, getUserByProjectId, updateDailyLogData, uploadMultipleDocs } from '../../Api/Admin/AllAPIs';
import { getProjectCode, getProjectId, getProjectName, getUserId, getUserName, toast } from '../../utils';
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import CustomDragAndDrop from '../../Components/Inputs/CustomDragAndDrop';
import moment from 'moment';
import TimeInput from '../../Components/Inputs/TimeSelector';
import { TOOL_STATUS } from '../../constants';


export const CreateUpdateDailyLog = () => {
    let navigate = useNavigate();
    let projectId = getProjectId()
    const { id } = useParams();
    let [dailyLogData, setDailyLogData] = useState({})
    let [deletedFiles, setDeletedFiles] = useState([])
    const [loader, setLoader] = useState(false)
    const projectData = JSON.parse(localStorage.getItem("projectData"));
    const domain = window.location.origin;
    const userData = localStorage.getItem("userData");
    const userObj = JSON.parse(userData);
    const userId = userObj._id;
    const userName = userObj.name;

    useEffect(() => {
        if (id) {
            getDailyLog(id)
        }
    }, [])

    async function getDailyLog(id) {
        try {
            let res = await getDailyLogDataById(id)
            console.log(res.data.data, "lllllll")
            let response = res?.data?.data?.[0]
            setDailyLogData(res?.data?.data?.[0])

            setFiles(response?.Attachments?.map(e => {
                delete e._id
                e.file = null;
                return e
            }) || [])

            reset({
                visitorName: response.visitorName,
                plannedStartDate: moment(response.plannedStartDate).format("YYYY-MM-DD") || "",
                plannedEndDate: moment(response.plannedEndDate).format("YYYY-MM-DD") || "",
                actualStartDate: moment(response.actualStartDate).format("YYYY-MM-DD") || "",
                actualEndDate: moment(response.actualEndDate).format("YYYY-MM-DD") || "",
                visitStatus: response.visitStatus,
                visitPurpose: response.visitPurpose,
                visitorCompanyName: response.visitorCompanyName,
                companyType: response.companyType,
                companyAddress: response.companyAddress,
                approver_id: response.approver_id,
                reviewer_id: response.reviewer_id,
                project_id: response.project_id,
                dailyLogId: response.dailyLogId,
                plannedStartTime: moment.utc(response.plannedStartDate).format("HH:mm") || "",
                plannedEndTime: moment.utc(response.plannedEndDate).format("HH:mm") || "",
                actualStartTime: moment.utc(response.actualStartDate).format("HH:mm") || "",
                actualEndTime: moment.utc(response.actualEndDate).format("HH:mm") || "",
                hoursSpent: response?.hoursSpent || "",
                approver_comment: response?.approver_comment || "",
                reviewer_comment: response?.reviewer_comment || "",
                created_by: userName,
                project_name:projectData.project_name,
                project_number: projectData.projectNumber,
            });


        } catch (error) {
            console.log(error)
        }
    }

    let [allUsersOfProject, setAllUsersOfProject] = useState([])
    let [files, setFiles] = useState([])



    let visitStatus = ["Access Granted", "Access Rejected"]

    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues:
        {
            visitorName: "",
            plannedStartDate: "",
            plannedStartTime: "",
            actualStartTime: "",
            plannedEndTime: "",
            actualEndTime: "",
            status: TOOL_STATUS.draft,
            plannedEndDate: "",
            actualStartDate: "",
            actualEndDate: "",
            visitStatus: "",
            visitPurpose: "",
            visitorCompanyName: "",
            companyType: "",
            companyAddress: "",
            dailyLogId: "",
            project_id: getProjectId(),
            approver_id: null,
            reviewer_id: null,
            hoursSpent: "",
            email_data: [],
            creator_email: getUserId(),
            created_by: userName,
            project_name:projectData.project_name,
            project_number: projectData.projectNumber,
        }

    });


    async function getAllUsersOfProject() {
        try {
            let res = await getUserByProjectId(projectId);
            let allUsers = res?.data?.data?.[0]?.combined_users || [];
            setAllUsersOfProject(allUsers);
            console.log(allUsers, "================================================");

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAllUsersOfProject()
    }, [])


    async function onSubmit(data) {
        try {
            setLoader(true)
            let allFiles = [...dailyLogData?.Attachments || []]
            console.log(files)

            let newFiles = files.filter(res => res.file).map(e => e.file)
            console.log(newFiles)

            if (newFiles.length > 0) {
                let re = await uploadMultipleDocs(newFiles)
                console.log(re.data.data)
                allFiles = [...allFiles, ...re?.data?.data || []]
            }

            let body = {
                ...data,
                plannedStartDate: `${data.plannedStartDate}T${data.plannedStartTime}`,
                plannedEndDate: `${data.plannedEndDate}T${data.plannedEndTime}`,
                actualStartDate: `${data.actualStartDate}T${data.actualStartTime}`,
                actualEndDate: `${data.actualEndDate}T${data.actualEndTime}`,
                Attachments: [...allFiles],
                email_data: []
            }

            let res;

            if (id) {
                // edit daily log 
                if (deletedFiles.length > 0) {
                    body.deletedFiles = deletedFiles
                }
                body.user_id = getUserId()
                body.user_name = getUserName()


                if (dailyLogData.status === TOOL_STATUS.draft && body.reviewer_id && body.approver_id) {
                    body.status = TOOL_STATUS.inreview
                }

                if (dailyLogData.reviewer_id !== body.reviewer_id) {

                    let approverData = allUsersOfProject.find(e => e._id == body.approver_id)
                    let reviewerData = allUsersOfProject.find(e => e._id == body.reviewer_id)
                    // add data of email to approver 
                    body.email_data.push({
                        name: approverData?.first_name,
                        to: approverData?.email_address,
                        reviewer_name: reviewerData.first_name,
                        tool_number: body?.dailyLogId,
                        tool: "Daily Log",
                        tool_name: body.visitorName,
                        project_name: getProjectName(),
                        subject: `FYI: Approver appointed to Daily Log #${body?.dailyLogId} ${body.visitorName}`,
                        project_id: getProjectCode(),
                        template_type: "addedReviewerEmailToAll",
                        url: `${domain}/projectManagement/dailyLog`,
                        updated_by: getUserName()
                    });
                }

                if (dailyLogData.approver_id !== body.approver_id) {
                    let reviewerData = allUsersOfProject.find(e => e._id == body.reviewer_id)
                    let approverData = allUsersOfProject.find(e => e._id == body.approver_id)
                    // send email to reviewer 
                    body.email_data.push({
                        name: reviewerData?.first_name,
                        approver_name: approverData.first_name,
                        to: reviewerData?.email_address,
                        tool_number: body?.dailyLogId,
                        tool: "Daily Log",
                        tool_name: body.visitorName,
                        project_name: getProjectName(),
                        subject: `FYI: Approver appointed to Daily Log #${body?.dailyLogId} ${body.visitorName}`,
                        project_id: getProjectCode(),
                        template_type: "addedApproverEmailToAll",
                        url: `${domain}/projectManagement/dailyLog`,
                        updated_by: getUserName()

                    });
                }



                res = await updateDailyLogData(id, body)
                toast.success("Daily log Updated successfully")
            }
            else {
                // create daily log 
                if (body.reviewer_id && body.approver_id) {
                    body.status = TOOL_STATUS.inreview
                    // add data for email send to approver and reviewer 
                }

                // send email to reviewer if selected 
                if (body.reviewer_id) {
                    let reviewerData = allUsersOfProject.find(e => e._id == body.reviewer_id)
                    if (reviewerData) {
                        body.email_data.push({
                            name: reviewerData?.first_name,
                            to: reviewerData?.email_address,
                            tool_number: body?.dailyLogId,
                            tool: "Daily Log",
                            tool_name: body.visitorName,
                            subject: `Action Required: Appointed as the Reviewer to  Daily Log #${body?.dailyLogId} ${body.visitorName}`,
                            project_name: getProjectName(),
                            project_id: getProjectCode(),
                            template_type: "createdToolEmailToReviewer",
                            created_by: getUserName(),
                            url: `${domain}/projectManagement/dailyLog`,
                        })
                    }
                }
                // send email to approver if selected 
                if (body.approver_id) {
                    let approverData = allUsersOfProject.find(e => e._id == body.approver_id)
                    if (approverData) {
                        body.email_data.push({
                            name: approverData?.first_name,
                            to: approverData?.email_address,
                            tool_number: body?.dailyLogId,
                            tool: "Daily Log",
                            subject: `Action Required: Appointed as the Approver to  Daily Log #${body?.dailyLogId} ${body.visitorName}`,
                            tool_name: body.visitorName,
                            project_name: getProjectName(),
                            project_id: getProjectCode(),
                            template_type: "createdToolEmailToApprover",
                            created_by: getUserName(),
                            url: `${domain}/projectManagement/dailyLog`,
                        })
                    }
                }
                res = await addDailyLogData(body)
                toast.success("Daily log Created successfully")
            }
            navigate("/projectManagement/dailyLog")
            console.log(res, "llll")
        }
        catch (err) {
            toast.error(err?.response?.data?.message || "Some Error occured")
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (deletedFiles.length > 0 && id) {

            setDailyLogData(pre => {
                pre.Attachments = pre.Attachments.filter(e => !deletedFiles.includes(e.fileName))
                return ({ ...pre })
            })
        }

    }, [deletedFiles])


    useEffect(() => {
        if (!id) {
            getCount()
        }
    }, [])

    async function getCount() {
        try {
            let res = await getCountForDailyByProjectId(getProjectId())
            console.log(res.data.data, "ppppp")
            setValue("dailyLogId", res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    function convertHourlySpentStringIntoObject(str) {
        return ({
            hours: parseInt(str.split("-")[0]) || "00",
            minutes: parseInt(str.split("-")[1]) || "00",
        })
    }
    function onError() {
        toast.info("Please Fill all the Fields Marked with * are Mandatory")
    }
    return (
        <div>

            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div>
                    <span
                        className="open_Rfi_text"
                        onClick={() => navigate("/projectManagement/dailyLog")}
                    >
                        Daily Log
                    </span>
                    <span className="px-1 open_Rfi_text">/</span>
                    <span className="createNew_text"> {id ? "Edit" : "Create"} Daily Log</span>
                </div>
                <div className="heading my-3">
                    DAILY LOG
                </div>

                <div className="row m-0 p-0">

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Visitor Name: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="visitorName"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.visitorName && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Visitor Name"}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Visit ID: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="dailyLogId"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.dailyLogId && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Visit ID"}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-6">
                        <label className="py-2 labelCss">Hours Spent <span className="text-danger">*</span></label>

                        <Controller
                            name="hoursSpent"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TimeInput
                                        className={errors?.hoursSpent && "Validation"}
                                        value={convertHourlySpentStringIntoObject(value)}
                                        onChange={(e) => {
                                            onChange(`${e.hours}-${e.minutes}`)
                                        }}
                                    />
                                );
                            }}
                        />




                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Visit Purpose: <span className="text-danger">*</span>
                        </label>

                        <Controller
                            name="visitPurpose"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.visitPurpose && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Visit Purpose"}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 row m-0 p-0 col-12">

                        <div className='col-6'>
                            <label htmlFor="" className="py-2 labelCss">
                                Planned Start Date: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="plannedStartDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="date"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                console.log(e.target.value)
                                            }}
                                            className={`${errors?.plannedStartDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="" className="py-2 labelCss">
                                Planned Start Time: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="plannedStartTime"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="time"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                console.log(e.target.value)
                                            }}
                                            className={`${errors?.plannedStartDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 row m-0 p-0 col-12">
                        <div className="col-6">

                            <label htmlFor="" className="py-2 labelCss">
                                Planned End Date: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="plannedEndDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="date"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.plannedEndDate ? "Validation" : ""
                                                } form-control`}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="col-6">

                            <label htmlFor="" className="py-2 labelCss">
                                Planned End Time: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="plannedEndTime"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="time"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.plannedEndDate ? "Validation" : ""
                                                } form-control`}
                                        />
                                    );
                                }}
                            />
                        </div>


                    </div>


                    <div className="col-md-6 row m-0 p-0 col-12">

                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Actual Start Date: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="actualStartDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="date"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.actualStartDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Actual Start Time: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="actualStartTime"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="time"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.actualStartDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 row m-0 p-0 col-12">

                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Actual End Date: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="actualEndDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="date"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.actualEndDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Actual End Time: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="actualEndTime"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            type="time"
                                            style={{ height: "50px" }}
                                            placeholder="Due Date"
                                            onChange={onChange}
                                            value={value}
                                            className={`${errors?.actualEndDate ? "Validation" : ""
                                                } form-control`}

                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>


                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Visit Status <span className="text-danger">*</span>:
                        </label>

                        <Controller
                            name="visitStatus"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <SingleSelect
                                        className={errors?.visitStatus ? "Validation" : ""}
                                        placeholder="Visit Status"
                                        options={visitStatus}
                                        value={value}
                                        onChange={onChange}
                                    />
                                );
                            }}
                        />


                    </div>




                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Visitor Company Name: <span className="text-danger">*</span>
                        </label>

                        <Controller
                            name="visitorCompanyName"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.visitorCompanyName && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Visitor Company Name"}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Company Type:
                        </label>

                        <Controller
                            name="companyType"

                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.companyType && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Company Type"}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Company Address: <span className="text-danger">*</span>
                        </label>

                        <Controller
                            name="companyAddress"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.companyAddress && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Company Address"}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Reviewer:
                        </label>

                        <Controller
                            name="reviewer_id"

                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <SingleSelect
                                        className={errors?.reviewer_id ? "Validation" : ""}
                                        placeholder="Reviewer"
                                        options={allUsersOfProject.filter(d => d._id !== getUserId())}
                                        optionLabel={"first_name"}
                                        value={allUsersOfProject?.find(e => e._id == value) || null}
                                        onChange={(e) => {
                                            let userData = e.value
                                            onChange(userData._id)
                                            // set email data on create mode 

                                        }}
                                        filter={true}
                                        filterInputAutoFocus={true} />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Approver:
                        </label>

                        <Controller
                            name="approver_id"

                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <SingleSelect
                                        className={errors?.approver_id ? "Validation" : ""}
                                        placeholder="Approver"
                                        options={allUsersOfProject.filter(d => d._id !== getUserId())}
                                        optionLabel={"first_name"}
                                        value={allUsersOfProject.find(e => e._id == value) || null}
                                        onChange={(e) => {
                                            onChange(e.value._id)
                                        }}
                                        filter={true}
                                        filterInputAutoFocus={true}
                                    />
                                );
                            }}
                        />


                    </div>

                    {id && <>
                        <div className="col-md-6 col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Approver Comment :
                            </label>
                            <Controller
                                name="approver_comment"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <textarea
                                            disabled
                                            className='form-control'
                                            value={value}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Reviewer Comment :
                            </label>
                            <Controller
                                name="reviewer_comment"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <textarea
                                            disabled
                                            className='form-control'
                                            value={value}
                                        />
                                    );
                                }}
                            />
                        </div>

                    </>}

                    <div className="col-12 mt-3">
                        <CustomDragAndDrop
                            value={files}
                            setDeleted={setDeletedFiles}
                            onChange={(e) => {
                                console.log(e)
                                setFiles(e)
                            }}
                            label={"Documents"}
                        />
                    </div>


                    <div className="col-12">
                        <div className="btn_main mt-5 d-flex align-items-center justify-content-end gap-3">
                            <Link to={"/projectManagement/dailyLog"}>
                                <span className="btn-Secondary  px-2 py-2">Cancel</span>
                            </Link>
                            <PrimaryButton
                                loader={loader}
                                type="submit" className={"py-2"} text={id ? "Update" : "Create"} />
                        </div>

                    </div>


                </div>
            </form>
            <br />
        </div >
    )
}
