import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getFormsById } from '../../Api/Admin/AllAPIs'
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable'
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName'
import moment from 'moment/moment'
import { AiOutlineExport } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ViewPagesPdfTemplate } from '../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate'
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText'
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf'
import FormSkeleton from '../../Components/Common/FormSkeleton'
import ViewVersion from '../../Components/Common/ViewVersion'

export const ViewForm = () => {
    let [data, setData] = useState({})
    let navigate = useNavigate()
    let { id } = useParams()
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const skeletonData = [
        { qty: 1, height: "2.5rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },

        { qty: 1, height: "5rem" },


    ]

    useEffect(() => {
        getData()
    }, [])
    async function getData() {
        try {
            setSkeletonLoader(true);
            let res = await getFormsById(id)
            console.log(res?.data?.data?.[0])
            setData(res?.data?.data?.[0]);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setSkeletonLoader(false);
        }
    }
    let pdfRef = useRef(null)
    function pdfDownload() {
        downloadPdf(pdfRef.current, [
            { heading: "Attached Documents", files: data?.forms_file.map(e => e.url) }
        ])
    }

    return (
        <div>
            <div>
                <div className="paint-touch">
                    <div className="row">
                        <div className="col-12 py-3 ">
                            <span
                                className="open_Rfi_text"
                                onClick={() => navigate("/projectManagement/forms")}
                            >
                                {" "}
                                Forms
                            </span>
                            <span className="px-1 open_Rfi_text"> /</span>
                            <span className="createNew_text"> {data?.forms_name} </span>
                        </div>
                    </div>
                    <br />

                    {
                        skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-1"} gap={"gap-3"} /> :
                            <div className="row">
                                <div className="d-flex justify-content-between mb-3">
                                    <h1 className="heading">{data?.forms_name} </h1>
                                    <div className="d-flex align-items-center gap-2" onClick={pdfDownload}>
                                        <div
                                            className="d-flex gap-2 align-items-center"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <AiOutlineExport />
                                            Export
                                            <MdOutlineKeyboardArrowDown />
                                        </div>
                                        {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                                    <BsThreeDotsVertical />
                                </div> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <ViewPageTable tableData={[
                                            { "Forms Name": data?.forms_name },
                                            { "Created On": data?.forms_date_created && moment(data?.forms_date_created).format("DD/MM/YYYY") || "--" },
                                            { "Status": data?.status }

                                        ]} />
                                    </div>
                                </div>
                                <div className="view_page_para_head mt-4 mb-2">Document Description:</div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data?.forms_description
                                    }}
                                ></div>

                                <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
                                <div className="d-flex flex-wrap gap-3">
                                    {data?.forms_file?.map((res, key) => (
                                        <ViewPageDocumentName docUrl={res?.url} key={key} name={res?.fileName} />
                                    )
                                    )}
                                </div>
                            </div>}
                    {/* <div className="end mt-3">
                        <Link to={"/projectManagement/forms"}>
                            <button className="btn border">Close</button>
                        </Link>
                    </div> */}
                </div>
            </div>

            <br />
            <ViewVersion isView={true} />

            <div style={{ height: "0px", overflow: "hidden" }}>
                <div ref={pdfRef}>
                    <ViewPagesPdfTemplate
                        header={`#Forms ${data?.forms_name}`}
                        tableData1={[
                            { "Form Name": data?.forms_name },
                            { "Created On": data?.forms_date_created && moment(data?.forms_date_created).format("DD/MM/YYYY") || "--" },
                            { "status": data?.status }
                            // { "Latest Version": "--" },
                            // { "Created By": "--" },
                        ]}
                        description={{
                            key: "Forms Description",
                            value: <HtmlIntoText html={data?.forms_description} />
                        }}

                    />

                </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
                <button className='btn border' onClick={() => navigate("/projectManagement/forms")}>Back</button>
            </div>
            <br />
        </div>
    )
}
