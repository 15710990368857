import React, { useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Popover from "../../../../Components/Common/Popover";
import { TabPanel, TabView } from "primereact/tabview";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../Components/Buttons/PrimaryButton";
import NewPayment from "./NewPayment";

function ViewContract() {
  const [activeStep, setActiveStep] = useState(0);
  let navigate = useNavigate();

  const steps = [
    {
      label: "General Details",
      path: "/financialManagement/Primecontract/ViewContract",
    },
    {
      label: "Schedule of Value",
      path: "/financialManagement/Primecontract/ViewContract/ScheduleValue",
    },
    {
      label: "Change Orders",
      path: "/financialManagement/Primecontract/ViewContract/ChangeOrders",
    },
    {
      label: "Invoices",
      path: "/financialManagement/Primecontract/ViewContract/Invoices",
    },
    {
      label: "Payment Received",
      path: "/financialManagement/Primecontract/ViewContract/PaymentReceived",
    },
    {
      label: "Financial Markups",
      path: "/financialManagement/Primecontract/ViewContract/FinancialMarkups",
    },
  ];
  return (
    <div>
      <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
        <div className="w-100">
          <span
            className="open_Budget_text pointer"
            onClick={() => navigate("/financialManagement/Primecontract")}
          >
            Prime Contract
          </span>
          <span className="px-1 open_Budget_text ">/</span>
          <span className="createNew_text">#02 Instructions</span>
        </div>
        <div style={{ width: "120px" }}>
          {/* <EmailTemplateWithPdf  sendEmail={() => {}} isDialog={true} /> */}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <h1>#02 Instructions</h1>
        <div className="d-flex gap-2 align-items-center">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <AiOutlineExport />
            Export
            <MdOutlineKeyboardArrowDown />
          </div>
          <div className="flex justify-content-center align-items-center gap-1">
            {activeStep === 1 && (
              <Link to="">
                <PrimaryButton addIcon text="Add SOV" />
              </Link>
            )}
            {activeStep === 4 && <NewPayment />}
            <Popover
              options={["Edit", "delete"]}
              onSelect={(index, event) => {
                if (index === 0)
                  // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                  console.log(index);
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <TabView
          activeIndex={activeStep}
          onTabChange={(e) => {
            setActiveStep(e.index);
            navigate(steps[e.index].path);
          }}
        >
          {steps.map((res, key) => (
            <TabPanel key={key} header={res.label} />
          ))}
        </TabView>
        <Outlet />
      </div>
    </div>
  );
}

export default ViewContract;
