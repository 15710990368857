import React from "react";
import Chart from "react-apexcharts";

export default function
  BarChart({ series ,rowclick}) {
    
  var options = {
    series: series,
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      // stackType: '100%',
      events: {
        click:rowclick
      }

    },
    colors: ['#D42620', '#0F973D', '#F3A218'],
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10, // Change the border radius here
        barHeight: '65%',
        // barWidth: '100%',

        dataLabels: {
          total: {
            enabled: false,
            position: 'inside',
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
            formatter: function (val) {
              return val
            }
            
          },
        },
      },
    },


    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    // title: {
    //   text: "100% Stacked Bar",
    //   display: false
    // },
    xaxis: {
      axisTicks: {
        show: false, // Hide the x-axis ticks
      },
      labels: {
        show: false, // Hide the x-axis labels
        style: {
          fontSize: '14px',
          // Change the font size here
        },
      },
      categories: ["RFIs",
        "Submittals",
        "Punch Lists",
        "Incidents",
        "Forms",
        "Drawing",
        "Issue",
        "Observation"
        ],
    },
    labels: {
      show: false,
      style: {
        fontSize: '14px',
        // Change the font size here
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 40,
      markers: {
        width: 20, // Adjust the width of legend marker boxes
        height: 20, // Adjust the height of legend marker boxes
      },
      itemMargin: {
        horizontal: 10, // Adjust the horizontal margin between legend items
      },
      labels: {
        colors: ['#353638'],
        fontSize: "14px",
        // Change the color of legend item texts
        // You can add more styling options for legend item texts here
      },
    },
   
  };
  return (
    <>
      <div className="">
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height={450}
          onRowClick={(hh) => {
            console.log(hh)
          }}
          className="w-100"
        />
        {/* <div className="d-flex justify-content-center ">
              <div className=" d-flex flex-column  align-items-center  chart_total ">
                <div className="mb-4">Total open </div>
                <span className="no_gap">12</span>
                <span className="no_gap"> 17</span>
                <span className="no_gap">12</span>
                <span className="no_gap">17</span>
                <span className="no_gap">12</span>
                <span className="no_gap">17</span>
              </div>
            </div> */}
      </div>
    </>
  );
}
