import React from 'react'
import style from "./table.module.css"

export const ProjectTableWrapper = ({ children }) => {
    return (
        <div className={style.borderedTable_main_con}>
            <div className='w-100' style={{minWidth:'1350px'}}>
            {children}
            </div>
        </div>
    )
}
