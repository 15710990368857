import React, { useEffect, useState } from 'react'
import { ExportPdfButton } from '../../Components/Buttons/ExportPdfButton'
import { CSVLink } from 'react-csv'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { SearchInput } from '../../Components/Inputs/SearchInput'
import FormSkeleton from '../../Components/Common/FormSkeleton'
import { BorderedTable } from '../../Components/Tables/BorderedTable'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Pagination from '../../Components/Common/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import { deleteGroup, getAllEmailGroup } from '../../Api/Admin/AllAPIs'
import { FilterEmail } from './FilterEmailGroup'
import moment from 'moment'
import Popover from '../../Components/Common/Popover'
import DeleteDialog from "../../Components/Common/DeleteDialog";

export const EmailGroupList = () => {
    let [visibleDelete, setVisibleDelete] = useState(false)
    let [deletedGroupId, setDeletedGroupId] = useState("")
    let navigate = useNavigate()
    let [totalCount, setTotalCount] = useState(0)
    let [searchValue, setSearchValue] = useState('')


    let [skeltonLoader, setSkeltonLoader] = useState(false)
    let [emailData, SetEmailData] = useState([])
    let perPageData = 10
    const [filteredData, setFilteredData] = useState({ project_id: "" })

    const [tableSortData, setTableSortData] = useState({
        page: 1,
        search: "",
        project_id: null
    })

    function exportToPDF() {

    }

    useEffect(() => {
        getAllData()
    }, [tableSortData])

    async function getAllData() {
        try {
            setSkeltonLoader(true)
            let body = {
                page: tableSortData.page,
                perPageData: perPageData,
                search: tableSortData.search,
                project_id: tableSortData.project_id,
            }
            let res = await getAllEmailGroup(body)
            console.log(res.data)
            SetEmailData(res?.data?.data || [])
            setTotalCount(res?.data?.totalCount || 0)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setSkeltonLoader(false)
        }
    }

    let csvData = []
    let csvHeaders = []
    let skeletonData = [
        { qty: 1, height: "2.5rem" },
        { qty: 1, height: "2.5rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
    ]

    function filterData() {
        console.log(filteredData)
        setTableSortData((pre) => ({ ...pre, page: 1, ...filteredData }))
    }

    async function confirmDelete() {
        try {
            let res = await deleteGroup(deletedGroupId)
            getAllData()
            console.lo(deletedGroupId, "iiiiiiiiiiiiiiii")
        } catch (error) {
            console.log(error)
        }
    }

    const allowExpansion = (rowData) => {
        return rowData.groupEmail?.length > 0;
    };
    const [expandedRows, setExpandedRows] = useState([]);

    const rowExpansionTemplate = (rowData, ind) => {
        console.log(ind, "pppppp")
        return (
            <div className="">
                <DataTable
                    value={rowData.groupEmail}
                    className="childrenTable"
                >
                    <Column style={{ width: "3rem" }}></Column>
                    <Column
                        field="#"
                        header="Sno."
                        body={(rowData, rowIndex) => <div>{`${ind.index + 1}.${rowIndex.rowIndex + 1}`}</div>}
                        style={{ width: "4.7rem" }}
                    ></Column>
                    <Column field="email" header="Distribution Mail"></Column>
                    <Column body={(e) => e?.user_id?.length || 0} header="Users"></Column>
                    <Column
                        body={(rowData) => {
                            return (
                                <Popover
                                    options={["Edit",
                                        //  "Delete",
                                        "View"]}

                                    onSelect={(index) => {
                                        if (index === 0) {
                                            navigate(`edit/${rowData._id}`)
                                        }
                                        // else if (index === 1) {
                                        //     setDeletedGroupId(rowData?._id);
                                        //     setVisibleDelete(true)
                                        // }
                                        else if (index === 1) {
                                            navigate(`view/${rowData._id}`)
                                        }
                                    }}
                                />
                            )
                        }}
                        header="Actions"></Column>


                </DataTable>
            </div>
        );
    };


    return (
        <div className="dailyLog">
            <div className="parent">
                <div className="body d-flex flex-column">
                    <div className="header d-flex flex-column">
                        <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
                            <h1 className="page_heading">EMAIL DISTRIBUTION LISTS</h1>
                            <div className="d-flex text-nowrap justify-content-end gap-2 mt-sm-0 mt-2">
                                {/* <div className="right d-flex gap-2 align-items-center">
                                    <ExportPdfButton exportToPDF={exportToPDF}
                                        csvBtn={<CSVLink
                                            className="pe-1"
                                            data={csvData}
                                            headers={csvHeaders}
                                            filename={"email.csv"}
                                        >
                                            CSV
                                        </CSVLink>
                                        }
                                    />

                                </div> */}
                                <PrimaryButton
                                    className={`primararyButton`}
                                    addIcon
                                    text={"Create Group"}
                                    onClick={() => {
                                        navigate("create")
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-md-3 SearchInputC">
                                <SearchInput
                                    value={tableSortData.search}
                                    onCancel={() => setTableSortData(pre => ({ ...pre, page: 1, search: '' }))}
                                    onChange={(e) => setTableSortData(pre => ({ ...pre, search: e.target.value }))} />
                            </div>
                            <div className="col-md-9 d-flex justify-content-end">
                                <FilterEmail
                                    filterData={filteredData}
                                    setFilterData={setFilteredData}
                                    onFilter={filterData}

                                />

                                <button
                                    className={` ${Object.keys(filteredData).length === 0 && 'disabled'} clear_btn`} onClick={() => {
                                        setTableSortData({
                                            page: 1,
                                            search: "",
                                            project_id: null
                                        })
                                        setFilteredData({})
                                    }}
                                    disabled={Object.keys(filteredData).length === 0}
                                >Clear All</button>

                            </div>
                        </div>
                    </div>




                    <div className="logTable w-100 d-flex flex-column">


                        <div className="react_table table_scroll_distribution_list">
                            {/* 
                            {skeltonLoader ?
                                <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :

                                <DataTable
                                    value={emailData}
                                >
                                    <Column body={(rowData) => rowData?.project?.[0]?.project_name} header="Project Name"></Column>
                                    <Column field="email" header="Distribution Mail"></Column>
                                    <Column body={(rowData) => rowData?.user_id?.length} header="Added User(s)"></Column>
                                    <Column body={(rowData) => moment(rowData?.createdAt).format("DD-MM-YYYY")} header="Created On"></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <Popover
                                                    options={["Edit", "Delete", "View"]}
                                                    onSelect={(index) => {
                                                        if (index === 0) {
                                                            navigate(`edit/${rowData._id}`)
                                                        }
                                                        else if (index === 1) {
                                                            setDeletedGroupId(rowData?._id);
                                                            setVisibleDelete(true)
                                                        }
                                                        else if (index === 2) {
                                                            navigate(`view/${rowData._id}`)
                                                        }
                                                    }}
                                                />
                                            )
                                        }}
                                        header="Actions"></Column>
                                </DataTable>


                            } */}
                            {skeltonLoader ?
                                <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                                <DataTable
                                    value={emailData}
                                    expandedRows={expandedRows}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                >
                                    <Column expander={allowExpansion} style={{ width: "3rem" }} />
                                    <Column
                                        style={{ width: "4.7rem" }}
                                        field="#"
                                        header="S No."
                                        body={(rowData, rowIndex) => (
                                            <div>{rowIndex.rowIndex + 1}</div>
                                        )}
                                    ></Column>
                                    <Column header="Project Name" body={(rowData) => rowData?.projectDetails?.[0].project_name}></Column>
                                </DataTable>
                            }
                        </div>

                        <Pagination
                            currentPage={tableSortData.page}
                            totalCount={totalCount}
                            pageSize={perPageData}
                            onPageChange={(e) => setTableSortData(pre => ({ ...pre, page: e }))} />
                    </div>
                </div>
            </div>

            <DeleteDialog
                visible={visibleDelete}
                setVisible={setVisibleDelete}
                onCancel={() => setDeletedGroupId(null)}
                onConfirm={confirmDelete}
                header={"Email Group"}
            />
        </div>
    )
}
