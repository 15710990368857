import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gettaskbyuser } from "../../Api/Admin/AllAPIs";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import ViewVersion from "../../Components/Common/ViewVersion";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { HtmlIntoString } from "../../Components/Common/HtmlIntoText";

import html2pdf from 'html2pdf.js';
import moment from "moment";
import { PdfHeaderAndFooter } from "../../Components/Common/PagesComponents/PdfHeaderAndFooter";

export const Viewtask = ({ usedAsComponent, includeVersionHistory }) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [skeltonLoader, setSkeletonLoader] = useState(false);
  const [taskData, setTaskData] = useState({});
  const pdfRef = useRef(null);
  const pdf2Ref = useRef(null);

  async function fetchDataById() {
    try {
      setSkeletonLoader(true);
      const res = await gettaskbyuser(id);
      const Data = res?.data?.data[0];
      console.log(res?.data?.data?.[0]);
      setTaskData(Data);
    } catch (error) {
      console.log(error, "error loading data");
    } finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchDataById();
  }, []);

  function combineContractorWithMember(data) {
    return (
      <div>
        {data?.responsibleContractor?.map((res) => (
          <div key={res._id}>
            <div>{res.company_name}- <span>{data?.responsibleMembers?.filter(e => e.company == res._id).map(d => d.first_name).join(", ")}</span></div>
          </div>
        ))}
      </div>
    );
  }



  async function downloadPdfhandler() {


    // Options for html2pdf
    const opt = {
      margin: [0.1, 0.1], // Margins: [top, left, bottom, right]
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Generate PDF
    await html2pdf().from(pdfRef.current).set(opt).save();
  }

  const skeletonData = [
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
  ];





  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <span
            className="open_Rfi_text"
            onClick={() => navigate("/coretools/tasktable")}
          >
            Task
          </span>
          <span className="px-1 open_Rfi_text">/</span>
          <span className="createNew_text"> View Task</span>
        </div>

        {!usedAsComponent && <div className="end">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ cursor: "pointer" }}
            onClick={downloadPdfhandler}
          >
            <AiOutlineExport />
            Export
            <MdOutlineKeyboardArrowDown />
          </div>
        </div>}
      </div>

      <div className="p-3 bg-white">
        <div className="newRfi_text">TASK</div>
        <br />
        <div>
          {skeltonLoader ?
            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
            <div>
              <div className="row">
                <div className="col-xl-6 col-12">
                  <ViewPageTable tableData={[
                    { "Task ID": taskData?.taskId },
                    { "Workflow Status": taskData?.workFlowStatus },
                    { "Task Name": taskData?.task_name },
                    { "Responsible Contractor Company / Name": combineContractorWithMember(taskData) },
                    { "Approver Comment": taskData?.approver_comment || "--" },
                    { "Reviewer Comment": taskData?.reviewer_comment || "--" }
                  ]} />
                </div>
                <div className="col-xl-6 col-12">
                  <ViewPageTable tableData={[
                    { "Due Date": moment(taskData?.dueDate).format("DD/MM/YYYY") },
                    { "Task Manager": taskData?.taskManager?.map(e => e.first_name).join(", ") },
                    { "Responsible Person": taskData?.responsiblePerson?.map(e => e.first_name).join(", ") },
                    { "Hours Spent": `${taskData?.hourSpent?.split("-")[0]} Hr ${taskData?.hourSpent?.split("-")[1]} Min` },
                    { "Approver": `${taskData?.approver?.first_name} ${taskData?.approver?.last_name}` },
                    { "Reviewer": `${taskData?.reviewer?.first_name} ${taskData?.reviewer?.last_name}` },
                    { "Description": <HtmlIntoString html={taskData?.description} /> }
                  ]} />
                </div>
              </div>

              <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
              <div className="d-flex flex-wrap gap-3">
                {taskData?.attachments?.map((res, key) => (
                  <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
                ))}
              </div>
            </div>
          }

          {(!usedAsComponent || includeVersionHistory) &&
            <>
              <hr />
              <ViewVersion isView={true} />
            </>
          }
        </div>
      </div>

      {!usedAsComponent &&
        <div className="d-flex justify-content-end mt-2">
          <button className='btn border' onClick={() => navigate("/coretools/tasktable")}>Back</button>
        </div>
      }
      <br />




      <div style={{ height: 0, overflow: "hidden" }}>

        <div ref={pdfRef} className="p-3 bg-white">

          <PdfHeaderAndFooter>

            <div className="newRfi_text">TASK</div>
            <br />
            <div>
              {skeltonLoader ?
                <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                <div>
                  <div className="row">
                    <div className="col-12">
                      <ViewPageTable tableData={[
                        { "Task ID": taskData?.taskId },
                        { "Workflow Status": taskData?.workFlowStatus },
                        { "Task Name": taskData?.task_name },
                        { "Responsible Contractor Company / Name": combineContractorWithMember(taskData) },
                        { "Approver Comment": taskData?.approver_comment || "--" },
                        { "Reviewer Comment": taskData?.reviewer_comment || "--" },
                        { "Due Date": moment(taskData?.dueDate).format("DD/MM/YYYY") },
                        { "Task Manager": taskData?.taskManager?.map(e => e.first_name).join(", ") },
                        { "Responsible Person": taskData?.responsiblePerson?.map(e => e.first_name).join(", ") },
                        { "Hours Spent": `${taskData?.hourSpent?.split("-")[0]} Hr ${taskData?.hourSpent?.split("-")[1]} Min` },
                        { "Approver": `${taskData?.approver?.first_name} ${taskData?.approver?.last_name}` },
                        { "Reviewer": `${taskData?.reviewer?.first_name} ${taskData?.reviewer?.last_name}` },
                        { "Description": <HtmlIntoString html={taskData?.description} /> }
                      ]} />
                    </div>

                  </div>

                  <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
                  <div className="d-flex flex-wrap gap-3">
                    {taskData?.attachments?.map((res, key) => (
                      <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
                    ))}
                  </div>
                </div>
              }

              {(!usedAsComponent || includeVersionHistory) &&
                <>
                  <hr />
                  <ViewVersion isView={true} />
                </>
              }
            </div>
          </PdfHeaderAndFooter>

        </div>


      </div>
















    </div>
  );
};
