import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { TreeTable } from "primereact/treetable";

const ViewCostCode = ({ costCodeData, hidePagination }) => {
  const [costCodeInfo, setCostCodeInfo] = useState([]);
  const [expandedRow, setExpandedRow] = useState([]);
  const rowExpansionTemplate = (data, tableIndex) => {
    if (data && data.children) {
      return (
        <DataTable value={data.children} header={null} dataKey="id">
          <Column
            field=""
            style={{ width: "4rem" }}
            headerStyle={{ display: "none" }}
          />

          <Column
            field="code"
            style={{ width: "4rem" }}
            headerStyle={{ display: "none" }}
          />
          <Column
            field="description"
            style={{ width: "14rem" }}
            headerStyle={{ display: "none" }}
          />
        </DataTable>
      );
    }

    return null;
  };
  const allowExpansionOne = (rowData, tableIndex) => {
    return rowData.children && rowData.children.length > 0 && tableIndex === 2;
  };
  useEffect(() => {
    if (costCodeData) {
      let res = transformData(costCodeData);
      setCostCodeInfo(res)
      console.log(res);

    }
  }, [costCodeData]);


  const transformData = (data) => {
    return data?.map(({ _id, code, description, children }) => ({
      key: _id,
      label: code,
      description: description,
      children: children?.map(({ _id, code, description, parentId }) => ({
        key: _id,
        label: code,
        description: description,
        parentId: parentId,
      })),
    }));
  };



  return (
    <>
     <span className="h5 my-3">PROJECT COST CODES LIST :-</span>
      {costCodeData ? (
        <div style={{width:'100%',overflow:'auto'}}>
          {hidePagination ? <TreeTable className="" value={costCodeInfo} tableStyle={{ minWidth: '50rem' }}>
            <Column field="" header="" expander style={{width: "10%"}}></Column>
            <Column field="company" header="Code" style={{width: "20%"}}  body={(rowData => {
              return (<span>{rowData?.label}</span>)
            })}></Column>
            <Column field="person" header="Description" body={(rowData => {
              return (<span>{rowData?.description}</span>)
            })}></Column>

          </TreeTable> : <TreeTable className="" value={costCodeInfo} tableStyle={{ minWidth: '50rem' }}>
            <Column field="" header="" expander style={{width: "10%"}}></Column>
            <Column field="company" header="Code" style={{width: "20%"}}  body={(rowData => {
              return (<span>{rowData?.label}</span>)
            })}></Column>
            <Column field="person" header="Description" body={(rowData => {
              return (<span>{rowData?.description}</span>)
            })}></Column>

          </TreeTable>}

        </div>
      ) : (
        <TableSkelton header={["", "Code", "Description"]} />
      )}
    </>
  );
};

export default ViewCostCode;
