import React from "react";
import { BorderedTable } from "../../../Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect } from "react";
import { FiCopy } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { GoArrowUp } from "react-icons/go";
import { Dialog } from "primereact/dialog";
import TextInput from "../../../Inputs/TextInput";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import product from "./Data";
import "./CostCodes.css";
import DeleteButton from "../../../Buttons/DeleteButton";
import {
  copyAllCodes,
  createCostCode,
  deleteMany,
  getCodeCost,
  getProject,
} from "../../../../Api/Admin/AllAPIs";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { AddDivision } from "./AddDivision";
import { AddCode } from "./AddCode";
import { EditData } from "./EditData";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TableSkelton } from "../../../Common/TableSkelton";
import { ConfirmDialog } from "../../../Common/ConfirmDialog";
import SevedModal from "../../../Common/SavedModal";
import { getUserName } from "../../../../utils";

function CostCodes({ mode }) {
  let [localCostData, setLocalCostData] = useState([...product]);
  const [isVersionHistory, setIsVersionHistory] = useState(true);
  const [selectedProductsTable1, setSelectedProductsTable1] = useState([]);
  const [selectedProductsTable2, setSelectedProductsTable2] = useState([]);
  let [secondTableData, setSecondTableData] = useState([]);
  let [btnLoader, setBtnLoader] = useState(false)
  const [nodes, setNodes] = useState([]);
  const [rowClick, setRowClick] = useState(true);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [idStore, setIdStore] = useState(localStorage.getItem("projectId"));
  const [data, setData] = useState([]);
  const [copyDataall, setCopyDataall] = useState([]);

  const [selectedData, setSelectedData] = useState([]);
  const [showCopyData, setShowCopyData] = useState(false);
  const [costCodeId, setCostCodeId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(mode == "Edit" ? true : false)
  const [backVisible, setBackVisible] = useState(false)


  useEffect(() => {
    setData(product);
  }, [product]);

  const [copiedCodes, setCopiedCodes] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsTable3, setSelectedProductsTable3] = useState([]);
  const [selectCode, setSelectCode] = useState(false);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const arr = [];

  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, [localStorage.getItem("projectId")]);

  const handleDelete = async (id) => {
    // setLoader(true)
  };

  const handleAddCodes = async () => { };

  const confirmDelete = async () => { };

  const handleCopyCodes = async () => {
    const newData = product.map((parentProduct) => {
      const newChildren = parentProduct.children.map((childProduct) => ({
        description: childProduct.description,
        code: childProduct.code,
      }));

      return {
        description: parentProduct.description,
        code: parentProduct.code,
        children: newChildren,
        project_id: idStore,
      };
    });

    try {
      setShowCopyData(true);
      setSelectedProductsTable1(product);
      setCopyDataall(product);
    } catch (err) {
      console.log(err);
    }
    // hendleSelectAll()
  };

  // const handleSelectionChangeTable1 = (e) => {
  //   setSelectedProductsTable1(e.value);
  //   setSelectedProductsTable2(e.value);
  //   if (e.value.length === 0) {
  //     setSelectCode(false);
  //   }
  // };

  const handleSelectionChangeTable2 = (e) => {
    setChecked(true);
    setSelectedProductsTable2((prevSelectedProductsTable2) => {
      const index = prevSelectedProductsTable2.findIndex(
        (item) => item.id === e.id
      );

      if (index !== -1) {
        prevSelectedProductsTable2[index].selected =
          !prevSelectedProductsTable2[index].selected;
      } else {
        prevSelectedProductsTable2.push({ ...e, selected: true });
      }

      return [...prevSelectedProductsTable2];
    });
  };

  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);

  const getProjectes = async () => {
    setShowCopyData(false);
    setSelectCode(true);
    try {
      setSkeletonLoader(true);
      const res = await getCodeCost(idStore);
      const getData = res?.data?.data;

      let allData = res.data.data;
      setSecondTableData(getData);

      if (allData?.length > 0) {
        setIsVersionHistory(true)
        setLocalCostData((pre) =>
          pre.map((res) => {
            let parentInd = allData.findIndex((d) => d.code == res.code);

            if (parentInd !== -1) {
              res.checked = true;

              let vvv = res.children.map((dd) => {
                if (
                  allData[parentInd].children.some((ee) => ee.code == dd.code)
                ) {
                  dd.checked = true;
                }

                return { ...dd };
              });

              res.children = [...vvv];
            }

            return { ...res };
          })
        );
      } else {
        setIsVersionHistory(false)
      }

      // setSelectedProductsTable2(Array.isArray(getData) ? getData : []);
    } catch (error) {
      console.error(error);
      // Handle error, e.g., show an error message to the user
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const handleCopySelectedCodes = () => {
    let arr = [...selectedProductsTable1];
    setSelectedProductsTable2(arr);
    setSelectCode(true);

    return;
    const selectedCodes = selectedProductsTable1.map((product) => ({
      cost_code_parent_name: product.description,
      cost_code_parent_code: product.code,
      cost_code_child: product.children.map((child) => ({
        cost_code_child_name: child.description,
        cost_code_child_code: child.code,
      })),
    }));

    arr.push(selectedCodes);
    setSelectedProductsTable2(arr); // Update copied data state

    const payload = {
      data: selectedCodes,
      project_id: idStore,
    };

    try {
      // await copyAllCodes(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const hanldeSubmitCostCode = async (btnName) => {
    // const payload = {
    //   data: selectedProductsTable1
    //     ? selectedProductsTable1
    //     : selectedProductsTable2,
    //   project_id: idStore,
    // };

    let dd = secondTableData?.map((res) => {
      delete res.checked;
      delete res._id;
      let rr = res.children.map((e) => {
        delete e.checked;
        delete e._id;
        return { ...e };
      });
      return { ...res, children: rr, project_id: idStore };
    });
    const userData = localStorage.getItem("userData");
    const userObj = JSON.parse(userData);
    const userName = userObj?.name;
    let payload = {
      data: { data: dd, project_id: idStore, code_ids: dd.map((e) => e.code) },
      isVersionHistory: isVersionHistory,
      user_name: userName,
      project_id: idStore
    };

    try {
      setBtnLoader(true)
      let res = await createCostCode({ ...payload, updated_by: getUserName() });
      setBtnLoader(false)
      setVisible(false)
      setVisible2(false)
      if (btnName !== "save") {
        navigate(
          mode === "Edit"
            ? "/coretools/admin/projectform/step-4"
            : "/portfolio/projectform/step-4"
        );
      } else {

        // navigate("/projects")

      }

    } catch (err) {
      console.log(err);
    }

    // try {
    //   await copyAllCodes(payload);
    //   setShowCopyData(true);
    //   navigate("/portfolio/projectform/step-5");
    // } catch (err) {
    //   console.log(err);
    // }
    // hendleSelectAll()
  };

  function shouldRemove(item) {
    return !selectedProductsTable3.some(
      (removeItem) =>
        removeItem.code === item.code && removeItem.name === item.name
    );
  }

  const handleDeleteSelectedRow = () => {
    const updatedProducts = selectedProductsTable2.filter(shouldRemove);
    setSelectedProductsTable2(updatedProducts);
  };

  const allowExpansion = (rowData, tableIndex) => {
    return rowData.children && rowData.children.length > 0 && tableIndex === 1;
  };

  const allowExpansionOne = (rowData, tableIndex) => {
    return rowData.children && rowData.children.length > 0 && tableIndex === 2;
  };

  const rowExpansionTemplate = (data, tableIndex) => {
    if (data && data.children) {
      return (
        <DataTable value={data.children} header={null} dataKey="id">
          <Column
            field=""
            style={{ width: "3.5rem", }}
            headerStyle={{ display: "none" }}
          />
          <Column
            body={() => (
              <FiMenu style={{ fontSize: "1.2rem", cursor: "grab" }} />
            )}
            style={{ width: "11.4%", textAlign: "center" }}
            reorderable={false}
            headerStyle={{ display: "none" }}
          />
          <Column
            body={(rowData) => {
              return (
                <Checkbox
                  onChange={(e) => {
                    tableIndex === 1
                      ? childCheckboxClick(e, rowData._id, data._id, tableIndex)
                      : rightTableChildClick(e, { ...rowData }, { ...data });
                  }}
                  checked={
                    tableIndex === 1
                      ? rowData.checked
                      : selectedData.some(
                        (d) =>
                          d.code == data.code &&
                          d.children.some((dd) => dd.code == rowData.code)
                      )
                  }
                />
              );
            }}
            style={{ width: "11.9%", textAlign: "center" }}
            headerStyle={{ display: "none" }}
          ></Column>
          <Column
            field="code"
            style={{ width: "14.6%" }}
            headerStyle={{ display: "none" }}
          />
          <Column
            field="description"
            style={{ width: "14rem" }}
            headerStyle={{ display: "none" }}
          />
        </DataTable>
      );
    }

    return null;
  };

  function rightTableChildClick(e, childData, parentData) {
    let chi = { ...childData };
    let par = { ...parentData };

    let dd = [...selectedData];

    let ddIndex = dd.findIndex((d) => d.code == par.code);

    if (e.target.checked) {
      if (ddIndex !== -1) {
        dd[ddIndex].children.push(chi);
      } else {
        dd.push({ ...par, children: [chi] });
      }

      setSelectedData(dd);
    } else {
      let allChildrens = dd[ddIndex].children;

      allChildrens.splice(
        allChildrens.findIndex((ed) => ed.code == chi.code),
        1
      );
      if (allChildrens.length == 0) {
        dd.splice(ddIndex, 1);
      } else {
        dd[ddIndex].children = allChildrens;
      }

      // dd[ddIndex] = { ...dd[ddIndex], children: dd[ddIndex].children.splice(dd[ddIndex].children.findIndex(g => g.code == chi.code), 1) }

      setSelectedData([...dd]);
    }
  }

  function rightParentClick(e, parentData) {
    let dddd = { ...parentData };

    if (e.target.checked) {
      setSelectedData((ddd) => {
        let pre = [...ddd];

        pre = pre.filter((d) => d.code !== dddd.code);

        return [...pre, dddd];
      });
    } else {
      setSelectedData((pre) => pre.filter((d) => d.code !== dddd.code));
    }
  }

  function parentCheckboxClick(e, parentId) {
    const updatedData = localCostData.map((item) => {
      if (item._id === parentId) {
        return {
          ...item,
          checked: e.target.checked,
          children: item.children.map((ee) => ({
            ...ee,
            checked: e.target.checked,
          })),
        };
      }
      return item;
    });

    setLocalCostData([...updatedData]);
  }

  function childCheckboxClick(e, childId, parentId, ind) {
    const updatedData = localCostData.map((parentItem) => {
      if (parentItem._id === parentId) {
        if (e.target.checked) {
          parentItem.checked = true;
        }
        const updatedChildren = parentItem.children.map((childItem) => {
          if (childItem._id === childId) {
            return { ...childItem, checked: e.target.checked };
          }
          return childItem;
        });
        return { ...parentItem, children: updatedChildren };
      }
      return parentItem;
    });
    setLocalCostData(updatedData);
  }

  function copyAllCodesHandler() {
    let data = [...localCostData];
    data.map((res) => {
      res.checked = true;
      res.children.forEach((d) => (d.checked = true));
    });
    setLocalCostData([...data]);
    let tableData = [...data, ...secondTableData];
    let tabelValue = [
      ...new Map(tableData.map((item) => [item.code, item])).values(),
    ];
    setSecondTableData(tabelValue);
  }

  function copySelectedCode() {
    let data = [...localCostData];
    let arr = [];

    data.forEach((res) => {
      if (res.checked) {
        arr.push({ ...res, children: res.children.filter((d) => d.checked) });
      }
    });
    let tableData = [...arr, ...secondTableData];
    let tabelValue = [
      ...new Map(tableData.map((item) => [item.code, item])).values(),
    ];
    setSecondTableData(tabelValue);
  }

  function parentChecked(rowData) {
    let currentParent = selectedData.find((ee) => ee.code == rowData.code);

    if (
      currentParent &&
      currentParent.children.length == rowData.children.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function deleteCodes() {
    let data = selectedData.map((item) => ({
      code: item.code,
      children: item.children.map((child) => child.code),
    }));
    console.log(selectedData)
    console.log(secondTableData)

    try {
      let tableData = [];

      secondTableData.forEach((dataItem) => {
        let selectedItem = data.find((item) => item.code === dataItem.code);

        if (selectedItem) {

          if (selectedItem.children.length !== dataItem.children.length) {
            let filteredChildren = dataItem.children.filter(
              (child) => !selectedItem.children.includes(child.code)
            );

            // if (filteredChildren.length !== selectedItem.children.length) {
            tableData.push({ ...dataItem, children: filteredChildren });
            // }
          }
        } else {
          tableData.push(dataItem);
        }
      });

      let tabelValue = [
        ...new Map(tableData.map((item) => [item.code, item])).values(),
      ];

      setSecondTableData(tabelValue);

      setSelectedData([]);

      // getProjects();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <div className="row pl-4 mt-5">
        <div className="col-12 px-3 d-flex  justify-content-between align-items-center">
          <span className="project_name_text"> 3. Cost Codes</span>
          <div className="d-flex gap-3">
            <AddDivision
              project_id={idStore}
              getProjectes={getProjectes}
              existCode={secondTableData?.map((e) => e.code)}
              secondTableData={secondTableData}
              setSecondTableData={setSecondTableData}
            />
            <AddCode
              reload={getProjectes}
              project_id={idStore}
              allParents={secondTableData?.map((e) => ({
                description: e.description,
                code: e.code,
                children: e.children,
              }))}
              setSecondTableData={setSecondTableData}
              secondTableData={secondTableData}
            />
          </div>
        </div>

        <div className="mt-5 row ">
          <div className="col-md-6">
            <div className="">
              <div>
                <div className="border_box  text-center p-2">
                  <span className="h5">STANDARD COST CODES LIST</span>
                </div>
                <div className="flex border_box p-2">
                  <button
                    className="btn border me-3 d-flex gap-2 align-items-center "
                    onClick={() => copyAllCodesHandler()}
                  >
                    <FiCopy /> Copy All Codes{" "}
                  </button>
                  <button
                    className="btn border me-3 d-flex gap-2 align-items-center"
                    onClick={() => copySelectedCode()}
                  // disabled={selectedProductsTable1.length === 0}
                  >
                    <FiCopy />
                    Copy Selected Codes
                  </button>
                </div>
              </div>

              {
                skeletonLoader ? <TableSkelton header={["", "", "", "Code", "Description"]} />
                  :

                  <div className="border" style={{ overflow: 'auto' }}>
                    <DataTable
                      value={localCostData}
                      expandedRows={expandedRows}
                      onRowToggle={(e) => setExpandedRows(e.data)}
                      rowExpansionTemplate={(data) => rowExpansionTemplate(data, 1)}
                      dataKey="_id"
                      className="treeTableCl"
                    >
                      <Column
                        expander={(rowData) => allowExpansion(rowData, 1)}
                        style={{ textAlign: "center", width: "0.5rem" }}
                      />
                      <Column
                        body={() => (
                          <FiMenu style={{ fontSize: "1.2rem", cursor: "grab" }} />
                        )}
                        style={{ width: "1rem", textAlign: "center" }}
                        reorderable={false}
                      />
                      <Column
                        body={(data) => (
                          <Checkbox
                            onChange={(e) => parentCheckboxClick(e, data._id)}
                            checked={data.checked}
                          />
                        )}
                        headerStyle={{ width: "3rem" }}
                      />
                      <Column field="code" header="Code" style={{ width: "4rem" }} />
                      <Column
                        field="description"
                        header="Description"
                        style={{ width: "14rem" }}
                      />
                    </DataTable>
                  </div>


              }
            </div>
          </div>

          <div className=" col-md-6">

            <div>
              <div className="border_box  text-center p-2">
                <span className="h5">PROJECT COST CODES LIST</span>
              </div>
              <div className="flex border_box p-2 ">
                {/* <button className="btn border me-3 d-flex gap-2 align-items-center ">
                    <IoMdAdd /> Add Codes
                  </button> */}

                <div className="d-flex  w-100 responsive_css">
                  <div className="flex">


                    {/* <button className="btn border me-3 d-flex gap-2 align-items-center">
                    <GoArrowUp />
                    Add Divisions
                  </button> */}

                  </div>
                  {/* <button
                    className="btn border me-3 d-flex gap-2 align-items-center "
                    onClick={() => setVisible(true)}
                    disabled={selectedProductsTable3.length !== 1}
                  >
                    <FaRegEdit /> Edit
                  </button> */}
                  <div className="flex">
                    {/* <EditData
                        reload={getProjectes}
                        selectedData={selectedData}
                        project_id={idStore}
                        setSelectedData={setSelectedData}
                        setSecondTableData={setSecondTableData}
                        secondTableData={secondTableData}
                      /> */}
                    <button
                      className="btn border me-3 d-flex gap-2 align-items-center"
                      // onClick={handleDeleteSelectedRow}
                      onClick={() => {
                        deleteCodes();
                      }}
                    // disabled={selectedProductsTable3.length === 0}
                    >
                      {/* <DeleteButton
                      visible={!costCodeId}
                      onCancel={() => setCostCodeId(null)}
                      onConfirm={confirmDelete}
                      header={"Field"}
                    /> */}
                      <RiDeleteBin5Line />
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {
              skeletonLoader ? <TableSkelton header={["", "", "", "Code", "Description"]} />
                :

                <div className="border" style={{ overflow: 'auto' }}>
                  <DataTable
                    // value={copiedCodes.map((items) => ({ ...items }))}
                    // value={
                    //   showCopyData
                    //     ? selectedProductsTable1
                    //     : selectCode
                    //       ? selectedProductsTable2
                    //       : []
                    // }
                    value={secondTableData}
                    expandedRows={expandedRow}
                    // selection={selectedProductsTable3}
                    // onSelectionChange={handleSelectionChangeTable2}
                    onRowToggle={(e) => setExpandedRow(e.data)}
                    rowExpansionTemplate={(data) => rowExpansionTemplate(data, 2)}
                    dataKey="_id"
                  >
                    <Column
                      expander={(rowData) => allowExpansionOne(rowData, 2)}
                      style={{
                        textAlign: "center",
                        width: "5%",

                      }}
                    />
                    <Column
                      body={() => (
                        <FiMenu style={{ fontSize: "1.2rem", cursor: "grab" }} />
                      )}
                      style={{ textAlign: "center", width: "5%" }}
                      reorderable={false}
                    />
                    <Column
                      body={(rowData) => {
                        return (
                          <Checkbox
                            onClick={(e) => {
                              rightParentClick(e, { ...rowData });
                            }}
                            // checked={selectedData.some(e => e.code == rowData.code)}
                            // checked={() => {
                            //   console.log("currentParent")

                            //   let currentParent = selectedData.find(ee => ee.code == rowData.code)
                            //   if (currentParent && currentParent.children.length == rowData.children.length) {
                            //     return false
                            //   }
                            //   else {
                            //     return true
                            //   }

                            // }}
                            checked={parentChecked(rowData)}
                          />
                        );
                      }}
                      style={{ textAlign: "center", width: "5%" }}
                    />
                    <Column field="code" header="Code" style={{ width: "15%" }} />
                    <Column
                      field="description"
                      header="Description"
                      style={{ width: "14rem" }}
                    />
                  </DataTable>
                </div>

            }


          </div>
          <div className="justify-content-between d-flex gap-3 mt-5">

            <div className="d-flex gap-3 ">
              <PrimaryButton
                text="Cancel"

                onClick={() => setCancelVisible(true)}
              />


              <PrimaryButton
                text="Back"
                onClick={() => setBackVisible(true)}
              />

              {/* <button className="skip filter_btn" onClick={() => setBackVisible(true)}>Back</button> */}
            </div>

            <div className="d-flex gap-3">

              <PrimaryButton text={"Save"} onClick={() => setVisible2(true)} />
              <PrimaryButton text={"Save & Continue"} onClick={() => setVisible(true)} />
            </div>
          </div>
        </div>
      </div>

      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-2")}
        visible={backVisible}
        setVisible={setBackVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}

      />
      <SevedModal
        yes={() => navigate("/projects")}
        visible={cancelVisible}
        setVisible={setCancelVisible}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />

      <SevedModal
        yes={() => hanldeSubmitCostCode("save")}
        visible={visible2}
        setVisible={setVisible2}
        loader={btnLoader}
        heading='Create the Project'
        type='info'
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => hanldeSubmitCostCode("save and continue")}
        visible={visible}
        loader={btnLoader}
        setVisible={setVisible}
        heading='Proceed to Next Step'
        type='info'
        headingText={`Are you sure you want to save your progress and continue?`}
      />

    </>
  );
}

export default CostCodes;
