import React, { useEffect, useRef, useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getPunchbyid } from "../../Api/Admin/AllAPIs";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import ViewVersion from "../../Components/Common/ViewVersion";
import { PdfHeaderAndFooter } from "../../Components/Common/PagesComponents/PdfHeaderAndFooter";
import html2pdf from 'html2pdf.js';
export const ViewPunch = ({ usedAsComponent, includeVersionHistory }) => {
  let [punchData, setPunchData] = useState({});
  let navigate = useNavigate();
  let { id } = useParams();
  let pdfRef = useRef();
  const [contractorData, setContractorData] = useState([])
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },

    { qty: 1, height: "5rem" },
    { qty: 1, height: "5rem" },
    { qty: 1, height: "1.8rem" },
  ];
  const capitalizeFirstLetter = (string) => {
    if (!string) return "--"; // Handle undefined or null cases
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  async function getData() {
    try {
      setSkeletonLoader(true);
      let res = await getPunchbyid(id);
      const punchd= res?.data?.data?.[0];
      setPunchData(res.data.data?.[0]);
      let data = punchd?.responsibleContractor?.map(res => {
        if (punchd?.MembersResponsible.length > 0) {
          let pp = punchd?.MembersResponsible?.filter(item => item?.company == res?._id);
          return {
            company: res?.company_name,
            persons: pp?.map(ress => ress?.first_name)?.join(', ') || '--' 
          };
        } else {
        
          return {
            company: res?.company_name,
            persons: '--'
          };
        }
      }) || []; 
      setContractorData(data)
      console.log(data,"*********")
    } catch (err) {
      console.log(err);
    } finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  console.log(punchData, "check data");

  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents",
        files: punchData?.attachFile.map((e) => e.fileUrl),
      },
    ]);
  }

  const formatHourSpent = (hourSpent) => {
    if (!hourSpent) return "--";
    const { hours = 0, minutes = 0 } = hourSpent;
    return `${hours} hr ${minutes} min`;
  };

  
  async function downloadPdfhandler() {


    // Options for html2pdf
    const opt = {
      margin: [0.1, 0.1], // Margins: [top, left, bottom, right]
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Generate PDF
    await html2pdf().from(pdfRef.current).set(opt).save();
  }

  return (
    <div className="rfiView card">
      <div className="rfiparent">
        {skeletonLoader ? (
          <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
        ) : (
          <>
            <div className="w-100 breadcrum mb-5">
              <div className="w-100">
                <span
                  className="open_Rfi_text"
                  onClick={() => navigate("/projectManagement/punch")}
                >
                  Punch List
                </span>
                <span className="px-1 open_Rfi_text">/</span>
                <span className="createNew_text">
                  View Punch List
                </span>
              </div>
            </div>

            <div className="rfibody d-flex flex-column gap-4">
              <div className="d-flex justify-content-between">
                <h1 className="headdingRFI">{punchData?.PunchItemName}</h1>
                <div
                  className="d-flex align-items-center gap-2"
                  onClick={downloadPdfhandler}
                >
                   {!usedAsComponent &&
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlineExport />
                    Export
                    <MdOutlineKeyboardArrowDown />
                  </div>}
                </div>
              </div>

              <div className="d-flex flex-column gap-3">
                <div className="row">
                  <div className="col-6">
                    <ViewPageTable
                      tableData={[
                        { "Punch List ID" : punchData?.punchID || "--"},
                        { "Location": punchData?.Location || "--" },
                       
                        {
                          "Responsible Member":
                            punchData?.MembersResponsible?.map(
                              (e) => e?.first_name
                            )?.join(", ") || "--",
                        },
                        {
                          "Responsible Person ":
                            punchData?.person
                              ?.map((e) => e?.first_name)
                              ?.join(", ") || "--",
                        },
                        {
                          "Punch List Manager":
                            punchData?.punchItemManager
                              ?.map((e) => e?.first_name)
                              ?.join(", ") || "--",
                        },
                      
                      ]}
                      
                    />
                    <div className="gray-lt text-nowrap pb-2">Responsible Contractor Company / Name:</div>
                        <div className="d-flex flex-column">
                          {contractorData?.map((res, index) => (<div className="gray-d pb-2" key={index}>{res?.company} - {res?.persons}</div>))}
                        </div>
                  </div>
                  
                  <div className="col-6 text-capitalize">
                    <ViewPageTable
                      tableData={[
                        { "Due Date": moment(punchData?.date).format("DD-MM-YYYY") ||"--", },
                        { " Workflow Status": punchData?.workflow_status || "--", },
                        { "Linked Drawing": punchData?.linkDrawing || "--" },
                        { "Category": capitalizeFirstLetter(punchData?.category?.name )|| "--" },
                        { "Trade": capitalizeFirstLetter(punchData?.Trade?.name) || "--" },
                        { "Hour Spent": formatHourSpent(punchData?.hourSpent) },
                        { "Reviewer's Comments" : punchData?.reviewer_comment},
                        { "Approver's Comment" : punchData?.approver_comment}
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>

            

            <div className="view_page_para_head mt-4 mb-2">Description</div>
            <div className="view_page_bold_text">
              <HtmlIntoText html={punchData?.discription} />
            </div>
            <div className="view_page_para_head mt-4 mb-2">
              Attached Document:
            </div>
            {punchData?.attachFile?.map((res, key) => {
              return (
                <ViewPageDocumentName
                  docUrl={res?.fileUrl}
                  name={res.fileName}
                />
              );
            })}
            <br />
            {/* <h2>{`Response (${punchData?.assignees?.length})`}</h2> */}
          </>
        )}

        <div>
          {console.log(punchData.assignees, "@@@@@@@@@@@@@@@@@@")}
          <br />
         
          {(!usedAsComponent || includeVersionHistory) && (
            <>
              <hr />
              <ViewVersion isView={true} />
              <br />
              <br />
            </>
          )}
        </div>
      </div>

      <div style={{ height: "0px", overflow: "hidden" }}>
      <div ref={pdfRef} className="p-3 bg-white">
        <PdfHeaderAndFooter>
        <div className="newRfi_text">PUNCH LIST</div>
<br />

<div>
  {skeletonLoader ? (
    <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
  ) : (
    <div>
      <div className="row">
        <div className="col-12">
          <ViewPageTable tableData={[
              { "Punch List ID" : punchData.punchID},
              {
                "Responsible Contractor Company": 
                  punchData?.responsibleContractor
                    ?.map((e) => e?.company_name)
                    ?.join(", ") || "--",
              },
              {
                "Responsible Member":
                  punchData?.MembersResponsible
                    ?.map((e) => e?.first_name)
                    ?.join(", ") || "--",
              },
              {
                "Responsible Person":
                  punchData?.person?.map((e) => e?.first_name)?.join(", ") || "--",
              },
              {
                "Punch List Manager":
                  punchData?.punchItemManager?.map((e) => e?.first_name) || "--",
              },
              {
                "Trade": capitalizeFirstLetter(punchData?.Trade?.name) || "--",
              },
              {
                "Due Date": moment(punchData?.date).format("DD-MM-YYYY") || "--",
              },
              {
                "Workflow Status": punchData?.workflow_status || "--",
              },
              {
                "Location": punchData?.Location || "--",
              },
              {
                "Category": capitalizeFirstLetter(punchData?.category?.name) || "--",
              },
              {
                "Hour Spent": formatHourSpent(punchData?.hourSpent),
              },
              // { "Approver": `${punchData?.approver?.first_name} ${punchData?.approver?.last_name}` },
              // { "Reviewer": `${punchData?.reviewer?.first_name} ${punchData?.reviewer?.last_name}` },
              { "Reviewer's Comments" : punchData?.reviewer_comment || "--"},
              { "Approver's Comment" : punchData?.approver_comment || "--"},
            ]}
            description={{
              key: "Punch List Description",
              value: <HtmlIntoText html={punchData?.discription} />,
            }}
                      />
        </div>
      </div>

      <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
      <div className="d-flex flex-wrap gap-3">
        {punchData?.attachFile?.map((res, key) => (
          <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
        ))}
      </div>
        <br/>
      <div className="ml-2">
        <ViewVersion isView={true} />
      </div>
      <br />
    </div>
  )}
</div>


          </PdfHeaderAndFooter>
        </div>
        
      </div>
      {!usedAsComponent && <div className="d-flex justify-content-end mt-2">
        <button
          className="btn border"
          onClick={() => navigate("/projectManagement/punch")}
        >
          Back
        </button>
      </div>}
      <br />
    </div>
  );
};
