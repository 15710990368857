import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { createGroup, getAllProjects, getGroupById, getUserByProjectId, updateGroup } from "../../Api/Admin/AllAPIs";
import { Editor } from "primereact/editor";
import { getProjectId, getUserId, toast } from "../../utils";

export const CreateUpdateEmailGroup = () => {
    let { id } = useParams();

    let [allUsersOfProject, setAllUsersOfProject] = useState([])
    let [allProjects, setAllProjects] = useState([]);
    let [oldEmailData, setOldEmailData] = useState({})

    let navigate = useNavigate();
    const { control, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues:
        {
            email: "",
            project_id: null,
            user_id: [],
            description: "",
            created_by: getUserId()
        }
    });

    let project_id = watch("project_id")





    useEffect(() => {
        getAllProject()
    }, []);

    useEffect(() => {
        if (id) {
            getEmailData()
        }
    }, [id])
    let userId = watch("user_id")




    async function getEmailData() {
        try {
            let res = await getGroupById(id)
            console.log(res.data.data[0])
            let emailData = res.data.data[0]

            setOldEmailData(emailData)

            reset({
                email: emailData.email,
                // passKey: emailData.pass_key,
                project_id: emailData.project_id,
                user_id: emailData?.usersData?.map(e => e._id),
                description: emailData.description
            })

            console.log({
                email: emailData.email,
                project_id: emailData.project_id,
                user_id: emailData?.usersData?.map(e => e._id),
                description: emailData.description
            })

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        console.log(userId, "lklklklklkllllllllllll")
    }, [userId])

    async function getAllProject() {
        try {
            let body = {
                user_id: getUserId(),
                type: "admin"
            }
            let res = await getAllProjects(body)
            console.log(res.data.data)
            setAllProjects(res?.data?.data || [])

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (project_id) {

            getAllUsersOfProject(project_id)
        }
    }, [project_id])

    async function getAllUsersOfProject(projectId) {
        try {
            let res = await getUserByProjectId(projectId);
            let allUsers = res?.data?.data?.[0]?.combined_users || [];
            setAllUsersOfProject(allUsers);
            console.log(allUsers, "================================================");

        } catch (err) {
            console.log(err);
        }
    }



    async function onSubmit(data) {
        try {
            console.log(data)

            let body = { ...data }

            if (id) {
                body.old_email = null;
            }

            if (oldEmailData?.email?.toLowerCase()?.trim() !== data?.email?.toLowerCase()?.trim() && id) {
                // console.log(oldEmailData.email)
                body.old_email = oldEmailData.email
            }



            if (id) {
                await updateGroup({ ...body, id })
            }
            else {
                await createGroup(data)
            }
            navigate("/emailManagement")

        } catch (error) {
            // console.log(error.response.data?.errormessage)
            if (error.response.data?.errormessage) {
                toast.error(error.response.data?.errormessage)
            }

        }
    };

    return (
        <div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row m-0 p-0">

                    <div className="col-md-12">
                        <span
                            className="open_Rfi_text"
                            onClick={() => navigate("/emailManagement")}
                        >
                            {" "}
                            Email
                        </span>
                        <span className="px-1 open_Rfi_text">/</span>
                        <span className="createNew_text"> {id ? "Update" : "Create"} Email</span>
                    </div>
                    <span className="newRfi_text my-3">
                        Email
                    </span>


                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Select Project <span className="text-danger">*</span>:
                        </label>
                        <Controller
                            name="project_id"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <SingleSelect
                                        className={errors?.project_id ? "Validation" : ""}
                                        placeholder="SelectProject"
                                        disabled={id}
                                        options={allProjects.map(e => ({ label: e.project_name, value: e._id }))}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            setValue("user_id", [])
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="" className="py-2 labelCss">
                            Select Users : <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="user_id"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MultiSelect
                                        filter={true}
                                        placeholder="Select Users"
                                        value={value}
                                        onChange={onChange}
                                        options={allUsersOfProject.map(e => ({ label: e.first_name, value: e._id }))}
                                        className={`${errors.user_id ? "Validation" : ""} w-100`}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Distribution Mail: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="email"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.email && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Distribution Mail"}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Description: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="description"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Editor
                                        style={{ height: "200px" }}
                                        value={value}
                                        onTextChange={(e) => onChange(e.htmlValue)}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>

                <div className="end gap-3 mt-3">

                    <Link to={"/emailManagement"}>
                        <button type="button" className="btn border">Cancel</button>
                    </Link>

                    <PrimaryButton type="submit" text={id ? "Update" : "Create"} />
                </div>

            </form >
            <br />

        </div >
    )
}