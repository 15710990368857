import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { FaDownload } from "react-icons/fa";

export const DocumentViewer = ({ children, links }) => {
    const [dialog, setDialog] = useState(false);

    const downloadFile = async (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const FileRenderer = ({ fileUrl, ...props }) => {
        const getFileType = (url) => {
            const extension = url.split('.').pop().toLowerCase();
            return extension;
        };

        const fileType = getFileType(fileUrl);

        return (
            <div {...props}>
                <div className="end">

                    <span

                        onClick={(e) => {
                            e.stopPropagation()
                            downloadFile(fileUrl)
                        }}
                        style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        <FaDownload />
                    </span>
                    <br/>
                    <br/>
                </div>



                {['jpg', 'jpeg', 'png', 'gif'].includes(fileType) ? (
                    <div>
                        <img
                            src={fileUrl}
                            alt="file"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                ) : (
                    <div>
                        <iframe
                            src={fileUrl}
                            title="PDF Viewer"
                            style={{ width: '100%', height: '500px' }}
                            frameBorder="0"
                        />
                    </div>
                )}


            </div>
        );
    };

    return (
        <div>
            <div onClick={(e) => {
                e.stopPropagation();
                setDialog(true);
            }}>
                {children}
            </div>

            <Dialog
                header={`Documents`}
                visible={dialog}
                style={{ width: '90%', minWidth: '30rem' }}
                // onHide={() => setDialog(false)}
                headerClassName="addBorder_bottom"
                draggable={false}
                closable={false}
            >
                <div style={{ marginBottom: '20px' }} className='row'>
                    {links.map((link, index) => (
                        <FileRenderer key={index} className="col-md-6 col-12 p-3" fileUrl={link} />
                    ))}
                </div>
                <div className="end mt-3">
                    <button className='btn-secondary border btn' onCkick={() => setDialog(false)}>Close</button>
                </div>
            </Dialog>
        </div>
    );
};
