import React from "react";
import { Skeleton } from "primereact/skeleton";
import "./common.css";

const FormSkeleton = ({ data, mt, gap }) => {


  function switchClasses(value) {
    switch (value) {
      case 1:
        return 'grid1'
        break;
      case 2:
        return 'grid2'
        break;
      case 3:
        return 'grid3'
        break;
      case 4:
        return 'grid4'
        break;

      default:
        return 'grid1'
        break;
    }
  }

  return (
    <>
      <div className={`w-100 d-flex flex-column ${gap ? gap : "gap-5"} ${mt ? mt : "mt-5"}`}>
        {data?.map((res, index) => {
          return (
            <div key={index} className={switchClasses(res.qty)}>
              {[...Array(res.qty)].map((res2, index2) => <Skeleton width="100%" height={res.height !== undefined ? res.height : '3rem'} key={index2} />)}
            </div>
          )
        })}
      </div>
      {/* <div
      className='parent_form_skeleton mt-5'
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${col}, 1fr)`,
        gap: '1.5rem', 
      }}
    >
      {[...Array(totalItems)].map((_, index) => (
        <div key={index}>
          <Skeleton width="100%" height="3rem" />
        </div>
      ))}
    </div> */}
    </>
  );
};

export default FormSkeleton;
