import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { getAllDirectCost } from '../../../../Api/Admin/AllAPIs'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import { SearchInput } from '../../../../Components/Inputs/SearchInput'
import { searchDataWithKeys } from '../../../../utils'
import "./directCost.css"
import Popover from '../../../../Components/Common/Popover'

export const GeneralTable = () => {
    let { setActiveStep } = useOutletContext()


    // update active step in outlet 
    useEffect(() => {
        setActiveStep(0)
    }, [])
    const [search, setSearch] = useState("")
let navigate=useNavigate();

    const [directCostData, setDirectCostData] = useState([])
    const [allData, setAllData] = useState([])

    async function fetchAllDirectCost() {
        try {
            let res = await getAllDirectCost()
            let data = res?.data
            setDirectCostData(data?.data || [])
            setAllData(data?.data || [])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAllDirectCost()
    }, [])

    // search table values 
    useEffect(() => {
        setDirectCostData(searchDataWithKeys(["direct_cost_name"], allData, search))
    }, [search]
    )


    return (
        <div>
            <div className="searchInput mt-4">
                <SearchInput onChange={(e) => setSearch(e.target.value)} value={search} onCancel={() => setSearch("")} />
            </div>
            <br />
            <div className="direct_cost_general_table_scroll">

                <DataTable
                    value={directCostData}
                >
                    <Column
                        field="id"
                        header="#"
                        body={(rowData, rowIndex) => (
                            <div>{rowIndex.rowIndex + 1}</div>
                        )}
                    ></Column>
                    <Column header="Name" field="direct_cost_name" />
                    <Column header="Type" field="direct_cost_type" />
                    <Column header="Contracting Party" field="nameData" />
                    <Column header="Line Items" field="size" />
                    <Column header="Paid Date" body={(rowData) => moment(rowData?.paid_date).format("DD/MM/YYYY")} />
                    <Column header="Amount" field='totalAmount' />
                    <Column header="Actions"
                        body={(rowData, meta) => (
                            <div className="flex justify-content-center">
                                <Popover
                                    options={["Edit"]}
                                    onSelect={(index, event) => {
                                        if (index === 0)
                                            navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                                    }}
                                />
                            </div>
                        )}

                    />
                </DataTable>
            </div>
            <div className="d-flex justify-content-between grand_total_contaier mt-3">
                <div>Grand Total</div>
                <div>{directCostData.reduce((a, b) => a + b.totalAmount, 0)}</div>
            </div>

        </div>
    )
}
