import React, { useRef, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import style from "../Buttons/button.module.css";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { PrimaryButton } from "../Buttons/PrimaryButton";

export const  DeleteDialog = ({ onConfirm, onCancel, header, visible, setVisible }) => {
  const toast = useRef(null);

  const accept = () => {
    onConfirm();
    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
    setVisible(false);
  };

  const reject = () => {
    onCancel();
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
    setVisible(false);
  };

  const confirmDelete = () => {
    setVisible(true);
  };

  return (
    <>
      
      <Toast ref={toast} />
      {/* <div className={style.deleteButton} onClick={confirmDelete}>
        <RiDeleteBin5Line />
      </div> */}
      <ConfirmDialog
        style={{
          // width: "30%",
          // minWidth: "25rem",
          // padding: "0.8rem",
          // background: "#fff",
        }}
        draggable={false}
        closable={false}
        visible={visible}
        onHide={() => setVisible(false)}
        header={null}
        message={
          <div>
            <div className='deleteconfirm_image_con' >
              <img src="/images/deleteConfirmation.png" className='w-100' alt="delete Image" />
            </div>
          </div>
        }
        acceptClassName="p-button-danger"
        acceptLabel="Yes"
        rejectLabel="No"
        footer={
          <div className="d-flex gap-4 justify-content-end">
            <button
              className="deleteConfirm_secondary_btn w-100 text-center" onClick={() => setVisible(false)}>
              {" "}
              Cancel
            </button>
            <PrimaryButton style={{ padding: "10px 16px" }} onClick={accept} className="border text-center d-flex justify-content-center filter_btn_apply w-100" text={"Delete"} />
          </div>
        }
      />
    </>
  );
};

export default DeleteDialog;
