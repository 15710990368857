import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import TextInput from "../../../../Components/Inputs/TextInput";
import SingleSelect from "../../../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { PrimaryButton } from "../../../../Components/Buttons/PrimaryButton";
import { HiDownload } from "react-icons/hi";
import { DragAndDrop } from "../../../../Components/Inputs/DragAndDrop";

function AddSOV({ onSubmit }) {
  const [visible, setVisible] = useState(false);
  const [importsov, setImportsov] = useState(false);

  const centeredHeaderTemplate = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">Add SOV Item</span>

      <span className=" " onClick={() => setVisible(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  const centeredHeaderTop = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">
        Import SOV from CSV
      </span>

      <span className=" " onClick={() => setImportsov(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  return (
    <div>
      <span className="mt-5 " onClick={() => setVisible(true)}>
        <span className="add_icon_text">
          {" "}
          <IoMdAdd size={15} /> Add SOV Item{" "}
        </span>
      </span>
      <Dialog
        style={{ width: "40%" }}
        visible={visible}
        closable={false}
        draggable={false}
        onHide={() => setVisible(false)}
        header={centeredHeaderTemplate}
      >
        <div className=" pl-4 ">
          <div className=" ">
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Budget Code:
              </label>
              <SingleSelect
                // inputId="integeronly"
                placeholder="Enter Title"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></SingleSelect>
            </div>
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Tax Code:
              </label>
              <SingleSelect
                // inputId="integeronly"
                placeholder="Enter Title"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></SingleSelect>
            </div>
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Amount:
              </label>
              <TextInput
                // inputId="integeronly"
                placeholder="Enter Title"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></TextInput>
            </div>
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Billed to Date:
              </label>
              <TextInput
                // inputId="integeronly"
                placeholder="Enter Title"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></TextInput>
            </div>
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Amount Remaining:
              </label>
              <TextInput
                // inputId="integeronly"
                placeholder="Enter Title"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></TextInput>
            </div>

            <div className="  mt-2 ">
              <div className="col-md-12">
                <label htmlFor="" className="py-2 labelCss">
                  Description:
                </label>
                <div className="card">
                  <Editor
                    //   value={data?.description}
                    // className={Validation.description ? "Validation" : ""}
                    // onClick={() => setValidation({ ...Validation, description: false })}
                    name="description"
                    //   onTextChange={handleTextChange}
                    style={{ height: "150px" }}
                  />
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center my-4">
              <div
                class="flex-grow-1 border-top"
                style={{ color: "#D0D5DD" }}
              ></div>
              <span class="mx-2 text-muted add_text fw-medium">OR</span>
              <div
                class="flex-grow-1 border-top"
                style={{ color: "#D0D5DD" }}
              ></div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <span className="flex justify-center w-full font-bold add_text text-decoration-none">
                Import Schedule of Value from CSV?
              </span>

              <span className=" ">
                <span
                  className="add_icon_text text-decoration-none d-flex align-items-center"
                  onClick={() => {
                    setImportsov(true);
                    setVisible(false);
                  }}
                >
                  {" "}
                  <IoMdAdd size={15} />
                  Import{" "}
                </span>
              </span>
            </div>

            <div className="end mt-5 gap-2">
              <button
                className="btn border"
                onClick={() => setVisible(false)}
                type="button"
              >
                Cancel
              </button>
              <PrimaryButton addIcon type="submit" text={"Add SOV"} />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        style={{ width: "40%" }}
        visible={importsov}
        closable={false}
        draggable={false}
        onHide={() => setImportsov(false)}
        header={centeredHeaderTop}
      >
        <div className="add_icon_text text-decoration-none">
          To import Schedule of Value line items from a CSV file, kindly
          download the CSV template given below and add your data to it in the
          given format. After saving the downloaded file along with changes
          made, you can upload the file below.
        </div>
        <div className="add_icon_text text-decoration-none">
          Kindly note that any line item whose amount value is left blank will
          not be imported.
        </div>
        <div className="mt-3">
          <button className="filter_btn ">
            {" "}
            <HiDownload style={{ marginRight: "5px" }} size={17} />
            Download CSV Template
          </button>
        </div>
        <div className="  mt-2  css_com">
          <div className="col-md-12">
            <DragAndDrop
              label={"CSV File"}
              // setRemovePhoto={setRemovePhoto}
              // value={fileName}
              // setFiles={setFileUpload}
              // onChange={handleFileSubmit}
              // setValue={setFileName}
              // setPreviousFiles={setPreviousFile}
            />
          </div>
        </div>
        <div className="end mt-5 gap-2">
          <button
            className="btn border"
            onClick={() => {
              setImportsov(false);
              setVisible(true);
            }}
            type="button"
          >
            Cancel
          </button>
          <PrimaryButton type="submit" text={"Add SOV"} />
        </div>
      </Dialog>
    </div>
  );
}

export default AddSOV;
