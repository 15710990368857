import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

const TextInput = ({
  label,
  inputId,
  onChange,
  width,
  value,
  placeholder,
  onNumberChange,
  type,
  code,
  ...rest
}) => {
  const renderInput = () => {
    return (
      <>
        {type === "number" ? (
          <InputNumber
            id={inputId}
            aria-describedby={`${inputId}-help`}
            value={value}
            onChange={onNumberChange}
            placeholder={placeholder}
            useGrouping={code ? false : true}
            {...rest}
          />
        ) : (
          <InputText
            id={inputId}
            aria-describedby={`${inputId}-help`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            {...rest}
          />
        )}
      </>
    );
  };

  return (
    <div className="card card-add" style={{ width: width ? width : "" }}>
      <div className="flex flex-column w-100">
        <label htmlFor={inputId} className="labelCss">
          {label}
        </label>
        {renderInput()}
      </div>
    </div>
  );
};

export default TextInput;
