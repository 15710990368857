import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewProjectInformation from "./ViewProjectInformation";
import {
  getCodeCost,
  getProject,
  getProjectAssignData,
  getSpecificationByProjectId,
  getSpecificationDataByProjectId,
} from "../../Api/Admin/AllAPIs";
import profile from "../../assets/profile.png";
import ViewProjectStages from "./ViewProjectStages";
import ViewProjectTools from "./ViewProjectTools";
import ViewProjectDirectory from "./ViewProjectDirectory";
import ViewProjectSpecifications from "./ViewProjectSpecifications";
import ViewProjectDrawing from "./ViewProjectDrawing";
import ViewCostCode from "./ViewCostCode";
import GridSkeleton from "../../Components/Common/GridSkeleton";
import Popover from "../../Components/Common/Popover";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import moment from "moment";
import ProjectScheduleView from "./ProjectScheduleView";
const ProjectView = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const location = useLocation();
  const [projectData, setProjectData] = useState();
  const [codeData, setCodeData] = useState();
  const [projectDirectory, setProjectDirectory] = useState();
  const [specificationData,setSpecificationData] = useState([])
  const [showPopUp,setShowPopUp] = useState(false)



  const transformDataBack = (input) => {
    return input.flatMap((data) =>
      data.person.map((person) => ({

        companyName: data.company.company_name === "All Individual" ? "Internal Employees" : data.company.company_name,
        company_id: data.company._id,
        personFirstName: person.user.first_name,
        personLastName: person.user.last_name,
        personCompanyRole: person.user.company_role,
        personName: person.user.first_name + " " + person.user.last_name,
        person_id: person.user._id,
        profile: '',
        role: person.project_role,
      }))
    );
  };

  const getProjectData = async () => {

    try {

      const response = await getProject(id);
      const code = await getCodeCost(id);
      const res = await getProjectAssignData(localStorage.getItem("projectId"));
      const directoryData = res.data.data;
      if (response?.data?.data?.length !== 0) {
        let params = {
          page:0
          // perPageData
        }
        // let body = { ...tempFilterData }
        const ress = await getSpecificationDataByProjectId(localStorage.getItem("projectId"), params);
        // const ress = await getSpecificationByProjectId(localStorage.getItem("projectId"));
        setSpecificationData(ress?.data?.data || [])
        console.log(ress?.data?.data || [])
        let listData = transformDataBack(directoryData);
        setProjectData(response.data.data[0]);
        setProjectDirectory(listData);
        setCodeData(code.data.data);
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  useEffect(() => {
    getProjectData();
  }, []);



  useEffect(() => {
   
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('sendEmail'); 
    if(query == 'send'){
      // buttonRef.current.click()
      setShowPopUp(true)
      navigate(location.pathname, { replace: true });
    }
  }, []);




  return (

    <div className="drawingparent">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Project View</h1>
        <div>
              <DownloadPdfAndSendEmail
                heading={"Project"}
                forProject={true}
                fileName={"Project"}
                id={projectData?._id}
                popupShow={showPopUp}
                hideButton={true}
                files={[
                  // ...projectData?.attachFile?.map((e) => e.fileUrl),
                ]}
              >
                <div className="p-5">
                  <div className='fw-bold fs-5'>1.Project Information</div>
                  <ViewProjectInformation projectData={projectData} />
                  <div className='fw-bold fs-5'>2.Project Directory</div>
                  <ViewProjectDirectory projectDirectory={projectDirectory} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>3.Cost Code </div>
                  <ViewCostCode costCodeData={codeData} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>4.Project Tools </div>
                  <ViewProjectTools toolData={projectData?.project_tools} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>5.Project Stages</div>
                  <ViewProjectStages stageData={projectData?.project_stages} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>6.Project Drawings</div>
                  <ViewProjectDrawing
                    drawingData={projectData?.project_drawings}
                    hidePagination={true}
                  />
                  <div className='fw-bold fs-5 my-4'>6.Project Specifications</div>
                  <ViewProjectSpecifications
                    specificationData={specificationData}
                    hidePagination={true}
                  />
                  <div className='fw-bold fs-5 my-4'>7.Project Schedule</div>
                  {projectData ? <div className="d-flex mt-3 flex-wrap gap-4">
                    {projectData?.project_schedule?.length !== 0 ? projectData?.project_schedule?.map((res) => (
                      // console.log(res?.fileUrl)
                      <img
                        src={res?.fileUrl}
                        width={"100px"}
                        height={"100px"}
                        alt=""
                      />
                    )) : <div className="ps-3">
                      <h6>No Photos available</h6>
                    </div>
                    }

                  </div> : <GridSkeleton cardCount={4} />}
                </div>
              </DownloadPdfAndSendEmail>
            </div>

        <Popover
          options={[
            'Edit',
            "Version History",
            "Send Via Post",
            <div>
              <DownloadPdfAndSendEmail
                heading={"Project"}
                forProject={true}
                fileName={"Project"}
                id={projectData?._id}
                files={[
                  // ...projectData?.attachFile?.map((e) => e.fileUrl),
                ]}
              >
                <div className="p-5">
                  <div className='fw-bold fs-5'>1.Project Information</div>
                  <ViewProjectInformation projectData={projectData} />
                  <div className='fw-bold fs-5'>2.Project Directory</div>
                  <ViewProjectDirectory projectDirectory={projectDirectory} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>3.Cost Code </div>
                  <ViewCostCode costCodeData={codeData} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>4.Project Tools </div>
                  <ViewProjectTools toolData={projectData?.project_tools} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>5.Project Stages</div>
                  <ViewProjectStages stageData={projectData?.project_stages} hidePagination={true} />
                  <div className='fw-bold fs-5 my-4'>6.Project Drawings</div>
                  <ViewProjectDrawing
                    drawingData={projectData?.project_drawings}
                    hidePagination={true}
                  />
                  <div className='fw-bold fs-5 my-4'>6.Project Specifications</div>
                  <ViewProjectSpecifications
                    specificationData={specificationData}
                    hidePagination={true}
                  />
                  <div className='fw-bold fs-5 my-4'>7.Project Schedule</div>
                  {projectData ? <div className="d-flex mt-3 flex-wrap gap-4">
                    {projectData?.project_schedule?.length !== 0 ? projectData?.project_schedule?.map((res) => (
                      // console.log(res?.fileUrl)
                      <img
                        src={res?.fileUrl}
                        width={"100px"}
                        height={"100px"}
                        alt=""
                      />
                    )) : <div className="ps-3">
                      <h6>No Photos available</h6>
                    </div>
                    }

                  </div> : <GridSkeleton cardCount={4} />}
                </div>
              </DownloadPdfAndSendEmail>
            </div>
          ]}
          onSelect={(index, event) => {
            if (index === 0)
              navigate("/coretools/admin/projectform")
            else if (index === 1)
              navigate("/coretools/admin/projectView/versionHistory")
            else if (index === 2)
              navigate("/coretools/admin/projectView/AddressListing")
          }}
        />

      </div>
      <div className="mt-3">
        <TabView>
          <TabPanel header="Project Information">
            <ViewProjectInformation projectData={projectData} />
          </TabPanel>
          <TabPanel header="Project Directory">
            <ViewProjectDirectory projectDirectory={projectDirectory} />
          </TabPanel>
          <TabPanel header="Cost Code">
            <ViewCostCode costCodeData={codeData} />
          </TabPanel>
          <TabPanel header="Project Tools">
            <ViewProjectTools toolData={projectData?.project_tools} />
          </TabPanel>
          <TabPanel header="Project Stages">
            <ViewProjectStages stageData={projectData?.project_stages} />
          </TabPanel>
          <TabPanel header="Project Drawing">
            <ViewProjectDrawing
              drawingData={projectData?.project_drawings}
            />
          </TabPanel>
          <TabPanel header="Specifications">
            <ViewProjectSpecifications
              specificationData={specificationData}
            />
          </TabPanel>
          <TabPanel header="Project Schedule">
            <ProjectScheduleView/>
          </TabPanel>
        </TabView>

      </div>
    </div>

  );
};

export default ProjectView;
