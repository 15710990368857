import React, { useEffect, useState } from "react";
import "./projecthome.css";
import { FiEdit } from "react-icons/fi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { BorderedTable } from "../Tables/BorderedTable";
import BarChart from "../Portfolio/BarChart";
import { useNavigate } from "react-router-dom";

const ProjectHome = () => {
  const [products, setProducts] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [visible, setVisible] = useState({});
  const navigate = useNavigate()

  const TableData = [
    {
      role: "Architect",
      name: "Elicia Katherine (Lorem & Co.)",
      email: "katherine123@gmail.com",
      office: "9874563210",
      mobile: "8745693210",
    },
    {
      role: "Architect",
      name: "Dennis Paul (Ipsum Works)",
      email: "dennisthepaul@gmail.com",
      office: "9632587410",
      mobile: "8963214750",
    },
    {
      role: "Architect",
      name: "Sheryll Lacie (Lorem & Co.)",
      email: "sherylll2345@gmail.com",
      office: "9874123650",
      mobile: "8426971350",
    },
  ];
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "RFIs",
        "Submittals",
        "Punch Lists",
        "Meetings",
        "Incidents",
        "Tasks",
      ],
      datasets: [
        {
          label: "Overdue",
          backgroundColor: "#D42620",
          borderColor: "#D42620",
          data: [100, 50, 100, 150, 100, 90],
          barThickness: 20,
          borderRadius: 10,
          barPercentage: 1.5,
        },

        {
          label: "Next 7 Days",
          backgroundColor: "#0F973D",
          borderColor: "0F973D",
          data: [150, 100, 50, 50, 100, 110],
          barThickness: 20,
          borderRadius: 10,
          barPercentage: 1.5,
        },

        {
          label: "> 7 Days",
          backgroundColor: "#F3A218",
          borderColor: "red",
          data: [50, 150, 150, 100, 100, 100],
          barThickness: 20,
          borderRadius: 10,
          barPercentage: 1,
        },
      ],
    };
    const options = {
      tooltips: {
        enabled: false,
      },
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            boxWidth: 20, // Adjust the width to make it square
            boxHeight: 20,
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);
  return (
    <div className=" Project_home_Section ">
      <div className="d-flex justify-content-between px-2 pt-2">
      <div className="heading ">PROJECT HOME</div>
      <button className="filter_btn " onClick={()=> navigate('documents')}>Documents</button>
      </div>

      <div className="mt-3">
        <div className="chart_head d-flex justify-content-between align-items-center">
          <span className="chart_heading">Project Team</span>
          <span className="text-white text-bold fs-5 d-flex align-items-center gap-2">
            <FiEdit />
            Edit
          </span>
        </div>
        <div className="card">
          <BorderedTable>
            <DataTable value={TableData} tableStyle={{ minWidth: "50rem" }}>
              <Column field="role" header="Code"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="email" header="Category"></Column>
              <Column field="office" header="Quantity"></Column>
              <Column field="mobile" header="Quantity"></Column>
            </DataTable>
          </BorderedTable>
        </div>
      </div>
      <div className="mt-5 mb-5">
        <div className="chart_head d-flex justify-content-between align-items-center">
          <span className="chart_heading">Overview</span>
          <span className="text-white text-bold fs-5 d-flex align-items-center gap-2">
            <FiEdit />
            Edit
          </span>
        </div>

        <div className="w-100 d-flex  border pt-2">
          <div className="w-100">
            <BarChart
              className=""
              series={[
                {
                  name: "Overdue",
                  data: [44, 55, 41, 37, 22, 10],
                },
                {
                  name: "Next 7 Days",
                  data: [53, 32, 33, 52, 13, 43],
                },
                {
                  name: "> 7 Days",
                  data: [12, 17, 11, 9, 15, 11],
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-center ">
            <div className=" d-flex flex-column  align-items-center  chart_total_home  ">
              <div className=" totalOpen">Total open </div>
               <div className="GapChart">
               <span className="no_gap">12</span>
              <span className="no_gap"> 17</span>
              <span className="no_gap">12</span>
              <span className="no_gap">17</span>
              <span className="no_gap">12</span>
              <span className="no_gap">17</span>
               </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};
export default ProjectHome;
