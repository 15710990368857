import React from 'react'
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

let tasks = [
    {
      start: new Date(2020, 1, 3),
      end: new Date(2020, 1, 4),
      name: 'Task 1',
      id: 'Task 1',
      type: 'task',
      progress: 45,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    {
        start: new Date(2020, 1, 12),
        end: new Date(2020, 1, 14),
        name: 'Task 2',
        id: 'Task 2',
        type: 'task',
        progress: 70,
        isDisabled: true,
        styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
      },
      {
        start: new Date(2020, 1, 1),
        end: new Date(2020, 1, 2),
        name: 'Task 1',
        id: 'Task 1',
        type: 'task',
        progress: 45,
        isDisabled: true,
        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
      },
      {
          start: new Date(2020, 1, 10),
          end: new Date(2020, 1, 11),
          name: 'Task 2',
          id: 'Task 2',
          type: 'task',
          progress: 90,
          isDisabled: true,
          styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
        },
        {
            start: new Date(2020, 1, 1),
            end: new Date(2020, 1, 2),
            name: 'Task 1',
            id: 'Task 1',
            type: 'task',
            progress: 45,
            isDisabled: true,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          },
          {
              start: new Date(2020, 1, 5),
              end: new Date(2020, 1, 6),
              name: 'Task 2',
              id: 'Task 2',
              type: 'task',
              progress: 90,
              isDisabled: true,
              styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
            },
            {
                start: new Date(2020, 1, 7),
                end: new Date(2020, 1, 8),
                name: 'Task 1',
                id: 'Task 1',
                type: 'task',
                progress: 45,
                isDisabled: true,
                styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              },
              {
                  start: new Date(2020, 1, 5),
                  end: new Date(2020, 1, 6),
                  name: 'Task 2',
                  id: 'Task 2',
                  type: 'task',
                  progress: 90,
                  isDisabled: true,
                  styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
                },
                {
                    start: new Date(2020, 1, 1),
                    end: new Date(2020, 1, 2),
                    name: 'Task 1',
                    id: 'Task 1',
                    type: 'task',
                    progress: 45,
                    isDisabled: true,
                    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                  },
                  {
                      start: new Date(2020, 1, 5),
                      end: new Date(2020, 1, 6),
                      name: 'Task 2',
                      id: 'Task 2',
                      type: 'task',
                      progress: 90,
                      isDisabled: true,
                      styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
                    },
                    {
                        start: new Date(2020, 1, 5),
                        end: new Date(2020, 1, 6),
                        name: 'Task 2',
                        id: 'Task 2',
                        type: 'task',
                        progress: 90,
                        isDisabled: true,
                        styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
                      },
                      {
                          start: new Date(2020, 1, 7),
                          end: new Date(2020, 1, 8),
                          name: 'Task 1',
                          id: 'Task 1',
                          type: 'task',
                          progress: 45,
                          isDisabled: true,
                          styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                        },
                        {
                            start: new Date(2020, 1, 5),
                            end: new Date(2020, 1, 6),
                            name: 'Task 2',
                            id: 'Task 2',
                            type: 'task',
                            progress: 90,
                            isDisabled: true,
                            styles: { progressColor: '#ff0000', progressSelectedColor: '#ff9e0d' },
                          },
      // Add more tasks with different progress bar colors as needed
];

export default function ScheduleLookHead() {
  return (
    <>
  <div className='' style={{ overflowX: 'auto' }}>

<Gantt tasks={tasks} />
    </div>

    </>
  )
}
