import React from 'react'

export const ViewPageDocumentName = ({ docUrl, name, ...props }) => {

    return (
        <div {...props} className='view_page_name_card pointer'>
            <a href={docUrl} target='_blank'>
                {name}
            </a>
        </div>
    )
}
