// import { Dialog } from 'primereact/dialog'
// import React from 'react'


// export default function CompareDrawingImage({ open, toggle, pageFor, viewData }) {
//     return (
//         <>
//             <Dialog header={`Compare ${pageFor}s`} visible={open} style={{ width: '90vw' }} onHide={toggle} breakpoints={{ '641px': '95vw' }}>
//                 <div className='row'>
//                     <div className='col-6 border py-5'>
//                         <><img src={viewData.view1} width="100%" /></>
//                     </div>
//                     <div className='col-6 border py-5'>
//                         <><img src={viewData.view2} width="100%" /></>
//                     </div>
//                 </div>
//             </Dialog>
//         </>
//     )
// }



import React from 'react';
import { Dialog } from 'primereact/dialog';
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function CompareDrawingImage({ open, toggle, pageFor, viewData }) {
    const docs1 = [{ uri: viewData.view1 }];
    const docs2 = [{ uri: viewData.view2 }];

    return (
        <>
            <Dialog header={`Compare ${pageFor}s`} visible={open} style={{ width: '90vw' }} onHide={toggle} breakpoints={{ '641px': '95vw' }}>
                <div className="row">

                    <div className="col-6 border py-5">
                        <DocViewer
                            documents={docs1}
                            pluginRenderers={DocViewerRenderers}
                        />
                    </div>
                    <div className="col-6 border py-5">
                        <DocViewer
                            documents={docs2}
                            pluginRenderers={DocViewerRenderers}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
}


