import React, { useEffect, useState } from "react";
import TextInput from "../Inputs/TextInput";
import { Dialog } from "primereact/dialog";
import { toast } from "../../utils";
import FormSkeleton from "../Common/FormSkeleton";
import Loader from "./Loader";
import { Spinner } from "react-bootstrap";

export default function AddSets({
  visible,
  setVisible,
  data,
  loading,
  setLoading,
  reqFor,
  label,
  CategoryName,value,setValue,
  onSubmit

}) {
  const [name, setName] = useState("");
  // const [loader, setLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(reqFor === "Update" ? true : false);
  const skeletonData = [
    { qty: 1 },

  ]


  async function handleCreate() {
    if (name?.trim() === "") {
      toast.info("Please Fill the Fields Marked with * are Mandatory");
    }

    setVisible(false);
    setLoading(false)
  }
  
 

  return (
    <div>
      
      <Dialog
        header={`${reqFor === "Update" ? reqFor : "Create"} ${ CategoryName ? CategoryName :label}`}
        visible={visible}
        style={{ width: "30rem", minWidth: "30rem" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        
        <div className="row">
          <div className="col-md-12 mt-4">
            <label className="labels mb-2">{  CategoryName ? CategoryName :label} Name:</label>
            <span className="text-danger">*</span>

            {
              false ? <FormSkeleton data={skeletonData} mt={"mt-1"} /> : <div className="card flex justify-content-center  ">
                <TextInput
                  placeholder={`${label} Name`}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  showIcon
                  style={{ textTransform: 'capitalize' }}
                />
              </div>
            }
          </div>

          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button
                className="filter_btn"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              {loading ? (
              <Spinner/>
 

              ):(
              <button class={` filter_btn_apply `}  onClick={onSubmit} >
               Submit
              </button>

              )}
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
}