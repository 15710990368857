import React, { useState } from 'react'
import { SingleEmailCard } from './SingleEmailCard'
import { SingleEmailView } from './SingleEmailView'
import Pagination from '../../Components/Common/Pagination/Pagination'
import "./Email.css"
import FormSkeleton from '../../Components/Common/FormSkeleton'
import { updateEmailById } from '../../Api/Admin/AllAPIs'

export const SentEmails = ({ allEmails, getAllEmails, checkedEmail, emailType, loader, emailCardClick, emailCheckClick, paginationProps, selectedEmail }) => {


  function selectEmail(emailData) {
    console.log(emailData)
    emailCardClick(emailData)
  }


  async function moveEmailToSpamOrBin(id, emailType) {

    console.log(emailType)

    try {
      let res = await updateEmailById(id, { type: emailType })
      console.log(res)
      getAllEmails()
      console.log("email editeddd etieddd")
    } catch (error) {
      console.log(error)
    }
  }



  async function deleteEmail(id) {
    console.log("final delete`")
    try {
      // let res=await 
    } catch (error) {
      console.log(error)
    }
  }



  const skeletonData = [
    { qty: 1, height: "5rem" },
    { qty: 1, height: "5rem" },
    { qty: 1, height: "5rem" },
    { qty: 1, height: "5rem" }

  ];
  const skeletonData2 = [
    { qty: 1, height: "2rem" },
    { qty: 4, height: "2rem" },
    { qty: 1, height: "2rem" },
    { qty: 1, height: "2rem" },
    { qty: 1, height: "2rem" },
    { qty: 1, height: "5rem" }

  ];
  return (
    <div>

      <div className="row">
        <div className="col-lg-6 col-12 mt-4">

          {loader ? <FormSkeleton data={skeletonData} mt={"mt-0"} gap={"gap-1"} /> :
            <div className='all_emails_container'>
              {allEmails?.map((res, ind) => {
                return (
                  <SingleEmailCard
                    moveEmailToSpamOrBin={moveEmailToSpamOrBin}

                    deleteEmail={deleteEmail}
                    emailType={emailType}
                    emailCheckClick={emailCheckClick}
                    className={`${selectedEmail?._id == res?._id ? "active_card" : ""}`}
                    onClick={() => selectEmail(res)} key={ind}
                    checkedEmail={checkedEmail}
                    cardData={res} />
                )
              })
              }
            </div>
          }

          <br />
          <Pagination {...paginationProps} />


        </div>

        <div className="col-lg-6 col-12 mt-4 ">

          {loader ? <FormSkeleton data={skeletonData2} mt={"mt-0"} gap={"gap-1"} /> :
            selectedEmail?.subject &&
            <div className='all_emails_container'>
              <SingleEmailView
                moveEmailToSpamOrBin={moveEmailToSpamOrBin}
                emailType={emailType}
                emailCheckClick={emailCheckClick}
                emailData={selectedEmail} />
            </div>
          }

        </div>
      </div>

    </div>
  )
}
