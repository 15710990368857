import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { TableSkelton } from "../../Components/Common/TableSkelton";

const ViewProjectSpecifications = ({ specificationData,hidePagination }) => {
  const [projectSpecificationsData, setProjectSpecificationsData] = useState(
    []
  );
  useEffect(() => {
    if (specificationData) {
      setProjectSpecificationsData(specificationData);
    }
  }, [specificationData]);
  return (
    <>
     {specificationData?<div style={{width:'100%',overflow:'auto'}}>
      {hidePagination?<DataTable
          value={projectSpecificationsData}
          // paginator
          // rows={5}
          // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
        >
          
          <Column
            field="spec_id"
            header="Specification Id"
            style={{ Width: "5%" }}
            // body={(rowData, meta) => (
            //   <div className="">
            //     {rowData?.spec_id}
            //   </div>
            // )}
          />
          <Column
            field="#"
            header="Set Name"
            // style={{ Width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2  align-items-center">
                {rowData?.set[0]?.name}
              </div>
            )}
          />
          <Column
            field="#"
            header="Format"
            // style={{ Width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2  align-items-center">
                {rowData?.format[0]?.name}
              </div>
            )}
          />
          <Column
            field="project_name"
            header="Issued Date"
            // style={{ width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0">{moment(rowData.issuedDate).format("MM/DD/YYYY")}</p>
              </div>
            )}
          ></Column>
          <Column
            field="createdOn"
            header="Receieved Data"
            body={(rowData, meta) => <>{moment(rowData.receievedData).format("MM/DD/YYYY")}</>}
            // style={{ width: "19%" }}
          ></Column>
        </DataTable>:<DataTable
          value={projectSpecificationsData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
        >
          
          <Column
            field="spec_id"
            header="Specification Id"
            style={{ Width: "5%" }}
            // body={(rowData, meta) => (
            //   <div className="">
            //     {rowData?.spec_id}
            //   </div>
            // )}
          />
          <Column
            field="#"
            header="Set Name"
            // style={{ Width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2  align-items-center">
                {rowData?.set[0]?.name}
              </div>
            )}
          />
          <Column
            field="#"
            header="Format"
            // style={{ Width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2  align-items-center">
                {rowData?.format[0]?.name}
              </div>
            )}
          />
          <Column
            field="project_name"
            header="Issued Date"
            // style={{ width: "20%" }}
            body={(rowData, meta) => (
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0">{moment(rowData.issuedDate).format("MM/DD/YYYY")}</p>
              </div>
            )}
          ></Column>
          <Column
            field="createdOn"
            header="Receieved Data"
            body={(rowData, meta) => <>{moment(rowData.receievedData).format("MM/DD/YYYY")}</>}
            // style={{ width: "19%" }}
          ></Column>
          {/* <Column
            field="createdOn"
            header="Default Received Date"
            body={(rowData, meta) => <>{moment(rowData.default_received_date).format("MM/DD/YYYY")}</>}
            style={{ width: "19%" }}
          ></Column> */}
        </DataTable>}
        
      </div>
      : <TableSkelton header={["Specification Id", "Set Name", "Format", "Issued Date","Receieved Data"]} />
      }
    </>
  );
};

export default ViewProjectSpecifications;
