import React, { useEffect, useState } from 'react'
import SingleSelect from '../../Components/Common/SingleSelect';

import { getUserId } from '../../utils';
import { getAllProjects } from '../../Api/Admin/AllAPIs';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';

export const FilterEmail = ({ filterData, setFilterData, onFilter }) => {

    let [visible, setVisible] = useState(false);

    let [allProjects, setAllProjects] = useState([])

    async function getAllProject() {
        try {
            let body = {
                user_id: getUserId(),
                type: "admin"
            }
            let res = await getAllProjects(body)
            console.log(res.data.data)
            setAllProjects(res?.data?.data || [])

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (visible && allProjects.length == 0) {
            getAllProject()
        }
    }, [visible])

    function handleApplyFilter() {
        onFilter()
        setVisible(false)
    }

    return (
        <div>
            <button
                className="filter_btn"
                onClick={() => setVisible(true)}
            >
                Filter Emails
            </button>
            <Dialog
                draggable={false}
                header="Filter Emails"
                visible={visible}
                maximizable
                style={{ width: "50rem", color: "#101928" }}
                onHide={() => setVisible(false)}
                headerClassName="addBorder_bottom"
            >
                <div>

                    <div className="row ">
                        <div className="col-md-6">
                            <label className="labels py-2">Projects</label>
                            <div className="card flex justify-content-center">
                                <SingleSelect
                                    placeholder="Projects"
                                    options={allProjects.map(e => ({ label: e.project_name, value: e._id }))}
                                    value={filterData?.project_id}
                                    name="visitStatus"
                                    onChange={(e) => {
                                        console.log(e.value)
                                        setFilterData(pre => ({ ...pre, project_id: e.value }))

                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-5 d-flex justify-content-end">
                        <div className="d-flex align-items-center gap-3">
                            <button className="filter_btn" onClick={() => setVisible(false)}>
                                Cancel
                            </button>
                            <PrimaryButton className="Apply_filter_btn" text=" Apply Filter" onClick={handleApplyFilter} />
                        </div>
                    </div>

                </div>
            </Dialog>


        </div>
    )
}
