import React, { useState, useEffect } from "react";
import { Editor } from "primereact/editor";
import { useNavigate } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import TextInput from "../../Components/Inputs/TextInput";
import { Checkbox } from "primereact/checkbox";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IoMdAdd } from "react-icons/io";
import AddMeetingItem from "./AddMeetingItem";
import { addTemplate, uploadDocs } from "../../Api/Admin/AllAPIs";
import { toast } from "../../utils";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import { RxCross2 } from "react-icons/rx";

export const CreateTemplate = () => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [privates, setPrivate] = useState(false);
  const [sections, setSections] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [question, setQuestion] = useState();
  const [visible, setVisible] = useState(false);
  const [itemIndex, setitemIndex] = useState(false);
  const [getAgenda, setGetAgenda] = useState([]);
  const [section, setSection] = useState([]);
  const [sectionValue, setSectionValue] = useState();
  const [getHeadName, setGetHeadName] = useState();
  const [headerInitialization, setHeaderInitialization] = useState(false);
  const navigate = useNavigate();
  const [Validation, setValidation] = useState({
    meetingName: false,
  });

  const checkValidation = () => {
    if (!meetingName) {
      setValidation((prevState) => ({
        ...prevState,
        meetingName: true,
      }));
    }

    if (!meetingName) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleAddSection = () => {
    const newId = section.length + 1;
    const newSection = { id: newId, header: "", tableData: [] };
    setSection([...section, newSection]);
  };

  const handleFileInput = (event) => {
    setFileUpload(event);
  };

  const handleAddItems = (sectionId) => {
    setVisible(true);
    setitemIndex(sectionId);
  };

  const handleHeaderChange = (sectionId, e) => {
    const value = e.target.value;
    const updatedSections = section.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          header: value,
        };
      }

      return section;
    });

    setSection(updatedSections);
  };

  const handleCreateTemplate = async () => {
    if (checkValidation()) {
      return;
    }

    try {
      let uploadedFiles = [];

      if (fileUpload && fileUpload.length > 0) {
        const formData = new FormData();
        fileUpload.forEach((file) => formData.append("upload", file));

        const uploadResponse = await uploadDocs(formData);

        uploadedFiles = uploadResponse.data;
      }

      const templateData = section.map((sec) => ({
        template_agenda: sec.header,
        template_meeting_item: sec.tableData.map((item) => ({
          template_meeting_title: item.template_meeting_title,
          template_meeting_priority: item.template_meeting_priority,
          template_meeting_category: item.template_meeting_category,
          template_meeting_description: item.template_meeting_description,
          template_meeting_attachment: item.template_meeting_attachment || [],
        })),
      }));

      const payload = {
        template_meeting_name: meetingName,
        template_private_meeting: privates,
        template_overview: question,
        template_attachment: uploadedFiles,
        template_data: templateData,
      };

      await addTemplate(payload);
      toast.success("Template Created Successfully");
      navigate("/projectManagement/meetings/template");
    } catch (error) {
      console.error("Error creating template:", error);
      toast.error("Something Went Wrong, Please Try Again!");
    }
  };

  const handleAddRow = (sectionId, data) => {
    console.log(data, 'jadoooo')
    const updatedSections = section.map((section, index) => {
      if (index === sectionId) {
        data.id = section.tableData.length+1;
        return {
          ...section,
          tableData: [...section.tableData, data],
        };
      }
      return section;
    });

    setSection(updatedSections);
  };

  const handleTableDelete = (sectionId) => {
    // alert(sectionId)
    const updatedSections = section.filter(
      (section) => section.id !== sectionId
    );
    setSection(updatedSections);
  };


  const handleRowDelete = (sectionId, rowId) => {
    console.log(sectionId, rowId, 'jhgffddss')
    const updatedSections = section.map((section) => {
      if (section.id === sectionId) {
        console.log(sectionId, rowId);
        return {
          ...section,
          tableData: section.tableData.filter((row) => row.id !== rowId),
        };
      }
      return section;
    });

    setSection(updatedSections);
  };
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("meetingFormData"));

    setGetAgenda(storedData);
  }, []);

  return (
    <>
      <div className="mainContainer main-container">
        <div className="card p-md-4 p-sm-3 p-2 ">
          <div className="row">
            <div className="col-md-12 py-3  px-4 " style={{ marginLeft: "4px" }}>
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/projectManagement/meetings")}
              >
                {" "}
                Meeting
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> Create Template</span>
            </div>
          </div>

          <div className="row px-4 mt-4">
            <div className="col-md-12 ">
              <span className="newRfi_text"> Create Template </span>
            </div>

            <div className="row mt-3  ">
              <div className="col-md-12">
                <label htmlFor="" className="py-2 labelCss">
                  Meeting: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Enter Meeting No"
                  value={meetingName}
                  onChange={(e) => {
                    setMeetingName(e.target.value);
                  }}
                  onClick={() =>
                    setValidation({ ...Validation, meetingName: false })
                  }
                  className={Validation.meetingName ? "Validation" : ""}
                />
              </div>
            </div>

            <div className="row mt-3  ">
              <div className="col-md-12">
                <label htmlFor="" className="py-2 labelCss">
                  Question:
                </label>
                <div className="card">
                  <Editor
                    value={question}
                    onTextChange={(e) => setQuestion(e.htmlValue)}
                    style={{ height: "100px" }}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3   css_com">
              <div className="col-md-12">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setFileUpload}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFile}
                />
              </div>
            </div>

            <div className="d-flex  gap-2 mt-3 ">
              <Checkbox
                inputId="ingredient1"
                name="category"
                onChange={(e) => setPrivate(e.checked)}
                checked={privates}
              />

              <label htmlFor="ingredient1" className="ml-2 mb-2">
                Private Meeting
              </label>
            </div>

            {/* <div className="my-4">
              <button
                className="filter_btn d-flex align-items-center gap-2"
                onClick={handleAddSection}
              >
                <IoMdAdd /> Add Section
              </button>
            </div> */}

            {section.map((section, index) => (
              <div key={section.id} className="mt-4">
                <div className="row my-2">
                  <div className="col-md-4 d-flex gap-2 align-items-center">
                    <span>{index + 1}</span>
                    <TextInput
                      className="w-100"
                      value={section.header}
                      onChange={(e) => handleHeaderChange(section.id, e)}
                      placeholder="Untitled Section"
                    />
                  </div>
                </div>
                <BorderedTable style={{ width: "100%" }}>
                  <DataTable
                    value={section.tableData}
                    style={{ width: "100%" }}
                  >
                    <Column
                      field="template_meeting_title"
                      style={{ width: "50%" }}
                      header={
                        !headerInitialization ? (
                          <>
                            <div className="d-flex w-100">Title</div>
                          </>
                        ) : (
                          section.header
                        )
                      }
                      body={(rowData, rowIndex) => {
                        return (
                          <div className="d-flex gap-3 align-items-center">
                            <div>{rowData?.template_meeting_title}</div>
                          </div>
                        );
                      }}
                    ></Column>
                    <Column
                      field="template_meeting_priority"
                      style={{ width: "20%" }}
                      header="Priority"
                      body={(rowData) => {
                        return <div>{rowData?.template_meeting_priority}</div>;
                      }}
                    ></Column>
                    <Column
                      field="attach"
                      header="Attachments"
                      style={{ width: "20%" }}
                      body={(rowData) => {
                        return (
                          <div className="col-md-md-8 col-md-12 d-flex">No Items</div>
                        );
                      }}
                    ></Column>
                    <Column
                      field="del"
                      style={{ minWidth: "5rem", paddingLeft: "2%",display:"flex",justifyContent:"center" }}
                      header={
                        <div
                        className="d-flex"
                        >
                          <DeleteButton onConfirm={() => handleTableDelete(section.id)} onCancel={()=> null}/>
                        </div>
                      }
                      body={(rowData) => (
                        <h5
                          className="d-flex align-items-center"
                          style={{cursor:'pointer'}}
                          onClick={() =>
                            handleRowDelete(section.id, rowData.id)
                          }
                        >
                          {console.log(rowData)}
                          <RxCross2 />
                        </h5>
                      )}
                    ></Column>
                  </DataTable>
                </BorderedTable>
                <br />
                <div>
                  <button
                    className="filter_btn d-flex align-items-center gap-2"
                    // onClick={() => handleAddRow(section.id)}

                    onClick={() => {
                      handleAddItems(section.id);
                      setitemIndex(index);
                    }}
                  >
                    <IoMdAdd /> Add Items
                  </button>
                </div>
                <br />
              </div>
            ))}

            <div className="my-5">
              <span className="filter_btn" onClick={handleAddSection}>
                <IoMdAdd /> Add Agenda
              </span>
            </div>

            <div className="btn_main px-5 mt-4 ">
              <span
                className="cancelBtn   py-2"
                onClick={() => navigate("/projectManagement/meetings")}
              >
                {" "}
                Cancel
              </span>

              <span
                className="CreateRfi px-2 py-2"
                onClick={handleCreateTemplate}
              >
                Create Template
              </span>
            </div>
          </div>
        </div>
      </div>

      <AddMeetingItem
        setVisible={setVisible}
        visible={visible}
        itemIndex={itemIndex}
        handleAddRow={handleAddRow}
      />
    </>
  );
};
