import React, { useEffect } from 'react'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { getRolesAndPermissionData } from '../../Store/Slice/RolesAndPermissionsSlice'
import { useDispatch } from 'react-redux'
import { getUserId, getUserType } from '../../utils'
import { useSelector } from 'react-redux'
import { ADMIN_ROLES } from '../../constants'
import { getProject } from '../../Api/Admin/AllAPIs'
import { BsCartCheck } from 'react-icons/bs'
import { getEmailTabPermission } from '../../Store/Slice/EmailTabSlice'

export const InsideProjectRoleAccessKeeper = () => {


    let dispatch = useDispatch()

    let project_id = localStorage.getItem('projectId')
    let isAdmin = ADMIN_ROLES.includes(getUserType())

    let rolesAndPermissionData = useSelector(d => d.rolesAndPermissionData.data)


    useEffect(() => {
        if (project_id) {
            let userData = {
                project_id,
                user_id: getUserId(),
                type: getUserType()
            }
            if (!isAdmin) {
                dispatch(getRolesAndPermissionData(userData))
            }
            dispatch(getEmailTabPermission())
        }



    }, [project_id])
    // let dd = useLocation()
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (project_id) {
            getProjectData()
        }
    }, [])

    async function getProjectData() {
        try {

            let res = await getProject(project_id)
            localStorage.setItem("projectData", JSON.stringify(res.data.data[0]));
            // console.log(res.data.data, "mammmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")
        }
        catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        const pathname = location.pathname;

        if (rolesAndPermissionData?.length > 0) {

            let firstCondition = rolesAndPermissionData.some(outer => {
                if (outer.path && matchPath({ path: outer?.path || "", exact: false }, pathname)) {
                    return true
                } else {
                    return outer.sub_module.some(inner => {
                        if (inner.path && inner.path && matchPath({ path: inner?.path || "", exact: false }, pathname)) {
                            return true
                        }
                        return false
                    })

                }
            })

            if (firstCondition) {
                let isUserHaveAccess = rolesAndPermissionData.some((outer) => {

                    if (outer.path && matchPath({ path: outer?.path || "", exact: false }, pathname) && outer.isAcess) {
                        return true
                    }
                    else {
                        return outer.sub_module.some((inner) => {
                            if (inner.path && matchPath({ path: inner?.path || "", exact: false }, pathname) && inner.isAcess) {
                                return true
                            }
                            return false
                        })
                    }
                    return false

                })

                if (!isUserHaveAccess) {
                    navigate("/innernoAccess")
                }
            }

        }





    }, [rolesAndPermissionData, location])


    return (
        <Outlet />
    )
}
