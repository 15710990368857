import React, { useState, useEffect } from 'react'
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ImCross } from "react-icons/im";
import { useNavigate, useParams } from 'react-router-dom';
import {  getProjectList } from '../../Api/Admin/AllAPIs';


function Projects({setActiveTabIndex}) {
    // const {id}=useParams()
    const navigate = useNavigate()
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [projectDataOne, setProjectDataOne] = useState([])
    const [projectDataTwo, setProjectDataTwo] = useState([])
    const [rowClick, setRowClick] = useState(true);
    const [idStore, setIdStore] = useState();
  


    // console.log(id,"tt")

    useEffect(() => {
        if (idStore) {
            handleAllProject()
        }
    }, [idStore])

    const handleAllProject = async () => {
        try {
            const res = await getProjectList(idStore)
            console.log(res)
            const projectData = res?.data?.data.not_belong_to
            console.log(projectData)
            setProjectDataOne(projectData)


        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        const storedProjectDataOne = localStorage.getItem('projectDataOne');
        const storedProjectDataTwo = localStorage.getItem('projectDataTwo');

        if (storedProjectDataOne) {
            setProjectDataOne(storedProjectDataOne);
        }

        if (storedProjectDataTwo) {
            setProjectDataTwo(storedProjectDataTwo);
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('projectDataOne', projectDataOne);
        localStorage.setItem('projectDataTwo', projectDataTwo);
    }, [projectDataOne, projectDataTwo]);
   


    useEffect(() => {
        const companyIdFromLocalStorage = localStorage.getItem("companyId");
        if (companyIdFromLocalStorage) {
            setIdStore(companyIdFromLocalStorage);
            console.log(idStore)
        }
    }, []);


    const handleAdd = (rowData) => {
        setProjectDataOne(projectDataOne.filter(project => project._id !== rowData._id));
        setProjectDataTwo([...projectDataTwo, rowData]);
        console.log(idStore);
        const id=idStore
        const array=[];




    };

    const handleRemove = (rowData) => {
        setProjectDataTwo(projectDataTwo.filter(project => project._id !== rowData._id));
        setProjectDataOne([...projectDataOne, rowData]);
    };
    const handleNext=()=>{
        setActiveTabIndex(2)
    }

    return (
        <>

            <div className='mb-3' >
                <span className='fw-bold'>PROJECTS GUY'S GUTTERS BELONGS TO</span>
            </div>
            <BorderedTable>
                <DataTable value={projectDataTwo}
                    selectionMode={rowClick ? null : 'checkbox'}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="_id" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="project_code" header="Project Code" style={{ width: '9rem' }} ></Column>
                    <Column field="project_name" header="Project Name" ></Column>
                    <Column field="" header="" style={{ width: '8rem' }} body={(rowData) => (
                        <div className="d-flex align-items-center justify-content-center">
                            <span
                                className="cancelBtn  px-3 py-2" onClick={()=>handleRemove(rowData)}>
                                {" "}
                                Remove
                            </span>
                        </div>
                    )}></Column>

                </DataTable>
            </BorderedTable>
            <div className='mb-3 mt-5' >
                <span className='fw-bold'>PROJECTS GUY'S GUTTERS DOES NOT BELONG TO</span>
            </div>
            <BorderedTable>
                <DataTable value={projectDataOne}
                    selectionMode={rowClick ? null : 'checkbox'}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="_id" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="project_code" header="Project Code" style={{ width: '9rem' }} ></Column>
                    <Column field="project_name" header="Project Name" ></Column>
                    <Column field="" header="" style={{ width: '8rem' }} body={(rowData) => (
                        <div className="d-flex align-items-center justify-content-center">
                            <span className="cancelBtn  px-3 py-2" onClick={()=>handleAdd(rowData)}>
                                {" "}
                                Add
                            </span>
                        </div>
                    )}></Column>
                </DataTable>
            </BorderedTable>

            <div className="btn_main  mt-4 ">
                <span
                    className="cancelBtn  px-2 py-2"
                    onClick={() => { navigate("/projectDirectories") }}
                >
                    {" "}
                    Cancel
                </span>
                <div >
                    <span className="CreateRfi px-2 py-2" onClick={handleNext} >
                        Next
                    </span>
                </div>
            </div>

        </>
    )
}

export default Projects