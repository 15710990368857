import React, { useState } from "react";
import style from "./input.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const MultipleDragAndDrop = ({
  value,
  onChange,
  acceptFiles,
  label,
  disabled,
}) => {
  let randomId = Math.random();
  let [files, setFiles] = useState(value || []);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    onChangeSubmit(file);
    setFiles((prev) => [...prev, file]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onChange(updatedFiles);
  };

  function onChangeSubmit(file) {
    setFiles((prev) => [...prev, file]);
    onChange([...files, file]);
  }

  return (
    <div className="">
      <label className="mb-1 add_class">{label || ""}</label>
      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        htmlFor={randomId}
        className="w-100"
      >
        <div className={`${style.dragAndDrop_main_con1}`}>
          {!files.length && (
            <div>
              Drag & Drop File(s)
              <br />
              <br />
            </div>
          )}

          <div className="d-flex flex-wrap align-item-center justify-content-center gap-2 p-3">
            {files.map((res, index) => (
              <div className={style.file_main_con1} key={index}>
                {res.name.length > 5 ? res.name.substr(0, 5) + "..." : res.name}
                <AiOutlineCloseCircle
                  className={style.remove_icon}
                  onClick={() => handleRemove(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </label>
      <div>
        <div>
          {files.length === 0 && (
            <input
              id={randomId}
              disabled={disabled}
              onChange={(e) => {
                onChangeSubmit(e.target.files[0]);
                e.target.value = "";
              }}
              type="file"
              hidden
            />
          )}
        </div>
      </div>
    </div>
  );
};
