import moment from 'moment';
import { success, error, info } from '../Store/Slice/toast/toastSlice';
import { store } from '../Store/store';

export const toast = {
    success: (e) => store.dispatch(success({ msg: e, show: true })),
    error: (e) => store.dispatch(error({ msg: e, show: true })),
    info: (e) => store.dispatch(info({ msg: e, show: true })),
}

export function searchDataWithKeys(keyarr, allData, searchString) {
    return allData.filter(d => keyarr.some(dd => d[dd]?.trim()?.toLowerCase().includes(searchString?.trim()?.toLowerCase())))
}

export function getUserType() {
    let userData = localStorage.getItem("userData");
    if (userData) {
        return JSON.parse(userData).user_type;
    }
    return null
}

export function getUserId() {
    let userId = null;
    let userData = localStorage.getItem("userData");
    if (userData) {
        userId = JSON.parse(userData)._id;

    }
    return userId
}

export function getUserData() {
    let userAllData = null;
    let userData = localStorage.getItem("userData");
    if (userData) {
        userAllData = JSON.parse(userData);

    }
    return userAllData
}
export const getUserOuterRoe = () => {
    let role = "";
    let userData = localStorage.getItem("userData");
    if (userData) {
        role = JSON.parse(userData)?.roleData?.[0]?.role || ""
    }
    return role

}

export const getUserRoleId = () => {
    let userId = null;
    let userData = localStorage.getItem("userData");
    if (userData) {
        userId = JSON.parse(userData).role;

    }
    return userId
}
export const getUserName = () => {
    let username = "";
    let userData = localStorage.getItem("userData");
    if (userData) {
        username = JSON?.parse(userData)?.name || (JSON?.parse(userData)?.first_name + " " + JSON?.parse(userData)?.last_name);

    }
    return username
}
export const getUserEmail = () => {
    let useremail = "";
    let userData = localStorage.getItem("userData");
    if (userData) {
        useremail = JSON?.parse(userData)?.email || JSON?.parse(userData)?.email_address;

    }
    return useremail
}

export const getActionsPermission = (moduleName) => {
    if (getUserType() === "admin") {
        return true
    }
    let permissionData = store.getState().rolesAndPermissionData?.data
    let isAccess = permissionData.some(d => (d.module == moduleName && d.isAcess) || d.sub_module.some(dd => dd.module == moduleName && dd.isAcess)
    )
    console.log(permissionData)
    return isAccess


    // return useSelector("rolesAndPermissionData")
}

export const getProjectName = () => {
    let projectData = localStorage.getItem("projectData")
    let projectName = ""
    if (projectData) {
        projectName = JSON.parse(projectData).project_name
    }
    return projectName
}
export const getProjectData = () => {
    let projectData = localStorage.getItem("projectData")
    if (projectData) {
        return JSON.parse(projectData)
    }
    return null
}
export const getProjectCode = () => {
    let projectData = localStorage.getItem("projectData")
    if (projectData) {
        return JSON.parse(projectData)?.projectNumber || JSON.parse(projectData)?.project_code
    }
    return null
}


export const getProjectId = () => {
    let projectId = localStorage.getItem("projectId")
    return projectId
}


export const isReviewerOfProject = () => {
    let projectData = localStorage.getItem("projectData")
    let isReviewer = false;
    if (projectData) {
        isReviewer = JSON.parse(projectData).reviewer_id == getUserId()
    }
    return isReviewer
}
export const isApproverOfProject = () => {
    let projectData = localStorage.getItem("projectData")
    let isReviewer = false;
    if (projectData) {
        isReviewer = JSON.parse(projectData).approver_id == getUserId()
    }
    return isReviewer
}




export const filterArrayWithMultipleKey = (dataArray, keyArr, string) => {
    return dataArray.filter(res => {
        return keyArr.some(key =>
            res.hasOwnProperty(key) &&
            res[key].toString().trim().toLowerCase().includes(string.trim().toLowerCase())
        )
    })
}

export const formatDateInIST = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

export const getPaginatedData = (data, page, perPage) => {
    const start = (page - 1) * perPage;
    const end = page * perPage;
    return data.slice(start, end);
};


export function showDateAndTime(date) {
    return `${moment(date).format('DD-MM-YYYY')} / ${moment.utc(date).format("HH:mm")}`
}
export function convertHourlySpentStringIntoObject(str) {
    return ({
        hours: parseInt(str.split("-")[0]) || "00",
        minutes: parseInt(str.split("-")[1]) || "00",
    })

}


export function changeStatusUi2(status) {
    switch (status) {
        case 'Not Started':
            return <div className="statusOuterns">
                <div className="statusDotns" />
                <div className="Statusns">Not Started</div>
            </div>
            break;
        case 'In Progress':
            return <div className="statusOuterip">
                <div className="statusDotip" />
                <div className="Statusip">In Progress</div>
            </div>
            break;
        case 'Completed':
            return <div className="statusOuterc">
                <div className="statusDotc" />
                <div className="Statusc">Completed</div>
            </div>
            break;

        case 'Ready for Review':
            return <div className="statusOuterr">
                <div className="statusDotr" />
                <div className="Statusr">Ready for Review</div>
            </div>
            break;
        case 'Incomplete':
            return <div className="statusOuterin">
                <div className="statusDotin" />
                <div className="Statusin">Incomplete</div>
            </div>
            break;

        default:
            break;
    }
}

export function shortenFilename(filename) {
    const extension = filename.split('.').pop();
    const normalizedName = filename.replace(/[^a-zA-Z0-9]/g, ' ').trim();
    const parts = normalizedName.split(' ');
    if (parts.length === 0) return `...${extension}`;
    const firstPart = parts[0].substring(0, 3);
    const lastPart = parts[parts.length - 1].substring(0, 3);
    const newFilename = `${firstPart}..${lastPart}.${extension}`;
    return newFilename;
}

export function formatKeysValues(key) {
    switch (key) {
        case "reviewer_id":
            return "Reviewer"
            break;
        case "approver_id":
            return "Approver"
            break;
        case "task_name":
            return "Task Name"
            break;
        case "workFlowStatus":
            return "Work flow Status"
            break;
        case "taskManager":
            return "Task Manager"
            break;
        case "description":
            return "Description"
            break;
        case "responsibleContractor":
            return "Responsible Contractor"
            break;
        case "responsibleMembers":
            return "Responsible Members"
            break;
        case "responsiblePerson":
            return "Responsible Person"
            break;
        case "hourSpent":
            return "Hours Spent"
            break;
        case "dueDate":
            return "Due Date"
            break;
        case "rfi_manager":
            return "Rfi Manager"
            break;
        case "questionReceived":
            return "Question Received"
            break;
        case "submittal_responsibleContractor":
            return "Responsible Contractor"
            break;
        case "submittal_receivedFrom":
            return "Received From"
            break;
        case "submittal_submittalManager":
            return "Submittal Manager"
            break;
        case "received_from":
            return "Received From"
            break;
        case "workflow_status":
            return "Workflow Status"
            break;
        case "submittal_issueDate":
            return "Issue Date"
            break;
        case "submittal_finalDueDate":
            return "Final Due Date"
            break;
        case "inspection_type":
            return "Inspection Type"
            break;
        case "meeting_name":
            return "Meeting Name"
            break;
        case "updatedBy":
            return "Updated By"
            break;
        case "phase_id":
            return "Phase"
            break;
        case "category_id":
            return "Category"
            break;
        case "sub_category_id":
            return "Sub Caegory"
            break;
        case "doctype_id":
            return "Doc Type"
            break;
        case "category_id":
            return "Category"
            break;
        case "category_id":
            return "Category"
            break;
        case "category_id":
            return "Category"
            break;
        case "category_id":
            return "Category"
            break;
        case "category_id":
            return "Category"
            break;
        case "category_id":
            return "Category"
            break;

        default:
            return key
    }
}