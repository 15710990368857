import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineExport } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '../../Components/Common/FormSkeleton';
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable';
import { getGroupById } from '../../Api/Admin/AllAPIs';
import "./emailManagement.css"
import moment from 'moment/moment';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { html2pdf } from 'html2pdf.js';
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf';

export const ViewEmailGroup = () => {
    let { id } = useParams()
    let [emailData, setEmailData] = useState({})
    let [skeltonLoader, setSkelTonLoader] = useState(false)
    let navigate = useNavigate()
    let pdfRef = useRef(null)


    async function downloadPdfhandler() {
        console.log("okokoko")
        downloadPdf(pdfRef.current, [], "Distribution List", false)


    }

    useEffect(() => {
        getData()
    }, [])


    async function getData() {
        try {
            let res = await getGroupById(id)
            console.log(res.data.data[0])

            setEmailData(res.data.data[0])

        } catch (error) {
            console.log(error)
        }
    }


    const skeletonData = [
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },

    ];

    let arr = [
        { name: "nam1" },
        { name: "nam2" },
        { name: "nam3" },
        { name: "nam4" },
        { name: "nam5" },
    ]





    return (
        <div>
            <div className="end">
                <div
                    className="d-flex gap-2 align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={downloadPdfhandler}
                >
                    <AiOutlineExport />
                    Export
                    <MdOutlineKeyboardArrowDown />
                </div>
            </div>
            <div className='p-3'>
                <div>
                    <div>
                        <span
                            className="open_Rfi_text"
                            onClick={() => navigate("/emailManagement")}
                        >
                            Email Management
                        </span>
                        <span className="px-1 open_Rfi_text"> / View</span>
                        <span className="createNew_text"> </span>
                    </div>

                </div>
                <br />
                <div className='heading'>Distribution Mail</div>

                <br />
                <div>

                    {skeltonLoader ?
                        <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                        <div>
                            <div className="row">
                                <div className="col-3 email_label"> Distribution Mail: </div>
                                <div className="col-3 email_data_value"> {emailData?.email} </div>

                                <div className="col-3 email_label"> Project Name: </div>
                                <div className="col-3 email_data_value"> {emailData?.project?.[0].project_name} </div>

                                <div className="col-3 email_label mt-3"> Created By: </div>
                                <div className="col-3 email_data_value mt-3"> {emailData?.createdData?.[0]?.name || emailData?.createdData?.[0]?.first_name
                                } </div>

                                <div className="col-3 email_label mt-3"> Created On: </div>
                                <div className="col-3 email_data_value mt-3"> {moment(emailData?.createdAt).format("DD-MM-YYYY")} </div>
                            </div>
                            <div>


                                <br />
                                <div className='email_label mb-2'>Added User (s):</div>




                                <div className='user_table_scroll'>

                                    <DataTable

                                        value={emailData?.usersData || []}
                                    >
                                        <Column header="Sno. " body={(e, ind) => ind.rowIndex + 1} />
                                        <Column sortable field="first_name" header="User Name" />
                                        {/* <Column sortable field="email_address" header="Email" /> */}

                                    </DataTable>

                                </div>

                                <br />
                                <div className='email_label mb-2'>Group Description:</div>

                                <div className='description_text'>
                                    <HtmlIntoText html={emailData?.description} />
                                </div>
                            </div>
                        </div>
                    }

                    <div className="end mt-4">
                        <Link to={"/emailManagement"}>
                            <button className='btn border'>Cancel</button>
                        </Link>
                    </div>

                </div>
            </div>

            <div style={{ height: "0px", overflow: "hidden" }}>

                <div ref={pdfRef} className='p-3'>
                    <div>
                        <div>
                            <span
                                className="open_Rfi_text"
                                onClick={() => navigate("/emailManagement")}
                            >
                                Email Management
                            </span>
                            <span className="px-1 open_Rfi_text"> / View</span>
                            <span className="createNew_text"> </span>
                        </div>

                    </div>
                    <br />
                    <div className='heading'>Distribution Mail</div>

                    <br />
                    <div>

                        {skeltonLoader ?
                            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                            <div>
                                <div className="row">
                                    <div className="col-3 email_label"> Distribution Mail: </div>
                                    <div className="col-3 email_data_value"> {emailData?.email} </div>

                                    <div className="col-3 email_label"> Project Name: </div>
                                    <div className="col-3 email_data_value"> {emailData?.project?.[0].project_name} </div>

                                    <div className="col-3 email_label mt-3"> Created By: </div>
                                    <div className="col-3 email_data_value mt-3"> {emailData?.createdData?.[0]?.name || emailData?.createdData?.[0]?.first_name
                                    } </div>

                                    <div className="col-3 email_label mt-3"> Created On: </div>
                                    <div className="col-3 email_data_value mt-3"> {moment(emailData?.createdAt).format("DD-MM-YYYY")} </div>
                                </div>
                                <div>


                                    <br />
                                    <div className='email_label mb-2'>Added User (s):</div>



                                    <DataTable

                                        value={emailData?.usersData || []}
                                    >
                                        <Column header="Sno. " body={(e, ind) => ind.rowIndex + 1} />
                                        <Column sortable field="first_name" header="User Name" />
                                        {/* <Column sortable field="email_address" header="Email" /> */}

                                    </DataTable>



                                    <br />
                                    <div className='email_label mb-2'>Group Description:</div>

                                    <div className='description_text'>
                                        <HtmlIntoText html={emailData?.description} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="end mt-4">
                            <Link to={"/emailManagement"}>
                                <button className='btn border'>Cancel</button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>



        </div >
    )
};

