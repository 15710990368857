import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFromEmailByUser } from "../../Api/Admin/AllAPIs";
import { getUserId } from "../../utils";



let initialState = {
    isAllow: false,
    loader: false,
    isError: false
};

export const getEmailTabPermission = createAsyncThunk('getEmailTabPermission', async (data) => {
    try {

        let res = await getFromEmailByUser(getUserId())
        if (res?.data?.data?.length > 0) {
            return true
        }
        else {
            return false
        }

    }
    catch (err) {
        return ([])

    }
},
)
export const EmailTabSlice = createSlice({
    name: "emailTabSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailTabPermission.fulfilled, (state, action) => {
            state.isAllow = action.payload
            state.loader = false
            state.isError = false
        })
        builder.addCase(getEmailTabPermission.pending, (state, action) => {
            state.isAllow = false
            state.loader = true
            state.isError = false
        })
        builder.addCase(getEmailTabPermission.rejected, (state, action) => {
            state.isAllow = false
            state.loader = false
            state.isError = true
        })
    }
})
