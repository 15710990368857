import React, { useState } from "react";
import { TreeSelect } from "primereact/treeselect";
import "./common.css";
import { IoIosSearch } from "react-icons/io";

export default function TreeSelectComp({
  placeholder,
  className,
  onChange,
  selectedItems,

  ...props
}) {
  return (
    <div className="treeSelect w-100">
      <div className="card flex justify-content-center w-100">
        <div className="search-wrapper w-100">
          <IoIosSearch className="search-icon" />
          <TreeSelect
            filter
            className={`w-100 ${className ? className : ""}`}
            metaKeySelection={false}
            selectionMode="checkbox"
            display="chip"
            placeholder={placeholder ? placeholder : ""}
            onChange={onChange}
            value={selectedItems}
            {...props}
            style={{ paddingLeft: "2rem" }}
          />
        </div>
      </div>
    </div>
  );
}
