

import React, { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import "./createAddress.css"
import TextInput from '../../Components/Inputs/TextInput'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { addAddress } from '../../Api/Admin/AllAPIs'
import { toast } from '../../utils'
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor'

export const CreateAddress = ({ getAllAddress }) => {

    let { id } = useParams()
    let projectId = localStorage.getItem('projectId')
    let [dialog, setDialog] = useState(false)

    let defaultData = {

        name: "",
        shipping_address: "",
        billing_address: "",
        phone_no: "",
        zipcode: "",
        project_id: projectId,
        tool_id: id == undefined ? null : id
    }

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            posts: [
                {

                    name: "",
                    shipping_address: "",
                    billing_address: "",
                    phone_no: "",
                    zipcode: "",
                    project_id: projectId,
                    tool_id: id == undefined ? null : id
                }
            ]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'posts'
    });

    async function formSubmit(data) {
        try {
            console.log(data.posts)
            let res = await addAddress({ data: data.posts })
            setDialog(false)
            getAllAddress()
            toast.success(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <PrimaryButton text={"Add Address"} addIcon onClick={() => setDialog(true)} />

            <Dialog visible={dialog}
                onHide={() => setDialog(false)}
                draggable={false}
                header="Add Address"
                style={{ width: "90vw" }}
                headerClassName='py-3 dialog_header'
                footer={
                    <div className="end gap-3">
                        <button onClick={() => setDialog(false)} className='btn border' type='button'>Cancel</button>
                        <PrimaryButton text={"Add"} onClick={handleSubmit(formSubmit)} />
                    </div>
                }
            >
                <div className="px-4">

                    <form>

                        <div className="post_form_container">

                            <div className='post_form row'>
                                {fields.map((res, index) => {
                                    return (
                                        <div key={index} className="post_form_item p-4 mt-4">
                                            {fields.length > 1 && <div className='cross_icon'
                                                onClick={() => remove(index)}
                                            >
                                                <i className='pi pi-times' />
                                            </div>}
                                            <div>
                                                <label className='create_address_label'>Name</label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors?.posts?.[index]?.name ? "Validation" : ""}`}
                                                    style={{ height: "50px" }}
                                                    {...register(`posts.${index}.name`, { required: true })}
                                                    placeholder="Name"
                                                    defaultValue={res.name}
                                                />
                                            </div>
                                            <div className='mt-3'>
                                                <label className='create_address_label'>Billing Address</label>

                                                <Controller
                                                    name={`posts.${index}.billing_address`}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Editor
                                                            style={{ height: '200px' }}
                                                            value={field.value}
                                                            onTextChange={(e) => field.onChange(e.htmlValue)}
                                                            className={`${errors?.posts?.[index]?.billing_address ? "Validation" : ""}`}
                                                        />
                                                    )}
                                                    rules={{ required: 'Description is required' }}
                                                />
                                            </div>
                                            <div className='mt-3'>
                                                <label className='create_address_label'>Shipping Address</label>
                                                <Controller
                                                    name={`posts.${index}.shipping_address`}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Editor
                                                            style={{ height: '200px' }}
                                                            value={field.value}
                                                            onTextChange={(e) => field.onChange(e.htmlValue)}
                                                            className={`${errors?.posts?.[index]?.shipping_address ? "Validation" : ""}`}
                                                        />
                                                    )}
                                                    rules={{ required: 'Description is required' }}
                                                />
                                            </div>
                                            <div className="row mt-3 p-0">

                                                <div className='col-6'>
                                                    <label className='create_address_label'>Phone Number</label>
                                                    <input
                                                        {...register(`posts.${index}.phone_no.`, { required: true })}
                                                        placeholder="Phone Number"
                                                        type="number"
                                                        className={`form-control ${errors?.posts?.[index]?.phone_no ? "Validation" : ""}`}
                                                        style={{ height: "50px" }}
                                                        defaultValue={res.name}
                                                    />
                                                </div>
                                                <div className='col-6'>
                                                    <label className='create_address_label'>Zip Code</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${errors?.posts?.[index]?.zipcode ? "Validation" : ""}`}
                                                        {...register(`posts.${index}.zipcode`, { required: true })}
                                                        placeholder="Zip Code"
                                                        style={{ height: "50px" }}
                                                        defaultValue={res.name}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>





                        </div>
                    </form>
                </div>

            </Dialog>


        </>

    )
}
