import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { getallrole } from "../../Api/Admin/AllAPIs";

const ViewProjectDirectory = ({ projectDirectory, hidePagination }) => {
  const [projectToolData, setProjectToolData] = useState([]);
  const [allRole, setRoleAll] = useState([]);
  const getAllRoleData = async () => {
    try {
      const res = await getallrole();
      const response = res.data.data;
      let data = response.map((e) => {
        return {
          label: e?.role,
          value: e?._id
        }
      })
      // console.log(data);
      setRoleAll(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllRoleData()
  }, []);
  useEffect(() => {
    if (projectDirectory) {
      setProjectToolData(projectDirectory);
      console.log(projectDirectory);
    }
  }, [projectDirectory]);
  return (
    <>
      {projectDirectory ? (
        <div style={{width:'100%',overflow:'auto'}}>
          {hidePagination ? <DataTable
            value={projectToolData}
          // paginator
          // rows={5}
          // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="companyName"
              header="Company Name"
              style={{ Width: "30%" }}
             
            />
            <Column
              field="project_name"
              header=""
              style={{ width: "10%" }}
              body={(rowData, meta) => (
                <div className="rounded-circle">
                  {rowData?.profile?<img
                    width={"40px"}
                    height={"40px"}
                    src={rowData?.profile}
                    alt=""
                  />:''}
                  
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Person Name"
              body={(rowData, meta) => <>{rowData.personName}</>}
              style={{ width: "30%" }}
            ></Column>
            <Column
              field="createdOn"
              header="Project Role"
              body={(rowData, meta) => <>{allRole?.find(res=>res?.value == rowData?.role)?.label}</>}
              style={{ width: "30%" }}
            ></Column>
          </DataTable> : <DataTable
            value={projectToolData}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="companyName"
              header="Company Name"
              style={{ Width: "30%" }}
             
            />
            <Column
              field="project_name"
              header=""
              style={{ width: "10%" }}
              body={(rowData, meta) => (
                <div className="rounded-circle">
                  {rowData?.profile?<img
                    width={"40px"}
                    height={"40px"}
                    src={rowData?.profile}
                    alt=""
                  />:''}
                  
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Person Name"
              body={(rowData, meta) => <>{rowData.personName}</>}
              style={{ width: "30%" }}
            ></Column>

            <Column
              field="createdOn"
              header="User Role"
              body={(rowData, meta) => <>{allRole?.find(res=>res?.value == rowData?.role)?.label}</>}
              style={{ width: "30%" }}
            ></Column>
          </DataTable>}

        </div>
      ) : (
        <TableSkelton header={["Company Name", "", "Person Name", "Project Role"]} />
      )}
    </>
  );
};

export default ViewProjectDirectory;
