import React, { useRef, useState } from "react";
import "./SignIn.css";

import CryptoJS from "crypto-js";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, googleLogin } from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { toast } from "../../utils";
import axios from "axios";
import { GOOGLE_SIGNIN_KEY } from "../../constants";
// import jwt_decode from "jwt-decode"
function TestLogin() {
    const [eye, setEye] = useState(true);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [emailerr1, setEmailerr1] = useState();
    const [emailerr, setEmailerr] = useState();
    const [loginData, setLogInData] = useState({
        email: "",
        password: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLogInData({ ...loginData, [name]: value });

        // Clear errors when the user modifies the input
        if (name === "email") {
            setEmailerr1("");
        } else if (name === "password") {
            setEmailerr("");
        }
    };

    const switchRole = (userType, data) => {
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("userData", JSON.stringify(data))
        // return;
        console.log(data);
        console.log(userType);

        switch (userType) {
            case "admin":
                navigate("/mainOverview");
                break;
            case "user":
                // navigate("/projects");
                navigate("/mainOverview");
                localStorage.setItem("projectId", data?.project_id);
                break;
            case "individual":
                // navigate("/projects");
                navigate("/mainOverview");
                localStorage.setItem("projectId", data?.project_id);
                break;


            default:
                break;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoader(true);
        setEmailerr1("")
        try {
            let res = await loginUser(loginData);

            let user_type = res.data.data.user_type;
            let userData = res.data.data;
            switchRole(user_type, userData)

            // if (res?.data?.data?.data4) {
            //   navigate("/overviewproject");
            //   localStorage.setItem("projectId", res?.data?.data?.project_id);
            //   localStorage.setItem("userData", JSON.stringify(res?.data?.data?.data4))
            //   return
            // }



            // if (res?.response?.data) {
            //   if (res.response.data.message.includes("enter correct password")) {
            //     setEmailerr("Please enter correct password");
            //   }
            //   if (res.response.data.message.includes("enter correct email id")) {
            //     setEmailerr1("Please enter correct email id");
            //   }
            // }






            // if (res.status === 200) {
            //   switchRole(res.data.data);
            // }
            // setLoader(false);

            // setLogInData({
            //   email: "",
            //   password: "",
            // });
        } catch (err) {
            console.log(err?.response.data?.message);
            toast.error(err?.response?.data?.message || "Some Error Occured !")
            setEmailerr1(err?.response?.data?.message || "Some Error Occured");

            setLoader(false);
        }
    };

    const responseGoogle = (response) => {
        var decoded = jwtDecode(response.credential);
        console.log(decoded, "response tokenId");
        const token = {
            tokenId: response.credential,
            id: decoded.sub,
            email: decoded.email,
            user_id: null
        }
        console.log("uhdasgfdfjesgwfwef", token)
        setLoader(true)
        googleLogin(token).then((res) => {
            console.log(res.data.data)
            let user_type = res.data.data.user_type;
            let userData = res.data.data;
            switchRole(user_type, userData)

        }).catch((err) => {
            console.log(err?.response?.data?.data)
            toast.error(err?.response?.data?.data || "User not found")
            setLoader(false)
        })
    };

    const onFailure = (res) => {
        console.log("[Login failed] res:", res);
    };

    return (
        <>

            {loader ? (<Loader />) : (
                <div className="maincontainer">


                    <div className="left-contain">
                        <div className="logoFixed">
                            <span className="eLogo d-flex align-items-center">
                                <img src="/images/cdc_logo.png" className="me-2" alt="" />
                                CDC
                            </span>
                        </div>
                    </div>
                    <div className="right-contain bg-white">
                        <div className="right-content-div ">
                            <div className="d-flex justify-content-center ">
                                <span className="logIn">Log In</span>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mt-4">
                                    <label className="HelveticaMedium mb-2">Email Address</label>
                                    <input
                                        placeholder="Enter Email Address"
                                        id="loginEmail"
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        value={loginData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {emailerr1 && <div className="text-danger">{emailerr1}</div>}
                                </div>
                                <div className="form-password mt-4">
                                    <label className="HelveticaMedium mb-2">Password</label>
                                    <input
                                        placeholder="Enter Password"
                                        className="form-control"
                                        id="loginPassword"
                                        name="password"
                                        value={loginData.password}
                                        onChange={handleInputChange}
                                        type={eye ? "password" : "text"}
                                        required
                                    />
                                    {eye ? (
                                        <i
                                            className="pi pi-eye-slash password-eye pointer"
                                            style={{ color: "#94989E" }}
                                            onClick={() => setEye(!eye)}
                                        ></i>
                                    ) : (
                                        <i
                                            className="pi pi-eye password-eye pointer"
                                            style={{ color: "#94989E" }}
                                            onClick={() => setEye(!eye)}
                                        ></i>
                                    )}
                                    {emailerr && <div className="text-danger">{emailerr}</div>}
                                </div>

                                <div className="d-flex justify-content-end mt-4">
                                    <Link to="/forgotPassword" className="textDecoration">
                                        <span className="forgot-password">Forgot Password?</span>
                                    </Link>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="continueButton w-100"
                                        type="submit"
                                        id="loginsubmit"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </form>
                            <div className="mt-4  w-100">
                                <GoogleOAuthProvider clientId={GOOGLE_SIGNIN_KEY}>
                                    <div className="w-100">
                                        <GoogleLogin
                                            buttonText="Login with Google"
                                            onSuccess={responseGoogle}
                                            onFailure={onFailure}
                                            cookiePolicy={"single_host_origin"}
                                            style={{
                                                marginTop: "100px",
                                                boxShadow: "none !important",
                                            }}
                                            isSignedIn={true}
                                        />
                                    </div>
                                </GoogleOAuthProvider>
                            </div>
                        </div>
                    </div>


                </div>
            )}





        </>
    );
}

export default TestLogin;
