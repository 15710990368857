import React, { useEffect, useRef, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdAdd, MdOutlineAttachment } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import {
  addPrioritytype,
  getPrioritytype,
  addTypeT,
  getTypeT,
  addTradeT,
  getTradeT,
  getIssueById,
  getUserByProjectId,
  getUsersByCompanyId,
  updateIssue,
  uploadDocs,
  getCompanyByProjectId,
  getCountIssueId,
} from "../../Api/Admin/AllAPIs";
import {
  getActionsPermission,
  getProjectCode,
  getProjectId,
  getProjectName,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { useSelector } from "react-redux";
import AddSets from "../../Components/Common/AddSets";
import { MultiSelect } from "primereact/multiselect";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import Loader from "../../Components/Common/Loader";
import moment from "moment";

export const EditIssue = () => {
  const isApproverAccess = getActionsPermission("Issue Approver");
  const isReviewerAccess = getActionsPermission("Issue Reviewer");
  const [approver, setApprover] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [IssueID, setIssueID] = useState();
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({});
  let set = useSelector((d) => d.rolesAndPermissionData);
  const [oldissue_review_status, setOldissue_review_status] = useState("");

  const dataType = [
    { name: "Building Coordination" },
    { name: "Clash" },
    { name: "Client Feedback" },
    { name: "Coordination" },
  ];
  // const priorityData = [
  //   { name: "Low" },
  //   { name: "Medium" },
  //   { name: "High" },
  //   { name: "Critical" },
  // ];
  const assigneeData = [
    { name: "Andrew" },
    { name: "John" },
    { name: "Smith" },
    { name: "Joseph" },
  ];
  const assCompanyData = [
    { name: "Dell Company" },
    { name: "Connie Group" },
    { name: "SB Mechanical" },
    { name: "ABC Contractor" },
  ];
  // const tradeData = [
  //   { name: "Insulation" },
  //   { name: "Insurance Vendor" },
  //   { name: "Lifts" },
  //   { name: "Landscaping" },
  // ];
  const priorityTypeData = [{ name: "Personal" }, { name: "Others" }];
  const statusData = [{ name: "open" }, { name: "close" }];
  const [selectedCity, setSelectedCity] = useState([]);
  const [text, setText] = useState("");
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");
  console.log(approverComment);
  console.log(reviewerComment);
  const navigate = useNavigate();
  const { id } = useParams();
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  console.log(userId, userName);
  const [listingType, setListingType] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachFile, setAttachFile] = useState([]);
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState();
  const [assignee, setAssignee] = useState();
  const [assigneeCompany, setAssigneeCompany] = useState();
  const [dueDate, setDueDate] = useState(new Date());
  const [priority, setPriority] = useState("");
  const [trade, setTrade] = useState("");
  console.log(trade);
  const [priorityType, setPriorityType] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [options, setOptions] = useState([]);
  const [identified, setIdentified] = useState();
  console.log(identified);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [visibleType, setVisibleType] = useState(false);
  const [loader, setLoader] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [priorityData, setPriorityData] = useState([]);
  const [typeVisible, setTypeVisible] = useState(false);
  const [typeData, setTypeData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tradeValue, setTradeValue] = useState(null);
  const [tradeData, setTradeData] = useState([]);
  const [typeValue, setTypeValue] = useState(null);
  const [approval, setApproval] = useState([]);
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [users, setUsers] = useState([]);
  const [personResponsible, setPersonResponsible] = useState([]);
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [companies, setCompanies] = useState([]);
  const mandatoryFields = [
    "title",
    "status",
    "type",
    "assignee",
    "assigneeCompany",
    "dueDate",
    "trade",
  ];

  const domain = window.location.origin;
  // console.log(identified,"test")

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1 },
    { qty: 1, height: "15rem" },
    { qty: 1, height: "7rem" },
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
  ];
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [Validation, setValidation] = useState({
    title: false,
    status: false,
    type: false,
    // assignee: false,
    // assigneeCompany: false,
    dueDate: false,
    trade: false,
    // membersResponsible: false,
    approver_id: false,
    reviewer_id: false,
    personResponsible: false,
    contractor: false,
  });

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };
  useEffect(() => {
    getPriority(projectId);
  }, []);

  async function getPriority(id) {
    // alert("sdfljkhsfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdl")
    try {
      let res = await getPrioritytype(id);

      console.log(res);
      setPriorityData(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }

  const addTypeP = async () => {
    console.log("oooooooooooooo");
    setLoading(true);
    try {
      let payload = {
        name: typeName,
        project_id: projectId,
      };
      console.log(payload);

      let t = await addPrioritytype(payload);
      console.log(t);
      setVisibleType(false);
      setLoading(false);
      getPriority(projectId);
      setTypeName("");
    } catch (err) {
      console.log(err);
    }
  };

  async function getTypeListing() {
    try {
      let res = await getTypeT(getProjectId());
      // console.log(res.data.data, "[[][][][][]")
      setListingType(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getTypeListing();
  }, []);
  const addTypeType = async () => {
    setLoading(true);
    try {
      const payload = {
        name: typeValue,
        project_id: projectId,
      };
      console.log(payload);
      // return
      let t = await addTypeT(payload);
      console.log(t);
      getTypeListing(projectId);
      setTypeVisible(false);
      setTypeName("");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //Trade-----------------------------------------------------------

  const addTradeP = async () => {
    console.log("oooooooooooooo");
    setLoading(true);
    try {
      let payload = {
        name: tradeValue,
        project_id: projectId,
      };
      console.log(payload);

      await addTradeT(payload);
      getTradeData();
      setVisible(false);
      setTradeValue("");
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTradeData();
  }, []);

  async function getTradeData() {
    try {
      let res = await getTradeT(getProjectId());
      // console.log(res, 'resr')
      setTradeData(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }

  const approvelOptions = [
    { label: "Draft", value: "draft" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Approved", value: "approved" },
  ];

  const generateNextRFI = async () => {
    try {
      let res = await getCountIssueId(projectId);
      // console.log(res)
      setIssueID(res?.data?.data);
    } catch (Err) {
      console(Err);
    }
  };

  useEffect(() => {
    generateNextRFI();
  }, []);

  // check validation
  const checkValidation = () => {
    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
    }
    if (!type) {
      setValidation((prevState) => ({
        ...prevState,
        type: true,
      }));
    }

    if (!membersResponsible) {
      setValidation((prevState) => ({
        ...prevState,
        membersResponsible: true,
      }));
    }
    // console.log("Members Responsible Validation", membersResponsible);
    // if (!membersResponsible.trim()) {
    //   updatedValidation.membersResponsible = true;
    //   isValid = false;
    // }
    if (!personResponsible) {
      setValidation((prevState) => ({
        ...prevState,
        personResponsible: true,
      }));
    }
    if (!responsibleContractor) {
      setValidation((prevState) => ({
        ...prevState,
        contractor: true,
      }));
    }
    if (!trade) {
      setValidation((prevState) => ({
        ...prevState,
        trade: true,
      }));
    }
    if (!identified) {
      setValidation((prevState) => ({
        ...prevState,
        identified: true,
      }));
    }
    if (!dueDate) {
      setValidation((prevState) => ({
        ...prevState,
        dueDate: true,
      }));
    }

    console.log(Validation);
    console.log(
      !title ||
        !type ||
        !assignee ||
        !trade ||
        !responsibleContractor ||
        // !assigneeCompany ||
        !dueDate
    );
    console.log(title);
    console.log(type);
    console.log(assignee);
    console.log(trade);
    // console.log(assigneeCompany)
    console.log(dueDate);

    if (
      !title ||
      !type ||
      !identified ||
      !trade ||
      // !assigneeCompany ||
      !dueDate
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const projectId = localStorage.getItem("projectId");
  const handleGetIssueById = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getIssueById(id);
      const issueData = res?.data?.data?.[0];
      console.log(issueData);
      setAllData(issueData);
      // console.log(issueData.approver?._id, "ashfdjhfshflakhflhfsldhsdfh")
      let approverData = issueData?.approver?._id;
      let reviewerData = issueData.reviewer?._id;

      // console.log(approverData, "hhslfhlskdhflksdfhlh")
      // console.log(reviewerData, "hhslfhlskdhflksdfhlh")
      setOldApproverReviewwerData({
        approver_id: approverData?._id || null,
        approver_id: reviewerData?._id || null,
      });

      setApprover(approverData);
      setReviewer(reviewerData);
      // setApprover(approverData?._id !== undefined ? approverData?._id : null);
      // setReviewer(reviewerData?._id !== undefined ? reviewerData?._id : null);
      setOldissue_review_status(issueData?.issue_review_status);

      setTitle(issueData.issue_title);
      setDescription(issueData.issue_description);
      // setStatus(issueData?.status);
      console.log(status, "fdlsjflfllllllllll");
      setLocation(issueData.issue_location);
      console.log(approver, "shfdiko");
      setAssignee(issueData.issue_assignee);
      setAssigneeCompany(issueData.issue_assigneeCompany);
      setDueDate(issueData?.issue_dueDate);
      setApproverComment(issueData?.approver_comment);
      setReviewerComment(issueData?.reviewer_comment);

      setPriority(issueData.issue_priority?._id);
      setTrade(issueData.issue_trade?._id);
      setType(issueData.issue_type?._id);
      setPersonResponsible(issueData?.responsiblePerson?.map((e) => e._id));
      setIssueID(issueData?.issue_ID);
      setIdentified(issueData?.Issue_IdentifiedBy[0]?._id);
      setResponsibleContractor(
        issueData.responsibleContractor?.map((e) => e._id)
      );
      setMembersResponsible(issueData?.responsibleMember?.map((e) => e._id));
      // setMembersResponsible(issueData.membersResponsible?.map((e) => e._id));
      let data = {
        value: issueData?.responsibleContractor?.map((res) => res?._id),
      };
      await handleCompanyChange(data);

      setFileName(
        issueData?.attachFile.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );

      setPreviousFile([...issueData?.issue_file]);

      let statuss = issueData?.issue_status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);
    } catch (err) {
      console?.error(err);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const handleUpdate = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (checkValidation()) {
      return;
    }
    setLoader(true);

    const formData = new FormData();
    for (const file of fileUpload) {
      formData.append("upload", file);
    }

    const uploadResponse = await uploadDocs(formData);

    const fileData = uploadResponse.data.map((item) => ({
      fileName: item?.fileName,
      url: item?.fileUrl,
    }));

    let emailData = [];
    if (reviewer) {
      let approverData = allUsersOfProject.find((e) => e._id == reviewer);

      emailData.push({
        to: approverData?.email_address,
        name: approverData?.first_name,
        tool_number: IssueID,
        tool_name: title,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue",
        subject: `FYI: Issue updated in ${getProjectName()}`,
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "emailToAllUserWhileUpdatingToolData",
        updated_by: getUserName(),
      });
    }
    if (approver) {
      let reviewerData = allUsersOfProject.find((e) => e._id == approver);
      emailData.push({
        to: reviewerData.email_address,
        name: reviewerData.first_name,
        tool_number: IssueID,
        tool_name: title,
        subject: `FYI: Issue updated in ${getProjectName()}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue",
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "emailToAllUserWhileUpdatingToolData",
        updated_by: getUserName(),
      });
    }
   

    
  
    if (identified) {
      let reviewerData = allUsersOfProject.find((e) => e._id == identified);
      console.log(reviewerData.first_name, "hsdhfoihsdoiffffffffff");
      emailData.push({
        to: reviewerData.email_address,
        name: reviewerData.first_name,
        tool_number: IssueID,
        tool_name: title,
        subject: `FYI: Issue updated in ${getProjectName()}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue Identified",
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "emailToAllUserWhileUpdatingToolData",
        updated_by: getUserName(),
      });
    }
    console.log(emailData);
    const Payload = {
      issue_title: title,
      issue_description: description,
      issue_status: status,
      issue_review_status:
        oldissue_review_status === "pending"
          ? status?.toLowerCase() === "close"
            ? "under_review"
            : "pending"
          : oldissue_review_status,
      issue_location: location,
      issue_assignee: assignee,
      issue_dueDate: dueDate.toLocaleString(),
      issue_priority: priority,
      issue_trade: trade,
      issue_dueDate: dueDate,
      issue_type: type,
      issue_file: [...previousFile, ...fileData],
      deleted_file: removePhoto,
      userName: userName,
      userId: userId,
      issue_ID: IssueID,
      reviewer_id: reviewer?._id,
      approver_id: approver?._id,
      email_data: emailData,
      responsibleContractor: responsibleContractor,
      responsiblePerson: personResponsible,
      responsibleMember: membersResponsible,
      Issue_IdentifiedBy: identified,
    };
    console.log(Payload);

    try {
      await updateIssue(id, Payload);
      toast.success("Issue Updated Successfully!");
      navigate("/projectManagement/coordinationIssues");
      setTitle("");
      setDescription("");
      setFileUpload("");
      setStatus("");
      setLocation("");
      setAssignee("");
      setDueDate("");
      setPriority("");
      setTrade("");
      setPriorityType("");
      setAssigneeCompany("");
      setType("");
      setResponsibleContractor("");
      setLoader(false);
    } catch (err) {
      console.error(err);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
  };
  // const navigate = useNavigate();

  useEffect(() => {
    handleGetIssueById();
  }, []);

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
    console.log(files);
  };

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      console.log("*********** Response:", res);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        console.log(companyNames, "companynames-------");

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id,
        }));

        setCompanies(formattedCompanies);
        console.log(companies, "hhhhhhhhhhhhhhh");
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany();
  }, []);

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);
    console.log(selectedCompanyIds);

    const handleaddPriority = async () => {};
    const workFlowOption = [
      { label: "Work In Progress", value: "Work In Progress" },
      { label: "Incomplete", value: "Incomplete" },
      { label: "Completed", value: "Completed" },
    ];

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, {
            company_id: [companyId],
          });
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map((user) => ({
              label: user.name,
              value: user._id,
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(
        new Set(usersFromAllCompanies.map((user) => user.value))
      ).map((id) => {
        return usersFromAllCompanies.find((user) => user.value === id);
      });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  return (
    <>
      <div className=" main-container">
        <div>
          <div className="row">
            <div
              className="col-md-12 py-3  px-4 "
              style={{ marginLeft: "4px" }}
            >
              <span
                className="open_Rfi_text"
                onClick={() =>
                  navigate("/projectManagement/coordinationIssues")
                }
              >
                {" "}
                Issue
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> Edit Issue</span>
            </div>
          </div>

          {/* Top Create New Rfi End */}
          {loader ? (
            <Loader />
          ) : (
            <div className="px-4 mt-4">
              <div className="col-md-12 ">
                <span className="newRfi_text">Edit ISSUE </span>
              </div>

              {/*  New section End */}
              {/* {
                skeletonLoader ?
                  <FormSkeleton data={skeletonData} />
                  :
                  <> */}
              <div className="w-100   ">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Title: <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="Enter New Title"
                    value={title}
                    onChange={(e) => setTitle(e?.target?.value)}
                    className={Validation.title ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, title: false })
                    }
                  >
                    {" "}
                  </TextInput>
                </div>
              </div>

              <div className="row  p-0   ">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Location:
                  </label>
                  <TextInput
                    placeholder="Location"
                    value={location}
                    onChange={(e) => {
                      setLocation(e?.target?.value);
                    }}
                  />
                </div>
                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Type: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={
                        listingType?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      }
                      value={type}
                      optionLabel="label" // Ensure this matches the property name in your options
                      onChange={(value) => {
                        setType(value);
                        setValidation({ ...Validation, type: false });
                      }}
                      className={`p-0 ${
                        Validation.type ? "Validation" : ""
                      } text-capitalize`}
                      onClick={() =>
                        setValidation({ ...Validation, type: false })
                      }
                      placeholder="Select Issue Type"
                    />
                  </div>
                </div>
              </div>

              <div className="row   gap-0 ">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Due Date: <span className="text-danger">*</span>
                  </label>

                  <input
                    type="date"
                    style={{ height: "50px" }}
                    // className="p-0"
                    placeholder="Due Date"
                    // selected={(moment(dueDate))}
                    value={dueDate}
                    onChange={(e) => setDueDate(e?.target?.value)}
                    className={
                      Validation.dueDate ? "Validation" : "form-control"
                    }
                    onClick={() =>
                      setValidation({ ...Validation, dueDate: false })
                    }
                  />
                </div>
                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Priority:
                    </label>
                    <AutoCompleteSelect
                      options={
                        priorityData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      }
                      placeholder="Select a Priority"
                      className="p-0"
                      value={priority}
                      onChange={(e) => {
                        setPriority(e);
                      }}
                    />{" "}
                  </div>
                  <span className="ml-3 mt-5">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisibleType(true)}
                    />
                  </span>
                </div>
              </div>
              <div className="row m-0 p-0  gap-0 ">
                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Trade: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={
                        tradeData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      }
                      placeholder="Select a Trade"
                      // className="p-0"
                      value={trade}
                      onChange={(e) => {
                        setTrade(e);
                      }}
                      className={Validation.trade ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, trade: false })
                      }
                    />
                  </div>
                  <span className="ml-3 mt-5">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisible(true)}
                    />
                  </span>
                </div>
                <div className="col-6 col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Issue ID
                  </label>
                  <TextInput
                    placeholder="Issue ID"
                    onChange={(e) => setIssueID(e?.target?.value)}
                    value={IssueID}
                  />
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor Company (s ):{" "}
                  </label>

                  <MultiSelect
                    value={responsibleContractor}
                    //  onChange={(e) => setResponsibleContractor(e.value)}
                    onChange={handleCompanyChange}
                    options={companies}
                    optionLabel="label"
                    filter
                    placeholder="Company"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`p-0 ${
                      Validation.contractor ? "Validation" : ""
                    }`}
                    onClick={() =>
                      setValidation({ ...Validation, contractor: false })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member(s):
                  </label>

                  <MultiSelect
                    value={membersResponsible}
                    onChange={(e) => setMembersResponsible(e.value)}
                    options={users}
                    optionLabel="label"
                    filter
                    placeholder="Select Member"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`w-full md:w-20rem ${
                      Validation.membersResponsible ? "Validation" : ""
                    }`}
                    onClick={() =>
                      setValidation({
                        ...Validation,
                        membersResponsible: false,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-md-6 col-12 mt-2">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person: <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                    value={personResponsible}
                    onChange={(e) => setPersonResponsible(e.value)}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    optionLabel="label"
                    filter
                    placeholder="Select Person"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`w-full md:w-20rem ${
                      Validation.personResponsible ? "Validation" : ""
                    }`}
                    onClick={() =>
                      setValidation({ ...Validation, personResponsible: false })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Issue Identified By(s):
                    <span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={identified}
                    onChange={(e) => setIdentified(e.value)}
                    options={allUsersOfProject?.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    optionLabel="label"
                    filter
                    placeholder="Select Member"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`w-full md:w-20rem ${
                      Validation.identified ? "Validation" : ""
                    }`}
                    onClick={() =>
                      setValidation({ ...Validation, identified: false })
                    }
                  />
                </div>
              </div>

              {/* </>} */}
              <div className="row m-0 p-0">
                {isReviewerAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer:
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({
                        label: item?.first_name,
                        value: item?._id,
                      }))}
                      value={reviewer}
                      optionLabel="label"
                      onChange={(value) => {
                        setReviewer(value);
                      }}
                      className={`p-0 `}
                      disabled={
                        reviewer === getUserId() ||
                        allData?.status == "approved"
                      }
                      placeholder="Select Reviewer"
                    />
                  </div>
                )}

                {isApproverAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver:
                    </label>

                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({
                        label: item?.first_name,
                        value: item?._id,
                      }))}
                      value={approver}
                      optionLabel="label"
                      onChange={(value) => {
                        setApprover(value);
                      }}
                      className={`p-0 `}
                      placeholder="Select Approver"
                      disabled={
                        approver === getUserId() ||
                        allData?.status == "approved"
                      }
                    />
                  </div>
                )}
              </div>
              <div className="row m-0 p-0">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer Comment:
                  </label>
                  <TextInput
                    placeholder="Reviewer Comment"
                    value={reviewerComment}
                    // onChange={(e) => setReviewerComment(e.target.value)}
                    disabled
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver Comment:
                  </label>
                  <TextInput
                    placeholder="Approver Comment"
                    value={approverComment}
                    // onChange={(e) => setApproverComment(e.target.value)}
                    disabled
                  />
                </div>
                c
              </div>

              <div className="row p-0 m-0  ">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Description:
                  </label>
                  <Editor
                    style={{ height: "200px" }}
                    value={description}
                    onTextChange={(e) => setDescription(e.htmlValue)}
                  />
                </div>
              </div>

              <div className="row  p-0 m-0  css_com">
                <div className="col-md-12">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={setFileUpload}
                    onChange={handleFileSubmit}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFile}
                  />
                </div>
              </div>
              <div className="btn_main mt-5 ">
                <span
                  className="cancelBtn px-3  py-2"
                  onClick={() =>
                    navigate("/projectManagement/coordinationIssues")
                  }
                >
                  {" "}
                  Cancel
                </span>

                <span className="CreateRfi px-2 py-2" onClick={handleUpdate}>
                  Update
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visibleType}
        setVisible={setVisibleType}
        CategoryName={"Priority"}
        label={"Priority"}
        reqFor={"create"}
        setValue={setTypeName}
        value={typeName}
        onSubmit={addTypeP}
      />

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Trade"}
        label={"Trade"}
        reqFor={"create"}
        setValue={setTradeValue}
        value={tradeValue}
        onSubmit={addTradeP}
      />

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={typeVisible}
        setVisible={setTypeVisible}
        CategoryName={"Type"}
        label={"Type"}
        reqFor={"create"}
        setValue={setTypeValue}
        value={typeValue}
        onSubmit={addTypeType}
      />
    </>
  );
};
