import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'

export default function ChangeStatusModal({ open, setOpen, value, setValue, onSubmit, setComment, statusRequired, setStatusRequired, type }) {
    let options = [
        { name: 'Reviewed', code: 'pendingapproval' },
        { name: 'Rejected', code: 'draft' },
    ]
    let options2 = [
        { name: 'Approved', code: 'approved' },
        { name: 'Rejected', code: 'draft' },
    ]

    const customStyles = {
        dropdown: {
            width: '200px',
            borderColor: 'red',  // example style
        },
        required: {
            color: 'red',
            marginLeft: '5px',
        }
    };

    function onClose() {
        setOpen(!open)
        setValue(null)
    }
console.log(type)
    return (
        <>
            <Dialog
                style={{ width: '50vw' }}
                visible={open}
                onHide={() => onClose()}
            >
                <form onSubmit={onSubmit}>
                    <label className='mb-1 text-black'>Select Status <span className='text-danger'>*</span></label>
                    <Dropdown
                        value={value}
                        onChange={(e) => {
                            setValue(e.value)
                            setStatusRequired(false)
                        }}
                        options={type == 'reviewer' ? options : options2}
                        className='w-100'
                        // required
                        optionLabel='name'
                        placeholder='Select Status'
                        style={statusRequired ? customStyles.dropdown : ''}
                    />
                    {statusRequired ? <span className='text-danger'>This field is Required</span> : ''}<br />
                    <label className='mt-3 mb-1 text-black'>Add Comment</label>
                    <textarea rows={5} className='form-control' onChange={(e) => setComment(e.target.value)} />
                    <div className='w-100 d-flex justify-content-end mt-3 gap-3'>
                        <button type='button' className='btn border' onClick={() => {
                            setOpen(false)
                            setValue(null)
                        }}>Cancel</button>
                        <button type='submit' className='btn btn-primary'>Submit</button>
                    </div>
                </form>

            </Dialog>
        </>
    )
}
