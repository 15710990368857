import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { PrimaryButton } from "../../../../Components/Buttons/PrimaryButton";
import SingleSelect from "../../../../Components/Common/SingleSelect";
import TextInput from "../../../../Components/Inputs/TextInput";
import Calender from "../../../../Components/Common/Calender";
import { Editor } from "primereact/editor";
import { DragAndDrop } from "../../../../Components/Inputs/DragAndDrop";

function NewPayment() {
  const [visible, setVisible] = useState(false);

  const centeredHeaderTemplate = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">Add Payment</span>

      <span className=" " onClick={() => setVisible(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  return (
    <div>
      <span className="mt-5 ">
        <span className="">
          <PrimaryButton
            addIcon
            onClick={(e) => {
              setVisible(true);
            }}
            text={"New Payment"}
          />
        </span>
      </span>

      <Dialog
        style={{ width: "40%" }}
        visible={visible}
        closable={false}
        draggable={false}
        onHide={() => setVisible(false)}
        header={centeredHeaderTemplate}
      >
        <div>
          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Invoice
            </label>
            <SingleSelect
              placeholder="Select Invoice"
              name="invoice"
              className=""
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Amount:
            </label>
            <TextInput
              placeholder="Enter Amount"
              name="amount"
              className={""}
            ></TextInput>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Invoice #
              </label>
              <SingleSelect
                placeholder="Select Invoice Number"
                name="invoiceNumber"
                className=""
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Check #
              </label>
              <SingleSelect
                placeholder="Select Check Number"
                name="checkNumber"
                className=""
              />
            </div>
          </div>

          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Payment Received Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Payment Received Date"
              className={""}
              name="paymentReceivedDate"
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Description:
            </label>
            <div className="card">
              <Editor
                name="description"
                style={{ height: "100px" }}
              />
            </div>
          </div>

          <div className="col-md-12">
            <DragAndDrop />
          </div>

          <div className="end mt-5 gap-2">
            <button
              className="btn border"
              onClick={() => {
                setVisible(false);
              }}
              type="button"
            >
              Cancel
            </button>
            <PrimaryButton type="submit" text={"Add Payment"} />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default NewPayment;