import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Carousel from "react-bootstrap/Carousel";

const SpendingSummary = ({ overViewGetData }) => {
  const [drawingDatas, setDrawingData] = useState([]);

  function drawingData() {
    let data = [];
    data = overViewGetData?.map((res) => {
      return res?.drawing_file;
    });
    console.log(data,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
    setDrawingData(data?.flat(Infinity));
  }
  useEffect(() => {
    drawingData();
  }, [overViewGetData]);
 

  return (
    <>
      <Carousel
        interval={2000}
        slide={true}
        controls={false}
        indicators={false}
        style={{height:"450px",overflow:"hidden"}}
      >
        {drawingDatas?.map((res, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={res?.fileUrl}
                alt="First slide"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default SpendingSummary;
