import React, { useEffect, useState } from 'react';
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { createProjectInformation, getAllProjects, getCountOfProjects } from '../../Api/Admin/AllAPIs';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filterArrayWithMultipleKey, formatDateInIST, getProjectCode, getProjectName, getUserId, getUserType } from '../../utils';
import { TableSkelton } from '../Common/TableSkelton';
import { TabCard } from '../Common/PagesComponents/TabCard';
import { PROJECT_STATUS } from '../../constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Pagination from '../Common/Pagination/Pagination';
import { ProjectStatus } from '../Common/ProjectStatus';
import style from "./HomeListing.module.css";
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal';
import { BorderedTable } from '../Tables/BorderedTable';
import { ProjectTableWrapper } from '../Tables/ProjectTableWrapper';
import ViewProjectInformation from '../../pages/ProjectList/ViewProjectInformation';
import ViewProjectDirectory from '../../pages/ProjectList/ViewProjectDirectory';
import ViewCostCode from '../../pages/ProjectList/ViewCostCode';
import ViewProjectTools from '../../pages/ProjectList/ViewProjectTools';
import ViewProjectStages from '../../pages/ProjectList/ViewProjectStages';
import ViewProjectDrawing from '../../pages/ProjectList/ViewProjectDrawing';
import ViewProjectSpecifications from '../../pages/ProjectList/ViewProjectSpecifications';
import { DownloadPdfAndSendEmail } from '../Common/ViewPageComponents/DownloadPdfAndSendEmail';
import Popover from '../Common/Popover';
import GridSkeleton from '../Common/GridSkeleton';
import { ViewPageDocumentName } from '../Common/ViewPageComponents/ViewPageDocumentName';

function ProjectHomeListing() {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState({ value: null, index: 0 });
    const [page, setPage] = useState(1);
    const perPageData = 10;
    const [open, setOpen] = useState(null);
    const [type, setType] = useState('')
    const [itemId, setItemId] = useState('')
    const [currentStatusRowData, setCurrentStatusRowData] = useState({})
    const [totalCount, setTotalCount] = useState(0);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [allData, setAllData] = useState([]);
    const [projectCount, setProjectCount] = useState({});
    const [dropdownvalue, setDropDownValue] = useState(null)
    const [comment, setComment] = useState('')
    const [statusRequired, setStatusRequired] = useState(false)
    const [tableSortData, setTableSortData] = useState({
        page: 1,
        search: ""
    });
    let tableHeaderArr = ["Project Number", "Project Name", "Start Date", "Project Status", "Department", "Approval Status", "Actions"]
    const projectsStatus = [
        { header: "All Projects", value: null, countKey: "total" },
        { header: "Active Projects", value: PROJECT_STATUS.active, countKey: "active" },
        { header: "On Hold Projects", value: PROJECT_STATUS.onHold, countKey: "onhold" },
        { header: "Completed Projects", value: PROJECT_STATUS.completed, countKey: "completed" }
    ]

    const domain = window.location.origin;

    useEffect(() => {
        fetchProjects();
    }, [tableSortData]);

    useEffect(() => {
        fetchProjectsCount();
    }, []);

    async function fetchProjects() {
        try {
            setSkeltonLoader(true);
            let body = { project_status: activeIndex.value };
            let query = {
                search: tableSortData.search,
                page: tableSortData.page,
                perPageData: perPageData,
                type: getUserType(),
                user_id: getUserId()
            };
            let res = await getAllProjects(query, body);
            console.log(res?.data?.data)
            setAllData(res?.data?.data || []);
            setTotalCount(res?.data?.totalCount || 0);

        } catch (error) {
            console.log(error);
        } finally {
            setSkeltonLoader(false);
        }
    }



    async function fetchProjectsCount() {
        try {
            let body = { type: getUserType(), id: getUserId() };
            let res = await getCountOfProjects(body);
            console.log(res?.data?.data?.[0])
            setProjectCount(res?.data?.data?.[0] || {});
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearchChange = (e) => {
        setTableSortData({
            ...tableSortData,
            search: e.target.value,
            page: 1
        });
    };

    function changeStatusUi(status) {
        switch (status) {
            case 'draft':
                return <div className="statusOuterD">
                    <div className="statusDotD" />
                    <div className="StatusD">Draft</div>
                </div>
                break;
            case 'inreview':
                return <div className="statusOuterI">
                    <div className="statusDotI" />
                    <div className="StatusI">In Review</div>
                </div>
                break;
            case 'pendingapproval':
                return <div className="statusOuterP">
                    <div className="statusDotP" />
                    <div className="StatusP">Pending Approver</div>
                </div>
                break;
            case 'approved':
                return <div className="statusOuterA">
                    <div className="statusDotA" />
                    <div className="StatusA">Approved</div>
                </div>
                break;

            default:
                break;
        }
    }
    function changeStatusUi2(status) {
        switch (status) {
            case 'draft':
                return <div className="statusOuterns">
                    <div className="statusDotns" />
                    <div className="Statusns">Draft</div>
                </div>
                break;
            case 'undermaintenace':
                return <div className="statusOuterip">
                    <div className="statusDotip" />
                    <div className="Statusip">Under Maintenace</div>
                </div>
                break;
            case 'active':
                return <div className="statusOuterc">
                    <div className="statusDotc" />
                    <div className="Statusc">Active</div>
                </div>
                break;
            case 'completed':
                return <div className="statusOuterc">
                    <div className="statusDotc" />
                    <div className="Statusc">Completed</div>
                </div>
                break;
            case 'onHold':
                return <div className="statusOuterr">
                    <div className="statusDotr" />
                    <div className="Statusr">On Hold</div>
                </div>
                break;
            case 'incomplete':
                return <div className="statusOuterin">
                    <div className="statusDotin" />
                    <div className="Statusin">Incomplete</div>
                </div>
                break;

            default:
                break;
        }
    }

    async function changeStatus(e) {
        e.preventDefault()
        let email_data = []

        if (dropdownvalue == null) {
            setStatusRequired(true)
            return
        }
        // send email for reviewer 
        console.log(dropdownvalue?.code)
        if (type == "reviewer") {
            if (dropdownvalue?.code === "pendingapproval") {
                // send email to creator after reviewe approved 
                email_data.push({
                    name: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    to: currentStatusRowData?.creator_email?.[0]?.email || currentStatusRowData?.creator_email?.[0]?.email_address,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    project_name: currentStatusRowData?.project_name,
                    subject: `FYI:Change in ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectCreatorDoneByReviewer",
                    status: dropdownvalue.code,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })
                // email to approver after reviewer approves 
                email_data.push({
                    name: currentStatusRowData?.approver_id?.first_name,
                    to: currentStatusRowData?.approver_id?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `Action Required: Change in ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectApproverDoneByReviewer",
                    status: dropdownvalue.code,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })
            }
            else {
                // send email to creator
                email_data.push({
                    name: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    to: currentStatusRowData?.creator_email?.[0]?.email || currentStatusRowData?.creator_email?.[0]?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `Action Required: Change in ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectCreatorAfterRejectionByReviewer",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })
                // email to approver if reviewer reject 
                email_data.push({
                    name: currentStatusRowData?.approver_id?.first_name,
                    to: currentStatusRowData?.approver_id?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `FYI: Change in  ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectApproverAfterRejectionByReviewer",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })

            }
        }
        // send email for approver 
        else {
            if (dropdownvalue?.code === "approved") {

                // email to reviewer after apprver approves 
                email_data.push({
                    name: currentStatusRowData?.reviewer_id?.first_name,
                    to: currentStatusRowData?.reviewer_id?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `FYI: Change in  ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectAllUserCreatorReviwerDoneByApproverData",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })

                // email to creator  after approver approves
                email_data.push({
                    name: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    to: currentStatusRowData?.creator_email?.[0]?.email || currentStatusRowData?.creator_email?.[0]?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `FYI: Change in  ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectAllUserCreatorReviwerDoneByApproverData",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })
            }

            else {
                // email to reviewer 
                email_data.push({
                    name: currentStatusRowData?.reviewer_id?.first_name,
                    to: currentStatusRowData?.reviewer_id?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `FYI: Change in ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectReviewerRejectByApproverData",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })

                // email to creator 
                email_data.push({
                    name: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    to: currentStatusRowData?.creator_email?.[0]?.email || currentStatusRowData?.creator_email?.[0]?.email_address,
                    project_name: currentStatusRowData?.project_name,
                    approver_name: currentStatusRowData?.approver_id?.first_name,
                    reviewer_name: currentStatusRowData?.reviewer_id?.first_name,
                    project_id: currentStatusRowData?.projectNumber || "--",
                    subject: `Action Required: Change in  ${getProjectName()} Project’s Approval Status`,
                    template_type: "emailToProjectCreatorRejectByApproverData",
                    created_by: currentStatusRowData?.creator_email?.[0]?.first_name || currentStatusRowData?.creator_email?.[0]?.name,
                    url: `${domain}/coretools/admin/projectform/step-1`
                })

            }

        }

        setOpen(false)

        try {

            if (type == 'reviewer') {
                let payload = {
                    approval_status: dropdownvalue?.code,
                    reviewer_comment: comment,
                    id1: itemId,
                    projectNumber: currentStatusRowData?.projectNumber
                }

                await createProjectInformation({ ...payload, email_data, url: domain })
            } else {

                let payload = {
                    approval_status: dropdownvalue?.code,
                    approver_comment: comment,
                    id1: itemId,
                    projectNumber: currentStatusRowData?.projectNumber
                }

                await createProjectInformation({ ...payload, email_data, url: domain })
            }




        } catch (err) {
            console.log(err)
        } finally {
            // fetchRfi()
            setItemId('')
            setType('')
            fetchProjects()
            // setLoader(false)
            setDropDownValue(null)

        }
    }



    return (
        <div className={style.projectHomeListing_container}>
            <ChangeStatusModal
                open={open}
                setOpen={setOpen}
                value={dropdownvalue}
                setValue={setDropDownValue}
                onSubmit={changeStatus}
                setComment={setComment}
                type={type}
                statusRequired={statusRequired}
                setStatusRequired={setStatusRequired}
            />
            <div className="page_heading">Projects</div>
            <div className="d-flex mb-20 gap-3 flex-sm-row flex-column-reverse justify-content-sm-between align-items-sm-center align-items-end mt-3">
                <div className="searchInput">
                    <SearchInput
                        onChange={handleSearchChange}
                        value={tableSortData.search}
                        onCancel={() => {
                            setTableSortData({ ...tableSortData, search: "" });

                        }}
                    />
                </div>
                <div>
                    <PrimaryButton
                        onClick={() => {
                            navigate("/portfolio/projectform/step-1");
                            localStorage.removeItem("projectId");
                        }}
                        addIcon={true}
                        text={"Create Project"}
                    />
                </div>
            </div>

            <div className="d-flex flex-md-row flex-column gap-lg-5 gap-sm-2">
                {projectsStatus?.map((res, key) => (
                    <TabCard
                        onClick={() => {
                            setActiveIndex({
                                value: res.value,
                                index: key
                            });
                            setTableSortData({ ...tableSortData, page: 1 });
                        }}
                        className={style.tab_card}
                        key={key}
                        headerClassName={`${style.tab_card_header_text}`}
                        valueClassName={`${style.tab_card_value_text}`}
                        header={res.header}
                        value={projectCount?.[res?.countKey]}
                        active={activeIndex.index === key}
                    />
                ))}
            </div>
            <br />
            <div>
                {
                    skeltonLoader ? <TableSkelton header={tableHeaderArr} /> :
                        <ProjectTableWrapper>
                            <DataTable value={allData}>
                                <Column
                                    sortable
                                    field="projectNumber"
                                    header="Project Number"
                                    // body={(rowData, rowIndex) => <div>{rowIndex.rowIndex + 1}</div>}
                                    style={{ width: "15%" }}
                                ></Column>
                                <Column
                                    className='pointer'
                                    sortable
                                    field="project_name"
                                    header={'Project Name'}
                                    body={rowData => (
                                        <span
                                            className='text-underline'
                                            onClick={() => {
                                                localStorage.setItem('projectId', rowData._id);
                                                navigate('/overviewproject');
                                            }}
                                        >
                                            {rowData?.project_name}
                                        </span>
                                    )}
                                ></Column>
                                <Column
                                    field="start_date"
                                    header="Start Date"
                                    sortable
                                    body={(rowData) => formatDateInIST(rowData.start_date)}
                                ></Column>
                                <Column
                                    style={{ width: '10%' }}
                                    field="completion_date"
                                    header="Project Status"
                                    sortable
                                    // body={(rowData) => changeStatusUi2(rowData?.workflow_status)}
                                    body={(rowData) => (
                                        <div className='d-fle flex-column'>
                                            <div>
                                                {changeStatusUi2(rowData?.project_status)}
                                                {/* <ProjectStatus status={rowData?.project_status} /> */}
                                            </div>
                                            <div className='mt-1 text-center'>
                                                {formatDateInIST(rowData?.completion_date)}
                                            </div>
                                        </div>
                                    )}
                                ></Column>
                                <Column
                                    sortable
                                    field="project_department"
                                    header="Department"
                                ></Column>
                                <Column
                                    sortable
                                    header="Approval Status"
                                    body={(rowData, meta) => {
                                        // console.log(rowData)
                                        return (
                                            <>
                                                <div
                                                    className={`d-flex align-items-center `}
                                                >
                                                    {rowData?.approval_status === "approved" && (
                                                        <div className="w-100">{changeStatusUi(rowData?.approval_status)}</div>
                                                    )}
                                                    {rowData?.approval_status === "pendingapproval" && (
                                                        <div className="w-100" title="Pending Approval" onClick={() => {
                                                            if (getUserId() === rowData?.approver_id?._id) {
                                                                setOpen(true)
                                                                setType("approver");
                                                                setItemId(rowData._id);
                                                                setCurrentStatusRowData(rowData)
                                                            }
                                                        }} >{changeStatusUi(rowData?.approval_status)}</div>
                                                    )}
                                                    {rowData?.approval_status === "draft" && changeStatusUi(rowData?.approval_status)}
                                                    {rowData?.approval_status === "inreview" && (
                                                        <div className="w-100" title="Pending review" onClick={() => {
                                                            // if (userData?.user_type == 'admin' || getUserId() === rowData?.reviewer_id) {
                                                            if (getUserId() === rowData?.reviewer_id?._id) {
                                                                setOpen(true)
                                                                setType('reviewer')
                                                                setItemId(rowData._id)
                                                                setCurrentStatusRowData(rowData)
                                                            }
                                                        }} >{changeStatusUi(rowData?.approval_status)}</div>
                                                    )}
                                                </div>
                                            </>
                                        )

                                    }

                                    }
                                    style={{ width: "15%" }}
                                ></Column>
                                <Column
                                    header='Actions'
                                    body={(rowData, rowIndex) => {
                                        return <Popover
                                            options={[
                                                'View',
                                                'Edit',
                                                "Version History",
                                                "Send Via Post",
                                                "Send Email"
                                                // <div>
                                                //     <DownloadPdfAndSendEmail
                                                //         heading={"Project"}
                                                //         forProject={true}
                                                //         fileName={"Project"}
                                                //         id={rowData?._id}
                                                //         files={[
                                                //             ...rowData?.project_photos?.map((e) => e?.fileUrl),
                                                //             ...rowData?.project_logo?.map((e) => e?.fileUrl),
                                                //         ]}
                                                //     >
                                                //         <div className="p-5">
                                                //             <div className='fw-bold fs-5'>1.Project Information</div>
                                                //             <ViewProjectInformation projectData={rowData} />
                                                //             <div className='fw-bold fs-5'>2.Project Directory</div>
                                                //             {/* <ViewProjectDirectory projectDirectory={rowData} /> */}
                                                //             <div className='fw-bold fs-5 my-4'>3.Cost Code </div>
                                                //             <ViewCostCode costCodeData={rowData?.costCodeData} hidePagination={true} />
                                                //             <div className='fw-bold fs-5 my-4'>4.Project Tools </div>
                                                //             <ViewProjectTools toolData={rowData?.project_tools} hidePagination={true} />
                                                //             <div className='fw-bold fs-5 my-4'>5.Project Stages</div>
                                                //             <ViewProjectStages stageData={rowData?.project_stages} hidePagination={true} />
                                                //             <div className='fw-bold fs-5 my-4'>6.Project Drawings</div>
                                                //             <ViewProjectDrawing
                                                //                 drawingData={rowData?.project_drawings}
                                                //                 hidePagination={true}
                                                //             />
                                                //             <div className='fw-bold fs-5 my-4'>6.Project Specifications</div>
                                                //             <ViewProjectSpecifications
                                                //                 specificationData={rowData?.project_specifications}
                                                //                 hidePagination={true}
                                                //             />
                                                //             <div className='fw-bold fs-5 my-4'>7.Project Schedule</div>
                                                //             {rowData ? <div className="d-flex mt-3 flex-wrap gap-4">
                                                //                 {rowData?.project_schedule?.length !== 0 ? rowData?.project_schedule?.map((res,key) => (
                                                //                     // console.log(res?.fileUrl)
                                                //                     <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
                                                //                 )) : <div className="ps-3">
                                                //                     <h6>No Photos available</h6>
                                                //                 </div>
                                                //                 }

                                                //             </div> : <GridSkeleton cardCount={4} />}
                                                //         </div>
                                                //     </DownloadPdfAndSendEmail>
                                                // </div>
                                            ]}
                                            onSelect={(index, event) => {
                                                if (index === 0) {
                                                    localStorage.setItem('projectId', rowData?._id)
                                                    navigate(`/coretools/admin/projectView/${rowData?._id}`)
                                                }
                                                else if (index === 1) {
                                                    localStorage.setItem('projectId', rowData?._id)
                                                    navigate("/coretools/admin/projectform")
                                                }
                                                else if (index === 2) {
                                                    localStorage.setItem('projectId', rowData?._id)
                                                    navigate("/coretools/admin/projectView/versionHistory")
                                                }
                                                else if (index === 3) {
                                                    localStorage.setItem('projectId', rowData?._id)
                                                    navigate("/coretools/admin/projectView/AddressListing")
                                                }
                                                else if (index === 4) {
                                                    localStorage.setItem('projectId', rowData?._id)
                                                    navigate(`/coretools/admin/projectView/${rowData?._id}?sendEmail=send`)
                                                }
                                            }}
                                        />
                                    }}
                                ></Column>
                            </DataTable>
                        </ProjectTableWrapper>
                }
                <br />
            </div>
            <Pagination
                currentPage={tableSortData.page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={page => setTableSortData({ ...tableSortData, page })}
            />
        </div>
    );
}

export default ProjectHomeListing;
