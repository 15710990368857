import React, { useEffect, useRef, useState } from "react";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { useNavigate } from "react-router-dom";
import IncidentFilter from "./Filter";
import {
  deleteIncident,
  filterIncident,
  getIncidentDataByProjectId,
  updateIncident,
} from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import { getProjectCode, getProjectName, getUserId, isApproverOfProject, isReviewerOfProject, searchDataWithKeys, toast } from "../../utils";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { ImCross } from "react-icons/im";
import { FaCheck, FaRegClock } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { HtmlIntoString, HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import moment from "moment";
import Pagination from "../../Components/Common/Pagination/Pagination";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";
function Incident() {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [originalRes, setOriginalRes] = useState([]);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [page, setPage] = useState(1)
  const perPageData = 10;
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: ""
  })
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAllData] = useState([])
  const [incidentData, setIncidentData] = useState([])

  const [filteredData, setFilteredData] = useState({
    status: null,
    incident_identifiedBy: null,
    priority: null
  })
  const [tempFilterData, setTempFilterDate] = useState({
    status: null,
    incident_identifiedBy: null,
    priority: null
  })
  const [filterActive, setFilterActive] = useState(false)



  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getIncidentRes = async () => {
    setLoader(true);
    try {

      // 

      let params = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search
      }
      if (search !== '') {
        params.search = search
      }
      setSearch("")
      let body = { ...tempFilterData }
      setSkeletonLoader(true);
      const response = await getIncidentDataByProjectId(projectId, params, body)
      console.log(response?.data?.data)
      setIncidentData(response?.data?.data || [])
      setTotalCount(response?.data?.totalCount)
      setAllData(response?.data?.data || [])
      setOriginalRes(response?.data?.data || [])
      console.log(incidentData)
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };


  useEffect(() => {
    getIncidentRes();
  }, [tableSortData.page, tableSortData.search, tempFilterData]);

  let navigate = useNavigate();



  const handleDelete = async (id) => {
    setVisibleDelete(true)
    setSelectedIncidentId(id);
  };

  const confirmDelete = async () => {
    let payload ={
      created_by:userName,
    }
    
    setLoader(true);
    try {
      await deleteIncident(selectedIncidentId,payload);
      toast.success("Incident Deleted Successfully!")
      getIncidentRes();
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const csvData = originalRes.map(item => ({
    "Incident Title": item.title,
    "Location": item.location,
    "Incident Date": moment(item.incident_date).format('DD MM YYYY'),
    "Incident Time": item.incident_time,
    "Incident Close Date": moment(item.incident_closeDate).format('DD- MM- YYYY  hh:mm A'),
    "Incident Identified By": item?.incident_identifiedBy.map(e => e.first_name),
    "Incident Type": item?.incident_type.map(e => e.name),
    "Priority": item?.priority.map(e => e.name),
    "Status": item.status,
    "Incident Feedback": HtmlIntoString({ html: item.description }),
    "Is Deleted": item.is_deleted,
    "Created At": moment(item.created_at).format('DD- MMM YYYY  hh:mm A')

  }));

  const csvHeaders = [
    { label: 'Incident Title', key: 'Incident Title' },
    { label: 'Location', key: 'Location' },
    { label: 'Incident Date', key: 'Incident Date' },
    { label: 'Incident Time', key: 'Incident Time' },
    { label: ' "Incident Close Date"', key: 'Incident Close Date'},
    { label: 'Incident Identified By', key: 'Incident Identified By' },
    { label: 'Incident Type', key: 'Incident Type' },
    { label: 'Priority', key: 'Priority' },
    { label: 'Status', key: 'Status' },
    { label: 'Incident Feedback', key: 'Incident Feedback' },
    { label: 'Is Deleted', key: 'Is Deleted' },
    { label: 'Created At', key: 'Created At' }

  ];


  const tableRef = useRef();

  // const exportToPDF = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     html: tableRef.current,
  //     // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
  //     didParseCell: function (data) {
  //       // if (data.row.index === 0) {
  //       //   // Scale down font size for header row
  //       data.cell.styles.fontSize = 2;
  //       // }
  //     },
  //   });
  //   doc.save('incident.pdf');
  // };
  const exportToPDF = () => {

    const doc = new jsPDF();
    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);

    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('Incident.pdf');
  };
  function filterData() {
    console.log(filteredData, "fil************************")

    setTempFilterDate(filteredData)
    setPage(1)
    setFilterActive(true)
  }
  function clearFilter() {
    setFilterActive(false)
    setFilteredData({
      status: null,
      incident_identifiedBy: null,
      priority: null
    })
    setTempFilterDate({
      status: null,
      incident_identifiedBy: null,
      priority: null
    })
  }



  async function statusChange(data, id, toolId, comment) {
    try {
      let t = await updateIncident(id, { ...data, ...comment, incidentID: toolId });
      getIncidentRes()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>



      <div>
        <div className="between">
          <h1 className="page_heading">Incident</h1>
          <div className="flex align_center gap-2">
            <div className="right d-flex gap-2 align-items-center">
              <ExportPdfButton exportToPDF={exportToPDF}
                csvBtn={<CSVLink
                  className="pe-1"
                  data={csvData}
                  headers={csvHeaders}
                  filename={"incident.csv"}
                >
                  CSV
                </CSVLink>
                }
              />


            </div>
            <PrimaryButton
              onClick={() => navigate("createincident")}
              addIcon
              text={"Create"}
            />
          </div>
        </div>


        <div style={{ height: '0', overflow: 'hidden' }}>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>Incident ID</th>
                <th>Incident Title</th>
                <th>Location</th>
                <th>Status</th>
                <th>Incident Feedback</th>
                <th>Incident Type</th>
                <th>Incident Identified by</th>
                <th>Priority</th>
                <th>Incident Date</th>
                <th>Incident Time</th>
                <th>Incident Close Date</th>
                <th>Is Deleted</th>
                <th>Created At</th>


              </tr>
            </thead>
            <tbody>
              {originalRes?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.incidentID}</td>
                    <td>{item.title}</td>
                    <td>{item.location}</td>
                    <td>{item.status}</td>
                    <td><HtmlIntoText html={item.description} /></td>
                    <td>{item.incident_type.map(e => e.name)}</td>
                    <td>{item.incident_identifiedBy.map(e => e.first_name)}</td>
                    <td>{item.priority.map(e => e.name)}</td>
                    <td>{moment(item.incident_date).format('DD-MM-YYYY ')}</td>
                    <td>{item.incident_time}</td>
                    <td>{moment(item.incident_closeDate).format('DD-MM-YYYY / hh:mm A')}</td>
                    <td>{item.is_deleted ? 'Yes' : 'No'}</td>
                    <td>{moment(item.created_at).format('DD-MM-YYYY / hh:mm A')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>


        <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
          <div className="searchInput">
            {/* <SearchInput
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setIncidentData(searchDataWithKeys(["title"], allData, e.target.value))
              }}
              onCancel={() => {
                setSearch("")
                setIncidentData(allData)
              }}
            /> */}
            <SearchInput
              value={tableSortData.search}
              onChange={(e) => {
                const searchTerm = e.target.value;
                setTableSortData(prev => ({
                  ...prev,
                  page: 1,
                  search: searchTerm
                }));

                setIncidentData(searchDataWithKeys(["title"], allData, searchTerm));
              }}
              onCancel={() => {
                setTableSortData(prev => ({ ...prev, page: 1, search: '' }));
                setIncidentData(allData);
              }}
            />


          </div>

          <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
            <IncidentFilter
              className="w-100"
              filterData={filteredData}
              setFilterData={setFilteredData}
              onFilter={filterData}

            />
            &nbsp;
            <button
              disabled={!filterActive}
              className="filter_btn w-100" onClick={clearFilter}>Clear Filter</button>
          </div>
        </div>


        <br />

        <div>
          <div>
            <div className="tools_table_scroll">

              {
                skeletonLoader ?
                  <TableSkelton
                    header={[
                      "incidentID",
                      "Incident Title",
                      "Incident Type",
                      "Priority",
                      "Location",
                      "Status",
                      "Approval Status",
                      "Action",
                    ]}
                  />
                  :
                  // <BorderedTable>
                    <DataTable value={incidentData}  >
                      <Column
                      headerClassName="text-nowrap"
                        sortable
                        header="Incident ID"
                        body={(rowData, rowIndex) => (
                          <div>{rowData.incidentID} </div>
                        )}
                      ></Column>
                      <Column
                      headerClassName="text-nowrap"
                      sortable
                      header="Incident Title"
                        body={(rowData) => {
                          return (
                            <div className="pointer"
                            >{rowData?.title}</div>
                          )
                        }}

                      ></Column>
                      <Column 
                      headerClassName="text-nowrap"
                      sortable
                      header="Incident Type"
                        body={(rowData) => {
                          return (
                            <div className="pointer">
                              {rowData?.incident_type.map(e => e.name)}
                            </div>
                          )
                        }}

                      ></Column>
                      <Column
                      headerClassName="text-nowrap" 
                      sortable
                      header="Priority"
                        body={(rowData) => {
                          return (
                            <div className="pointer">
                              {rowData?.priority.map(e => e.name)}
                            </div>
                          )
                        }}

                      ></Column>

                      <Column headerClassName="text-nowrap" sortable field="location" header="Trade/Location"></Column>
                      <Column headerClassName="text-nowrap" sortable field="incident_time" header="Incident Time"></Column>
                      <Column headerClassName="text-nowrap" sortable header="Incident Identified By"
                        body={(rowData) => {
                          return (
                            <div className="pointer">
                              {rowData?.incident_identifiedBy.map(e => e.first_name)}
                            </div>
                          )
                        }}

                      ></Column>
                      
                       <Column headerClassName="text-nowrap"  header="Incident closed Date/Time"
                           body={(rowData) => {
                            const date = new Date(rowData.incident_closeDate);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                            const year = date.getFullYear();
                            const hours = date.getHours() % 12 || 12; // 12-hour format
                            const minutes = String(date.getMinutes()).padStart(2, '0');
                            const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
                            return (

                              <div className="pointer">
                              {/* {rowData.incident_closeDate} */}
                             { moment(rowData.incident_closeDate).format('DD- MM- YYYY / hh:mm A')}
                              </div>

                            )
                        }}
                      ></Column>

                      <Column
                      
                        headerClassName="text-nowrap"
                        body={(rowData) => {
                          return (<ChangeApprovalStatus
                            status={rowData.status}
                            onSubmit={statusChange}
                            toolData={{
                              tool_id: rowData?._id,
                              tool_number: rowData?.incidentID,
                              tool_name: rowData?.title,
                              tool: "Incident",
                              _id: rowData?._id,
                              approver_id: rowData?.approver_id,
                              reviewer_id: rowData?.reviewer_id,
                              reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                              approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                              reviewer_email: `${rowData?.reviewer?.email_address}`,
                              approver_email: `${rowData?.approver?.email_address}`,
                              creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                              creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                            }}
                            rowData={rowData}
                          />
                          )
                        }}
                        header="Approval Status"></Column>

                      <Column
                        header={"Action"}
                        style={{ width: "6rem" }}
                        body={(rowData) => (
                          <div className="d-flex justify-content-center">
                            <Popover
                              options={["Version History", "Edit", "Delete", "View",

                                <DownloadPdfAndSendEmail
                                fileName={"INCIDENT"}
                                  heading={"INCIDENT"}
                                  id={rowData._id}
                                  files={rowData?.attachFile?.map(e => e.url)}
                                >
                                  <ViewPagesPdfTemplate
                                    header={`${rowData?.title}`}
                                    className="text-capitalize"
                                    description={{
                                      key: "Incident Descriptions",
                                      value: <HtmlIntoText html={rowData?.description} />
                                      
                                    }}
                                    tableData1={[
                                      { "IncidentID" : rowData?.incidentID || ""},
                                      { "Incident Title": rowData?.title || "" },
                                      { "Priority": rowData?.priority?.map(e => e.name) || "--" },
                                      { "Incident Type": rowData?.incident_type?.map(e => e.name) || "--" },
                                      { "Incident Idntified By": rowData?.incident_identifiedBy.map(e => e.first_name) || "--" },
                                      { "Noify Project Team": rowData?.notification ? "Yes" : "No" }
                                    ]}
                                    tableData2={[
                                      { "Incident Date": moment(rowData?.IncidentDate).format("DD/MM/YY") || "--" },
                                      { "Incident Time": moment(rowData?.IncidentTime).format("HH:MM") || "--" },
                                      { "Incident Closed Date/Time": moment(rowData?.closeDate).format('DD-MM-YYYY / hh:mm A') || "--" },
                                      { "Location": rowData?.location || "--" },
                                    

                                    ]}
                                  />
                                </DownloadPdfAndSendEmail>,
                                "Send Via Post"
                              ]}
                              onSelect={(index) => {
                                if (index === 0) {
                                  navigate(`/projectManagement/version-history/incident/${rowData?.title}/${rowData?._id}`);
                                }
                                else if (index === 1) {
                                  navigate(`editincident/${rowData?._id}`)


                                }
                                // else if (index === 2) {

                                // }
                                else if (index === 2) {
                                  handleDelete(rowData?._id)
                                }
                                else if (index === 3) {
                                  navigate(`view/INCIDENT/${rowData?.title}/${rowData?._id}`)
                                }
                                else if (index === 5) {

                                  navigate(`/projectManagement/addressListing/INCIDENT/${rowData?.title}/${rowData?._id}`)

                                }
                              }}
                            />
                          
                          </div>
                        )}
                      ></Column>
                    </DataTable>
                  //  </BorderedTable
                  }
            </div>

            <Pagination
              currentPage={tableSortData.page}
              totalCount={totalCount}
              pageSize={perPageData}
              onPageChange={(e) => setTableSortData(pre => ({ ...pre, page: e }))} />
            <br />


          </div>
        </div>
      </div>


      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setSelectedIncidentId(null)}
        onConfirm={confirmDelete}
        header={"Incident"}
      />
      {/* <IncidentFilter
        setVisible={setVisible}
        visible={visible}
        applyFilter={filterData}
        res={getRes}
      /> */}
    </>
  );
}

export default Incident;
