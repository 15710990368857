import React, { useState, useEffect, useRef } from "react";
import "./Transmittals.css";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaRegClock } from "react-icons/fa";
import {
  deleteTransmittal,
  getTransmittalDataByProjectId,
  transmittalUpdate,
} from "../../Api/Admin/AllAPIs";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { ImCross } from "react-icons/im";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import Pagination from "../../Components/Common/Pagination/Pagination";
import { getProjectCode, getProjectName, getUserId, searchDataWithKeys } from "../../utils";
import {
  HtmlIntoString,
  HtmlIntoText,
} from "../../Components/Common/HtmlIntoText";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { Statuscomp } from "../../Components/Common/StatusComp";
import Filter from "./Filter";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import moment from "moment";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";

export const Transmittals = () => {
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [transmittalsId, setTransmittalsId] = useState(null);
  const [transmittalData, setTransmittalData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [test, setTest] = useState([]);
  const [search, setSearch] = useState("");
  let navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPageData = 10;
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: "",
  });
  const [filteredData, setFilteredData] = useState({
    approver_id: null,
    reviewer_id: null,
    status_data: null,
  });
  const [tempFilterData, setTempFilterData] = useState({});
  const [filterActive, setFilterActive] = useState(false);
  const [open, setOpen] = useState(null);
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [currentStatusRowData, setCurrentStatusRowData] = useState({});
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  const [loader, setLoader] = useState(false)
  const domain = window.location.origin;


  const fetchTransmittal = async () => {
    try {
      let params = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search,
      };
      setSkeletonLoader(true);
      let body = { ...tempFilterData };
      const res = await getTransmittalDataByProjectId(projectId, params, body);
      console.log(res.data.data);
      let reverseData = res?.data?.data;
      // let dataReverse = reverseData?.reverse();
      setTransmittalData(reverseData || []);
      setTransmittalData(reverseData.map((item)=>({
        ...item,
        "sender_name": item?.companySenderData?.length === 0
        ? item?.senderData?.map((ress) => {
          return ress?.first_name;
        })
        : "---",
        "sender_contractor": item?.companySenderData?.length > 0
        ? //
        `
        ${item?.companySenderData?.[0]?.company_name || "---"}
        /
        ${item?.senderData?.[0]?.first_name || "--"}`
        : "---",
        "sender_address": item?.senderData?.map((ress) => {
          return ress?.address;
        }),
        "receipent_name":item?.companyRecipientData?.length === 0
        ? item?.recipientData?.map((ress) => {
          return ress?.first_name;
        })
        : "---",
        "receipent_contractor":item?.companyRecipientData?.length > 0
        ? //
        `
        ${item?.companyRecipientData?.[0]?.company_name || "---"}
        /
        ${item?.recipientData?.[0]?.first_name || "--"}`
        : "---",
        "receipent_address":item?.recipientData?.map((ress) => {
          return ress?.address;
        }),
        // "status":item.status,
        "receipient_action":item?.recipientAcknowledgmentDate ? "Yes" : "No"
      })))
      setAllData(res?.data?.data || []);
      setTest(res?.data?.data || []);
      setTotalCount(res?.data?.totalCount || 0);
    } catch (err) {
      console.error(err);
    } finally {
      setSkeletonLoader(false);
    }
  };
  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setTransmittalsId(id);
  };

  const confirmDelete = async (id) => {
    let payload ={
      created_by:userName,
    }
    try {
      // console.log(id);
      const res = await deleteTransmittal(transmittalsId,payload);
      fetchTransmittal();
      // console.log(id);
      // console.log("res", res);
      const formattedData = res?.data?.data.map((item) =>
        console.log("del", item)
      );
      // setTest(formattedData)
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchTransmittal();
    }
  }, [tempFilterData, tableSortData]);

  const csvData = test.map((item) => ({
    Subject: item.Title,
    ID: item.ID,
    "Sender Name":
      item.companySenderData.length === 0
        ? item?.senderData?.map((ress) => {
          return ress?.first_name;
        })
        : "---",
    "sendor contractor company/Sender contractor Name":
      item.companySenderData.length > 0
        ? //
        `
        ${item?.companySenderData?.[0]?.company_name || "---"}
        /
        ${item?.senderData?.[0]?.first_name || "--"}`
        : "---",
    "Sendor Address": item?.senderData?.map((ress) => {
      return ress?.address;
    }),
    "Recipient’s Name":
      item.companyRecipientData.length === 0
        ? item?.recipientData?.map((ress) => {
          return ress?.first_name;
        })
        : "---",
    "Recipient’s contractor company/Recipient’s contractor Name":
      item.companyRecipientData.length > 0
        ? //
        `
        ${item?.companyRecipientData?.[0]?.company_name || "---"}
        /
        ${item?.recipientData?.[0]?.first_name || "--"}`
        : "---",
    "Recipient’s Address": item?.recipientData?.map((ress) => {
      return ress?.address;
    }),
    // Title: item.Title,
    // "Contract Company": item.contract_Company,
    Status: item.status,
    // "Default Percentage": item.default_Percentage,
    // Question: HtmlIntoString({ html: item.question }),
    "Recipient’s Acknowledgment Recived": item?.recipientAcknowledgmentDate
      ? "Yes"
      : "No",
      "Workflow Status":item?.status_data,
      "Hours Spent":`${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Min`,
      "Reviewer":item?.reviewer?.first_name,
      "Approver":item?.approver?.first_name,
      "Recipient’s Action":item?.recipientAction,
      "Issued date":moment(item?.issued_date).format("DD/MM/YYYY"),
    // "Is Deleted": item.is_deleted,
  }));

  const csvHeaders = [
    { label: "Subject", key: "Subject" },
    { label: "ID", key: "ID" },
    // { label: "Title", key: "Title" },
    // { label: "Contract Company", key: "Contract Company" },
    { label: "Status", key: "Status" },
    // { label: "Default Percentage", key: "Default Percentage" },
    { label: "Sender Name", key: "Sender Name" },
    {
      label: "sendor contractor company/Sender contractor Name",
      key: "sendor contractor company/Sender contractor Name",
    },
    { label: "Sendor Address", key: "Sendor Address" },
    { label: "Recipient’s Name", key: "Recipient’s Name" },
    {
      label: "Recipient’s contractor company/Recipient’s contractor Name",
      key: "Recipient’s contractor company/Recipient’s contractor Name",
    },
    { label: "Recipient’s Address", key: "Recipient’s Address" },
    {
      label: "Recipient’s Acknowledgment Recived",
      key: "Recipient’s Acknowledgment Recived",
    },
    { label: "Workflow Status", key: "Workflow Status" },
    { label: "Hours Spent", key: "Hours Spent" },
    { label: "Reviewer", key: "Reviewer" },
    { label: "Approver", key: "Approver" },
    { label: "Recipient’s Action", key: "Recipient’s Action" },
    { label: "Issued date", key: "Issued date" },

    // { label: "Is Deleted", key: "Is Deleted" },
  ];

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    const imgData = CDC_LOGO_URL
    const pageMargin = 10;  // Adjust this value for overall page margin

    const imgW = 20;  
    const imgH = 10;  
    const imageX = pageMargin;  
    const imageY = pageMargin;  
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;  
    const textY = imageY + 7.5;         
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);
    doc.autoTable({
      html: tableRef.current,
      startY: imageY + imgH + 10,  // Start table below the image with some margin
      margin: { top: pageMargin, left: pageMargin, right: pageMargin },  // Table margins
      // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
      didParseCell: function (data) {
        // if (data.row.index === 0) {
        //   // Scale down font size for header row
        data.cell.styles.fontSize = 2;
        // }
      },
    });
    doc.save("transmittals.pdf");
  };
  function onFilter() {
    setTempFilterData(filteredData);
    setPage(1);
    setFilterActive(true);
  }
  function clearFilter() {
    setFilterActive(false);
    setFilteredData({
      Title: null,
      status_data: null,
      ID: null,
    });
    setTempFilterData({
      Title: null,
      status_data: null,
      ID: null,
    });
  }

  // approval status functionality

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }
    setOpen(false);

    try {
      if (type == "reviewer") {
        let emailData = [];
        let payload = {
          status: dropdownvalue?.code,
          reviewer_comment: comment,
          project_id: projectId,
          email_data: [],
          id: currentStatusRowData?.ID

        };

        // creator_email
        if (dropdownvalue?.code == "pendingapproval") {
          emailData = [
            {
              name: currentStatusRowData?.approver?.first_name,
              to: currentStatusRowData?.approver?.email_address,
              tool_number: currentStatusRowData?.ID,
              tool: "Transmittal",
              tool_name: currentStatusRowData?.Title,
              project_name: getProjectName(),
              project_id: "8767",
              template_type: "createdToolEmailToApprover",
              url: `${domain}/projectManagement/transmittals`,
              created_by: userData?.name,
              // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
            },
          ];
        }

        emailData.push({
          name: currentStatusRowData?.approver?.first_name,
          to: currentStatusRowData?.creator_email?.email_address,
          tool_number: currentStatusRowData?.ID,
          tool: "Trnsmittal",
          tool_name: currentStatusRowData?.Title,
          project_name: getProjectName(),
          project_id: "8767",
          template_type: "Project Creator",
          url: `${domain}/projectManagement/transmittals`,
          created_by: userData?.name,
          // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
        });

        payload.email_data = emailData;

        await transmittalUpdate(itemId, payload);
      } else {
        let emailData = [];
        let payload = {
          status: dropdownvalue?.code,
          approver_comment: comment,
          project_id: projectId,
          id: currentStatusRowData?.ID
        };
        if (dropdownvalue?.code == "approved") {
          emailData.push({
            name: currentStatusRowData?.approver?.first_name,
            to: currentStatusRowData?.creator_email?.email_address,
            tool_number: currentStatusRowData?.ID,
            tool: "Trnsmittal",
            tool_name: currentStatusRowData?.Title,
            project_name: getProjectName(),
            project_id: "8767",
            template_type: "Project Creator",
            url: `${domain}/projectManagement/transmittals`,
            created_by: userData?.name,
            // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
          });
        }
        payload.email_data = emailData;
        await transmittalUpdate(itemId, payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchTransmittal();
      setItemId("");
      setType("");
      // setLoader(false)
      setDropDownValue(null);
    }
  }
  useEffect(() => {

  }, [currentStatusRowData])
  function changeStatusUi(status) {
    switch (status) {
      case "draft":
        return (
          <div className="statusOuterD">
            <div className="statusDotD" />
            <div className="StatusD">Draft</div>
          </div>
        );
        break;
      case "inreview":
        return (
          <div className="statusOuterI">
            <div className="statusDotI" />
            <div className="StatusI">In Review</div>
          </div>
        );
        break;
      case "pendingapproval":
        return (
          <div className="statusOuterP">
            <div className="statusDotP" />
            <div className="StatusP">Pending Approval</div>
          </div>
        );
        break;
      case "approved":
        return (
          <div className="statusOuterA">
            <div className="statusDotA" />
            <div className="StatusA">Approved</div>
          </div>
        );
        break;

      default:
        break;
    }
  }


  async function statusChange(data, id, toolId,comment) {
    try {
      let res = await transmittalUpdate(id, { ...data, ...comment });
      fetchTransmittal()
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        type={type}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />
      <div className=" transmittals">
        <div>
          <div className="d-sm-flex gap-3 justify-content-between align-items-center">
            <div className="page_heading">Transmittals</div>
            <div className="flex align_center justify-content-end  mt-sm-0 mt-2 gap-2">
              <div className="right d-flex gap-2 align-items-center">
                <ExportPdfButton
                  exportToPDF={exportToPDF}
                  csvBtn={
                    <CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"transmittals.csv"}
                    >
                      CSV
                    </CSVLink>
                  }
                />
              </div>
              <PrimaryButton
                onClick={() => navigate("create")}
                addIcon
                text={"Create"}
              />
            </div>
          </div>
          <div style={{ height: "0", overflow: "hidden", }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                 
                  <th>Transmittals Number</th>
                  
                  <th>Subject</th>
                  
                  <th>Status</th>
                 
                  <th>Sender Name</th>
                  <th>sendor contractor company/Sender contractor Name</th>
                  <th>Sendor Address</th>
                  <th>Recipient’s Name</th>
                  <th>
                    Recipient’s contractor company/Recipient’s contractor Name
                  </th>
                  <th>Recipient’s Address</th>
                  <th>Recipient’s Acknowledgment Recived</th>
                  <th>Workflow Status</th>
                  <th>Hours Spent</th>
                  <th>Reviewer</th>
                  <th>Approver</th>
                  <th>Recipient’s Action</th>
                  <th>Issued date</th>
                  
                </tr>
              </thead>
              <tbody>
                {test?.map((item, index) => {
                  return (
                    <tr key={index}>
                      
                     
                      <td>{item.ID}</td>
                      <td>{item.Title}</td>
                     
                      <td>{item.status}</td>
                      <td>
                        {item.companySenderData.length === 0
                          ? item?.senderData?.map((ress) => {
                            return ress?.first_name;
                          })
                          : "---"}
                      </td>
                      <td>
                        {item.companySenderData.length > 0
                          ? //
                          `
        ${item?.companySenderData?.[0]?.company_name || "---"}
        /
        ${item?.senderData?.[0]?.first_name || "--"}`
                          : "---"}
                      </td>
                      <td>
                        {item?.senderData?.map((ress) => {
                          return ress?.address;
                        })}
                      </td>
                      <td>
                        {item.companyRecipientData.length === 0
                          ? item?.recipientData?.map((ress) => {
                            return ress?.first_name;
                          })
                          : "---"}
                      </td>
                      <td>
                        {item.companyRecipientData.length > 0
                          ? //
                          `
        ${item?.companyRecipientData?.[0]?.company_name || "---"}
        /
        ${item?.recipientData?.[0]?.first_name || "--"}`
                          : "---"}
                      </td>
                      <td>
                        {item?.recipientData?.map((ress) => {
                          return ress?.address;
                        })}
                      </td>
                     
                      <td>
                        {item?.recipientAcknowledgmentDate ? "Yes" : "No"}
                      </td>
                      <td>{item?.status_data}</td>
                      <td>{`${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Min`}</td>
                      <td>{item?.reviewer?.first_name}</td>
                      <td>{item?.approver?.first_name}</td>
                      <td>{item?.recipientAction}</td>
                      <td>{moment(item?.issued_date).format("DD/MM/YYYY")}</td>
                    
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
            <div className="searchInput">
              <SearchInput
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);

                  setTableSortData({ page: 1, search: e.target.value });
                }}
                onCancel={() => {
                  setSearch("");
                  setTableSortData({ page: 1, search: "" });
                }}
              />
            </div>
            <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
              <div className="w-100">
                <Filter
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  onFilter={onFilter}
                />
              </div>
              &nbsp;
              <button
                disabled={!filterActive}
                className="w-100 filter_btn"
                onClick={clearFilter}
              >
                Clear Filter
              </button>
            </div>
          </div>
          <br />
          <div>
            <div>
              <div className="tools_table_scroll">
                {skeletonLoader ? (
                  <TableSkelton
                    header={[
                      "Transmittals No",
                      "Subject",
                      "Sender Name",
                      "Sender contractor company/Sender Name",
                      "Sender Address",
                      "Recipient’s Name",
                      "Recipient’s contractor company/Recipient’s Name",
                      "Recipient’s Address",
                      "Approval",
                      "Recipient’s Acknowledgment Received",
                      "Actions",
                    ]}
                  />
                ) : (
                  // <BorderedTable>
                  <div style={{width:'100%',overflow:'auto'}}>
                    <div style={{width:'2400px'}}>
                    <DataTable value={transmittalData}>
                    
                    <Column sortable field="ID" header="Transmittals No"></Column>
                    <Column sortable field="Title" header="Subject" 
                    body={(rowData)=>(
                      <div style={{textTransform:"capitalize"}}>{rowData?.Title}</div>
                    )}
                    ></Column>
                   
                    <Column
                      sortable
                      field="sender_name"
                      
                      header="Sender Name"
                    ></Column>
                    <Column
                    sortable
                      header="Sender contractor company/Sender Name"
                      
                      field="sender_contractor"
                    ></Column>

                    <Column
                    sortable
                      
                      field="sender_address"
                      header="Sender Address "
                    ></Column>
                    <Column
                    sortable
                     
                      header="Recipient’s Name"
                      field="receipent_name"
                    ></Column>
                    <Column
                    sortable
                      
                    
                      field="receipent_contractor"
                      header="Recipient’s contractor company/Recipient’s Name "
                    ></Column>
                    <Column
                   sortable
                      
                      field="receipent_address"
                      header=" Recipient’s Address "
                    ></Column>
                    


                    <Column
                    
                      headerClassName="text-nowrap"
                      body={(rowData) => {
                        return (<ChangeApprovalStatus
                          status={rowData.status}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.ID,
                            tool_name: rowData?.Title,
                            tool: "Transmittal",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                            creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                          }}
                          rowData={rowData}

                        />
                        )

                      }}
                    sortable
                    field="status"

                      header="Approval Status"></Column>

                    <Column
                    sortable
                      header="Recipient’s Acknowledgment Received"
                      field="receipient_action"
                      
                    ></Column>
                    <Column

                      body={(rowData) => {
                        return (
                          <div className="d-flex justify-content-center">
                            <Popover
                              options={[
                                "Version History",
                                "Edit",
                                "Delete",
                                "View",
                                <DownloadPdfAndSendEmail
                                  heading={"TRANSMITTAL"}
                                  fileName={"Transmittal"}
                                  id={rowData._id}
                                  files={[
                                    ...(rowData?.attachFile?.map(
                                      (e) => e.url
                                    ) || []),

                                    ...(rowData?.documentList
                                      ?.map((e) =>
                                        e.attachFile?.map((d) => d.fileUrl)
                                      )
                                      .flat(Infinity) || []),
                                  ]}
                                >
                                  
                                  <div className="card p-5">
                                    <h1 className="headdingRFI">
                                      {" "}
                                      {rowData.Title}
                                    </h1>
                                    <div className="row mt-3">
                                      <div className="col-6">
                                        <h5 className="mb-3">Sender Detail</h5>
                                        <ViewPageTable
                                          tableData={[
                                            {
                                              "Sender Name":
                                                rowData?.senderData
                                                  ?.map((e) => e?.first_name)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              //
                                              "Sendor contractor company":
                                                rowData?.companySenderData?.[0]
                                                  ?.company_name || "---",
                                            },
                                            {
                                              "Sender Phone":
                                                rowData?.senderData
                                                  ?.map((e) => e?.cell_phone)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              "Sender Email":
                                                rowData?.senderData
                                                  ?.map((e) => e?.email_address)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              "Sender Address":
                                                rowData?.senderData
                                                  ?.map((e) => e?.address)
                                                  ?.join(", ") || "--",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <h5 className="mb-3">
                                          Receiver Detail
                                        </h5>

                                        <ViewPageTable
                                          tableData={[
                                            {
                                              "Reciever Name":
                                                rowData?.recipientData
                                                  ?.map((e) => e?.first_name)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              "Recipient’s contractor company":
                                                rowData
                                                  ?.companyRecipientData?.[0]
                                                  ?.company_name || "---",
                                            },
                                            {
                                              "Reciever Phone":
                                                rowData?.recipientData
                                                  ?.map((e) => e?.cell_phone)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              "Reciever Email":
                                                rowData?.recipientData
                                                  ?.map((e) => e?.email_address)
                                                  ?.join(", ") || "--",
                                            },
                                            {
                                              "Reciever Address":
                                                rowData?.recipientData
                                                  ?.map((e) => e?.address)
                                                  ?.join(", ") || "--",
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <h5>Other Details</h5>
                                      <div className="col-6">
                                        <ViewPageTable
                                          tableData={[
                                            {
                                              "Transmittals Number":
                                                rowData?.ID,
                                            },
                                            {
                                              "Recipients Acknowledgment":
                                                moment(
                                                  rowData?.recipientAcknowledgmentDate
                                                ).format("DD-MM-YYYY"),
                                            },
                                            {
                                              Reviewer:
                                                rowData?.reviewer?.first_name

                                            },
                                            // {
                                            //   "Created By": "--",
                                            // },
                                            {
                                              "Issue Date": moment(
                                                rowData?.created_at
                                              ).format("DD-MM-YYYY"),
                                            },
                                            { "Approver Comment": <div style={{textTransform:"capitalize"}}>{rowData?.approver_comment || "--"}</div> },
                                            { "Reviewer Comment": <div style={{textTransform:"capitalize"}}>{rowData?.reviewer_comment || "--"}</div> }
                                          ]}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <ViewPageTable
                                          tableData={[
                                            {
                                              "Workflow Status":
                                                rowData?.status_data,
                                            },
                                            {
                                              Approver:
                                                rowData?.approver?.first_name,
                                            },
                                            { Subject: rowData?.Title },
                                            {
                                              "Hours Spent": 
                                              // data?.hoursSpent || "--"
                                              `${rowData?.hoursSpent?.split("-")[0]} Hr ${rowData?.hoursSpent?.split("-")[1]} Min` 
                                              
                                              ,
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-4">
                                      <h5>Recipients Action:</h5>
                                    </div>
                                    <div>{rowData?.recipientAction}</div>
                                    <div className="mt-4">
                                      <h5>Purpose of Transmittals:</h5>
                                    </div>
                                    <div>
                                      {HtmlIntoString({
                                        html: rowData?.question,
                                      })}
                                    </div>
                                    <div className="mt-4">
                                      <h5>Attached Document:</h5>
                                    </div>
                                    <div>
                                      {rowData?.attachFile?.map(
                                        (file, index) => (
                                          <ViewPageDocumentName
                                            docUrl={file?.url}
                                            key={index}
                                            name={file.fileName}
                                          />
                                        )
                                      )}
                                    </div>
                                    <DataTable value={rowData?.documentList}>
                                      <Column
                                        header="Document Number"
                                        body={(rowData1, meta) => (
                                          <div className="">
                                            {rowData1.documentNumber}
                                          </div>
                                        )}
                                      />
                                      <Column
                                        header="Document Title"
                                        body={(rowData1, meta) => (
                                          <div className="">
                                            {rowData1.documentTitle}
                                          </div>
                                        )}
                                      />
                                      <Column
                                        header="Document version"
                                        body={(rowData1, meta) => (
                                          <div className="">
                                            {rowData1.documentVersion}
                                          </div>
                                        )}
                                      />
                                      <Column
                                        header="Remark"
                                        body={(rowData1, meta) => (
                                          <div className="">
                                            {rowData1.remarks}
                                          </div>
                                        )}
                                      />
                                      <Column
                                        header="Attachment"
                                        body={(rowData1, meta) => (
                                          <div>
                                            {rowData1?.attachFile?.map(
                                              (file, index) => (
                                                <ViewPageDocumentName
                                                  docUrl={file?.url}
                                                  key={index}
                                                  name={file.fileName}
                                                />
                                              )
                                            )}
                                          </div>
                                        )}
                                        style={{ minWidth: "4rem" }}
                                      />
                                    </DataTable>
                                  </div>
                                </DownloadPdfAndSendEmail>,
                                "Send Via Post",
                              ]}
                              onSelect={(index) => {
                                if (index === 0)
                                  navigate(
                                    `/projectManagement/version-history/TRANSMITTAL/${rowData?.Title}/${rowData?._id}`
                                  );
                                else if (index === 1) {
                                  navigate(rowData._id);
                                } else if (index === 2) {
                                  handleDelete(rowData?._id);
                                }  else if (index === 3) {
                                  navigate(
                                    `/projectManagement/transmittals/view/TRANSMITTAL/${rowData.Title}/${rowData?._id}`
                                  );
                                }else if (index === 5) {
                                  navigate(
                                    `/projectManagement/addressListing/TRANSMITTAL/${rowData?.Title}/${rowData?._id}`
                                  );
                                }
                              }}
                            />
                           
                          </div>
                        );
                      }}
                      header="Actions"
                    ></Column>
                  </DataTable>
                  </div>
                  </div>
                  // </BorderedTable>
                )}
              </div>
              <br />

              <Pagination
                currentPage={tableSortData.page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={(e) =>
                  setTableSortData((pr) => ({ ...pr, page: e }))
                }
              />
            </div>
          </div>
          <DeleteDialog
            visible={visibleDelete}
            setVisible={setVisibleDelete}
            onCancel={() => setTransmittalsId(null)}
            onConfirm={confirmDelete}
            header={"Submittal"}
          />
        </div>
      </div>
    </>
  );
};
