import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import { MultiSelect } from "primereact/multiselect";
import Calender from "../../Components/Common/Calender";
import { getUserByProjectId } from "../../Api/Admin/AllAPIs";

const Filter = ({ filterData, setFilterData, onFilter }) => {
  const status_optionsP = [
    { id: 1, value: "open", label: "Open" },
    { id: 2, value: "close", label: "Close" },
    { id: 3, value: "reopen", label: "Reopen" },
    { id: 4, value: "onhold", label: "On Hold" },
  ];


  const [visible, setVisible] = useState(false)
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const projectId = localStorage.getItem("projectId");

  // function inpChange(e) {
  //   let { name, value } = e.target;
  //   if (name === "punchItemManager") {
  //     value = value || [];
  //   }
  //   setFilterData(pre => ({ ...pre, [name]: value }))
  // }

  function inpChange(e) {
    let { name, value } = e.target;
    setFilterData((pre) => ({ ...pre, [name]: value }));
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleApplyFilter = () => {
    setVisible(false);
    onFilter()
  };
  useEffect(() => {
    getAllUsersOfProject()
  }, [])

  return (
    <div className="card flex justify-content-center ">

      <button className="filter_btn" onClick={() => setVisible(true)}>
        Filter
      </button>
      <Dialog
        draggable={false}
        header="Filter Punch List"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row">

          <div className="col-md-6 ">
            <label className="labels py-2">Responsible Member:</label>
            <div className="card flex justify-content-center  ">
              {/* <Dropdown
                value={filterData?.MembersResponsible}
                onChange={inpChange}
                name="MembersResponsible"
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                placeholder=" Responsible Member "
              /> */}
                <MultiSelect
                    name="MembersResponsible"
                    value={filterData?.MembersResponsible}
                    onChange={inpChange}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))} optionLabel="label"
                    filter 
                    placeholder="Select Member"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}

                  />
            </div>
          </div>
          <div className="col-md-6 ">
            <label className="labels py-2">Punch List Manager :</label>
            <div className="card flex justify-content-center  ">
            <MultiSelect
            name="punchItemManager"
            value={filterData?.punchItemManager}
            onChange={inpChange}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))} optionLabel="label"
                    filter 
                    placeholder="Select Manager"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}

                  />
              {/* <Dropdown
                value={filterData?.punchItemManager}
                onChange={inpChange}
                name="punchItemManager"
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                placeholder="Punch List Manager "
              /> */}
            </div>
          </div>
          {/* <div className="col-md-6">
            <label className="labels py-2">Status :</label>
            <div className="card flex justify-content-center  ">
              <Dropdown
                value={filterData?.status}
                onChange={inpChange}
                options={status_optionsP}
                name="status"
                optionLabel="label"
                placeholder="Status"
              />
            </div>
          </div> */}

          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <Button className="cancel_btn" onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button className="Apply_filter_btn" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
