import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { DataTable } from "primereact/datatable"; 
import { Column } from "primereact/column";
import { RxCross2 } from "react-icons/rx";
import { Dialog } from "primereact/dialog";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import SingleSelect from "../../../../../../Components/Common/SingleSelect";
import { PrimaryButton } from "../../../../../../Components/Buttons/PrimaryButton";
import { BorderedTable } from "../../../../../../Components/Tables/BorderedTable";
import VerticalMarkup_Subcontract from "./VerticalMarkup_Subcontract";


function FinancialMarkup_Subcontract() {
  const [visible, setVisible] = useState(false);

  const datavalue = [
    {
      Name: "Profit",
      Percentage: "5.00%",
      Compounding: "Off",
      Maps_to: "02-172 Profit",
      cost_types:
        "Labor, Equipment, Materials, Commitments, Owner Cost, Professional Services, Others",
    },
  ];

  const centeredHeaderTemplate = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">Add Horizontal Markup</span>

      <span className=" " onClick={() => setVisible(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  return (
    <div>
      <div>
        <div className="d-flex mt-4 align-items-center justify-content-between">
          <h5>Horizontal Markups:</h5>
          <div className="d-flex gap-2 align-items-center">
            <div
              className="d-flex gap-2 align-items-center add_icon_text"
              style={{ cursor: "pointer" }}
              onClick={() => setVisible(true)}
            >
              <IoMdAdd size={15} />
              Add Horizontal Markup
            </div>
          </div>
        </div>

        <BorderedTable>
          <DataTable className="mt-3" tableStyle={{ minWidth: "50rem" }}>
            <Column
              field="#"
              header="#"
              body={(rowData, rowIndex) => (
                <div>
                  {rowIndex.rowIndex < 9 ? "0" : ""}
                  {rowIndex.rowIndex + 1}
                </div>
              )}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{
                minWidth: "8rem",
              }}
            ></Column>
            <Column field="category" header="Percentage"></Column>
            <Column field="quantity" header="Compounding"></Column>
            <Column field="quantity" header="Maps to"></Column>
            <Column field="quantity" header="Applies to (cost types)"></Column>
          </DataTable>
        </BorderedTable>
      </div>

      <Dialog
        style={{ width: "40%" }}
        visible={visible}
        closable={false}
        draggable={false}
        onHide={() => setVisible(false)}
        header={centeredHeaderTemplate}
      >
        <div className="col-md-12">
          <label htmlFor="" className="py-2 labelCss">
            Markup Name
          </label>
          <TextInput
            placeholder="Enter Markup Name"
            name="markupName"
            className={""}
          ></TextInput>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Markup Percentage
            </label>
            <TextInput
              placeholder="Enter Markup Percentage"
              name="markupPercentage"
              className=""
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Compounding
            </label>
            <SingleSelect
              placeholder="Select Compounding"
              name="compounding"
              className=""
            />
          </div>
        </div>

        <div className="col-md-12">
          <label htmlFor="" className="py-2 labelCss">
            Maps To (budget code):
          </label>
          <SingleSelect
            placeholder="Select Budget Code"
            name="budgetCode"
            className={""}
          ></SingleSelect>
        </div>

        <div className="col-md-12">
          <label htmlFor="" className="py-2 labelCss">
            Applies To (cost code):
          </label>
          <SingleSelect
            placeholder="Select Cost Code"
            name="costCode"
            className={""}
          ></SingleSelect>
        </div>

        <div className="end mt-5 gap-2">
          <button
            className="btn border"
            onClick={() => setVisible(false)}
            type="button"
          >
            Cancel
          </button>
          <PrimaryButton addIcon type="submit" text={"Add Markup"} />
        </div>
      </Dialog>

      <VerticalMarkup_Subcontract />
    </div>
  );
}

export default FinancialMarkup_Subcontract;
