import React from 'react';
import Chart from 'react-apexcharts';

export const VerticalBarChart = ({ value, valueKey }) => {
    const series = [
        {
            name: 'Total Budget',
            group: 'budget',
            data: value.map(e => e[valueKey || "value"]) || []
        },


    ];
    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                columnWidth: '60px',
            }
        },
        xaxis: {
            categories: value.map(e => e.name) || [],
        },
        fill: {
            opacity: 1
        },
        colors: ['#68c9eb', '#ffd56d'],
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val / 1000 + ' £'
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => {
                return val / 1000 + ' £'
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    dataLabels: {
                        enabled: false,
                    },
                }

            }
        ],

    };


    return (
        <Chart
            options={chartOptions}
            series={series}
            type="bar"
            height={400}
        />
    )
}
