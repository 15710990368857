import { Legend } from 'chart.js';
import React from 'react';
import Chart from 'react-apexcharts';

function BudgetSummary({overViewGetData}) {
  // Configuration for the chart (options and series data)
  console.log(Number(overViewGetData))
  const chartConfig = {

    series: [{
      name: 'Population',
      data: [Number(overViewGetData?overViewGetData:0)]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 400,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: false,       
      },
      colors: ["#fa9538"],
      xaxis: {
        categories: [
          'Estimate Project'
        ]
      },
      legend: {
        show: false
      }
    }
  };

  return (
    <div>
      <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="bar"
       
      />
    </div>
  );
}

export default BudgetSummary;
