import React, { useEffect, useState } from 'react'
import { getProject } from '../../Api/Admin/AllAPIs';
import Chart from "react-apexcharts";
import FormSkeleton from '../../Components/Common/FormSkeleton';

export default function ProjectScheduleView() {
    const [idStore, setIdStore] = useState();
    const [skeletonLoader, setSkeletonLoader] = useState(true)
    const [options] = useState({
        chart: {
            type: 'rangeBar',
            distributed: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '50%',
                distributed: true,
                borderRadius: 15,
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            x: {
                format: 'dd MMM',
                // format: 'dd MMM HH:mm',
            },
        },
        dataLabels: {
            enabled: false,
        },
    });
    const [series, setSeries] = useState([
        { data: [] }
    ])
    const skeletonData = [
        { qty: 1 },
        { qty: 1 },
        { qty: 1 },
        { qty: 1 },
    ]
    useEffect(() => {
        if (idStore) {
            getProjectes();
        }
    }, [idStore]);

    const getProjectes = async () => {
        try {
            setSkeletonLoader(true);
            const res = await getProject(idStore);
            const getData = res?.data?.data[0];
            if (getData && getData?.project_stages?.length !== 0) {
                const transformedData = getData?.project_stages?.map(item => ({
                    x: item.stage_name,
                    y: [
                        new Date(item.expected_start_date).getTime(),
                        new Date(item.expected_end_date).getTime()
                    ]
                }));
                console.log(transformedData)
                setSeries([{
                    data: transformedData
                }])

            } else {
                // setIsVersionHistory(false)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setSkeletonLoader(false);
        }
    };

    useEffect(() => {
        const projectIdFromLocalStorage = localStorage.getItem("projectId");
        if (projectIdFromLocalStorage) {
            setIdStore(projectIdFromLocalStorage);
        }
    }, []);


    return (
        <>
            <div className="py-3">
                {skeletonLoader ? <FormSkeleton data={skeletonData} /> : series[0]?.data !== undefined && series[0]?.data?.length !== 0 ? <Chart options={options} series={series} type="rangeBar" height={400} /> : <div>No data available</div>}

            </div>
        </>
    )
}
