
import React, { useState } from "react";
import './common.css'
import { Dialog } from 'primereact/dialog';

export default function Modal({ children, open, setOpen, width, draggable }) {


    return (
        <div className="card flex justify-content-center custom_modal">
            <Dialog
                visible={open}
                style={{ minWidth: '30vw', width: width ? width : "auto" }}
                onHide={() => setOpen(false)}
                draggable={draggable}
                breakpoints={{ '1200px': '75vw','960px': '75vw', '641px': '90vw' }}
            >
                {children}
            </Dialog>
        </div>
    )
}
