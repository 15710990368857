import React from 'react'

export default function FinancialManagement() {
  return (
    <>
    
    <div className='mainContainer'>FinancialManagement</div>
    </>
  )
}
