import React, { useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TabPanel, TabView } from "primereact/tabview";
import { Outlet, useNavigate } from "react-router-dom";
import Popover from "../../../../../../Components/Common/Popover";
import NewPayment_Purchase from "./NewPayment_Purchase";

function ViewPurchaseOrder() {
  const [activeStep, setActiveStep] = useState(0);
  let navigate = useNavigate();

  const steps = [
    {
      label: "General Details",
      path: "/financialManagement/commitment/ViewPurchaseOrder/generalDetail",
    },
    {
      label: "Schedule of Value",
      path: "/financialManagement/commitment/ViewPurchaseOrder/scheduleValue",
    },
    {
      label: "Change Orders",
      path: "/financialManagement/commitment/ViewPurchaseOrder/changeOrder",
    },
    {
      label: "Invoices",
    //   path: "/financialManagement/commitment/subcontractor",
    },
    {
      label: "Payment Received",
      path: "/financialManagement/commitment/ViewPurchaseOrder/paymentRecieve",
    },
    {
      label: "Financial Markups",
      path: "/financialManagement/commitment/ViewPurchaseOrder/financialMarkup",
    },
  ];
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1>#02 Instructions</h1>
        <div className="d-flex gap-2">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <AiOutlineExport />
            Export
            <MdOutlineKeyboardArrowDown />
          </div>
          <div className="flex justify-content-center">
            {activeStep === 4 && <NewPayment_Purchase/>}
            <Popover
              options={["Edit", "delete"]}
              onSelect={(index, event) => {
                if (index === 0)
                  // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                  console.log(index);
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <TabView
          activeIndex={activeStep}
          onTabChange={(e) => {
            setActiveStep(e.index);
            navigate(steps[e.index].path);
          }}
        >
          {steps.map((res, key) => (
            <TabPanel key={key} header={res.label} />
          ))}
        </TabView>
        <Outlet />
      </div>
    </div>
  );
}

export default ViewPurchaseOrder;
