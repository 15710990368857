import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../../../Components/Inputs/SearchInput'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Filter from '../../../DailyLog/Filter';
import { BorderedTable } from '../../../../Components/Tables/BorderedTable';
import Popover from '../../../../Components/Common/Popover';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Statuscomp } from '../../../../Components/Common/StatusComp';

const PrimeContract = () => {
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [skeletonLoader, setSkeletonLoader]= useState(false);
    const navigate = useNavigate();

    const demodata = [
        {
          id: "PC001",
          title: "Finishing on Concreate Floor",
          Prime_Contract: "04-Temperary",
          status: "draft",
          initiated_on : "22/03/2024",
          dueDate: "24/03/2024",
          reviewDate: "26/03/2024",
          amount: "$ 25000.00",
         
        },
        {
          id: "PC002",
          title: "Change Orders for April",
          Prime_Contract: "04-Temperary",
          status: "void",
          initiated_on : "25/03/2024",
          dueDate: "28/03/2024",
          reviewDate: "30/03/2024",
          amount: "$ 29000.00",
        },
        {
          id: "PC003",
          title: "Ceramic and Stone Tiling",
          Prime_Contract: "04-Temperary",
          status: "rejected",
          initiated_on : "22/03/2024",
          dueDate: "24/03/2024",
          reviewDate: "26/03/2024",
          amount: "$ 30000.00",
        },
        {
          id: "PC004",
          title: "Wood doors and windows",
          Prime_Contract: "04-Temperary",
          status: "approved",
          initiated_on : "22/03/2024",
          dueDate: "24/03/2024",
          reviewDate: "26/03/2024",
          amount: "$ 35000.00",
        },
        
      ];

      const fetchdata=async()=>{
        try{
          setSkeletonLoader(true);

        }
        catch(error){
          console.log(error,"error loading data")
        }
        finally{
          setSkeletonLoader(false)
        }
       
      }

      // useEffect(()=>{
      //   fetchdata();
      // })

    function onFilter() {
       
    }
    function clearFilter(){

    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Grand Total:" colSpan={2} footerStyle={{ textAlign: 'center' }}  />
                <Column footer={''} />
                <Column footer={""} />
                <Column footer={""} />
                <Column footer={""} />
                <Column footer={""} />
                <Column footer={""} />
                <Column footer={(rowData, meta) => (
                    <div className="flex justify-content-center">
                        <Popover
                            options={["Edit", "delete"]}
                            onSelect={(index, event) => {
                                if (index === 0)
                                    // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                                  console.log(index);
                            }}
                        />
                    </div>
                )} />
            </Row>
        </ColumnGroup>
    );
    
  return (
    <>
     <div className="row mt-3">
        <div className='col-md-3 SearchInputC'>
                <SearchInput onChange={(e) => setSearch(e.target.value)} value={search} onCancel={() => setSearch("")}/>
        </div>
    
    <div className="col-md-9 d-flex justify-content-end  ">
       
        <button  className="btn btn-secondary " onClick={clearFilter}>Filter</button>
        <span className="clearAll ms-3">Clear All</span>
    </div>
    </div>
   
    <br/>
    <div>

    <BorderedTable>
        <DataTable
          scrollable
          scrollHeight="flex"
          value={demodata}
          footerColumnGroup={footerGroup}
          tableStyle={{ minWidth: "100rem" }}>
            <Column header="#" field="id" style={{ width: "5rem" }}/>
            <Column header="Title" field="title" />
            <Column header="Prime Contact" field='Prime_Contract'/>
            <Column
            field="status"
            header="Status"
            style={{ width: "5rem" }}
            body={(rowData) => (
              <div className="d-flex justify-content-center">
                {rowData?.status?.toLowerCase() === "draft" ? (
                  <Statuscomp className={"draft_status"} />
                ) : rowData?.status?.toLowerCase() === "void" ? (
                  <Statuscomp className={"void"} />
                ) : rowData?.status?.toLowerCase() === "rejected" ? (
                  <Statuscomp className={"rejected"} />
                ) : rowData?.status?.toLowerCase() === "approved" ? (
                  <Statuscomp className={"approved"} />
                ) : null}
              </div>
            )}
          ></Column>
            <Column header="Initaiated On" field='initiated_on'/>
            <Column header="Due Date" field='dueDate'/>
            <Column header="Review Date" field='reviewDate'/>
            <Column header="Amount" field='amount'/>
            <Column 
            header="" 
            field=""
            body = {(rowData, meta)=>(
                <div className='d-flex justify-content-center align-items-center'>
                    <Popover
                        options={["Edit", "Delete", "View"]}
                        onSelect={(index, event)=>{
                            if(index=== 0)
                            {
                                console.log(index)
                            }
                            if(index === 1){}
                            if(index === 2){
                              navigate("PCCOView")
                            }
                        }}/>
                </div>
            )

            } />

        </DataTable>
        </BorderedTable>
    </div>
    {/* <div className='d-flex justify-content-between grand_total_contaier mt-3'>
        <div>Grand Total</div>
    </div> */}
    </>
  )
}

export default PrimeContract