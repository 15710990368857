import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleByClick, toggleByValue } from "../../Store/Slice/CommonSlice";
import { TopBar } from "./TopBar"; // Adjust the import path
import AdminProject from "../Roles/RoleOne/AdminProject"
import AdminWhole from "../Roles/RoleOne/AdminWhole"
import { UserProfile } from "../Common/PagesComponents/UserProfile";
import { getUserEmail, getUserName, getUserOuterRoe } from "../../utils";
// import TopBar from "../TopBar/TopBar";

export function Sidebar({ layer, children, role }) {
    const drawerWidth = "224px";
    const [open, setOpen] = useState(true);
    const [count, setCount] = useState(0);
    const [xs, setXs] = useState(false);

    let layer1 = layer ? layer : "project"
    let role1 = role ? role : "admin"

    console.log("sidenav sidenav sidenav rerererererer")

    const roleComponents = {
        adminWhole: AdminWhole,
        adminProject: AdminProject,
    };

    const roleLayerKey = `${role1}${layer1?.charAt(0)?.toUpperCase() + layer1?.slice(1)}`;
    const RoleComponent = roleComponents[roleLayerKey];


    const openToggle = useSelector((state) => state.ToggleButtonState);
    const dispatch = useDispatch();

    function toggleOpen() {
        dispatch(toggleByClick());
    }

    function toggleFalse(val) {
        dispatch(toggleByValue(val));
    }


    useEffect(() => {
        if (layer == 'whole') {
            localStorage.removeItem('projectId')
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 800) {
                setXs(true);
                toggleFalse(false);
                setOpen(false);
            } else {
                setXs(false);
                toggleFalse(true);
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    useEffect(() => {
        if (window.innerWidth <= 800) {
            setXs(true);
            toggleFalse(false);
            if (count === 0) {
                setOpen(false);
                setCount(1);
            }
        } else {
            setXs(false);
            setCount(0);
            toggleFalse(true);
        }
    }, [count, dispatch]);

    const drawerThemeOpen = {
        width: drawerWidth,
        padding: "21px 14px 0 14px",
    };

    const drawerThemeOpenMobile = {
        width: drawerWidth,
        padding: "21px 14px 0 14px",
    };

    const drawerThemeClose = {
        width: "84px",
        padding: "21px 20px 0 15px",
    };

    const drawerThemeCloseMobile = {
        width: "0",
        padding: "21px 0 0 0",
    };

    return (
        <div className="w-100 d-flex">
            <div
                id="drawer"
                className={`${xs ? "mobile-effect drawer2" : "drawer"} sidebar_dard_container`}
                style={
                    xs
                        ? openToggle
                            ? drawerThemeOpenMobile
                            : drawerThemeCloseMobile
                        : openToggle
                            ? drawerThemeOpen
                            : drawerThemeClose
                }
            >
                <MainNavBar />
            </div>
            <div
                className={`${openToggle ? "content" : "content2"}`}
                style={{
                    width: xs ? "100%" : openToggle ? `calc(100% - ${drawerWidth})` : `calc(100% - 84px)`,
                }}
            >
                {xs && openToggle && <div className="bg-transparent-dark" onClick={toggleOpen}></div>}
                <div className="childrenContent">
                    {/* <TopBar /> */}
                    <TopBar layer={layer} roleLayerKey={roleLayerKey} />
                    <div className="child_main_container">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );

    function MainNavBar() {
        return (
            <div className="main-nav-container">
                {xs && (
                    <div className="pb-2 pt-1 d-flex justify-content-end sticky-top">
                        <img
                            src="/images/toggle-left.png"
                            alt="Close Sidebar"
                            onClick={toggleOpen}
                        />
                    </div>
                )}
                <div className="logo pb-2" style={{ zIndex: "999999", backgroundColor: "#ECECEC" }}>
                    {!xs && (
                        openToggle ? (
                            <>
                                {/* <img src="/images/cdc_logo.png" width='35' /> */}

                                <img src="/images/cdclogo.svg" alt="" width={45} />
                                <div className="mainLogo_text">
                                    <div className="mainLogo_text_one">BRICK</div>
                                    <div className="mainLogo_text_two">JOURNEY</div>

                                </div>
                            </>
                        ) : (
                            <img src="/images/cdclogo.svg" alt="" width={40} />
                        )
                    )}
                </div>
                <UserProfile userEmail={getUserEmail()} isSidebarOpen={openToggle} userName={getUserName()} userRole={getUserOuterRoe()} />
                <div className={`sidebar_content_container mt-md-4 mt-sm-2 mt-2`}>
                    {RoleComponent && <RoleComponent isSidebarOpen={openToggle} opentoggle={openToggle} />}
                </div>
            </div>
        );
    }
}


