import React, { useEffect, useRef, useState } from 'react'
import "./pagesComponents.css"
import { BiEdit } from "react-icons/bi";
import { getUserId, getUserRoleId, getUserType, toast } from '../../../utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FaRegUser } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { cleanRolesAndPermissions } from '../../../Store/Slice/RolesAndPermissionsSlice';
import { useNavigate } from 'react-router-dom';
import { updateUser, uploadDocs } from '../../../Api/Admin/AllAPIs';
export const UserProfile = ({ userName, userRole, userEmail, isSidebarOpen }) => {
    const op = useRef(null);
    let dispatch = useDispatch()

    let [profileImage, setProfileImage] = useState("")

    let profileUrl = null
    let userData = localStorage.getItem("userData")
    if (userData) {
        userData = JSON.parse(userData)
    }

    if (userData?.user_profile?.[0]?.fileUrl) {
        profileUrl = userData?.user_profile?.[0]?.fileUrl
    }

    useEffect(() => {
        if (profileUrl) {
            setProfileImage(profileUrl)
        }
    }, [])



    async function handleInput(event) {
        let file = event.target.files[0]

        if (getUserType() === "admin") {
            return
        }

        const fileType = file.type;
        if (!fileType.startsWith('image/')) {
            toast.error("Only Image is Acceptable !")
            return
        }

        let oldImagesUrl = userData?.user_profile?.map(e => e.fileName)

        try {
            if (file) {
                const formData = new FormData();
                formData.append("upload", file);
                let uploadResponse = await uploadDocs(formData);
                console.log(uploadResponse.data)

                let payload = {
                    user_profile: uploadResponse.data,
                    deleted_file: oldImagesUrl
                }
                console.log(payload)
                await updateUser(getUserId(), payload);

                setProfileImage(uploadResponse?.data?.[0]?.fileUrl)

                let localUseData = userData;
                localUseData.user_profile = uploadResponse.data;
                localStorage.setItem("userData", JSON.stringify(localUseData));
                console.log("user uploaded successfully")
            }
        }
        catch (err) {
            console.log(err)
        }


    }


    let navigate = useNavigate()

    return (
        <div className='userProfile_container'>

            <input type='file' hidden id="userProfileInput" disabled={getUserType() === "admin"} onChange={(e) => handleInput(e)} />

            {isSidebarOpen ?
                <div>
                    <div className='userImage_con'>
                        <div className='userProfile_img'
                        >
                            {profileImage && <img src={profileImage} className='w-100 h-100' />}
                        </div>
                        <div className='userImage_dotted_border_img'></div>
                        <div className='userImage_edit_con' onClick={(e) => op.current.toggle(e)}>
                            <BiEdit />
                        </div>
                    </div>
                    <div className='userProfile_userName2 d-flex justify-content-center align-items-center'>
                        <div>{userName}</div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 15" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.66309 4.63427C2.66309 4.17107 2.93587 3.75132 3.35915 3.5632L7.46146 1.73994C7.76457 1.60524 8.11045 1.60524 8.41355 1.73994L12.5159 3.5632C12.9391 3.75132 13.2119 4.17107 13.2119 4.63427V8.56095C13.2119 12.0049 9.49458 13.7968 8.30144 14.2831C8.06662 14.3789 7.80846 14.3789 7.57357 14.2831C6.38045 13.7968 2.66309 12.0049 2.66309 8.56095V4.63427ZM10.5747 5.92372C10.7464 6.09536 10.7464 6.37367 10.5747 6.54531L7.66224 9.45778C7.49059 9.62943 7.21233 9.62943 7.04068 9.45778L5.59332 8.01042C5.42167 7.83877 5.42167 7.56051 5.59332 7.38886C5.76497 7.2172 6.04327 7.2172 6.21492 7.38886L7.35146 8.52538L9.9531 5.92372C10.1248 5.75207 10.4031 5.75207 10.5747 5.92372Z" fill="#3DA864" />
                            </svg>
                        </div>
                    </div>
                    <div className='useProfile_userRole text-center'>{getUserType() === "admin" ? "Admin" : userRole}</div>

                </div> :
                <div>
                    <div onClick={(e) => op.current.toggle(e)} className='pointer'><FaRegUser /></div>
                </div>
            }

            <OverlayPanel ref={op} position="right">
                <div className='userProfile_container px-5'>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='userImage_con'>
                            <div className='userProfile_img'>
                                {profileImage && <img src={profileImage} className='w-100 h-100' />}
                            </div>
                            {/* <div className='userImage_dotted_border_img'></div> */}
                            <div className='userImage_edit_con2'>
                                <label htmlFor='userProfileInput'>
                                    <BiEdit />
                                </label>
                            </div>
                        </div>
                        <div className='userProfile_userName text-center'>{userName}    </div>
                        <div className='userProfile_userEmail text-center'>{userEmail}</div>
                        <div className='useProfile_userRole text-center'>{getUserType() === "admin" ? "Admin" : userRole}</div>
                        <div className="userProfile_logoutBtn pointer" onClick={() => {
                            dispatch(cleanRolesAndPermissions())
                            navigate("/")
                        }}>Logout</div>
                    </div>
                </div>
            </OverlayPanel>


        </div >
    )
}
