import { Column } from 'primereact/column'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { TableSkelton } from '../../Components/Common/TableSkelton';
import { getProjectHistory } from '../../Api/Admin/AllAPIs';
import moment from 'moment';
import Pagination from '../../Components/Common/Pagination/Pagination';

function ProjectVersionHistory() {
  const navigate = useNavigate();
  const [skeletonLoader, setSkeletonLoader] = useState(false)
  const { heading, name, id } = useParams()
  const projectId = localStorage.getItem('projectId')
  const [version, setVersion] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPageData = 10;


  const fetchVersion = async () => {
   
    try {
      setSkeletonLoader(true);
      let res = await getProjectHistory(projectId)
      console.log(res)
      setVersion(res?.data?.data?.reverse());
      // setTotalRecords(res?.data?.data?.length() || 0);
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchVersion()
  }, [])

  const keyNameTemplate = (rowData) => {
    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2"
            key={index}
          >
            {/* {change.keyName} */}
          </div>
        ))}
      </div>
    );
  };

  const preValTemplate = (rowData) => {
    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2"
            key={index}
          >
            {/* {change.preVal} */}
          </div>
        ))}
      </div>
    );
  };

  // Function to render the newVal
  const newValTemplate = (rowData) => {
    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2"
            key={index}
          >
            {/* {change.newVal} */}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <button className='btn border' onClick={()=>navigate(-1)}>Back</button>
      <div className="drawingTable d-flex flex-column gap-3 mt-3">
        <h2>Changes History</h2>
        {
          skeletonLoader ?
            <TableSkelton header={["Version", "Date & time", "Change In", "Changed by"]} />
            :
            <BorderedTable>
              <DataTable
                value={version}
              //   footer={customFooter}
              >
                <Column
                  field="#"
                  header="Version"
                  headerStyle={{ width: "4rem", fontWeight: "bold" }}
                body={(rowData, rowIndex) => (
                  <div className="d-flex justify-content-center">
                    {rowIndex?.rowIndex + 1}
                  </div>
                )}
                ></Column>


                <Column headerStyle={{ fontWeight: "bold" }}
                  field="date"
                  header="Date & time"
                body={(rowData) => moment(rowData?.date)?.format('DD-MM-YYYY h:mm a')}
                ></Column>
                

                <Column headerStyle={{ fontWeight: "bold" }}
                  field="stageName"
                  header="Change In"
                // body={keyNameTemplate}

                />
                <Column headerStyle={{ fontWeight: "bold" }}
                  field="user_name"
                  header="Changed By"
                // body={preValTemplate}
                // style={{margin:"10px 0"}}
                />
              
              </DataTable>
            </BorderedTable>}
            {/* <Pagination
          currentPage={page}
          totalCount={totalRecords}
          pageSize={perPageData}
          onPageChange={(page) => {
            console.log(page);
            setPage(page);
          }}
        /> */}
      </div>
    </>
  )
}

export default ProjectVersionHistory
