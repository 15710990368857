   export const currency=[
    { "name": "US Dollar", "code": "USD" },
    { "name": "Canadian Dollar", "code": "CAD" },
    { "name": "Euro", "code": "EUR" },
    { "name": "United Arab Emirates Dirham", "code": "AED" },
    { "name": "Afghan Afghani", "code": "AFN" },
    { "name": "Albanian Lek", "code": "ALL" },
    { "name": "Armenian Dram", "code": "AMD" },
    { "name": "Argentine Peso", "code": "ARS" },
    { "name": "Australian Dollar", "code": "AUD" },
    { "name": "Azerbaijani Manat", "code": "AZN" },
    { "name": "Bosnia-Herzegovina Convertible Mark", "code": "BAM" },
    { "name": "Bangladeshi Taka", "code": "BDT" },
    { "name": "Bulgarian Lev", "code": "BGN" },
    { "name": "Bahraini Dinar", "code": "BHD" },
    { "name": "Burundian Franc", "code": "BIF" },
    { "name": "Brunei Dollar", "code": "BND" },
    { "name": "British pound sterling", "code": "GBP" },
    { "name": "Bolivian Boliviano", "code": "BOB" },
    { "name": "Bosnia...", "code": "BAM" },
    { "name": "Brazilian Real", "code": "BRL" },
    { "name": "Bahamian Dollar", "code": "BSD" },
    { "name": "Botswana Pula", "code": "BWP" },
    { "name": "Belarusian Ruble", "code": "BYN" },
    { "name": "Belize Dollar", "code": "BZD" },
    { "name": "Congolese Franc", "code": "CDF" },
    { "name": "Wester Dollar", "code": "CDF" },
    { "name": "Swiss Franc", "code": "CHF" },
    { "name": "Chinese Yuan", "code": "CNY" },
    { "name": "Colombian Peso", "code": "COP" },
    { "name": "Costa Rican Colón", "code": "CRC" },
    { "name": "Cuban Peso", "code": "CUP" },
    { "name": "Cape Verdean Escudo", "code": "CVE" },
    { "name": "Czech Koruna", "code": "CZK" },
    { "name": "Djiboutian Franc", "code": "DJF" },
    { "name": "Danish Krone", "code": "DKK" },
    { "name": "Dominican Peso", "code": "DOP" },
    { "name": "Algerian Dinar", "code": "DZD" },
    { "name": "Estonian Kroon", "code": "EEK" },
    { "name": "Egyptian Pound", "code": "EGP" },
    { "name": "Ethiopian Birr", "code": "ETB" },
    { "name": "Fiji Dollar", "code": "FJD" },
    { "name": "Falkland Islands Pound", "code": "FKP" },
    { "name": "Gibraltar Pound", "code": "GIP" },
    { "name": "Ghanaian Cedi", "code": "GHS" },
    { "name": "Gambian Dalasi", "code": "GMD" },
    { "name": "Guinean Franc", "code": "GNF" },
    { "name": "Equatorial Guinean Franc", "code": "XAF" },
    { "name": "Guyanese Dollar", "code": "GYD" },
    { "name": "Hong Kong Dollar", "code": "HKD" },
    { "name": "Honduran Lempira", "code": "HNL" },
    { "name": "Hungarian Forint", "code": "HUF" },
    { "name": "Icelandic Króna", "code": "ISK" },
    { "name": "Indian Rupee", "code": "INR" },
    { "name": "Indonesian Rupiah", "code": "IDR" },
    { "name": "Iranian Rial", "code": "IRR" },
    { "name": "Iraqi Dinar", "code": "IQD" },
    { "name": "Israeli New Shekel", "code": "ILS" },
    { "name": "Jamaican Dollar", "code": "JMD" },
    { "name": "Jordanian Dinar", "code": "JOD" },
    { "name": "Japanese Yen", "code": "JPY" },
    { "name": "Kenyan Shilling", "code": "KES" },
    { "name": "Kyrgyzstani Som", "code": "KGS" },
    { "name": "Cambodian Riel", "code": "KHR" },
    { "name": "Comorian Franc", "code": "KMF" },
    { "name": "North Korean Won", "code": "KPW" },
    { "name": "South Korean Won", "code": "KRW" },
    { "name": "Kuwaiti Dinar", "code": "KWD" },
    { "name": "Kazakhstani Tenge", "code": "KZT" },
    { "name": "Lao Kip", "code": "LAK" },
    { "name": "Lebanese Pound", "code": "LBP" },
    { "name": "Sri Lankan Rupee", "code": "LKR" },
    { "name": "Liberian Dollar", "code": "LRD" },
    { "name": "Lesotho Loti", "code": "LSL" },
    { "name": "Lithuanian Litas", "code": "LTL" },
    { "name": "Luxembourgish Franc", "code": "LUF" },
    { "name": "Moroccan Dirham", "code": "MAD" },
    { "name": "Moldovan Leu", "code": "MDL" },
    { "name": "Malagasy Ariary", "code": "MGA" },
    { "name": "Macedonian Denar", "code": "MKD" },
    { "name": "Myanmar Kyat", "code": "MMK" },
    { "name": "Mongolian Tugrik", "code": "MNT" },
    { "name": "Mauritanian Ouguiya", "code": "MRU" },
    { "name": "Mauritian Rupee", "code": "MUR" },
    { "name": "Malawian Kwacha", "code": "MWK" },
    { "name": "Mexican Peso", "code": "MXN" },
    { "name": "Malaysian Ringgit", "code": "MYR" },
    { "name": "Mozambican Metical", "code": "MZN" },
    { "name": "Namibian Dollar", "code": "NAD" },
    { "name": "Nigerian Naira", "code": "NGN" },
    { "name": "Nicaraguan Córdoba", "code": "NIO" },
    { "name": "Norwegian Krone", "code": "NOK" },
    { "name": "Nepalese Rupee", "code": "NPR" },
    { "name": "New Zealand Dollar", "code": "NZD" },
    { "name": "Omani Rial", "code": "OMR" },
    { "name": "Panamanian Balboa", "code": "PAB" },
    { "name": "Peruvian Nuevo Sol", "code": "PEN" },
    { "name": "Papua New Guinean Kina", "code": "PGK" },
    { "name": "Philippine Peso", "code": "PHP" },
    { "name": "Pakistani Rupee", "code": "PKR" },
    { "name": "Polish Zloty", "code": "PLN" },
    { "name": "Portuguese Escudo", "code": "PTE" },
    { "name": "Qatari Rial", "code": "QAR" },
    { "name": "Romanian Leu", "code": "RON" },
    { "name": "Russian Ruble", "code": "RUB" },
    { "name": "Rwandan Franc", "code": "RWF" },
    { "name": "Saudi Riyal", "code": "SAR" },
    { "name": "Solomon Islands Dollar", "code": "SBD" },
    { "name": "Seychellois Rupee", "code": "SCR" },
    { "name": "Sierra Leonean Leone", "code": "SLL" },
    { "name": "Somali Shilling", "code": "SOS" },
    { "name": "South African Rand", "code": "ZAR" },
    { "name": "Sudanese Pound", "code": "SDG" },
    { "name": "Surinamese Dollar", "code": "SRD" },
    { "name": "Swedish Krona", "code": "SEK" },
    { "name": "Swiss Franc", "code": "CHF" },
    { "name": "Syrian Pound", "code": "SYP" },
    { "name": "Tajikistani Somoni", "code": "TJS" },
    { "name": "Thai Baht", "code": "THB" },
    { "name": "Tunisian Dinar", "code": "TND" },
    { "name": "Turkish Lira", "code": "TRY" },
    { "name": "Trinidad and Tobago Dollar", "code": "TTD" },
    { "name": "Tanzanian Shilling", "code": "TZS" },
    { "name": "Ukrainian Hryvnia", "code": "UAH" },
    { "name": "Uruguayan Peso", "code": "UYU" },
    { "name": "Uzbekistani Som", "code": "UZS" },
    { "name": "Vanuatu Vatu", "code": "VUV" },
    { "name": "Venezuelan Bolívar", "code": "VES" },
    { "name": "Vietnamese Dong", "code": "VND" },
    { "name": "West African CFA Franc", "code": "XOF" },
    { "name": "Central African CFA Franc", "code": "XAF" },
    { "name": "East Caribbean Dollar", "code": "XCD" },
    { "name": "Special Drawing Rights", "code": "XDR" },
    { "name": "Yemeni Rial", "code": "YER" },
    { "name": "Zambian Kwacha", "code": "ZMK" },
    { "name": "Zimbabwean Dollar", "code": "ZWL" }
  ]
  