import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { changePassword, checkUserToeknIsValid } from '../../Api/Admin/AllAPIs';
import { toast } from '../../utils';
import Loader from '../../Components/Common/Loader';

export const ResetForgotPassword = () => {
    let navigate = useNavigate();
    let token = new URLSearchParams(window.location.search).get("token");
    const [linkExpired, setLinkExpired] = useState(false)
    const [loader, setLoader] = useState(true)

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            password: "",
            confirmPassword: ""
        }
    });

    const password = watch("password");

    const onSubmit = async data => {
        try {
            console.log(data);
            let body = {
                token,
                password: data.password
            }
            let res = await changePassword(body)
            toast.success(res?.data?.message)
            navigate("/")
            console.log(res)

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (token) {
            chechTokenValidation()
        }
        else {
            navigate("/")
        }
    }, [])

    async function chechTokenValidation() {
        try {
            let res = await checkUserToeknIsValid({ token });
            if (res.data.data == true) { return }
            else {
                setLinkExpired(true)
                // navigate("/forgotPassword")
            }
        } catch (error) {
            // navigate("/forgotPassword")
            setLinkExpired(true)
            console.log(error)
        }
        finally {
            setLoader(false)
        }
    }



    return (
        <>
            {loader ? <Loader /> : 
            <div className="maincontainer">

                <div className="left-contain">
                    <div className="logoFixed">
                        <span className="eLogo d-flex align-items-center">
                            <img src="/images/cdc_logo.png" className="me-2" alt="" />
                            CDC
                        </span>
                    </div>
                </div>
                <div className="right-contain bg-white">
                    <div className="right-content-div ">

                        {linkExpired ?
                            <h3 className='text-center'>Link Expired !</h3> :

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex justify-content-center ">
                                    <span className="logIn">Reset Password</span>
                                </div>

                                <div className="mt-3">
                                    <label className="HelveticaMedium mb-1">Password</label>
                                    <input
                                        placeholder="Password"
                                        className="form-control"
                                        name="password"
                                        type="password"
                                        {...register('password', {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{15,}$/,
                                                message: 'Password must be at least 15 characters long and contain at least one uppercase letter, one number, and one special character'
                                            }
                                        })}
                                    />
                                    <div className="text-danger">
                                        {errors.password && <p>{errors.password.message}</p>}
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="HelveticaMedium mb-1">Confirm Password</label>
                                    <input
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        type="password"
                                        name="confirmPassword"
                                        {...register('confirmPassword', {
                                            required: 'Confirm Password is required',
                                            validate: value =>
                                                value === password || 'Passwords do not match'
                                        })}
                                    />
                                    <div className="text-danger">
                                        {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="continueButton w-100"
                                        type="submit"
                                        id="loginsubmit"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </form>
                        }
                        <br />
                        <div
                            style={{ color: "#FF7B00" }}
                            className="mt-2 mb-3 pointer w-100 text-center" onClick={() => navigate("/")}>
                            Back to Sign In
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
};
