import TextInput from "../../../Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { IoMdAdd } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import {
  AddSpecificationFormat,
  AddSpecificationLanguage,
  AddSpecificationName,
  createProjectVersionHistory,
  createSpecification,
  createSpecificationInProject,
  getCompanyByProjectId,
  getCountForSpecificationsByProjectId,
  getFormatByProjectId,
  getLanguageByProjectId,
  getNameByProjectId,
  getProject,
  getSpecificationByProjectId,
  getUserByProjectId,
  getUsersByCompanyId,
  specificatonDelete,
  updateSpecification,
  uploadDocs,
} from "../../../../Api/Admin/AllAPIs";
import { convertHourlySpentStringIntoObject, getUserId, toast } from "../../../../utils";
import FormSkeleton from "../../../Common/FormSkeleton";
import SevedModal from "../../../Common/SavedModal";
import AutoCompleteSelect from "../../../Common/AutoCompleteSelect";
import { MultiSelect } from "primereact/multiselect";
import TimeInput from "../../../Inputs/TimeSelector";
import { Editor } from "primereact/editor";
import moment from "moment";
import CustomDragAndDrop from "../../../Inputs/CustomDragAndDrop";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from "@mui/material";
import AddSetsDialog from "../AddSetsDialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ProjectSpecifications = ({ mode }) => {
  const navigate = useNavigate();
  const projectId = localStorage.getItem("projectId");
  const [idStore, setIdStore] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [sets, setSets] = useState([{
    setName: "",
    setDate: "",
    drawingDate: "",
    receivedDate: "",
    files: [],
    fileName: [],
    removePhoto: [],
    previousFile: [],
  }]);
  const [allData, setAllData] = useState([])
  const [formData, setFormData] = useState([])
  const [name, setName] = useState([])
  const [formats, setFormats] = useState();
  const [namevisible, setNameVisible] = useState(false)
  const [formatvisible, setFormatVisible] = useState(false)
  const [addName, setAddName] = useState()
  const [formatName, setFormatName] = useState()
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [languageName, setLanguageName] = useState('')
  const [languageData, setLanguageData] = useState([])
  const [languagevisible, setLanguagevisible] = useState(false)
  const [deleteDataIds, setDeleteDataIds] = useState([])
  const [deleteFile, setDeleteFile] = useState([])
  const [openAddModal, setOpenAddModal] = useState(false)
  let [btnLoader, setBtnLoader] = useState(false)
  const [singleData, setSingleData] = useState({
    set: '',
    format: '',
    spec_manager: [],
    specificationLanguage: '',
    defaultRevision: '',
    issuedDate: '',
    receivedDate: '',
    description: '',
    responsible_contractor_company: [],
    responsible_member: [],
    numberToIgnore: '',
    status: "draft",
    responsible_person: [],
    spec_id: 0,
    workflow_status: 'not started',
    attachFile: [],
    project_id: projectId,
    hours_spent: '',
    creator_email: getUserId(),
    fileForUpload: []
  })
  const [Validation, setValidation] = useState({
    title: false,
    format: false,
    specManager: false,
    language: false,
    spec_id: false,
    revision: false,
    noIgnore: false,
    issuedDate: false,
    receivedDate: false,
    description: false,
  });
  const [modalType, setModalType] = useState('add')
  const [loader, setLoader] = useState(false)
  const [deleteDataId, setDeleteDataId] = useState('')
  const [deleteDialog, setDeleteDialog] = useState(false)










  const [skeletonLoader, setSkeletonLoader] = useState(mode == "Edit" ? true : false)
  const [backVisible, setBackVisible] = useState(false)
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  function cleareData() {
    setDeleteFile([])
    setLoader(false)
    setSingleData({
      set: '',
      format: '',
      spec_manager: [],
      specificationLanguage: '',
      defaultRevision: '',
      issuedDate: '',
      receivedDate: '',
      description: '',
      responsible_contractor_company: [],
      responsible_member: [],
      numberToIgnore: '',
      status: "draft",
      responsible_person: [],
      spec_id: 0,
      workflow_status: 'not started',
      attachFile: [],
      project_id: projectId,
      hours_spent: '',
      creator_email: getUserId(),
      fileForUpload: []
    })
  }
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1 },
    { qty: 1 },

  ]
  const checkValidation = () => {
    if (!singleData?.set) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
    }
    if (!singleData?.format) {
      setValidation((prevState) => ({
        ...prevState,
        format: true,
      }));
    }
    if (singleData?.spec_manager?.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        specManager: true,
      }));
    }
    if (!singleData?.specificationLanguage) {
      setValidation((prevState) => ({
        ...prevState,
        language: true,
      }));
    }
    if (!singleData?.spec_id) {
      setValidation((prevState) => ({
        ...prevState,
        spec_id: true,
      }));
    }
    if (!singleData?.defaultRevision) {
      setValidation((prevState) => ({
        ...prevState,
        revision: true,
      }));
    }
    if (!singleData?.issuedDate) {
      setValidation((prevState) => ({
        ...prevState,
        issuedDate: true,
      }));
    }

    if (!singleData?.receivedDate) {
      setValidation((prevState) => ({
        ...prevState,
        receivedDate: true,
      }));
    }

    if (!singleData?.description) {
      setValidation((prevState) => ({
        ...prevState,
        description: true,
      }));
    }


    if (
      !singleData?.set ||
      !singleData?.format ||
      singleData?.spec_manager?.length == 0 ||
      !singleData?.specificationLanguage ||
      !singleData?.spec_id ||
      !singleData?.defaultRevision ||
      !singleData?.issuedDate ||
      !singleData?.receivedDate ||
      !singleData?.description
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);

  const addNewSet = () => {
    const newSet = {
      set: '',
      format: '',
      responsible_contractor_company: [],
      responsible_member: [],
      issuedDate: '',
      receivedDate: '',
      numberToIgnore: '',
      specificationLanguage: '',
      status: "draft",
      description: '',
      defaultRevision: '',
      responsible_person: [],
      spec_id: formData?.length + 1,
      workflow_status: 'not started',
      spec_manager: [],
      attachFile: [],
      project_id: projectId,
      hours_spent: '',
      creator_email: getUserId(),
    }
    setFormData((prevSets) => [...prevSets, newSet]);
  };

  const handleInputChange = (event, index, field) => {
    const value = event.target.value;
    const newSets = [...sets];
    newSets[index][field] = value;
    setSets(newSets);
  };


  const handleDeleteSet = (index, id) => {
    const newSets = [...formData];
    newSets.splice(index, 1);
    setFormData(newSets);
    if (id !== undefined) {
      setDeleteDataIds(pre => {
        return [...pre, id]
      })
    }
  };



  const handleAddSpecifications = async (btnName) => {
    // let data = [...formData];
    // if (checkValidationInData(data)) {
    //   return;
    // }

    // try {
    //   let update = [...data?.filter(res => res?._id && res?.dataForUpdate)]
    //   let add = data?.filter(res => !res?._id)
    //   let payload = {
    //     added: [],
    //     updated: [],
    //     deleted: deleteDataIds,

    //   }
    //   if (update?.length !== 0) {
    //     update?.map(res => delete res?.dataForUpdate)
    //     payload.updated = update
    //   } if (add?.length !== 0) {
    //     payload.added = add
    //   }
    //   console.log(payload)
    //   console.log(payload)

    //   return
    //   let res = await createSpecificationInProject(payload)
    // for (const [index, set] of sets.entries()) {
    //   let uploadResponse = {};
    //   if (selectedFiles) {
    //     const formData = new FormData();
    //     let URlImges = selectedFiles[index].filter((e) => e.fileUrl);
    //     for (const file of selectedFiles[index]) {
    //       if (!file.fileUrl) {
    //         formData.append("upload", file);
    //       }
    //     }
    //     uploadResponse = await uploadDocs(formData);

    //     set.files = [...URlImges, ...(uploadResponse.data || [])];


    //     payload.push({
    //       set_name: set.setName,
    //       set_date: set.setDate,
    //       default_issue_date: set.drawingDate,
    //       default_received_date: set.receivedDate,
    //       attach_specifications: [...URlImges, ...uploadResponse.data],
    //     });

    //   }
    // }


    // await addSpecifications({
    //   id: getProjectId(),
    //   project_specifications: payload,
    // });
    const userData = localStorage.getItem("userData");
    const userObj = JSON.parse(userData);
    const userName = userObj?.name;
    setBtnLoader(true)
    if (mode == "Edit") {
      await createProjectVersionHistory({
        stageName: 'Specification',
        user_name: userName,
        project_id: projectId
      })
    }
    setBtnLoader(false)
    setVisible(false)
    setVisible2(false)
    if (btnName !== "save") {
      navigate(
        mode === "Edit"
          ? "/coretools/admin/projectform/step-8"
          : "/portfolio/projectform/step-8"
      );
    } else {
      // navigate("/projects");
    }
    // } catch (error) {
    //   console.error("Error adding specifications:", error);
    // }
  };



  const getProjectes = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getProject(idStore);
      const getData = res?.data?.data[0];

      if (getData && getData.project_specifications) {
        const stages = getData.project_specifications.map((stage, index) => ({
          id: index.toString(),
          setName: stage.set_name || "",
          setDate: new Date(stage.set_date) || "",
          drawingDate: new Date(stage?.default_issue_date) || "",
          receivedDate: new Date(stage.default_received_date) || "",
          files: stage.attach_specifications.map((file) => ({
            name: file.fileName,
            url: file.fileUrl,
          })),
          removePhoto: [],
          previousFile:
            stage.attach_specifications.length > 0
              ? stage.attach_specifications.map((file) => ({
                name: file.fileName,
                url: file.fileUrl,
              }))
              : [],
        }));
        setSets(stages);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);


  const getTitileName = async () => {
    try {
      const res = await getNameByProjectId(projectId)
      const data = res?.data?.data || []
      setName(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  const getFormatename = async () => {
    try {
      const res = await getFormatByProjectId(projectId)
      const Name = res?.data?.data || []
      setFormats(Name)
    }
    catch (error) {
      console.log(error)
    }
  }


  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const submitName = async () => {
    try {
      const payload = {
        name: addName,
        project_id: projectId
      }
      await AddSpecificationName(payload)
      setAddName('')
      setNameVisible(false)
      getTitileName()
    } catch (error) {
      console.log(error)
    }
  }

  const submitFormat = async () => {
    try {
      const payload = {
        name: formatName,
        project_id: projectId
      }
      await AddSpecificationFormat(payload)
      setFormatName('')
      setFormatVisible(false)
      getFormatename()
    } catch (error) {
      console.log(error)
    }
  }

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id
        }));

        setCompanies(formattedCompanies);
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value;

    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, { company_id: [companyId] });
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }
      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      let data = allUsers?.map((item) => ({
        label: item?.first_name,
        value: item?._id,
        company: item?.company
      }))
      setAllUsersOfProject(data)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getLanguagename = async () => {
    try {
      const res = await getLanguageByProjectId(projectId)
      const Name = res?.data?.data
      setLanguageData(Name)
    }
    catch (error) {
      console.log(error)
    }
  }

  const submitLanguage = async () => {

    try {
      const payload = {
        name: languageName,
        project_id: projectId
      }
      await AddSpecificationLanguage(payload)
      setLanguageName('')
      setLanguagevisible(false)
      getLanguagename()
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSpecificationsCount = async () => {
    try {
      const count = await getCountForSpecificationsByProjectId(projectId);
      setSingleData(pre => {
        return {
          ...pre,
          spec_id: count?.data?.data || ''
        }
      })
    } catch (error) {
      console.error('Error fetching specifications count:', error);
    }
  }


  function handleChangeData(key, value) {
    setSingleData((pre) => {
      return {
        ...pre,
        [key]: value
      }
    })
    // let data = [...formData]
    // if (data[index]?._id !== undefined) {
    //   data[index].dataForUpdate = true
    // }
    // data[index][key] = value
    // setFormData(data)

  }



  // function checkValidationInData(data) {

  //   let isValid = true;
  //   let newValidation = data?.map((set, index) => {
  //     let setValidation = {
  //       "set": false,
  //       "format": false,
  //       "spec_manager": false,
  //       "specificationLanguage": false,
  //       "defaultRevision": false,
  //       "issuedDate": false,
  //       "receivedDate": false,
  //       "description": false
  //     };

  //     if (!set.set) {
  //       setValidation.set = true;
  //       isValid = false;
  //     }

  //     if (!set.format) {
  //       setValidation.format = true;
  //       isValid = false;
  //     }

  //     if (set.spec_manager.length == 0) {
  //       setValidation.spec_manager = true;
  //       isValid = false;
  //     }
  //     if (!set.specificationLanguage) {
  //       setValidation.specificationLanguage = true;
  //       isValid = false;
  //     }
  //     if (!set.defaultRevision) {
  //       setValidation.defaultRevision = true;
  //       isValid = false;
  //     }
  //     if (!set.issuedDate) {
  //       setValidation.issuedDate = true;
  //       isValid = false;
  //     }

  //     if (!set.receivedDate) {
  //       setValidation.receivedDate = true;
  //       isValid = false;
  //     }
  //     if (!set.description) {
  //       setValidation.description = true;
  //       isValid = false;
  //     }

  //     return setValidation;
  //   });

  //   setValidation(newValidation);

  //   if (!isValid) {
  //     toast.info("Please fill all the required fields.");
  //   }

  //   return !isValid;



  // }

  const getSpecifications = async () => {
    try {
      // setSkeletonLoader(true);
      const response = await getSpecificationByProjectId(projectId);
      setAllData(response?.data?.data || [])
      setFormData(response?.data?.data || [])

    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };


  async function deleteDataSet() {
    try {
      setDeleteDialog(false)
      await specificatonDelete(deleteDataId);
      setDeleteDataId('')
      toast.success("Specification Deleted Successfully!");
      setSkeletonLoader(true);
      getSpecifications();
    } catch (err) {
      console.error(err);
    }
  }






  useEffect(() => {
    if (projectId) {
      getTitileName()
      getFormatename()
      getcompany()
      getAllUsersOfProject()
      getLanguagename()
      getSpecifications()
      fetchSpecificationsCount()
    }
  }, [])


  async function addSetInSpecification() {
    if (checkValidation()) {
      return;
    }
    try {
      setLoader(true)
      let files = []
      if (singleData?.attachFile?.length !== 0) {
        let forUpload = singleData?.attachFile?.filter(res => res?.file?.name !== undefined)
        let notForUpload = singleData?.attachFile?.filter(res => res?.file?.name === undefined && !deleteFile?.includes(res?.fileName))
        let uploadResponse = { data: [] }
        if (forUpload?.length !== 0) {
          const formData = new FormData();
          forUpload?.forEach(res => formData.append('upload', res.file))
          uploadResponse = await uploadDocs(formData);
        }
        files = [...notForUpload, ...uploadResponse?.data]

      }
      let payload = { ...singleData, project_id: projectId, attachFile: files, deleted_file: deleteFile }
      let res = ''
      if (modalType == 'add') {
        res = await createSpecification(payload);
      } else {
        res = await updateSpecification(payload?._id, payload);
      }
      setLoader(false)
      if (res?.data?.message === "Spec ID Already Exist") {
        toast.error("Spec ID Already Exist");
      } else {
        toast.success("Specification Update");
        setOpenAddModal(false)
        getSpecifications()
        setSkeletonLoader(true)
        fetchSpecificationsCount()
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error details:", error);
      }
    }
  }


























  return (
    <>
      <AddSetsDialog
        visible={namevisible}
        setVisible={setNameVisible}
        CategoryName={"Specification Set"}
        label={"Set"}
        reqFor={"create"}
        value={addName}
        setValue={setAddName}
        onSubmit={submitName}
      />
      <AddSetsDialog
        visible={formatvisible}
        setVisible={setFormatVisible}
        CategoryName={" Format "}
        label={"Format"}
        reqFor={"create"}
        value={formatName}
        setValue={setFormatName}
        onSubmit={submitFormat}
      />
      <AddSetsDialog
        visible={languagevisible}
        setVisible={setLanguagevisible}
        CategoryName={"Spec Language "}
        label={"language"}
        reqFor={"create"}
        value={languageName}
        setValue={setLanguageName}
        onSubmit={submitLanguage}
      />
      <BootstrapDialog open={openAddModal} onClose={() => { cleareData(); setOpenAddModal(false) }}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span className="text-capitalize">{modalType}</span> Set
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { cleareData(); setOpenAddModal(false) }}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <IoClose />
        </IconButton>
        <DialogContent dividers>
          <div className="">
            <div className="row">
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label className="labels mb-2"> Set Name :</label>{" "}
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center  ">

                    <AutoCompleteSelect
                      options={name?.map((item) => ({
                        label: capitalizeFirstLetter(item?.name),
                        value: item?._id,
                      })) || []}
                      value={singleData?.set}
                      placeholder="Set Name"
                      optionLabel="label"
                      onChange={(e) => {
                        handleChangeData('set', e)
                        setValidation({ ...Validation, title: false })
                      }}
                      className={Validation?.title ? "Validation" : ""}
                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setNameVisible(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end mt-md-0 mt-sm-3 mt-3">
                <div className="w-100">
                  <label className="labels mb-2"> Format :</label>{" "}
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center  ">


                    <AutoCompleteSelect
                      optionLabel="lebal"
                      options={formats?.map((item) => ({
                        label: capitalizeFirstLetter(item?.name),
                        value: item?._id,
                      })) || []}
                      placeholder="Format"
                      value={singleData?.format}
                      onChange={(e) => {
                        handleChangeData('format', e)
                        setValidation({ ...Validation, format: false })
                        // setFormat(value)
                        // setValidation({ ...Validation, format: false })
                      }}
                      className={Validation?.format ? "Validation" : ""}

                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setFormatVisible(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Contractor Company (s):{" "}
                </label>

                <MultiSelect
                  value={singleData?.responsible_contractor_company}
                  onChange={(e) => {
                    handleChangeData('responsible_contractor_company', e.value)
                    handleCompanyChange(e)
                  }}
                  options={companies}
                  optionLabel="label"
                  filter placeholder="Company"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                // className={`p-0 ${Validation.contractor ? "Validation" : ""}`}
                // onClick={() =>
                //   setValidation({ ...Validation, contractor: false })
                // }

                />

              </div>
              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Member (s):
                </label>

                <MultiSelect
                  value={singleData?.responsible_member}
                  onChange={(e) => {
                    handleChangeData('responsible_member', e.value)
                  }}
                  options={allUsersOfProject?.filter(item => singleData?.responsible_contractor_company?.includes(item?.company))}
                  // options={users}
                  optionLabel="label"
                  filter
                  placeholder="Select Member"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                // className={`w-full md:w-20rem ${Validation.membersResponsible ? "Validation" : ""}`}
                // onClick={() =>
                //   setValidation({ ...Validation, membersResponsible: false })
                // }
                />


              </div>
              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec Manager: <span className="text-danger">*</span>
                </label>
                <MultiSelect
                  value={singleData?.spec_manager}
                  optionLabel="label"
                  onChange={(e) => {
                    handleChangeData('spec_manager', e.value)
                    setValidation({ ...Validation, specManager: false })
                  }}
                  options={allUsersOfProject}
                  filter
                  placeholder="Select Member"
                  style={{ width: "100%" }}
                  className={Validation?.specManager ? "Validation" : ""}

                />
              </div>


              <div className="col-md-6 col-12 mt-3 d-flex align-items-end">
                <div className="w-100">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec Language:<span className="text-danger">*</span>
                  </label>

                  <div className="card flex justify-content-center  ">


                    <AutoCompleteSelect
                      // className="p-0"
                      placeholder="Specifications Language"
                      options={languageData?.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      })) || []} // Add fallback for options
                      optionLabel="lebal"
                      value={singleData?.specificationLanguage}
                      onChange={(e) => {
                        handleChangeData('specificationLanguage', e)
                        setValidation({ ...Validation, language: false })
                      }}
                      className={Validation?.language ? "Validation" : ""}

                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setLanguagevisible(true)}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec ID: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Spec ID"
                  className={`${Validation?.spec_id ? 'Validation' : ''}`}
                  onChange={(e) => {
                    handleChangeData('spec_id', e.target.value)
                    setValidation({ ...Validation, spec_id: false })
                  }}
                  value={singleData?.spec_id}
                />
              </div>

              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec version: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Spec version"
                  value={singleData?.defaultRevision}
                  onChange={(e) => {
                    handleChangeData('defaultRevision', e.target.value)
                    setValidation({ ...Validation, revision: false })
                  }}
                  className={Validation?.revision ? "Validation" : ""}

                />
              </div>


              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec Issue Date:<span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  placeholder="dd/mm/yy"
                  className={`form-control ${Validation?.issuedDate ? "Validation" : ""}`}
                  // value={singleData?.issuedDate}
                  value={singleData?.issuedDate?.includes('/') ? singleData?.issuedDate : moment(singleData?.issuedDate).format('yyyy-MM-DD')}

                  // selected={moment(issuedDate, "DD/MM/YY").format("YYYY-MM-DD")}
                  onChange={(e) => {
                    handleChangeData('issuedDate', e.target.value)
                    setValidation({ ...Validation, issuedDate: false })
                  }}
                />
              </div>

              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Spec Received Date:<span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  placeholder="dd/mm/yy"
                  value={singleData?.receivedDate?.includes('/') ? singleData?.receivedDate : moment(singleData?.receivedDate).format('yyyy-MM-DD')}
                  onChange={(e) => {
                    handleChangeData('receivedDate', e.target.value)
                    setValidation({ ...Validation, receivedDate: false })
                  }}
                  className={`form-control ${Validation?.receivedDate ? "Validation" : ""}`}

                // selected={receivedDate}
                // onChange={(e) => setReceivedDate(e.target.value)}
                // className={Validation.receivedDate ? "Validation" : "form-control"}
                // onClick={() =>
                //   setValidation({ ...Validation, receivedDate: false })
                // }
                />
              </div>

              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Hours Spent:
                </label>


                <TimeInput
                  value={convertHourlySpentStringIntoObject(singleData?.hours_spent)}
                  onChange={(newValue) =>
                    handleChangeData('hours_spent', `${newValue.hours}-${newValue.minutes}`)
                  }
                />
              </div>

              <div className="col-md-6 col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Person (s):
                </label>
                <MultiSelect
                  value={singleData?.responsible_person}
                  onChange={(e) => {
                    handleChangeData('responsible_person', e.value)
                  }}
                  options={allUsersOfProject}
                  optionLabel="label"
                  filter
                  placeholder="Select Person"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                // className={`p-0 ${Validation.contractor ? "Validation" : ""}`}

                />

              </div>

              <div className="col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Description :<span className="text-danger">*</span>
                </label>
                <div className="">
                  <Editor
                    style={{ height: "200px" }}
                    value={singleData?.description}
                    onTextChange={(e) => {
                      handleChangeData('description', e.htmlValue)
                      setValidation({ ...Validation, description: false })
                    }}
                    className={Validation?.description ? "Validation" : ""}

                  />
                </div>
              </div>

              <div className="col-md-12  row m-0 p-0">
                <CustomDragAndDrop
                  value={singleData?.attachFile}
                  setDeleted={setDeleteFile}
                  onChange={(e) => {
                    handleChangeData('attachFile', e)
                  }}
                />
              </div>
            </div>


          </div>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-end p-3">
            <button className="btn btn-sm border me-3" onClick={() => { cleareData(); setOpenAddModal(false) }}>Cancel</button>
            <button className="btn btn-sm btn-primary text-capitalize" disabled={loader} onClick={addSetInSpecification}>{loader ? <div class="spinner-border text-light spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> : modalType}</button>
          </div>
        </DialogActions>
      </BootstrapDialog>



      <div className="row m-0 p-0 mt-5">
        <div className="col-12">
          <span className="project_name_text"> 7. PROJECT SPECIFICATIONS</span>
        </div>


        <div className="w-100">
          {/* {skeletonLoader ? <FormSkeleton data={skeletonData} /> : formData?.map((res, index) => {
            return (
              <>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div key={index} className="fw-bold">Set {index + 1}:-</div>
                  <div className="d-flex">
                    <div
                      className="d-flex delete_main_text my-3 justify-content-end gap-1 align-items-center px-3"
                      onClick={() => {
                        setSingleData(res)
                        setOpenAddModal(true)
                        setModalType('edit')
                      }}
                    >
                      <span>
                        <FaEdit className="deleteIconColor" />
                      </span>
                      <span className="deleteText">Edit Set</span>
                    </div>
                    <div
                      className="d-flex delete_main_text my-3 justify-content-end gap-1 align-items-center px-3"
                      onClick={() => {
                        setDeleteDataId(res?._id)
                        setDeleteDialog(true)
                      }}
                    >
                      <span>
                        <RiDeleteBin5Line className="deleteIconColor" />
                      </span>
                      <span className="deleteText">Delete Set</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 d-flex align-items-end mt-3">
                    <div className="w-100">
                      <label className="labels mb-2"> Set Name :</label>{" "}
                      <span className="text-danger">*</span>
                      <div className="card flex justify-content-center  ">
                        <AutoCompleteSelect
                          options={name?.map((item) => ({
                            label: capitalizeFirstLetter(item?.name),
                            value: item?._id,
                          })) || []}
                          value={res?.set}
                          placeholder="Set Name"
                          optionLabel="label"
                          disabled
                      
                        />

                      </div>
                    </div>
                  
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-end mt-3">
                    <div className="w-100">
                      <label className="labels mb-2"> Format :</label>{" "}
                      <span className="text-danger">*</span>
                      <div className="card flex justify-content-center  ">


                        <AutoCompleteSelect
                          optionLabel="lebal"
                          options={formats?.map((item) => ({
                            label: item?.name,
                            value: item?._id,
                          })) || []}
                          placeholder="Format"
                          value={res?.format}
                          disabled
                       
                        />

                      </div>
                    </div>
                    
                  </div>
                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Responsible Contractor Company (s):{" "}
                    </label>

                    <MultiSelect
                      value={res?.responsible_contractor_company}
                     
                      options={companies}
                      optionLabel="label"
                      filter placeholder="Company"
                      disabled
                      style={{ width: "100%" }}
                   

                    />

                  </div>
                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Responsible Member (s):
                    </label>

                    <MultiSelect
                      value={res?.responsible_member}
                     
                      disabled
                      options={allUsersOfProject?.filter(item => res?.responsible_contractor_company?.includes(item?.company))}
                      // options={users}
                      optionLabel="label"
                      filter
                      placeholder="Select Member"
                      // maxSelectedLabels={3} 
                      style={{ width: "100%" }}
                   
                    />


                  </div>
                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec Manager: <span className="text-danger">*</span>
                    </label>
                    <MultiSelect
                      value={res?.spec_manager}
                      optionLabel="label"
                     
                      options={allUsersOfProject}
                      filter
                      placeholder="Select Member"
                      style={{ width: "100%" }}
                      disabled
                   
                    />
                  </div>


                  <div className="col-md-6 col-12 mt-3 d-flex align-items-end">
                    <div className="w-100">
                      <label htmlFor="" className="py-2 labelCss">
                        Spec Language:<span className="text-danger">*</span>
                      </label>

                      <div className="card flex justify-content-center  ">


                        <AutoCompleteSelect
                          // className="p-0"
                          placeholder="Specifications Language"
                          options={languageData?.map((item) => ({
                            label: item?.name,
                            value: item?._id,
                          })) || []} 
                          optionLabel="lebal"
                          value={res?.specificationLanguage}
                          disabled
                      
                        />

                      </div>
                    </div>
                   
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec ID
                    </label>
                    <TextInput
                      placeholder="Spec ID"
                     
                      disabled
                      value={res?.spec_id}
                    />
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec version:<span className="text-danger">*</span>
                    </label>
                    <TextInput
                      placeholder="Spec version"
                      value={res?.defaultRevision}
                     
                      disabled
                    />
                  </div>


                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec Issue Date:<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      style={{ height: "50px" }}
                      placeholder="dd/mm/yy"
                      className={`form-control`}
                   
                      value={res?.issuedDate?.includes('/') ? res?.issuedDate : moment(res?.issuedDate).format('yyyy-MM-DD')}
                      disabled
                   
                    />
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec Received Date:<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      style={{ height: "50px" }}
                      placeholder="dd/mm/yy"
                      value={res?.receivedDate?.includes('/') ? res?.receivedDate : moment(res?.receivedDate).format('yyyy-MM-DD')}
                     
                      className={`form-control `}
                     
                      disabled
                 
                    />
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Hours Spent:
                    </label>


                    <TextInput
                      value={res?.hours_spent !== '' ? res?.hours_spent?.split('-')?.join(':') : ''}
                      disabled
                 
                    />
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Responsible Person (s):
                    </label>
                    <MultiSelect
                      value={res?.responsible_person}
                    
                      options={allUsersOfProject}
                      optionLabel="label"
                      filter
                      disabled
                      placeholder="Select Person"
                      style={{ width: "100%" }}

                    />

                  </div>

                  <div className="col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Description :<span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <Editor
                        style={{ height: "200px" }}
                        value={res?.description}
                        readOnly
                     
                      />
                    </div>
                  </div>

                  <div className="col-md-12  row m-0 p-0">
                    <CustomDragAndDrop
                      value={res?.attachFile}
                      disabled={true}
                    />
                  </div>
                </div>

              </>
            )
          })} */}
          <div style={{ width: '100%', overflow: 'auto' }}>
            <DataTable style={{ minWidth: '1200px' }} value={formData}>
              <Column
                field="spec_id"
                header='Spec ID'
                sortable

              />
              <Column
                field="set"
                header='Set Name'
                body={(rowData) => {
                  let set = name?.find(item => item?._id == rowData?.set)
                  return (<div>{set?.name}</div>)
                }}
              />
              <Column
                field="format"
                header='format'
                body={(rowData) => {
                  let format = formats?.find(item => item?._id == rowData?.format)
                  return (<div>{format?.name}</div>)
                }}
              />
              <Column
                field="specificationLanguage"
                header='Spec Language'
                body={(rowData) => {
                  let language = languageData?.find(item => item?._id == rowData?.specificationLanguage)
                  return (<div>{language?.name}</div>)
                }}
              />
              <Column
                field="defaultRevision"
                header='Spec Version'
              />
              <Column
                sortable
                field="issuedDate"
                header='Spec Issue Date'
                body={(rowData) => {
                  return (<div>{moment(rowData?.issuedDate).format('DD/MM/YYYY')}</div>)
                }}
              />
              <Column
                sortable
                field="receivedDate"
                header='Spec Received Date'
                body={(rowData) => {
                  return (<div>{moment(rowData?.receivedDate).format('DD/MM/YYYY')}</div>)
                }}
              />
              <Column
                field="hours_spent"
                header='Hours Spent'
              />
              <Column
                field=""
                header='Actions'
                body={(rowData) => {
                  return (
                    <div className="d-flex gap-2">
                      <FaEdit
                        className="deleteIconColor fs-5"
                        onClick={() => {
                          setSingleData(rowData)
                          setOpenAddModal(true)
                          setModalType('edit')
                        }}
                      />
                      <RiDeleteBin5Line
                        className="deleteIconColor fs-5"
                        onClick={() => {
                          setDeleteDataId(rowData?._id)
                          setDeleteDialog(true)
                        }}
                      />
                    </div>
                  )
                }}
              />

            </DataTable>
          </div>
        </div>

        <span className="mt-4 add_icon_text" onClick={() => {
          setModalType('add')
          setOpenAddModal(true)
        }}>
          <IoMdAdd size={15} /> Create New Set
        </span>

        <div className="contoleButtons mt-3 d-flex justify-content-between">


          <div className="d-flex gap-3">
            <PrimaryButton
              onClick={() => setVisible3(true)}
              text={"Cancel"}
            />
            <PrimaryButton
              onClick={() => setBackVisible(true)}
              text={"Back"}
            />


            {/* <button
              className="btn border me-3"
              onClick={() => setBackVisible(true)}
            >
              Back
            </button> */}

          </div>

          <div className="right d-flex gap-3">
            <PrimaryButton
              text={"Save"}
              onClick={() => setVisible2(true)}
            />
            <PrimaryButton
              text={"Save & Continue"}
              onClick={() => setVisible(true)}
            />
          </div>
        </div>
      </div>

      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-6")}
        visible={backVisible}
        setVisible={setBackVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />
      <SevedModal
        yes={() => deleteDataSet()}
        visible={deleteDialog}
        setVisible={setDeleteDialog}
        heading='Confirm Deletion'
        type='warning'
        headingText={`Are you Sure you want to delete this item?`}
      />
      <SevedModal
        yes={() => handleAddSpecifications("Continue")}
        visible={visible}
        setVisible={setVisible}
        loader={btnLoader}
        heading='Proceed to Next Step'
        type='info'
        headingText={`Are you sure you want to save your progress and continue?`}
      />
      <SevedModal
        yes={() => handleAddSpecifications("save")}
        visible={visible2}
        loader={btnLoader}
        setVisible={setVisible2}
        heading='Create the Project'
        type='info'
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => navigate("/projects")}
        visible={visible3}
        setVisible={setVisible3}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />

    </>
  );
};
