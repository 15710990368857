import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import TextInput from "../../Components/Inputs/TextInput";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { Editor } from "primereact/editor";
import { uploadDocs } from "../../Api/Admin/AllAPIs";
import { toast } from "../../utils";

const EditAgenda = ({ editDailg, setEdit, agendaItem, onSubmit }) => {

  const [title, setTitle] = useState(agendaItem?.template_meeting_title || "");
  const [priority, setPriority] = useState(
    agendaItem?.template_meeting_priority || ""
  );

  const [category, setCategory] = useState(
    agendaItem?.template_meeting_category || ""
  );
  const [description, setDescription] = useState(
    agendaItem?.template_meeting_description || ""
  );
  const [fileName, setFileName] = useState();
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const priorities = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  const categories = [{ name: "Safety" }];

  const [Validation, setValidation] = useState({
    title: false,
    priority: false,
    category: false,
  });

  const checkValidation = () => {
    let hasErrors = false;

    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        title: false,
      }));
    }

    if (!priority) {
      setValidation((prevState) => ({
        ...prevState,
        priority: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        priority: false,
      }));
    }

    if (!category) {
      setValidation((prevState) => ({
        ...prevState,
        category: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        category: false,
      }));
    }

    if (hasErrors) {
      toast.info("Please fill all fields marked with * (required).");
    }

    return hasErrors;
  };

  useEffect(() => {
    setTitle(agendaItem?.template_meeting_title || "");
    setPriority(agendaItem?.template_meeting_priority || "");
    setCategory(agendaItem?.template_meeting_category || "");
    setDescription(agendaItem?.template_meeting_description || "");
    setFileName(
      agendaItem?.template_meeting_attachment?.map((res) => ({
        name: res?.fileName,
        id: res?._id,
      })) || []
    );
  }, [agendaItem]);

  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };

const handleSubmit = async () => {
  if (checkValidation()) {
    return;
  }

  let uploadedFiles = [];


  if (selectedFiles && selectedFiles.length > 0) {
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append("upload", file));


    const uploadResponse = await uploadDocs(formData);
    uploadedFiles = uploadResponse.data;
  }


  const updatedAgendaItem = {
    ...agendaItem,
    template_meeting_title: title,
    template_meeting_priority: priority,
    template_meeting_category: category,
    template_meeting_description: description,
    template_meeting_attachment: [...previousFile, ...uploadedFiles],
    deleted_file: removePhoto,
  };


  onSubmit(updatedAgendaItem);

  setEdit(false);
};


  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Edit a Meeting Item"
        visible={editDailg}
        maximizable
        style={{ width: "50vw", color: "#101928" }}
        onHide={() => setEdit(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row mt-4">
          {/* Title input */}
          <div className="col-md-6 ">
            <label className="labels py-2">Title :</label>
            <div className="card flex justify-content-center">
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onClick={() => setValidation({ ...Validation, title: false })}
                className={Validation.title ? "Validation" : ""}
                placeholder="Title"
              />
            </div>
          </div>

          {/* Priority dropdown */}
          <div className="col-md-6">
            <label className="labels py-2">Priority :</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={priority}
                onChange={(e) => setPriority(e.value)}
                options={priorities.map((res) => ({
                  label: res.name,
                  value: res.name,
                }))}
                opti
                placeholder="Priority"
                onClick={() =>
                  setValidation({ ...Validation, priority: false })
                }
                className={`${
                  Validation.priority ? "Validation" : ""
                } w-full md:w-14rem dropdown_box`}
              />
            </div>
          </div>

          {/* Category dropdown */}
          <div className="col-md-12 mt-4">
            <label className="labels py-2">Category :</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={category}
                onChange={(e) => setCategory(e.value)}
                options={categories.map((res) => ({
                  label: res.name,
                  value: res.name,
                }))}
                placeholder="Select Category"
                onClick={() =>
                  setValidation({ ...Validation, category: false })
                }
                className={`${
                  Validation.category ? "Validation" : ""
                } w-full md:w-14rem dropdown_box`}
              />
            </div>
          </div>

          {/* Drag and Drop file input */}
          <div className="col-md-12 mt-4">
            <div className="card flex justify-content-center">
              <DragAndDrop
                setRemovePhoto={setRemovePhoto}
                value={fileName}
                setFiles={setSelectedFiles}
                onChange={handleFileInput}
                setValue={setFileName}
                setPreviousFiles={setPreviousFile}
              />
            </div>
          </div>

          {/* Description editor */}
          <div className="col-md-12 mt-4">
            <label htmlFor="" className="py-2 labelCss">
              Description:
            </label>
            <div className="card flex justify-content-center">
              <Editor
                value={description}
                onTextChange={(e) => setDescription(e.htmlValue)}
                style={{ height: "200px" }}
              />
            </div>
          </div>

          {/* Submit and Cancel buttons */}
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <span className="clear_btn">Clear All</span>
              <button className="filter_btn" onClick={() => setEdit(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditAgenda;
