import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from '../../Components/Inputs/SearchInput'
import Filter from '../../Components/openrfis/Filter'
import { TableSkelton } from '../../Components/Common/TableSkelton'
import { TreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'
import AddEditPicklistItem from './AddEditPicklistItem'
import ViewTempletes from './ViewTempletes'
import Popover from '../../Components/Common/Popover'
import { checkForTemplateUpdate, createPickListitem, deletePhaseData, getPickListData, updatePeaklistTemp, uploadDocs, uploadMultipleDocs } from '../../Api/Admin/AllAPIs'
import style from './style.module.css'
import { getPaginatedData, toast } from '../../utils'
import Pagination from '../../Components/Common/Pagination/Pagination'
import DeleteDialog from '../../Components/Common/DeleteDialog'
import { FaFolder } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa";
import { FaRegFolderOpen } from "react-icons/fa6";
import { IoDocumentSharp } from "react-icons/io5";
import { AiFillPicture } from "react-icons/ai";

export default function PicklistManagement() {
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPageData = 8;
  const [btnloader, setBtnLoader] = useState(false)
  const [search, setSearch] = useState('')
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [viewData, setViewData] = useState([])
  const [file, setFile] = useState([])
  const [deleteFile, setDeleteFile] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [visibleDelete, setVisibleDelete] = useState(false)
  let [breadCrumData, setBreadCrumData] = useState({})

  const [validatePiclistItemLoader, setValidatePiclistItemLoader] = useState(false)

  const [formData, setFormData] = useState({
    phase_id: null,
    category_id: null,
    sub_category_id: null,
    doctype_id: null,
    templates: []
  })
  const [Validation, setValidation] = useState({
    phase_id: false,
    category_id: false,
    sub_category_id: false,
    doctype_id: false,
    templates: false
  });

  const checkValidation = () => {
    if (formData?.phase_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        phase_id: true,
      }));
    }
    if (formData?.category_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        category_id: true,
      }));
    }
    // if (formData?.sub_category_id == null) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     sub_category_id: true,
    //   }));
    // }
    if (formData?.doctype_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        doctype_id: true,
      }));
    }
    // if (file?.length == 0) {
    //   toast.info("Templates are mandatory to upload");
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     templates: true,
    //   }));
    // }




    if (
      formData?.phase_id == null ||
      formData?.category_id == null ||
      // formData?.sub_category_id == null ||
      formData?.doctype_id == null
      // file?.length == 0

    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  async function submitData() {
    if (checkValidation()) {
      return
    }
    try {
      setBtnLoader(true)
      let files = []
      if (file?.length !== 0) {
        let forUpload = file?.filter(res => res?.file?.name !== undefined)
        let notForUpload = file?.filter(res => res?.file?.name === undefined && !deleteFile?.includes(res?.fileName))
        let uploadResponse = { data: [] }
        if (forUpload?.length !== 0) {
          const formData = new FormData();
          forUpload?.forEach(res => formData.append('upload', res.file))
          uploadResponse = await uploadDocs(formData);
        }

        files = [...notForUpload, ...uploadResponse?.data]

      }
      let payload = { ...formData, templates: files }

      let res = await createPickListitem(payload)
      setOpenAddModal(false)
    } catch (err) {
      console.log(err)
    } finally {
      setBtnLoader(false)
      setFormData({
        phase_id: null,
        category_id: null,
        sub_category_id: null,
        doctype_id: null,
        templates: []
      })
      setFile([])
      getAllPickListData()
    }
  }


  async function getAllPickListData() {
    try {
      let res = await getPickListData()
      let data = res?.data?.data || []
      setTotalRecords(data?.length)
      setAllData(data)

      let arr = getPaginatedData(data, page, perPageData)
      setTableData(arr)

    } catch (Err) {
      console.log(Err)
    } finally {
      setSkeletonLoader(false)

    }
  }

  useEffect(() => {
    getAllPickListData()
  }, [])


  function searchData(e) {
    let value = e.target.value
    setSearch(value)
    if (e.target?.value == ' ') {
      e.target.value = ''
    } else {
      let data = [...allData]
      if (value !== '') {
        let newArr = data?.filter(res => res?.phase_name?.toLowerCase()?.includes(value?.toLowerCase()))
        setTableData(newArr)
      } else {
        setTableData(data)
      }



    }
  }


  async function confirmDelete() {
    let payload = {
      id: deleteId,
      role: 'phase'
    }
    try {
      let res = await deletePhaseData(payload)

      setVisibleDelete(false)
      setDeleteId('')
      getAllPickListData()
    } catch (err) {
      console.log(err)
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message)
      } else {
        toast.error('Some error occupied')
      }
    }
  }

  const [openEditModal, setOpenEditModal] = useState(false)
  const [editFiles, setEditFile] = useState([])
  const [editDeletedFile, setEditDeleteFile] = useState([])
  const [editFormData, setEditFormData] = useState({})
  const [editValidation, setEditValidation] = useState({})

  async function editPickList() {
    try {
      setBtnLoader(true)
      let allFiles = editFiles.filter(e => !e.file)

      let newFiles = editFiles.filter((res) => res.file).map((e) => e.file);

      if (newFiles.length > 0) {
        let re = await uploadMultipleDocs(newFiles);

        allFiles = [...allFiles, ...(re?.data?.data || [])];
      }
      let res = await updatePeaklistTemp(editFormData._id, { templates: allFiles })
      setOpenEditModal(false)
      getAllPickListData()

    }
    catch (err) {
      console.log(err)
    } finally {
      setBtnLoader(false)
    }


  }

  async function validatePickListItem(rowId) {
    try {
      let res = await checkForTemplateUpdate(rowId)
      return res
    }
    catch (err) {
      toast.error(err?.response?.data?.message)
      throw new Error(err)

    }
  }

  let [visibleItemDelete, setVisibleItemDelete] = useState(false)
  let [itemDeleteId, setItemDeleteId] = useState("")


  async function confirmItemDelete() {
    try {
      let payload = {
        id: itemDeleteId,
        role: 'template'
      }
      let res = await deletePhaseData(payload)
      getAllPickListData()
    }
    catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message)
      } else {
        toast.error('Some error occupied')
      }
    }
  }

  return (
    <>
      <AddEditPicklistItem
        open={openAddModal}
        toggle={() => setOpenAddModal(false)}
        type='Add'
        btnloader={btnloader}
        submitData={submitData}
        file={file}
        setFile={setFile}
        setDeleteFile={setDeleteFile}
        formData={formData}
        setFormData={setFormData}
        Validation={Validation}
        setValidation={setValidation}
      />
      <AddEditPicklistItem
        open={openEditModal}
        toggle={() => setOpenEditModal(false)}
        type='Edit'
        btnloader={btnloader}
        submitData={editPickList}
        file={editFiles}
        setFile={setEditFile}
        setDeleteFile={setEditDeleteFile}
        formData={editFormData}
        setFormData={setEditFormData}
        Validation={editValidation}
        setValidation={setEditValidation}
      />



      <ViewTempletes
        open={openViewModal}
        breadCrumData={breadCrumData}
        data={viewData}
        toggle={() => {
          setViewData([])
          setOpenViewModal(false)
        }}
      />

      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setDeleteId(null)}
        onConfirm={confirmDelete}
        header={"Picklist"}
      />

      <DeleteDialog
        visible={visibleItemDelete}
        setVisible={setVisibleItemDelete}
        onCancel={() => setItemDeleteId(null)}
        onConfirm={confirmItemDelete}
        header={"Picklist Item"}
      />


      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">PICKLIST MANAGEMENT</div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <PrimaryButton
            onClick={() => setOpenAddModal(true)}
            addIcon
            text={"Create New"}
          />
        </div>
      </div>
      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput
            onCancel={() => {
              setSearch("");
              let blank = { target: { value: '' } }
              searchData(blank)
              // searchData({ target: { value: "" } });
            }}
            value={search}
            placeholder={'Search by phase'}
            onChange={searchData}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">

        </div>
      </div>
      <div className={`mt-4 ${style.tableBox}`}>
        {skeletonLoader ? (
          <TableSkelton
            header={[
              "",
              "",
              "Phases",
              "Categories",
              "Sub - Categories",
              "Document Type",
              "Template",
              ""
            ]}
          />
        ) : (
          <>
            <div style={{ minWidth: '1000px', width: '100%' }}>
              <TreeTable className="" value={tableData} tableStyle={{ minWidth: '50rem' }}>
                <Column style={{ width: '70px' }} field="" header="" expander  ></Column>
                <Column field="phase_name" header="Phase" body={(rowData) => <span>{rowData?.phase_name ? rowData?.phase_name : <FaFolder />}</span>}></Column>
                <Column field="category_name" header="Category" body={(rowData) => <span>{rowData?.category_name ? rowData?.category_name : <FaFolderOpen />}</span>} ></Column>
                <Column field="subcategory_name" header="Sub - Categories" body={(rowData) => <span>{rowData?.subcategory_name ? rowData?.subcategory_name : <FaRegFolderOpen />}</span>} ></Column>
                <Column field="doctype_name" header="Document Type" body={(rowData) => <span>{rowData?.doctype_name ? rowData?.doctype_name : <IoDocumentSharp />}</span>} ></Column>
                <Column field="fileName" header="Template" body={(rowData, i) => {
                  let length = rowData?.files?.length
                  return (<>
                    {rowData?.doctype_name ? length !== 0 ? <><div className='text-underline text-black pointer' onClick={() => {

                      let [phaseIndex, categoryIndex, subCategoryIndex, docIndex] = i.rowIndex.split("_")

                      setBreadCrumData({
                        phase_name: tableData[phaseIndex]?.phase_name,
                        category_name: tableData[phaseIndex].children[categoryIndex].category_name,
                        sub_category_name: tableData[phaseIndex].children[categoryIndex].children[subCategoryIndex].subcategory_name,
                        document_name: rowData.doctype_name

                      }
                      )

                      // return
                      setOpenViewModal(true)

                      setViewData(rowData?.files)
                    }}>{`${length} Template${length == 1 ? '' : 's'}`}</div></> : <AiFillPicture /> : <AiFillPicture />}
                  </>)
                }} ></Column>

                {/* <Column field="fileName" header="Template" body={() => "testintttt"} /> */}




                <Column style={{ width: '70px' }} field="" header="" body={(rowData, i) => (
                  <>
                    {rowData?.phase_name ? <Popover
                      options={['Delete']}
                      onSelect={(index) => {
                        if (index == 0) {
                          setDeleteId(rowData?.key)
                          setVisibleDelete(true)
                        }

                      }}
                    /> : ''}
                    {rowData?.document_id && <Popover options={["Edit", "Delete"]}
                      onSelect={async (index) => {

                        if (index === 0) {
                          let [phaseIndex, categoryIndex, subCategoryIndex, docIndex] = i.rowIndex.split("_")
                          await validatePickListItem(rowData.key).then(() => {

                            setEditFormData({
                              phase_id: tableData[phaseIndex].key,
                              category_id: tableData[phaseIndex].children[categoryIndex].key,
                              sub_category_id: tableData[phaseIndex].children[categoryIndex].children[subCategoryIndex].key,
                              doctype_id: rowData.key,
                              templates: rowData.files,
                              _id: rowData.document_id

                            })
                            setEditFile(rowData.files)
                            setOpenEditModal(true)


                          }
                          ).catch(err => console.log(err))
                        }

                        else if (index === 1) {
                          setVisibleItemDelete(true)
                          setItemDeleteId(rowData.document_id)
                        }


                      }}
                    />}






                  </>
                )} ></Column>
              </TreeTable>
              <div className='my-3'>
                <Pagination
                  currentPage={page}
                  totalCount={totalRecords}
                  pageSize={perPageData}
                  onPageChange={(page) => {
                    let res = getPaginatedData(allData, page, perPageData)
                    setTableData(res)
                    setPage(page);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
