import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import Contractor from "./Contractor";
import SubContractor from "./SubContractor";
import Individual from "./Individual";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { getContractorSubIndCount, getProjectAssignData } from "../../Api/Admin/AllAPIs";
import { TabCard } from "../../Components/Common/PagesComponents/TabCard";

function Directory() {

  let project_id = localStorage.getItem("projectId");

  const [activeTabIndex,setActiveTabIndex]=useState(0)

  let [usersCount, setUsersCount] = useState({
    contractor: 0,
    subcontractor: 0,
    individual: 1
  })

  useEffect(() => {
    getCount()
    getProjectDirectoryData()
  }, [])

  async function getCount() {
    try {
      let res = await getContractorSubIndCount(project_id)
      // console.log(res.data.data)
      setUsersCount(res.data.data)
    }
    catch (error) {
      console.log(error)
    }
  }
  let [allData, setAllData] = useState({
    contractor: [],
    subcontractor: [],
    individual: []
  })

  async function getProjectDirectoryData() {
    try {
      let res = await getProjectAssignData(project_id)
      console.log(res.data.data, "llllllllllllllllllllllllllll")

      let data = {
        contractor: [],
        subcontractor: [],
        individual: []
      }

      res.data.data.forEach((res) => {


        switch (res.company.company_type) {
          case "contractor":
            data.contractor.push({ ...res.company, person: res.person.map(e => e.user) })
            break;
          case "subcontractor":
            data.subcontractor.push({ ...res.company, person: res.person.map(e => e.user) })
            break;
          case "individual":
            data.individual.push(...res.person.map(e => e.user))

            break;

          default:
            break;
        }





      })
      console.log(data)
      setAllData(data)

    }
    catch (err) {
      console.log(err)
    }
  }



  return (
    <>



      <div className="d-flex justify-content-between align-items-center">
        <div className="page_heading">PROJECT DIRECTORY</div>
        <div className="right d-flex align-items-center ">
        </div>
      </div>

      {/* table panel  */}
      <div>
        <div className="tabView mt-3">
          <TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
          >
            <TabPanel
              // header={`Contractors(${allData?.contractor?.length || 0})`}


              headerTemplate={
                <TabCard
                 
                  onClick={() => setActiveTabIndex(0)}
                  key={"ok"}
                  smallCard
                  header={`Contractors(${allData?.contractor?.length || 0})`}
                  active={activeTabIndex === 0}
                />
              }

            >
              <Contractor tableData={allData?.contractor} />
            </TabPanel>

            <TabPanel
            //  header={`Sub-Contractors(${allData?.subcontractor?.length || 0})`}
             
             headerTemplate={
              <TabCard
                className={"mx-3"}
                onClick={() => setActiveTabIndex(1)}
                key={"ok2"}
                headerClassName={"text-nowrap"}
                smallCard
                header={`Sub-Contractors(${allData?.subcontractor?.length || 0})`}
                active={activeTabIndex === 1}
              />
            }
             
             >
              <SubContractor tableData={allData?.subcontractor} />
            </TabPanel>
            <TabPanel
            //  header={`Individual(${allData?.individual?.length || 0})`}
             
             headerTemplate={
              <TabCard
               style={{marginLeft:"30px"}}
                onClick={() => setActiveTabIndex(2)}
                key={"ok3"}
                smallCard
                header={`Individual(${allData?.individual?.length || 0})`}
                active={activeTabIndex === 2}
              />
            }
             
             >
              <Individual tableData={allData?.individual} />
            </TabPanel>
          </TabView>
        </div>
      </div>

    </>
  );
}

export default Directory;
