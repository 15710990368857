import React, { useEffect ,useState} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getAllProjects } from "../../Api/Admin/AllAPIs";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import style from './Project.module.css'
import { BorderedTable } from "../../Components/Tables/BorderedTable";
// Define your Table component
const MyTable = ({ data }) => {
 
  
    let navigate = useNavigate();
  return (
    <div className="datatable">
        <BorderedTable>
        <DataTable value={data}>
                    <Column
                      field="id"
                      header="id"
                      body={(rowData, rowIndex) => (
                        <div>{rowIndex.rowIndex + 1} </div>
                      )}
                    ></Column>
                      <Column
                      field="member"
                      header="member"
                      body={(rowData, rowIndex) => (
                        <div>{rowIndex.rowIndex + 1} </div>
                      )}
                    ></Column>
      <Column header="Name" body={(rowData) => (
          <Link to={`/updateProjectForm/projectInfo/${rowData._id}`}>
            {rowData.project_name}
          </Link>
        )} />
     
      </DataTable>
        </BorderedTable>
    
    </div>
  );
};

const App = () => {
    const[data1,setData1] = useState([]);


  const fetchProjects = async () => {
    const res = await getAllProjects();
    return //get all projects api changess
    console.log(res?.data?.data);
    const val = res?.data?.data

     setData1(val)
  };

  useEffect(() => {
    
    fetchProjects();
    console.log("hi",data1)
  }, []);

  const userData = [
    { name: "John Doe", age: 30, email: "john@example.com" },
    { name: "Jane Smith", age: 25, email: "jane@example.com" },
    { name: "Bob Johnson", age: 40, email: "bob@example.com" },
  ];

  return (
    <div className="mainContainer">
      <h1 className={style.headingTable}>Project Data</h1>
      <MyTable data={data1} />
    </div>
  );
};

export default App;
