import React, { useState } from 'react';
import { format, addDays, subDays } from 'date-fns';
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import Progressbar from '../../Common/ProgressBar';

function DateNavigator() {
    const [currentDate, setCurrentDate] = useState(new Date());

    const handlePreviousDay = () => {
        setCurrentDate(prevDate => subDays(prevDate, 1));
    };

    const handleNextDay = () => {
        setCurrentDate(prevDate => addDays(prevDate, 1));
    };

    const [taskdata, settaskdata] = useState([
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 50,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 40,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 30,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 20,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 10,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 50,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 40,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 30,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 20,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 10,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 50,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 40,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 30,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 20,
        },
        {
            taskid: "5.1",
            taskname: "5.1.1 - Contract with General Managers",
            from: "01 May 2023",
            to: "01 May 2024",
            Progress: 10,
        },



    ])

    return (
        <div>
            <div className={`d-flex align-items-center justify-content-between my-3`}>
                <div className='pre_next_date' onClick={handlePreviousDay}><FaChevronLeft /></div>
                <div className='date_container'>{format(currentDate, 'PPPP')}</div>
                <div className='pre_next_date' onClick={handleNextDay}><FaChevronRight /></div>
            </div>
            <hr></hr>
            <div className='tasks_parent'>
                {taskdata.map((res, index) => {
                    return (
                        <div key={index} className='task_container'>
                            <div className='task_name'>
                                {res.taskid}-{res.taskname}
                            </div>
                            <div className='task_range'>
                                {res.from}-{res.to}
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                <div className="w-100">
                                    <Progressbar now={res.Progress} variant="warning" />
                                </div>
                                <div>
                                    {res.Progress}%
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default DateNavigator;
