import { useNavigate, useOutletContext } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const sampleCustomers = [
  {
    id: 1,
    Name: "General Requirements",
    Type: { name: "", code: "us" },
    Contracting_Party: "",
    Line_Item: "",
    Amount: "$ 280,000.00",
    person: [
      {
        id: 1,
        Description: "General Purposes",
        Category: "Labor",
        Original_Budget_Amount: "$ 65,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 65,000.00",
      },
      {
        id: 2,
        Description: "Specific Task",
        Category: "Materials",
        Original_Budget_Amount: "$ 35,000.00",
        Budget_Modifications: "$ 5,000.00",
        Approved_COs: "$ 2,000.00",
        Revised_Budget: "$ 42,000.00",
      }
    ],
    equipment: [
      {
        id: 1,
        Equipment_Name: "Excavator",
        Category: "Machinery",
        Original_Budget_Amount: "$ 50,000.00",
        Budget_Modifications: "$ 10,000.00",
        Approved_COs: "$ 5,000.00",
        Revised_Budget: "$ 65,000.00",
      }
    ],
  },
  {
    id: 2,
    Name: "Site Development",
    Type: { name: "Construction", code: "ca" },
    Contracting_Party: "",
    Line_Item: "",
    Amount: "$ 500,000.00",
    person: [
      {
        id: 1,
        Description: "Earthworks",
        Category: "Machinery",
        Original_Budget_Amount: "$ 120,000.00",
        Budget_Modifications: "$ 10,000.00",
        Approved_COs: "$ 8,000.00",
        Revised_Budget: "$ 138,000.00",
      },
      {
        id: 2,
        Description: "Landscaping",
        Category: "Labor",
        Original_Budget_Amount: "$ 80,000.00",
        Budget_Modifications: "$ 20,000.00",
        Approved_COs: "$ 5,000.00",
        Revised_Budget: "$ 105,000.00",
      }
    ],
    equipment: [
      {
        id: 1,
        Equipment_Name: "Bulldozer",
        Category: "Machinery",
        Original_Budget_Amount: "$ 75,000.00",
        Budget_Modifications: "$ 15,000.00",
        Approved_COs: "$ 10,000.00",
        Revised_Budget: "$ 100,000.00",
      }
    ],
  }
];

export const SummaryByCostCode = () => {
  let { setActiveStep } = useOutletContext();
  const [customers, setCustomers] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setCustomers(sampleCustomers);
    setActiveStep(1);
  }, [setActiveStep]);

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={rowData?.Category?.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`flag flag-${rowData?.Category?.code}`}
          style={{ width: "24px" }}
        />
        <span>{rowData?.Category?.name}</span>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.person?.length > 0 || rowData.equipment?.length > 0;
  };

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="border">

        <DataTable
          value={rowData.person}
          className="childrenTable"
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode={"checkbox"}
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column
            field="#"
            header="Sno."
            body={(rowData, { rowIndex }) => <div>{rowIndex + 1.1}</div>}
            style={{ width: "4.7rem" }}
          />
          <Column
            header="General Purposes"
            body={(rowData) => (
              <div className="flex align-items-center gap-2">
                <span>{rowData?.Description}</span>
              </div>
            )}
            style={{ width: "10rem", minWidth: "12.1rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Category"
            header=""
            style={{ width: "8.5rem", minWidth: "8.5rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Original_Budget_Amount"
            header=""
            style={{ width: "5rem", minWidth: "5rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Budget_Modifications"
            header=""
            style={{ width: "13.3rem", minWidth: "13.3rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Approved_COs"
            header=""
            style={{ width: "13.3rem", minWidth: "13.3rem", whiteSpace: "nowrap" }}
          />
        </DataTable>


        <DataTable
          value={rowData.equipment}
          className="childrenTable"
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode={"checkbox"}
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column
            field="#"
            header="Sno."
            body={(rowData, { rowIndex }) => <div>{rowIndex + 1.1}</div>}
            style={{ width: "4.7rem" }}
          />
          <Column
            header="Temporary Facilities"
            body={(rowData) => (
              <div className="flex align-items-center gap-2">
                <span>{rowData?.Equipment_Name}</span>
              </div>
            )}
            style={{ width: "10rem", minWidth: "12.1rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Category"
            header=""
            style={{ width: "8.5rem", minWidth: "8.5rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Original_Budget_Amount"
            header=""
            style={{ width: "5rem", minWidth: "5rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Budget_Modifications"
            header=""
            style={{ width: "13.3rem", minWidth: "13.3rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Approved_COs"
            header=""
            style={{ width: "13.3rem", minWidth: "13.3rem", whiteSpace: "nowrap" }}
          />
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center mb-3">
        <div className="searchInput">
          <SearchInput />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <button className="filter_btn w-100">Filter Contracts</button>
          <button className="filter_btn w-100">Clear All</button>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={customers}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column expander={allowExpansion} style={{ width: "3rem" }} />
          <Column
            field="Name"
            header="Name"
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
          <Column
            field="Type"
            header="Type"
            body={countryBodyTemplate}
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
          <Column
            field="Contracting_Party"
            header="Contracting Party"
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
          <Column
            field="Line_Item"
            header="Line Item #"
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
          <Column
            field="Description"
            header="Description"
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
          <Column
            field="Amount"
            header="Amount"
            style={{ width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
        </DataTable>
      </div>
    </>
  );
};

