import React, { useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BorderedTable } from "../../../../../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import ViewVersion from "../../../../Components/Common/ViewVersion";
import moment from "moment";
import FormSkeleton from "../../../../../../Components/Common/FormSkeleton";
import { ViewPageTable } from "../../../../../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../../../../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { TableSkelton } from "../../../../../../Components/Common/TableSkelton";

function GeneralDetail_Purchase({ includeVersionHistory, usedAsComponent }) {
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 1, height: "2.4rem" },
    { qty: 1, height: "5rem" },
  ];

  const budgetData = {
    subject: "#01-002 Instructions",
    responsibleContractor: "Owner Enterprises",
    membersResponsible: ["CDC General Contractors", ""],
    budgetManager: "Anderson Wills",
    assignees: ["10.00%", ""],
    reference: "Budget Ref 2024",
    status: "Approved",
    dueDate: "27/06/2024",
    location: "Pat Johnsons",
    category: "$ 110,000.00",
    costCode: "CC-1234",
    billTo: "B-123 Cloud Towers, Times Street Sector 62, Noida, Uttar Pradesh",
    shipTo : "A-504 Cloud Towers, Times Street Sector 62, Noida, Uttar Pradesh",
    question:
      "Lorem ipsum dolor sit amet consectetur. Morbi dictum mauris aliquet ut dictum cursus. Platea lacinia enim id tincidunt eros urna semper malesu ada quisque. Euismod a semper ac eget. Auctor morbi lectus felis tellus massa a at.",
    attachFile: [{ fileName: "budget_document.pdf", fileUrl: "#" }],
    response: [
      {
        Original_Budget_Amount: "Garry Scott",
        Projected_Budget: "Construction",
        Revised_Contract_Amount: "Construction Wrecking",
        Projected_Costs: "Construction",
        Forecast_to_Complete: "Unresolved",
        Estimated_Cost_at_Completion: "Unresolved",
        Projected_Over_Under: "Unresolved",
      },
    ],
  };

  const budgetDatadown = {
    dueDate: "27/06/2024",
  };
  return (
    <>
      <div className={`budgetView card`}>
        <div className="budgetparent">
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
          ) : (
            <>
              {/* {!usedAsComponent && (
                <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <span className="open_Budget_text">Budgets</span>
                    <span className="px-1 open_Budget_text ">/</span>
                    <span className="createNew_text">
                      {budgetData?.subject}
                    </span>
                  </div>
                  <div style={{ width: "120px" }}>
                    <EmailTemplateWithPdf
                      sendEmail={() => {}}
                      isDialog={true}
                    />
                  </div>
                </div>
              )} */}

              <div className="budgetbody d-flex flex-column gap-4">
                {/* {!usedAsComponent && (
                  <div className="d-flex justify-content-between">
                    <h1 className="headdingBudget"> {budgetData?.subject}</h1>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {}}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </div>
                  </div>
                )} */}

                <div className="d-flex flex-column gap-3 mt-4">
                  <div className="row">
                    <div className="  col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Contract Company": budgetData?.responsibleContractor,
                          },
                          {
                            'Default Retainage':
                              budgetData?.membersResponsible.join(", "),
                          },
                          { "Assigned To": budgetData?.budgetManager },
                          { "Payment Terms": budgetData?.assignees },
                          { "Ship Via": budgetData?.assignees },
                        ]}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          { Status: budgetData?.status },
                          { "Execution Status": budgetData?.location },
                          { "Created By": budgetData?.location },
                          { "Created On": budgetData?.dueDate },
                          // {
                          //   "Created On": moment(
                          //     budgetData?.dueDate
                          //   ).format("MM/DD/YYYY"),
                          // },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="view_page_para_head mt-4 mb-2">
              Bill To:
              </div>
              <div className="view_page_bold_text">
                <div
                  dangerouslySetInnerHTML={{ __html: budgetData?.billTo }}
                />
              </div>
              <div className="view_page_para_head mt-4 mb-2">
              Ship To:
              </div>
              <div className="view_page_bold_text">
                <div
                  dangerouslySetInnerHTML={{ __html: budgetData?.shipTo }}
                />
              </div>
              <div className="view_page_para_head mt-4 mb-2">
                Contract Description:
              </div>
              <div className="view_page_bold_text">
                <div
                  dangerouslySetInnerHTML={{ __html: budgetData?.question }}
                />
              </div>
              <div className="view_page_para_head  mt-4 mb-2">
                Attached Document:
              </div>
              {budgetData?.attachFile?.map((res, key) => (
                <div>
                  <ViewPageDocumentName
                    key={key}
                    docUrl={res?.fileUrl}
                    name={res?.fileName}
                  />
                </div>
              ))}

              <div className="budgetbody d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-3 mt-4">
                  <div className="row">
                    <div className="  col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Contract Date": budgetDatadown?.dueDate,
                          },
                          {
                            "Signed PO Receive Date":
                              budgetDatadown?.dueDate,
                          },
                    
                        ]}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Delivery Date":
                              budgetDatadown?.dueDate,
                          },
                          {
                            "Issue Date": budgetDatadown?.dueDate,
                          },
                        
                          // {
                          //   "Created On": moment(
                          //     budgetData?.dueDate
                          //   ).format("MM/DD/YYYY"),
                          // },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <hr />

          <div className="d-flex flex-column mt-4 gap-3">
            <div className="d-flex justify-content-between">
              <h2>Contract Table Summary:</h2>
              {/* <div className="clear_btn gray-lt">Add Response</div> */}
            </div>

            {budgetData?.response?.length === 0 ? (
              <div className="noData gray-ult w-100 justify-content-center text-center">
                There are no responses to this Budget right now. Once added, all
                the responses will be visible here.
              </div>
            ) : skeletonLoader ? (
              <TableSkelton
                header={[
                  "",
                  "Response By",
                  "Response Date",
                  "Response",
                  "Attachments",
                ]}
              />
            ) : (
              <div>
                <BorderedTable>
                  <DataTable value={budgetData?.response}>
                    <Column
                     field="Original_Budget_Amount"
                     header="Original Contract Amount"
                     style={{
                       width: "15%",
                       whiteSpace: "nowrap",
                       overflow: "hidden",
                       textOverflow: "ellipsis",
                     }}
                      // body={(data, rowIndex) => (
                      //   <div className="text-center">
                      //     {rowIndex.rowIndex + 1}
                      //   </div>
                      // )}
                      // style={{ minWidth: "4rem" }}
                    />
                    <Column
                      field="Projected_Budget"
                      header="Approved Change Orders"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                    <Column
                      field="Revised_Contract_Amount"
                      header="Revised Contract Amount"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                    <Column
                      field="Projected_Costs"
                      header="Pending Cost Changes"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                    <Column
                      field="Forecast_to_Complete"
                      header="Draft Change Orders"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />

                    <Column
                      field="Estimated_Cost_at_Completion"
                      header="Amount Invoiced"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />

                    <Column
                      field="Projected_Over_Under"
                      header="Payment Received"
                      style={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  </DataTable>
                </BorderedTable>
              </div>
            )}
          </div>

          {includeVersionHistory && (
            <>
              <hr />
              {/* <ViewVersion isView={true} /> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default GeneralDetail_Purchase;
