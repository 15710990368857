import React, { useState } from "react";
import Chart from "react-apexcharts";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function ProjectStazeTracker({overViewGetData}) {
  const chartConfig = {
    series: [
      {
        name: "Population",
        data: [98, 62, 21, 10, 6, 12, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: true,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: ["#aeda7f", "#69caec", "#ffd56d"],
      xaxis: {
        categories: [
          "Staze 7",
          "Staze 6",
          "Staze 5",
          "Staze 4",
          "Staze 3",
          "Staze 2",
          "Staze 1",
        ],
      },
    },
  };

  const [products, setProducts] = useState([
    {
      id: "1",
      projectStage: "alfa one",
    },
    {
      id: "2",
      projectStage: "beta one",
    },
    {
      id: "3",
      projectStage: "beta",
    },
    {
      id: "4",
      projectStage: "alfa one",
    },
    {
      id: "5",
      projectStage: "alfa one",
    },
  ]);
  return (
    <div>
      {/* <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="bar"
        height={400}
      /> */}
      <DataTable value={overViewGetData} tableStyle={{ minWidth: '15rem' }} className={`custom-datatable-daily-task`} height={400}>
                <Column field="" body={(index,resdata)=><span>{resdata.rowIndex+1}</span>}></Column>
                <Column field="stage_name"></Column>

            </DataTable>
    </div>
  );
}

export default ProjectStazeTracker;
