import React from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../Api/Admin/AllAPIs';
import { toast } from '../../utils';

export const ForgotPassword = () => {
    let navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: ""
        }
    })
    async function formSubmit(data) {
        try {
            let res = await forgotPassword(data)
            toast.success(res?.data?.message)
            navigate("/")

        } catch (error) {
            console.log(error.response.data.message)
            toast.error(error?.response?.data?.message || "Some error occured !")
        }
    }


    return (

        <div className="maincontainer">
            <div className="left-contain">
                <div className="logoFixed">
                    <span className="eLogo d-flex align-items-center">
                        <img src="/images/cdc_logo.png" className="me-2" alt="" />
                        CDC
                    </span>
                </div>
            </div>
            <div className="right-contain bg-white">
                <div className="right-content-div ">
                    <form onSubmit={handleSubmit(formSubmit)}>
                        <div className="d-flex justify-content-center ">
                            <span className="logIn">Forgot Password</span>
                        </div>

                        <div className="mt-3">
                            <label className="HelveticaMedium mb-2">Email</label>
                            <input
                                {...register(`email`, {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[^@]+@[^@]+\.[^@]+$/,
                                        message: 'Invalid email address'
                                    }
                                })}
                                placeholder="Email"
                                className="form-control"
                                type="text"
                            />
                            {errors.email && <div className="text-danger">{errors.email.message}</div>}
                        </div>

                        <div className="mt-4">
                            <button
                                className="continueButton w-100"
                                type="submit"
                                id="loginsubmit"
                            >
                                Continue
                            </button>
                        </div>
                    </form>
                    <div className="mt-2 mb-3 pointer w-100 text-center" onClick={() => navigate("/")}>
                        Back to Sign In
                    </div>

                </div>
            </div>
        </div>
    )
};




