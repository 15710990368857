import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { TableSkelton } from "../../Components/Common/TableSkelton";

const ViewProjectDrawing = ({ drawingData,hidePagination }) => {
  const [projectDrawingData, setProjectDrawingData] = useState([]);
  const [visible, setVisible] = useState({ status: false, images: [] });
  useEffect(() => {
    if (drawingData) {
      setProjectDrawingData(drawingData);
    }
  }, [drawingData]);
  return (
    <>
      {drawingData ? (
        <div style={{width:'100%',overflow:'auto'}}>
          {hidePagination?<DataTable
            value={projectDrawingData}
            // paginator
            // rows={5}
            // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="#"
              header=""
              style={{ Width: "10%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  {meta?.rowIndex + 1}
                </div>
              )}
            />
            <Column
              field="#"
              header="Set Name"
              style={{ Width: "16%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2  align-items-center">
                  {rowData.set_name}
                </div>
              )}
            />
            <Column
              field="project_name"
              header="Set Date"
              style={{ width: "16%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 align-items-center">
                  <p className="m-0">
                    {moment(rowData.set_date).format("MM/DD/YYYY")}
                  </p>
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Default Drawing Date"
              body={(rowData, meta) => (
                <>{moment(rowData.default_drawing_date).format("MM/DD/YYYY")}</>
              )}
              style={{ width: "17%" }}
            ></Column>
            <Column
              field="createdOn"
              header="Default Received Date"
              body={(rowData, meta) => (
                <>
                  {moment(rowData.default_received_date).format("MM/DD/YYYY")}
                </>
              )}
              style={{ width: "17%" }}
            ></Column>
            {/* <Column
              field="createdOn"
              header=""
              body={(rowData, meta) => (
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <PrimaryButton
                      onClick={() =>
                        setVisible({
                          status: true,
                          images: rowData.attach_drawings,
                        })
                      }
                      text="View Photos"
                    />
                  </div>
                </>
              )}
              style={{ width: "15%" }}
            ></Column> */}
          </DataTable>:<DataTable
            value={projectDrawingData}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="#"
              header=""
              style={{ Width: "10%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  {meta?.rowIndex + 1}
                </div>
              )}
            />
            <Column
              field="#"
              header="Set Name"
              style={{ Width: "16%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2  align-items-center">
                  {rowData.set_name}
                </div>
              )}
            />
            <Column
              field="project_name"
              header="Set Date"
              style={{ width: "16%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 align-items-center">
                  <p className="m-0">
                    {moment(rowData.set_date).format("MM/DD/YYYY")}
                  </p>
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Default Drawing Date"
              body={(rowData, meta) => (
                <>{moment(rowData.default_drawing_date).format("MM/DD/YYYY")}</>
              )}
              style={{ width: "17%" }}
            ></Column>
            <Column
              field="createdOn"
              header="Default Received Date"
              body={(rowData, meta) => (
                <>
                  {moment(rowData.default_received_date).format("MM/DD/YYYY")}
                </>
              )}
              style={{ width: "17%" }}
            ></Column>
            <Column
              field="createdOn"
              header=""
              body={(rowData, meta) => (
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <PrimaryButton
                      onClick={() =>
                        setVisible({
                          status: true,
                          images: rowData.attach_drawings,
                        })
                      }
                      text="View Photos"
                    />
                  </div>
                </>
              )}
              style={{ width: "15%" }}
            ></Column>
          </DataTable>}
          
        </div>
      ) : (
        <TableSkelton header={["", "Set Name", "Set Date", "Default Drawing Date","Default Received Date",""]} />
      )}
      <Dialog
        header="Drawing Images"
        draggable={false}
        visible={visible.status}
        style={{ width: "60vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible({
            status: false,
            images: [],
          });
        }}
      >
        <div className="d-flex flex-wrap gap-4">
          {visible.images.map((img) => (
            <>
              <img src={img.fileUrl} width={"140px"} height={"140px"} alt="" />
            </>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default ViewProjectDrawing;
