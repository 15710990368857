import React, { useEffect, useRef } from "react";
import "./common.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";

export default function Popover({ options, onSelect, button }) {
  const op = useRef(null);
  

  return (
    <div className="popoverComp">
      <Button
      type="button"
        className="dot-btn"
        label={button?button:<BsThreeDotsVertical />}
        onClick={(e) => {
          op.current.toggle(e);
          e.stopPropagation();
        }}
        style={{ fontWeight: "400 !important", color: "#4b5563", border: "0px soli #fff" }}
      />

      <OverlayPanel
        ref={op}
        style={{ padding: "0 !important" }}
        className="customPopover1"
      >
        <div className="popoverListing">
          <ul style={{ listStyleType: "none" }}>
            {options &&
              options.map((option, index) => option ? <li key={index} onClick={(e) => { e.stopPropagation(); op.current.hide(); onSelect(index, e) }}>{option}</li> : "")}
          </ul>
        </div>
      </OverlayPanel>
    </div>
  );
}
