import React, { useEffect, useRef, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdOutlineAttachment } from "react-icons/md";
import { FileUpload } from "primereact/fileupload";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import style from "./forms.module.css";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { useNavigate } from "react-router-dom";
import { createForms, getUserByProjectId, uploadDocs } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectName, getUserId, toast } from "../../utils";
import Loader from "../../Components/Common/Loader";
import { useSelector } from "react-redux";

export const CreateForm = () => {
  // const [selectedCity, setSelectedCity] = useState([]);
  // const [text, setText] = useState("");
  let set = useSelector(d => d.rolesAndPermissionData)
  const isApproverAccess = getActionsPermission("Form Approver")
  const isReviewerAccess = getActionsPermission("Form Reviewer")
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [approver, setApprover] = useState(null)
  const [reviewer, setReviewer] = useState(null)


  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dateCreated, setDateCreated] = useState("");

  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const mandatoryFields = ["name", "dateCreated"];
  const [validationName, setValidationName] = useState(false);
  const [validationDate, setValidationDate] = useState(false);

  const [validationApprover, setValidationApprover] = useState(false);
  const [validationReviewer, setValidationReviewer] = useState(false);


  const projectId = localStorage.getItem('projectId')
  console.log(projectId)

  // toast.error('this is snak');

  // const toast = useRef(null);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  const checkValidation = () => {
    if (!name.trim()) {
      setValidationName(true);
    }
    if (!dateCreated) {
      console.log("jafdoooooo");
      setValidationDate(true);
    }

    if (isApproverAccess) {
      if (!approver) {
        setValidationApprover(true)
      }
    }
    if (isReviewerAccess) {
      if (!reviewer) {
        setValidationReviewer(true)
      }
    }


    if (name.trim() === "" || !dateCreated) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleForms = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }

    if (checkValidation()) {
      return;
    }
    // return;
    setLoader(true);
    const formData = new FormData();
    for (const file of fileUpload) {
      formData.append("upload", file);
    }
    let fileData = [];
    if (fileUpload.length != 0) {
      const uploadResponse = await uploadDocs(formData);
      fileData = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        url: item.fileUrl,
      }));
    }
    let emailData = []
    if (isReviewerAccess) {
      emailData.push({
        email: reviewer.email_address,
        type: "Reviewer",
        rfi_name: name,
        project: getProjectName(),
        toolName: "RFI",
        name: reviewer?.first_name
      })
    }
    const payload = {
      forms_name: name,
      forms_description: description,
      forms_date_created: dateCreated.toLocaleString(),
      forms_file: fileData,
      project_id: projectId,
      reviewer_id: reviewer?._id || null,
      approver_id: approver?._id || null,
      email_data: emailData
    };
    try {
      await createForms(payload);

      toast.success("Forms Created successfully!");

      navigate("/projectManagement/forms");

      setName("");
      setDescription("");
      setDateCreated("");
      setFileUpload("");
    } catch (err) {
      console.error(err);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
    setLoader(false);
  };

  // toast.error('this is snak');

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
    console.log(files);
  };
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
      console.log(allUsers, "================================================")
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllUsersOfProject()
  }, [])
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="main-container">
          <div>
            <div className="row m-0 p-0">
              <div
                className="col-md-12 py-3 px-3 "
              >
                <span
                  className="open_Rfi_text"
                  onClick={() => navigate("/projectManagement/forms")}
                >
                  {" "}
                  Forms
                </span>
                <span className="px-1 open_Rfi_text">
                  {" "}
                  <FaAngleRight size={15} />{" "}
                </span>
                <span className="createNew_text"> New Form </span>
              </div>
            </div>

            {/* Top Create New Rfi End */}

            <div className="row m-0 p-0  mt-4">
              <div className="col-md-12 px-3">
                <span className="newRfi_text"> New Form  </span>
              </div>

              {/*  New section End */}

              {/* ----------------alret------------- */}

              <div className=" my-4 border py-3">
                <div className="row m-0 p-0 ">
                  <div className="col-md-12 ">
                    <div className="d-flex align-items-center gap-3 ">
                      <IoIosInformationCircle
                        size={45}
                        style={{ color: "#F97B06" }}
                      />
                      <div className="  d-flex flex-column">
                        <span className={style.alret_text}>
                          {" "}
                          1. Select a fillable PDF
                        </span>

                        <span className={style.alret_text}>
                          {" "}
                          2. Click "Attach File" or drag and drop file into{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-0 p-0 mt-3">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Form Name: <span className="text-danger">*</span>
                  </label>
                  {/* {console.log(validationName)} */}
                  <TextInput
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={validationName ? "Validation" : ""}
                    onClick={() => setValidationName(false)}
                  >
                    {" "}
                  </TextInput>
                </div>
              </div>
              <div className="row m-0 p-0">
                {isApproverAccess &&
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver:
                    </label>
                    <SingleSelect
                      className={validationApprover ? "Validation" : ""}
                      placeholder="Approver"
                      options={allUsersOfProject.filter(e => e._id !== getUserId())}
                      optionLabel={"first_name"}
                      value={allUsersOfProject.find(dd => dd._id == approver?._id)}
                      onClick={() =>
                        setValidationApprover(false)
                      }
                      onChange={(e) => {
                        setApprover(e.target.value);
                      }}
                    />
                  </div>
                }
                {isReviewerAccess &&
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer:
                    </label>
                    <SingleSelect
                      className={validationReviewer ? "Validation" : ""}
                      placeholder="Reviewer"
                      options={allUsersOfProject.filter(e => e._id !== getUserId())}
                      optionLabel={"first_name"}
                      value={allUsersOfProject.find(dd => dd._id == reviewer?._id)}
                      onClick={() =>
                        setValidationReviewer(false)
                      }
                      onChange={(e) => {
                        setReviewer(e.target.value);
                      }}
                    />
                  </div>}
              </div>


              <div className="row m-0 p-0 mt-3">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Description:
                  </label>

                  <Editor
                    style={{ height: "200px" }}
                    value={description}
                    onTextChange={(e) => setDescription(e.htmlValue)}
                  />
                </div>
              </div>

              {/* calender start  */}
              <div className="row m-0 p-0 mt-3">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Date Created: <span className="text-danger">*</span>
                  </label>
                  {/* {console.log(validationDate)} */}
                  {/* <Calender
                    placeholder="Date Created"
                    selected={dateCreated}
                    onChange={(e) => setDateCreated(e.target.value)}
                    className={validationDate ? "Validation" : ""}
                    onClick={() => setValidationDate(false)}
                  /> */}
                   <input 
                   type="date"
                   style={{height : "50px"}}
                    placeholder="Date Created"
                    selected={dateCreated}
                    onChange={(e) => setDateCreated(e.target.value)}
                    className={validationDate ? "Validation" : "form-control"}
                    onClick={() => setValidationDate(false)}
                  />
                </div>
              </div>

              <div className="row m-0 p-0 mt-4 css_com">
                <div className="col-md-12">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={setFileUpload}
                    onChange={handleFileSubmit}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFile}
                  />
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-12 d-flex justify-content-end mt-3 ">
                  <span
                    className="cancelBtn  px-3 py-2"
                    onClick={() => navigate("/projectManagement/forms")}
                  >
                    {" "}
                    Cancel
                  </span>&nbsp;&nbsp;

                  <span className="CreateRfi px-3 py-2" onClick={handleForms}>
                    Create
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br/>
        </div>
      )}
    </>
  );
};
