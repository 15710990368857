
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import style from './UpcomingTask.module.css'
import moment from 'moment/moment';

function UpcomingTask({overViewGetData}) {
    const [products, setProducts] = useState([
        {
            id: '1000',
            code: '34',
            name: 'Plumbing Bid Package',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Ennis Whales',
            quantity: '01 May 2024',
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1000',
            code: '35',
            name: 'Repairs and other work',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Steve Stone',
            quantity: '01 May 2024',
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1000',
            code: '36',
            name: 'Confirm layout documents',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Lucy Hutton',
            quantity: '01 May 2024',
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1000',
            code: '37',
            name: 'Ceiling design finalization',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Joshua Templeton',
            quantity: '01 May 2024',
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1000',
            code: '38',
            name: 'Lobby Walls cleaning',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Benji Helge',
            quantity: '01 May 2024',
            inventoryStatus: 'INSTOCK',
            rating: 5
        },

       
    ]);

    const formatDate = (dateString) => {
        if (dateString) {
    
          const date = new Date(dateString);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1
            }/${date.getFullYear()}`;
          return formattedDate;
        } else {
          return ""
        }
      };

    // useEffect(() => {
    //     ProductService.getProductsMini().then(data => setProducts(data));
    // }, []);

    return (
        <div className="card">
           <DataTable value={overViewGetData} tableStyle={{ minWidth: '50rem' }} className={`custom-datatable-daily-task`}>
                <Column field=""  body={(index,resdata)=><span>{resdata.rowIndex+1}</span>}></Column>
                <Column field="title"></Column>
                <Column field="category"></Column>
                <Column field="" body={(rowData)=><span>{moment(rowData.due_date).format("YYYY-MM-DD")}</span>}></Column>
            </DataTable>
        </div>
    );
}

export default UpcomingTask;