import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { getUserId } from "../../utils";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";

export default function Filter({ filteredData, setFilteredData, onFilter }) {
  const [visible, setVisible] = useState(false);
  const projectId = localStorage.getItem("projectId");
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);

  const type = [
    "Work In Progress",
    "Incomplete",
    "Completed",
    "Ready for Review",
    "Not Started",
  ];

  function inpChange(e) {
    let { name, value } = e.target;
    setFilteredData((pre) => ({ ...pre, [name]: value }));
  }

  function applyFilterr() {
    setVisible(false);
    onFilter();
  }
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getAllUsersOfProject();
  }, []);
  return (
    <>
      <div className="card flex justify-content-center ">
        <button
          style={{ width: "fit-content !important" }}
          className="filter_btn"
          onClick={() => setVisible(true)}
        >
          Filter 
        </button>
        <Dialog
          header="Filter Transmittals"
          visible={visible}
          maximizable
          style={{ width: "50rem", color: "#101928" }}
          onHide={() => setVisible(false)}
          headerClassName="addBorder_bottom"
          draggable={false}
        >
          <div className="row">
            <div className="col-md-6">
              <label className="labels">Workflow Status:</label>
              <div className="card flex justify-content-center mt-2 ">
                <Dropdown options={type} onChange={(e) => inpChange(e)} 
                  name="status_data"
                  placeholder="Workflow Status"
  value={filteredData?.status_data}

                  />
              </div>
            </div>
            <div className="col-md-6">
              <label className="labels">Reviewer:</label>
              <div className="card flex justify-content-center mt-2 ">
                <Dropdown
                value={filteredData?.reviewer_id}
                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  ).map(s=>({value:s._id,label:s.first_name}))}
                  placeholder="Select Receiver Name"
                  onChange={(e) => inpChange(e)}
                  name="reviewer_id"
                />
                {console.log(filteredData)}
              </div>
            </div>
            <div className="col-md-6">
              <label className="labels">Approver:</label>
              <div className="card flex justify-content-center mt-2 ">
                <Dropdown
                value={filteredData?.approver_id}

                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  ).map(s=>({value:s._id,label:s.first_name}))}
                  placeholder="Select Approver Name"
                  onChange={(e) => inpChange(e)}
                  name="approver_id"
                />
              </div>
            </div>
            <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>

              <PrimaryButton
                onClick={applyFilterr}
                addIcon
                text={"Apply Filter"}
              />
            </div>
          </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
