import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import React, { useState } from "react";
import { DragAndDrop } from "../Inputs/DragAndDrop";
import { Button } from "primereact/button";
import { toast } from "../../utils";
import { addResponseInRfi, updateRfi, uploadDocs } from "../../Api/Admin/AllAPIs";
import Loader from "../Common/Loader";

export default function ResponseDialog({ visible, setVisible, reloade, id}) {
  const [response, setResponse] = useState("");
  const [fileName, setFileName] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  const handleUpdate = async () => {
    setLoader(true);
    if(fileUpload.length > 10){
      toast.info('You can add only 10 attachments');
      return;
    }

    let uploadedFiles = [];

    if (fileUpload && fileUpload.length > 0) {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);

      uploadedFiles = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        fileUrl: item.fileUrl,
      }));
    }
    const payload ={response_by: "", response_date: new Date(), response_descripttion: response, attachFile : uploadedFiles}

    console.log("payload", payload);
    setLoader(true);
    // return;
    try {
      await addResponseInRfi(id, payload);
      toast.success("Response Added Successfully!");
      setResponse(``)
      setFileName('')
      // navigate("/openrfis");
      await reloade();
    } catch (err) {
      console.error(err);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
    setVisible(false)
    setLoader(false);

  };

  return (
    <div className="rfiResponse">
    
      <Dialog
        header="Add Response"
        visible={visible}
        style={{ width: "50vw", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
           {loader?(<Loader/>):
        <div className="dialogBody mt-3 d-flex gap-4 flex-column">
          <div className="d-flex flex-column w-100">
            <label htmlFor="Response mb-2">Response:</label>
            <div className="w-100">
              <Editor
                value={response}
                onTextChange={(e) => setResponse(e.htmlValue)}
                style={{ height: "100px" }}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            <div className="w-100">
              <DragAndDrop
                label={"Attachments:"}
                setRemovePhoto={[]}
                value={fileName}
                setFiles={setFileUpload}
                onChange={handleFileSubmit}
                setValue={setFileName}
                setPreviousFiles={[]}
              />
            </div>
          </div>
          <div className="w-100 mt-3 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn py-1 px-3" onClick={handleUpdate}>
                Save
              </Button>
            </div>
          </div>
        </div>}
      </Dialog>
    </div>
  );
}
