import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import {
  getUserByProjectId,
  getAllCategoriesByPhase,
  getAllSubCategoriesByPhase,
  getAllDocType,
  getAllPhase,
} from "../../Api/Admin/AllAPIs";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import SingleSelect from "../../Components/Common/SingleSelect";
import { toast } from "../../utils";

const Filter = ({ filterData, setFilterData, onFilter, role }) => {
  const [visible, setVisible] = useState(false);
  const projectId = localStorage.getItem("projectId");
  const [allPhase, setAllPhase] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allDocTypeData, setAllDocTypeData] = useState([]);
  const [workStatus, setWorkStatus] = useState("");

  // const [formData, setFormData] = useState({
  //   phase_id: null,
  //   category_id: null,
  //   sub_category_id: null,
  //   doctype_id: null,
  // });


  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" },
  ];

  const getAllPhaseData = async () => {
    try {
      let res = await getAllPhase();
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      setAllPhase(finalData);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCategoriesData = async (id) => {
    try {
      let res = await getAllCategoriesByPhase(id);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      console.log(finalData);
      setAllCategory(finalData);
    } catch (err) {
      console.log(err);
    }
  };

  async function getAllSubCategoriesData(id) {
    try {
      let res = await getAllSubCategoriesByPhase(id);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      console.log(finalData);
      setAllSubCategory(finalData);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAllDocTypeData(id, role) {
    try {
      let res = await getAllDocType(id, role);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      setAllDocTypeData(finalData);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllPhaseData();
  }, [])



  const handleChange = async (e, key) => {
    setFilterData((pre) => {
      return {
        ...pre,
        [key]: e.value,
      };
    });

    if (key == "phase_id") {
      if (e.value == null) {
        setAllCategory([]);
      } else {
        await getAllCategoriesData(e.value);
      }
      setFilterData((pre) => {
        return {
          ...pre,
          category_id: null,
          sub_category_id: null,
          doctype_id: null,
        };
      });
    }
    if (key == "category_id") {
      if (e.value == null) {
        setAllSubCategory([]);
        setAllDocTypeData([]);
      } else {
        await getAllSubCategoriesData(e.value);
        await getAllDocTypeData(e.value, "category");
      }
      setFilterData((pre) => {
        return {
          ...pre,
          sub_category_id: null,
          doctype_id: null,
        };
      });
    }

    if (key == "sub_category_id") {
      if (e.value == null) {
        setAllDocTypeData([]);
      } else {
        await getAllDocTypeData(e.value, "subcategory");
      }
      setFilterData((pre) => {
        return {
          ...pre,
          doctype_id: null,
        };
      });
    }
  };




  // function inpChange(e) {
  //   let { name, value } = e.target;
  //   setFilterData((pre) => ({ ...pre, [name]: value }));
  // }

  const handleApplyFilter = () => {
    if (!filterData?.phase_id && !filterData.category_id && !filterData?.sub_category_id && !filterData?.doctype_id && !filterData?.workStatus) {
      toast.info("Please select at least one field");
      return;
    }
    setVisible(false);
    onFilter()
  };

  return (
    <div className="card flex justify-content-center ">
      <button
        style={{ width: "fit-content !important" }}
        className="filter_btn"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>
      <Dialog
        header={`Filter ${role}`}
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row">
          <div className="col-md-6 mt-2">
            <label className="labels">Phase:</label>
            <div className="card flex justify-content-center mt-2 ">
              <Dropdown
                options={allPhase}
                value={filterData?.phase_id}
                optionLabel="label"
                onChange={(e) => {
                  handleChange(e, "phase_id");
                }}
                placeholder="All"
              // name="observationStatus"
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <label className="labels">Category</label>
            <div className="card flex justify-content-center mt-2">
              <Dropdown
                disabled={filterData?.phase_id == null}
                options={allCategory}
                value={filterData?.category_id}
                optionLabel="label"
                onChange={(e) => {
                  handleChange(e, "category_id");
                }}
              // name="observedBy"
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <label className="labels">Sub-Category</label>
            <div className="card flex justify-content-center mt-2">
              <Dropdown
                disabled={
                  filterData?.phase_id == null || filterData?.category_id == null
                }
                options={allSubCategory}
                value={filterData?.sub_category_id}
                optionLabel="label"
                onChange={(e) => {
                  handleChange(e, "sub_category_id");
                }}
              // name="observedBy"
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <label className="labels">Document Type</label>
            <div className="card flex justify-content-center mt-2">
              <Dropdown
                disabled={
                  filterData?.phase_id == null || filterData?.category_id == null
                  // formData?.sub_category_id == null
                }
                options={allDocTypeData}
                value={filterData?.doctype_id}
                optionLabel="label"
                onChange={(e) => {
                  handleChange(e, "doctype_id");
                }}
              // name="observedBy"
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <label className="labels">Work Flow Status:</label>
            <div className="card flex justify-content-center mt-2">
              <SingleSelect
                value={filterData?.workflow_status}
                optionLabel="label"
                options={statusOptions}
                placeholder="Status"
                onChange={(e) => {
                  setFilterData((prev) => ({
                    ...prev,
                    workflow_status: e.value
                  }))
                }}
              />
            </div>
          </div>

          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => {
                setFilterData(null)
                setVisible(false)
              }}>
                Cancel
              </button>

              <PrimaryButton
                onClick={handleApplyFilter}
                addIcon
                text={"Apply Filter"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
