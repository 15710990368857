import React, { useState } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];


  return (

    <div className="d-flex justify-content-center pagination_main_con">
      <ul
        className={classnames("pagination-container", { [className]: className })}
      >
        <li
          className={classnames("change_arrow_icon", {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >
          <div><MdOutlineArrowBackIosNew /></div>
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames("change_arrow_icon", {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
          <div><MdOutlineArrowForwardIos /></div>
        </li>
      </ul>
      <Dropdown
        value={currentPage}
        style={{ height: "35px" }}
        options={[...Array(lastPage)].map((d, e) => e + 1)}
        onChange={(e) => {
          onPageChange(e.value)
        }}
        placeholder="0"
        dropdownIcon={<div className="selectDropDownIcon">
          <svg width="38" height="38" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.57338 17.0262C13.2639 17.004 17.0483 13.1835 17.0261 8.49302C17.0038 3.80251 13.1834 0.018116 8.49292 0.0403351C3.80241 0.0625543 0.0180122 3.88297 0.0402313 8.57348C0.0624504 13.264 3.88287 17.0484 8.57338 17.0262ZM4.73342 6.34587C4.34137 5.95752 3.70873 5.96052 3.32038 6.35256C2.93203 6.74461 2.93503 7.37725 3.32708 7.7656L7.84463 12.2406L8.55449 12.9437L9.25766 12.2339L13.7326 7.71631C14.121 7.32426 14.118 6.69162 13.7259 6.30327C13.3339 5.91492 12.7012 5.91792 12.3129 6.30997L8.5411 10.1177L4.73342 6.34587Z" fill="#615F5F" />
          </svg>
        </div>}
        className="custom-dropdown"
      />
    </div>
  );
};

export default Pagination;