import React, { useEffect, useRef, useState } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { SlArrowDown } from "react-icons/sl";
import { Editor } from "primereact/editor";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineAttachment } from "react-icons/md";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import Calender from "../../Components/Common/Calender";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { Checkbox } from "primereact/checkbox";
import CheckboxGroup from "../../Components/Common/CheckBox";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import {
  addTransmittal,
  uploadDocs,
  getTransmittalUid,
  getUserByProjectId,
  getTransmittalbyid,
  getCompanyByProjectId,
  getUsersByCompanyId,
  transmittalUpdate,
} from "../../Api/Admin/AllAPIs";
import { InputNumber } from "primereact/inputnumber";
import { getProjectName, getUserId, toast } from "../../utils";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LoopFileUpload } from "../../Components/Inputs/LoopFileUpload";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import TimeInput from "../../Components/Inputs/TimeSelector";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import moment from "moment";

function CreateTransmittals({ reqFor }) {
  // console.log(reqFor);

  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const projectId = localStorage.getItem("projectId");
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const domain = window.location.origin;

  // console.log(userData)

  const contractCompany = [
    "Elicia Katherine (Lorem & Co.)",
    "Dennis Paul ",
    "Sheryll Lacie (Lorem & Co.)",
    "Dennis Paul ",
  ];

  const type = [
    "Work In Progress",
    "Incomplete",
    "Completed",
    "Ready for Review",
    "Not Started",
  ];
  const Invoice = ["Build", "Constructabilty", "Coordinations"];

  const trades = [
    "Electrician",
    "Plumber",
    "Carpenter",
    "Painter",
    "Elevator Mechanic",
  ];
  const drawings = [
    "site plan",
    "Pot plan",
    "Excavation Plan",
    "Floor plan",
    "Elevation drawings",
  ];
  const [selectedCity, setSelectedCity] = useState([]);
  const [text, setText] = useState("");
  const [checked, setChecked] = useState(false);

  const [Question, setQuestion] = useState("");
  // const [types, setTypes] = useState(type);
  const [ID, setID] = useState();
  const [Title, setTitle] = useState("");
  const [ContractCompany, setContractCompany] = useState();

  const [InvoiceContracts, setInvoiceContracts] = useState();
  const [Status, setStatus] = useState("");
  const [DefaultRetainage, setDefaultRetainage] = useState(0);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [allUsersOfProjectStatus, setAllUsersOfProjectStatus] = useState([]);
  const [approver, setApprover] = useState("");
  const [reviewer, setReviewer] = useState("");
  const [fromUser, setFromUser] = useState();
  const [toUsers, setToUsers] = useState();
  const [initiatedDate, setInitiatedDate] = useState(new Date());
  const [responsibleContractor, setResponsibleContractor] = useState("");
  const [responsibleContractorTo, setResponsibleContractorTo] = useState("");

  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesTo, setCompaniesTo] = useState([]);
  const [membersResponsible, setMembersResponsible] = useState();
  const [membersResponsibleTo, setMembersResponsibleTo] = useState();
  const [disableFieldFor, setDisableFieldFor] = useState(0);
  const [disableFieldTo, setDisableFieldTo] = useState(0);
  const [hoursSpent, setHoursSpent] = useState("");
  const [skeletonLoader, setSkeletonLoader] = useState(
    reqFor === "edit" ? true : false
  );
  // console.log(membersResponsibleTo)
  // console.log(selectedFiles);
  const [tableData, setTableData] = useState([
    {
      documentNumber: "",
      documentTitle: "",
      remarks: "",
      documentVersion: "",
      attachFile: [],
    },
  ]);

  const [assigneeAttachment, setAssigneeAttachment] = useState({});
  const [reciepentAction, setReciepentAction] = useState("");
  const [users, setUsers] = useState([]);
  const [usersTo, setUsersTo] = useState([]);
  const [constructorValid, setConstructorValid] = useState({ to: 0, from: 0 });
  const [allData, setAllData] = useState({});
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");
  const [checkStatus, setCheckStatus] = useState("draft");

  const handleFileInput = (event) => {
    setSelectedFiles(event);
    // console.log(event);
  };

  const [validation, setValidation] = useState({
    fromUser: false,
    toUsers: false,
    subject: false,
    company: false,
    contructorsFrom: false,
    contructorsTo: false,
    // invoiceContact: false,
    statuss: false,
    defaultRetain: false,
    reviewers: false,
    approvers: false,
    transId: false,
  });

  const checkValidation = () => {
    let isValid = true;
    const updatedValidation = {};
    if (!fromUser && !membersResponsible) {
      updatedValidation.fromUser = true;
      isValid = false;
    }
    if (!toUsers && !membersResponsibleTo) {
      updatedValidation.toUsers = true;
      isValid = false;
    }
    if (!Title) {
      updatedValidation.subject = true;
      isValid = false;
    }
    if (!responsibleContractor && constructorValid.from === 0) {
      updatedValidation.contructorsFrom = true;
      isValid = false;
    }
    if (!responsibleContractorTo && constructorValid.to === 0) {
      updatedValidation.contructorsTo = true;
      isValid = false;
    }

    // if (!ContractCompany) {
    //   updatedValidation.company = true;
    //   isValid = false;
    // }
    // if (!InvoiceContracts) {
    //   updatedValidation.invoiceContact = true;
    //   isValid = false;
    // }
    if (!Status) {
      updatedValidation.statuss = true;
      isValid = false;
    }
    if (!ID) {
      updatedValidation.transId = true;
      isValid = false;
    }
    setValidation((prevState) => ({
      ...prevState,
      ...updatedValidation,
    }));

    if (!isValid) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
    }

    return isValid;
  };

  let assigneeUsers = ["Garry Scott", "Ennis Fedrie"];

  const categories = [
    // {
    //   name: "Make this visible only to administrators and the following users.",
    //   key: "A",
    // },
    // { name: "Allow These Users To See SOV Items", key: "M" },
  ];
  const [selectedCategories, setSelectedCategories] = useState([categories[1]]);

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];

    if (e.checked) _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(
        (category) => category.key !== e.value.key
      );

    setSelectedCategories(_selectedCategories);
  };

  function documnetFileData(value, name, index) {
    let infoData = assigneeAttachment;
    infoData[index][name] = value;
    setAssigneeAttachment(infoData);
    // if(name === "attachFile"){

    // }
  }

  const handleCreate = async () => {
    let chachData = checkValidation();
    if (!chachData) {
      return;
    }
    // console.log("arr");
    setSkeletonLoader(true);
    let fileData = [];
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (const file of selectedFiles) {
        // console.log(file, "tesselectedFilest");
        formData.append("upload", file);
      }
      let uploadResponse = await uploadDocs(formData);
      // uploadResponseData = [...uploadResponseData, ...uploadResponse1.data];
      fileData = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        url: item.fileUrl,
      }));
    }

    let emailData = [];

    let findEmail = allUsersOfProjectStatus?.find((res) => res._id == reviewer);
    emailData.push({
      name: findEmail?.first_name,
      to: findEmail?.email_address,
      tool_number: ID,
      tool: "Transmittal",
      tool_name: Title,
      subject: `Action Required: Appointed as the Reviewer to  Task #${ID} ${Title}`,
      project_name: getProjectName(),
      project_id: projectData?.project_code,
      template_type: "createdToolEmailToReviewer",
      url: `${domain}/projectManagement/transmittals`,
      created_by: userData?.name,
      // createdAt: moment().format('DD MMM YYYY  hh:mm A'),
      // project_name: getProjectName(),

      // name: reviewerData?.first_name,
      // to: reviewerData?.email_address,
      // tool_number: body?.taskId,
      // tool: "Task",
      // tool_name: body.task_name,
      // project_name: getProjectName(),
      // project_id: "8767",
      // template_type: "createdToolEmailToApprover",
      // created_by: getUserName()
    });
    let findEmailA = allUsersOfProjectStatus?.find(
      (res) => res._id == approver
    );
    emailData.push({
      name: findEmailA?.first_name,
      to: findEmailA?.email_address,
      tool_number: ID,
      tool: "Transmittal",
      tool_name: Title,
      subject: `Action Required: Appointed as the Approver to  Task #${ID} ${Title}`,
      project_name: getProjectName(),
      project_id: projectData?.project_code,
      template_type: "createdToolEmailToApprover",
      url: `${domain}/projectManagement/transmittals`,
      created_by: userData?.name,

      // type: "Approver",

      // createdAt: moment().format('DD MMM YYYY  hh:mm A'),
      // project: getProjectName(),
    });

    // let manager = allUsersOfProjectStatus?.filter(res => rfiManager.includes(res?._id))
    // let assignees = allUsersOfProjectStatus?.filter(res => responsiblePerson.includes(res?._id))

    try {
      for (const key in assigneeAttachment) {
        const formData = new FormData();
        let attachfile1 = [];
        for (const data in assigneeAttachment[key]) {
          if (assigneeAttachment[key][data].name) {
            formData.append("upload", assigneeAttachment[key][data]);
          } else {
            attachfile1.push(assigneeAttachment[key][data]);
          }
        }
        if (assigneeAttachment[key].length > 0) {
          const uploadResponse = await uploadDocs(formData);
          attachfile1 = [...attachfile1, ...uploadResponse?.data];
        }

        if (tableData[key]) {
          tableData[key].attachFile = attachfile1;
          // console.log("jadoooo", tableData, tableData[key]);
          setTableData(tableData);
        }
      }
      let payload = {
        id: ID,
        title: Title,
        // contract_Company: ContractCompany,
        // invoice_Contacts: InvoiceContracts,
        // status: "open",
        // default_Percentage: DefaultRetainage,
        // _id: id,
        // status: reviewer !== null && approver !== null ? 'inreview' : 'draft',
        question: text,
        attachFile: [...previousFiles, ...fileData],
        project_id: projectId,
        documentList: tableData,
        issued_date: initiatedDate,
        reviewer_id: reviewer || null,
        approver_id: approver || null,
        recipientAction: reciepentAction,
        recipientAcknowledgmentDate: checked === true ? new Date() : "",
        status_data: Status,
        to: disableFieldTo === 1 ? toUsers : membersResponsibleTo,
        from: disableFieldFor === 1 ? fromUser : membersResponsible,
        hoursSpent: hoursSpent,
        fromCompany:
          responsibleContractor?.length !== 0 ? responsibleContractor : null,
        toCompany:
          responsibleContractorTo?.length !== 0
            ? responsibleContractorTo
            : null,
        deleted_file: removePhoto,
        email_data: emailData,
        creator_email: getUserId(),
        status: checkStatus,
        created_by: userName,
      project_name:projectData.project_name,
      project_number: projectData.projectNumber,
      };

      // if(reviewer !== null && approver !== null){
      //   if(allData.status === undefined){
      //     //create
      //     payload.status = 'inreview'

      //   }else{
      //     //edit
      //    if(allData.status === 'draft'){
      //     payload.status = 'draft'
      //    }else{
      //     payload.status = allData?.status
      //    }
      //   }

      // }else{
      //   payload.status = 'draft'
      // }
      // if (payload.reviewer_id && payload.approver_id && !allData?.status
      // ) {
      //   payload.status = "inreview";
      // }
      // else {
      //   if (allData.status) {
      //     //create

      //     payload.status = allData?.status;
      //   } else {
      //     payload.status = "draft";
      //     //edit
      //   }
      // }

      console.log(payload);

      if (reqFor === "edit") {
        console.log(payload);
        if (payload.status == "draft") {
          if (payload.reviewer_id && payload.approver_id) {
            payload.status = "inreview";
          } else {
          }
        } else {
          if (payload.reviewer_id && payload.approver_id) {
          } else {
            payload.status = "draft";
          }
        }

        await transmittalUpdate(id, payload);
        toast.success("Transmittal Updated Successfully!");
      } else {
        if (payload.reviewer_id && payload.approver_id) {
          payload.status = "inreview";
        } else {
          payload.status = "draft";
        }

        let result = await addTransmittal(payload);
        toast.success("Transmittal Created Successfully!");
      }
      setSkeletonLoader(false);
      navigate(-1);
    } catch (error) {
      console.error("Error creating Transmittal:", error);
      setSkeletonLoader(false);
      if (
        error?.response?.data?.message === "Transmittal number already exists"
      ) {
        toast.error("Transmittal number already exists");
      }
    }
  };
  console.log(allData);
  console.log(allData.status);
  console.log(reviewer);

  const handleUpdate = async () => {};
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  const { id } = useParams();

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
  };

  const handleAddRow = () => {
    const newRow = {
      documentNumber: "",
      documentTitle: "",
      remarks: "",
      documentVersion: "",
      attachFile: [],
    };

    setTableData((pre) => [...pre, newRow]);
  };

  const deleteAssignee = (ob, index) => {
    let arr = tableData.splice(index, 1);

    setTableData((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const autoGenarateUid = async () => {
    if (!reqFor)
      await getTransmittalUid(projectId)
        .then((res) => {
          setID(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];

      setAllUsersOfProject(allUsers);
      if (reqFor === "edit") {
        setAllUsersOfProjectStatus(allUsers);
      } else {
        setAllUsersOfProjectStatus(
          allUsers?.filter((item) => item?._id !== getUserId())
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getTransMitalByIdData = async () => {
    if (reqFor !== "edit") {
      return;
    }
    try {
      setSkeletonLoader(true);
      let res = await getTransmittalbyid(id);
      // console.log(res);
      const data_array = res?.data?.data;
      setAllData(data_array);
      setApproverComment(data_array?.approver_comment);
      setReviewerComment(data_array?.reviewer_comment);
      // console.log(data_array.documentList);

      // console.log(data_array, "lllllllllllll");
      let approverData = data_array?.approver?.[0] || {};
      let reviewerData = data_array?.reviewer?.[0] || {};
      // let fromUserData = data_array?.
      setID(data_array?.ID);
      setTitle(data_array?.Title);
      // setContractCompany(data_array?.contract_Company);
      // setInvoiceContracts(data_array?.invoice_Contacts);
      // setDefaultRetainage(data_array?.default_Percentage);
      setApprover(data_array?.approver_id);
      setReviewer(data_array?.reviewer_id);
      // console.log(data_array?.reviewer_id);
      setReciepentAction(data_array?.recipientAction);
      setInitiatedDate(new Date(data_array?.issued_date));
      setChecked(data_array?.recipientAcknowledgmentDate ? true : false);
      setText(data_array?.question);
      // setCompanies(data_array?.from)
      setCheckStatus(data_array?.status);
      setResponsibleContractor(data_array?.fromCompany);
      setResponsibleContractorTo(data_array?.toCompany);
      setStatus(data_array?.status_data);
      setHoursSpent(data_array?.hoursSpent);
      // selectedFiles(data_array?.attachFile)
      setPreviousFiles([...data_array?.attachFile]);

      setFileName(
        data_array?.attachFile.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );
      let attach = {};
      data_array?.documentList?.forEach((value, index) => {
        attach[index] = value.attachFile;
      });
      setAssigneeAttachment(attach);
      setTableData(data_array?.documentList);
      let companyes = {
        from: 0,
        to: 0,
      };
      if (data_array.fromCompany) {
        setDisableFieldFor(2);
        setMembersResponsible(data_array?.from);
        await handleCompanyChange({ value: data_array?.fromCompany });
        companyes.from = 2;
      } else {
        setFromUser(data_array?.from);
        setDisableFieldFor(1);
        companyes.from = 1;
      }

      // setCompaniesTo(data_array?.to)
      if (data_array.toCompany) {
        setDisableFieldTo(2);
        setMembersResponsibleTo(data_array?.to);
        await handleCompanyChangeTo({ value: data_array?.toCompany });
        companyes.to = 2;
      } else {
        setDisableFieldTo(1);
        setToUsers(data_array?.to);
        companyes.to = 1;
      }
      setConstructorValid(companyes);
    } catch (error) {
      console.log("error", error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      // console.log("*********** Response:", res);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        // console.log(companyNames, "companynames-------");

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id,
        }));

        setCompanies(formattedCompanies);
        setCompaniesTo(formattedCompanies);
        // console.log(companies, "hhhhhhhhhhhhhhh");
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      try {
        const res = await getUsersByCompanyId(projectId, {
          company_id: [selectedCompanyIds],
        });

        if (res && res?.data?.data) {
          // console.log(res);
          const usersFromCompany = res.data.data.map((user) => ({
            label: user.name,
            value: user._id,
          }));
          usersFromAllCompanies.push(...usersFromCompany);
        } else {
          console.error("Response data is not in the expected format");
        }
      } catch (error) {
        console.log("Error fetching company data:", error);
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(
        new Set(usersFromAllCompanies.map((user) => user.value))
      ).map((id) => {
        return usersFromAllCompanies.find((user) => user.value === id);
      });
      // console.log(uniqueUsers);

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };
  const handleCompanyChangeTo = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractorTo(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      try {
        const res = await getUsersByCompanyId(projectId, {
          company_id: [selectedCompanyIds],
        });
        if (res && res?.data?.data) {
          const usersFromCompany = res.data.data.map((user) => ({
            label: user.name,
            value: user._id,
          }));
          usersFromAllCompanies.push(...usersFromCompany);
        } else {
          console.error("Response data is not in the expected format");
        }
      } catch (error) {
        console.log("Error fetching company data:", error);
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(
        new Set(usersFromAllCompanies.map((user) => user.value))
      ).map((id) => {
        return usersFromAllCompanies.find((user) => user.value === id);
      });

      setUsersTo(uniqueUsers);
    } else {
      setUsersTo([]);
    }
  };
  useEffect(() => {
    autoGenarateUid();
    getAllUsersOfProject();
    getTransMitalByIdData();
    getcompany();
  }, []);

  function convertHourlySpentStringIntoObject(str) {
    if (typeof str === "string") {
      return {
        hours: parseInt(str.split("-")[0]) || "00",
        minutes: parseInt(str.split("-")[1]) || "00",
      };
    } else {
      return {
        hours: "0",
        minutes: "0",
      };
    }
  }
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "15rem" },
    { qty: 1, height: "7rem" },
  ];
  return (
    <div className="create-trans">
      <div>
        {skeletonLoader ? (
          <FormSkeleton data={skeletonData} />
        ) : (
          <>
            <div className="row m-0 p-0 mb-3">
              <div className="col-md-12 mb-3">
                <span
                  className="open_Rfi_text "
                  onClick={() => navigate("/projectManagement/transmittals")}
                >
                  {" "}
                  Transmittals
                </span>
                <span className=" open_Rfi_text">
                  {" "}
                  <FaAngleRight size={15} />{" "}
                </span>
                <span className="createNew_text">
                  {reqFor === "edit"
                    ? "Edit Transmittals"
                    : "Create Transmittals"}
                </span>
              </div>
            </div>

            <div className="d-flex gap-2 ">
              <h5>Transmittals</h5>
              {/* <span>
            {" "}
            <SlArrowDown
              style={{ fontSize: "12", marginTop: "2px", color: "#f97b06" }}
            />
          </span> */}
            </div>
            <div className="row m-0 p-0">
              <h6>From</h6>
            </div>
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Sender Name <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  disabled={disableFieldFor === 2}
                  placeholder="Select Sender Name"
                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  )}
                  optionLabel={"first_name"}
                  value={allUsersOfProject.find((dd) => dd._id == fromUser)}
                  onChange={(e) => {
                    setFromUser(e.target.value._id);
                    setDisableFieldFor(1);
                    setConstructorValid((pre) => ({ ...pre, from: 1 }));
                  }}
                  className={validation.fromUser ? "Validation" : ""}
                  onClick={() =>
                    setValidation({
                      ...validation,
                      fromUser: false,
                      contructorsFrom: false,
                    })
                  }
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-3">
              <h6>Or</h6>
            </div>
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Sender Contractor Name:<span className="text-danger">*</span>
                </label>
                <SingleSelect
                  disabled={disableFieldFor === 1}
                  placeholder="Select Sender Contractor Name"
                  options={companies}
                  value={responsibleContractor}
                  onChange={(e) => {
                    handleCompanyChange(e);
                    setDisableFieldFor(2);
                    setMembersResponsible();
                    setConstructorValid((pre) => ({ ...pre, from: 2 }));
                  }}
                  className={validation.contructorsFrom ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, contructorsFrom: false })
                  }
                />
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Sender User Name:<span className="text-danger">*</span>
                </label>
                <SingleSelect
                  disabled={disableFieldFor === 1}
                  placeholder="Select Sender User Name"
                  value={membersResponsible}
                  onChange={(e) => setMembersResponsible(e.value)}
                  options={users}
                  className={validation.fromUser ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, fromUser: false })
                  }
                />
              </div>
            </div>

            <div className="row m-0 p-0 mt-3">
              <h6>To</h6>
            </div>

            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Receiver Name:<span className="text-danger">*</span>
                </label>
                <SingleSelect
                  placeholder="Select Receiver Name"
                  disabled={disableFieldTo === 2}
                  // disabled={toUsers?._id === getUserId()}
                  optionLabel={"first_name"}
                  value={allUsersOfProject.find((dd) => dd._id == toUsers)}
                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  )}
                  onChange={(e) => {
                    setToUsers(e.target.value._id);
                    setDisableFieldTo(1);
                    setConstructorValid((pre) => ({ ...pre, to: 1 }));
                  }}
                  className={validation.toUsers ? "Validation" : ""}
                  onClick={() =>
                    setValidation({
                      ...validation,
                      toUsers: false,
                      contructorsTo: false,
                    })
                  }
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-3">
              <h6>Or</h6>
            </div>
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Receiver Contractor Name:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  disabled={disableFieldTo === 1}
                  placeholder="Select Receiver Contractor Name"
                  options={companiesTo}
                  value={responsibleContractorTo}
                  onChange={(e) => {
                    handleCompanyChangeTo(e);
                    setDisableFieldTo(2);
                    setMembersResponsibleTo();
                    setConstructorValid((pre) => ({ ...pre, to: 2 }));
                  }}
                  className={validation.contructorsTo ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, contructorsTo: false })
                  }
                />
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Receiver User Name:<span className="text-danger">*</span>
                </label>
                <SingleSelect
                  disabled={disableFieldTo === 1}
                  placeholder="Select Receiver User Name"
                  value={membersResponsibleTo}
                  onChange={(e) => setMembersResponsibleTo(e.value)}
                  options={usersTo}
                  className={validation.toUsers ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, toUsers: false })
                  }
                />
              </div>
            </div>

            <div className="row m-0 p-0 mt-3">
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Transmittals Number:<span className="text-danger">*</span>
                </label>

                <TextInput
                  placeholder="Enter Transmittals No."
                  value={ID}
                  onChange={(e) => setID(e.target.value)}
                  className={validation.transId ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, transId: false })
                  }
                ></TextInput>
              </div>
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Subject:<span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Enter Subject"
                  value={Title}
                  onChange={(e) => setTitle(e.target.value)}
                  className={validation.subject ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, subject: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>
            </div>

            <div className="row m-0 p-0">
              {/* <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Contract Company:
            </label>

            <SingleSelect
              options={contractCompany}
              value={ContractCompany}
              onChange={(e) => setContractCompany(e.target.value)}
              placeholder="Contract Company"
              className={validation.company ? "Validation" : ""}
              onClick={() => setValidation({ ...validation, company: false })}
            />
          </div> */}
              {/* <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Invoice Contacts:
            </label>
            <SingleSelect
              placeholder="Invoice Contacts"
              options={Invoice}
              value={InvoiceContracts}
              onChange={(e) => setInvoiceContracts(e.target.value)}
              className={validation.invoiceContact ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...validation, invoiceContact: false })
              }
            />
          </div> */}
            </div>

            <div className="row gap-0 m-0 p-0  mt-3">
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Workflow Status:<span className="text-danger">*</span>
                </label>
                <SingleSelect
                  placeholder="Status"
                  options={type}
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                  className={validation.statuss ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, statuss: false })
                  }
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Hours Spent:
                </label>
                <TimeInput
                  value={convertHourlySpentStringIntoObject(hoursSpent)}
                  onChange={(newValue) =>
                    setHoursSpent(`${newValue.hours}-${newValue.minutes}`)
                  }
                />
              </div>
              {/* <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Deafult Retainage:
            </label>
            <div className="d-flex gap-1">
              <input
                type="number"
                inputId="integeronly"
                className="form-control common_input"
                value={DefaultRetainage}
                onChange={(e) => setDefaultRetainage(e.target.value)}
              />
              <span style={{ fontSize: "20px", marginTop: "8px" }}>%</span>
            </div>
          </div> */}
            </div>
            <div className="row m-0 p-0 mt-3">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Reviewer:
                </label>
                {/* <SingleSelect
                  placeholder="Reviewer"
                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  )}
                  optionLabel={"first_name"}
                  value={allUsersOfProject.find((dd) => dd._id == reviewer)}
                  onChange={(e) => {
                    setReviewer(e.target.value._id);
                  }}
                /> */}

                <AutoCompleteSelect
                  options={allUsersOfProjectStatus?.map((item) => {
                    return {
                      label: item?.first_name,
                      value: item?._id,
                    };
                  })}
                  value={reviewer}
                  // value={allUsersOfProject?.filter((dd) => dd?._id.toString() == reviewer.toString())}
                  optionLabel="label"
                  onChange={(value) => {
                    setReviewer(value);
                  }}
                  className={`p-0`}
                  placeholder="Select Reviewer"
                  disabled={
                    reviewer === getUserId() || allData?.status == "approved"
                  }
                />
              </div>
              {/* {console.log(allUsersOfProjectStatus)}
              {console.log(allUsersOfProject?.filter((dd) => dd?._id.toString() === reviewer.toString()))}
              {console.log(reviewer)} */}
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Approver:
                </label>
                {/* <SingleSelect
                  placeholder="Approver"
                  disabled={approver?._id === getUserId()}
                  optionLabel={"first_name"}
                  value={allUsersOfProject.find((dd) => dd._id == approver)}
                  options={allUsersOfProject.filter(
                    (d) => d._id !== getUserId()
                  )}
                  onChange={(e) => {
                    setApprover(e.target.value._id);
                  }}
                /> */}
                <AutoCompleteSelect
                  options={allUsersOfProjectStatus?.map((item) => {
                    return {
                      label: item?.first_name,
                      value: item?._id,
                    };
                  })}
                  value={approver}
                  // value={allUsersOfProject.find((dd) => dd._id == approver)}

                  optionLabel="label"
                  onChange={(value) => {
                    setApprover(value);
                  }}
                  className={`p-0 `}
                  placeholder="Select Approver"
                  disabled={
                    approver === getUserId() || allData?.status == "approved"
                  }
                />
              </div>
            </div>
            {reqFor === "edit" ? (
              <>
                <div className="row m-0 p-0 mt-3">
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer Comment:
                    </label>
                    <textarea
                      // placeholder="Reviewer Comment"
                      value={reviewerComment}
                      // onChange={(e) => setReviewerComment(e.target.value)}
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver Comment:
                    </label>
                    <textarea
                      // placeholder="Approver Comment"
                      value={approverComment}
                      // onChange={(e) => setApproverComment(e.target.value)}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="row m-0 p-0 mt-3">
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Recipient’s Action
                </label>
                <TextInput
                  placeholder="Enter Recipient’s  Action "
                  value={reciepentAction}
                  onChange={(e) => {
                    setReciepentAction(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Issued date
                </label>
                <input
                  className="form-control"
                  style={{ height: "50px" }}
                  placeholder=""
                  value={formatDate(initiatedDate)}
                  onChange={(e) => setInitiatedDate(e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-3">
              <div className="col-md-3 d-flex align-items-center gap-2">
                <label htmlFor="" className="py-2 labelCss">
                  Recipient’s Acknowledgment Received:
                </label>
                <Checkbox
                  style={{ color: "blue" }}
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
              </div>
              {checked === true ? (
                <div className="col-md-3">
                  {" "}
                  <input
                    className="form-control"
                    style={{ height: "50px" }}
                    placeholder=""
                    value={formatDate(initiatedDate)}
                    onChange={(e) => setInitiatedDate(e.target.value)}
                    disabled
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row m-0 p-0">
              <div className="col-md-12">
                {/* <label htmlFor="" className="py-2 labelCss">
              Private:
            </label> */}
                {/* <div className="flex flex-column gap-3">
              {categories.map((category) => {
                return (
                  <div key={category.key} className="flex align-items-center">
                    <Checkbox
                      inputId={category.key}
                      name="category"
                      value={category}
                      onChange={onCategoryChange}
                      checked={selectedCategories.some(
                        (item) => item.key === category.key
                      )}
                    />
                    <label htmlFor={category.key} className="ml-2 ">
                      {category.name}
                    </label>
                  </div>
                );
              })}
            </div> */}
                {/* 
            <SingleSelect
              className="p-0 mt-2"
              placeholder="Question"
              options={[]}
            /> */}
              </div>
              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss"></label>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="col-md-12">
                <label htmlFor="" className="py-2 labelCss">
                  Purpose of Transmittals:
                </label>
                <div className="card">
                  <Editor
                    value={text}
                    onTextChange={(e) => setText(e.htmlValue)}
                    style={{ height: "200px" }}
                  />
                </div>
              </div>
            </div>

            <div className="row css_com m-0 p-0">
              <div className="col-md-12">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={selectedFiles}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                />
                {/* <CustomDragAndDrop/> */}
              </div>
            </div>
            <div className="fw-bold fs-6">Document List</div>
            <br />
            {/* {console.log(tableData)}
            {console.log(assigneeAttachment)} */}
            <BorderedTable>
              <DataTable value={tableData}>
                <Column
                  field="id"
                  header=""
                  style={{ minWidth: "4rem" }}
                  body={(rowData, meta) => (
                    <div className="d-flex justify-content-center">
                      {meta.rowIndex + 1}
                    </div>
                  )}
                ></Column>
                <Column
                  header="Document Number"
                  style={{ width: "15%" }}
                  body={(rowData, rowIndex) => (
                    <input
                      style={{ height: "50px" }}
                      className="form-control"
                      onChange={(e) => {
                        setTableData((pre) => {
                          // console.log(rowIndex);
                          pre[rowIndex.rowIndex].documentNumber =
                            e.target.value;
                          return [...pre];
                        });
                        // console.log(e.target.value);
                      }}
                      value={rowData.documentNumber}
                      placeholder="Enter Number"
                      type="number"
                    />
                  )}
                ></Column>
                <Column
                  header="Document Title"
                  style={{ width: "15%" }}
                  body={(rowData, rowIndex) => (
                    <TextInput
                      onChange={(e) => {
                        setTableData((pre) => {
                          // console.log(rowIndex);
                          pre[rowIndex.rowIndex].documentTitle = e.target.value;
                          return [...pre];
                        });
                        // console.log(e.target.value);
                      }}
                      value={rowData.documentTitle}
                      placeholder="Enter Title"
                    />
                  )}
                ></Column>
                <Column
                  header="Document Version"
                  style={{ width: "15%" }}
                  body={(rowData, rowIndex) => (
                    <TextInput
                      onChange={(e) => {
                        setTableData((pre) => {
                          // console.log(rowIndex);
                          pre[rowIndex.rowIndex].documentVersion =
                            e.target.value;
                          return [...pre];
                        });
                        // console.log(rowData);
                      }}
                      value={rowData.documentVersion}
                      placeholder="Enter Version"
                    />
                  )}
                ></Column>

                <Column
                  header="Document Remark"
                  style={{ width: "15%" }}
                  body={(rowData, rowIndex) => (
                    <TextInput
                      onChange={(e) => {
                        setTableData((pre) => {
                          // console.log(rowIndex);
                          pre[rowIndex.rowIndex].remarks = e.target.value;
                          return [...pre];
                        });
                      }}
                      value={rowData.remarks}
                      placeholder="Enter remark"
                    />
                  )}
                ></Column>
                <Column
                  style={{ width: "40%" }}
                  body={(rowData, meta) => (
                    <div>
                      {/* <div> */}
                      <LoopFileUpload
                        index={meta?.rowIndex}
                        data={assigneeAttachment}
                        setData={setAssigneeAttachment}
                      />
                      {/* </div> */}
                    </div>
                  )}
                  header="Attachment"
                ></Column>
                <Column
                  style={{ minWidth: "4rem" }}
                  body={(rowData, rowIndex) => (
                    <div>
                      <span
                        onClick={() =>
                          deleteAssignee(rowData, rowIndex.rowIndex)
                        }
                      >
                        <DeleteButton />
                      </span>
                    </div>
                  )}
                ></Column>
              </DataTable>
            </BorderedTable>
            <div className="mt-2">
              +
              <span
                className="addAssignee_btn ms-1 fw-bold fs-6 pt-2"
                onClick={handleAddRow}
              >
                Add Document
              </span>
            </div>
          </>
        )}

        <div className="row m-0 p-0">
          <div className="col-12 d-flex justify-content-end mt-5">
            <button
              className="btn border me-3"
              onClick={() => navigate("/projectManagement/transmittals")}
            >
              Cancel
            </button>
            <PrimaryButton
              text={reqFor === "edit" ? "Update" : "Create"}
              onClick={handleCreate}
            />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default CreateTransmittals;
