import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { MdAdd } from "react-icons/md";
import AutoCompleteSelect from '../../Components/Common/AutoCompleteSelect';
import CustomDragAndDrop from '../../Components/Inputs/CustomDragAndDrop';
import AddInternalAttribute from './AddInternalAttribute';
import { addCategoryByPhase, addDocType, addPhase, addSubCategoryByPhase, getAllCategoriesByPhase, getAllDocType, getAllPhase, getAllSubCategoriesByPhase } from '../../Api/Admin/AllAPIs';
import SingleSelect from '../../Components/Common/SingleSelect';
import { toast } from '../../utils';


export default function AddEditPicklistItem({ open, toggle, type, submitData, btnloader, file, setFile, setDeleteFile, formData, setFormData, Validation, setValidation }) {



    const [loader, setLoader] = useState(false)
    console.log(formData)
    const [openModal1, setOpenModal1] = useState(false)
    const [openModal2, setOpenModal2] = useState(false)
    const [openModal3, setOpenModal3] = useState(false)
    const [openModal4, setOpenModal4] = useState(false)
    const [name, setName] = useState('')
    const [allPhase, setAllPhase] = useState([])
    const [allCategory, setAllCategory] = useState([])
    const [allSubCategory, setAllSubCategory] = useState([])
    const [allDocTypeData, setAllDocTypeData] = useState([])



    async function getAllPhaseData() {
        try {
            let res = await getAllPhase()
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllPhase(finalData)

        } catch (err) {
            console.log(err)
        }
    }

    async function getAllCategoriesData(id) {
        try {
            let res = await getAllCategoriesByPhase(id)
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllCategory(finalData)

        } catch (err) {
            console.log(err)
        }

    }
    useEffect(() => {
        console.log(type === "Edit" && formData.phase_id, "get all category data data")
        if (type === "Edit" && formData.phase_id && open) {
            console.log("get all categgggggggg")
            getAllCategoriesData(formData.phase_id)
        }
    }, [formData.phase_id, open])

    useEffect(() => {
        console.log(type === "Edit" && formData.category_id, "get all sub sub category data data")
        if (type === "Edit" && formData.category_id && open) {
            console.log("get all subbbbbbbcateggggggggg")
            getAllSubCategoriesData(formData.category_id)
        }
    }, [formData.category_id, open])

    useEffect(() => {
        console.log(type === "Edit" && formData.sub_category_id, "get all doc doc doc  data data")
        if (type === "Edit" && formData.sub_category_id && open) {
            console.log(formData.sub_category_id, "get all doc doccccccccccccc")

            if (formData.sub_category_id === "NA") {
                getAllDocTypeData(formData.category_id, 'category')
            }
            else {
                getAllDocTypeData(formData.sub_category_id, "subcategory")
            }
        }
    }, [formData.sub_category_id, open])

    async function getAllSubCategoriesData(id) {
        try {
            let res = await getAllSubCategoriesByPhase(id)
            let data = res?.data?.data || []
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllSubCategory(finalData)

        } catch (err) {
            console.log(err)
        }

    }
    async function getAllDocTypeData(id, role) {
        try {
            let res = await getAllDocType(id, role)
            let data = res?.data?.data || []
            console.log(data, "[][]][][][]")
            let finalData = data?.map(res => ({ label: res?.name, value: res?._id }))
            console.log(finalData)
            setAllDocTypeData(finalData)

        } catch (err) {
            console.log(err)
        }

    }


    useEffect(() => {
        if (open) {
            getAllPhaseData()

        }
    }, [open])


    async function createPhase(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let res = await addPhase({ name: name })
            console.log(res)
            toast.success("Phase created successfully")
            setName('')
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
            setOpenModal1(false)
            getAllPhaseData()
        }

    }
    async function createCategory(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let res = await addCategoryByPhase({ name: name, phase_id: formData?.phase_id })
            console.log(res)
            toast.success("Category created successfully")
            setName('')
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
            setOpenModal2(false)
            getAllCategoriesData(formData?.phase_id)
        }

    }
    async function createSubCategory(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let res = await addSubCategoryByPhase({ name: name, phase_id: formData?.phase_id, category_id: formData?.category_id })
            console.log(res)
            toast.success("Sub Category created successfully")

            setName('')
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
            setOpenModal3(false)
            getAllSubCategoriesData(formData?.category_id)
        }

    }
    async function createDocType(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let res = await addDocType({ name: name, phase_id: formData?.phase_id, category_id: formData?.category_id, sub_category_id: formData?.sub_category_id })
            console.log(res)
            toast.success("Doctype created successfully")

            setName('')
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
            setOpenModal4(false)
            getAllDocTypeData(formData?.sub_category_id == null ? formData?.category_id : formData?.sub_category_id, formData?.sub_category_id == null ? 'category' : 'subcategory')
        }

    }


    async function handleChange(e, key) {
        setFormData(pre => {
            return {
                ...pre,
                [key]: e
            }
        })

        if (key == 'phase_id') {
            if (e == null) {
                setAllCategory([])
            } else {
                await getAllCategoriesData(e)
            }
            setFormData(pre => {
                return {
                    ...pre,
                    category_id: null,
                    sub_category_id: null,
                    doctype_id: null
                }
            })
        }

        if (key == 'category_id') {
            if (e == null) {
                setAllSubCategory([])
                setAllDocTypeData([])
            } else {
                await getAllSubCategoriesData(e)
                await getAllDocTypeData(e, 'category')
            }
            setFormData(pre => {
                return {
                    ...pre,
                    sub_category_id: null,
                    doctype_id: null
                }
            })
        }
        if (key == 'sub_category_id') {
            if (e == null) {
                setAllDocTypeData([])
            } else {
                await getAllDocTypeData(e, 'subcategory')
            }
            setFormData(pre => {
                return {
                    ...pre,
                    doctype_id: null
                }
            })
        }
    }


    return (
        <>
            <AddInternalAttribute
                visible={openModal1}
                toggle={() => setOpenModal1(false)}
                dialogFor={"Phase"}
                label={"Phase"}
                value={name}
                setValue={setName}
                onSubmit={createPhase}
                loader={loader}
            />
            <AddInternalAttribute
                visible={openModal2}
                toggle={() => setOpenModal2(false)}
                dialogFor={"Category"}
                label={"Category"}
                value={name}
                setValue={setName}
                onSubmit={createCategory}
                loader={loader}
            />
            <AddInternalAttribute
                visible={openModal3}
                toggle={() => setOpenModal3(false)}
                dialogFor={"Sub-Category"}
                label={"Sub-Category"}
                value={name}
                setValue={setName}
                onSubmit={createSubCategory}
                loader={loader}
            />
            <AddInternalAttribute
                visible={openModal4}
                toggle={() => setOpenModal4(false)}
                dialogFor={"Document Type"}
                label={"Document Type"}
                value={name}
                setValue={setName}
                onSubmit={createDocType}
                loader={loader}
            />
            <Dialog header={type == 'Edit' ? 'Edit Item' : 'Create New'} visible={open} style={{ width: '40vw' }} onHide={toggle} breakpoints={{ '1200px': '60vw', '960px': '75vw', '641px': '90vw' }}>
                <div className='row'>
                    <div className="col-12 d-flex align-items-end">
                        <div className="w-100">
                            <label className="labels mb-2">Phase: <span className="text-danger">*</span></label>
                            {/* <AutoCompleteSelect
                                options={allPhase}
                                value={formData?.phase_id}
                                placeholder="Select Phase"
                                optionLabel="name"
                                className={`p-0 ${Validation.phase_id ? "Validation" : ""}`}
                                onChange={(e) => {
                                    handleChange(e, 'phase_id')
                                    // handleChangeData('set', e)
                                    setValidation({ ...Validation, phase_id: false })
                                }}
                            /> */}

                            <SingleSelect
                                disabled={type === "Edit"}
                                options={allPhase}
                                value={formData?.phase_id}
                                placeholder="Select Phase"
                                className={`p-0 ${Validation.phase_id ? "Validation" : ""}`}
                                onChange={(e) => {
                                    handleChange(e.value, 'phase_id')
                                    // handleChangeData('set', e)
                                    setValidation({ ...Validation, phase_id: false })
                                }}
                            />


                        </div>
                        <div className="mt-4 d-flex align-items-end ms-2">
                            {type !== "Edit" && <MdAdd
                                className="h2"
                                style={{ cursor: "pointer" }}
                                onClick={() => setOpenModal1(true)}
                            />}
                        </div>
                    </div>
                    <div className="col-12 mt-3 d-flex align-items-end">
                        <div className="w-100">
                            <label className="labels mb-2">Category: <span className="text-danger">*</span></label>
                            <SingleSelect
                                disabled={formData?.phase_id == null || type === "Edit"}
                                options={allCategory}
                                value={formData?.category_id}
                                className={`p-0 ${Validation.category_id ? "Validation" : ""}`}
                                placeholder="Select Category"
                                onChange={(e) => {
                                    handleChange(e.value, 'category_id')
                                    // handleChangeData('set', e)
                                    setValidation({ ...Validation, category_id: false })
                                }}
                            />
                        </div>
                        <div className="mt-4 d-flex align-items-end ms-2">
                            {type !== "Edit" && <MdAdd
                                className="h2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (formData?.phase_id !== null) {
                                        setOpenModal2(true)
                                    }
                                }}
                            />}
                        </div>
                    </div>
                    <div className="col-12 mt-3 d-flex align-items-end">
                        <div className="w-100">
                            <label className="labels mb-2">Sub-Category:</label>
                            <SingleSelect
                                disabled={formData?.phase_id == null || formData?.category_id == null || type === "Edit"}
                                options={allSubCategory}
                                value={formData?.sub_category_id}
                                className={`p-0 ${Validation.sub_category_id ? "Validation" : ""}`}
                                placeholder="Select Sub-Category"
                                onChange={(e) => {
                                    handleChange(e.value, 'sub_category_id')
                                    // handleChangeData('set', e)
                                    setValidation({ ...Validation, sub_category_id: false })
                                }}
                            />
                        </div>
                        <div className="mt-4 d-flex align-items-end ms-2">
                            {type !== "Edit" && <MdAdd
                                className="h2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (formData?.phase_id !== null && formData?.category_id !== null) {
                                        setOpenModal3(true)
                                    }
                                }}
                            />}
                        </div>
                    </div>
                    <div className="col-12 mt-3 d-flex align-items-end">
                        <div className="w-100">
                            <label className="labels mb-2">Document Type: <span className="text-danger">*</span></label>
                            <SingleSelect
                                disabled={formData?.phase_id == null || formData?.category_id == null || type === "Edit"}
                                options={allDocTypeData}
                                value={formData?.doctype_id}
                                placeholder="Select Document Type"
                                className={`p-0 ${Validation.doctype_id ? "Validation" : ""}`}
                                onChange={(e) => {
                                    handleChange(e.value, 'doctype_id')
                                    // handleChangeData('set', e)
                                    setValidation({ ...Validation, doctype_id: false })
                                }}
                            />
                        </div>
                        <div className="mt-4 d-flex align-items-end ms-2">
                            {type !== "Edit" && <MdAdd
                                className="h2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (formData?.phase_id !== null && formData?.category_id !== null) {
                                        setOpenModal4(true)
                                    }

                                }}
                            />}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <label className="labels mb-2">Add Template:</label>
                        <CustomDragAndDrop
                            value={file}
                            label='Attach File(s)'
                            setDeleted={setDeleteFile}
                            onChange={(e) => {
                                setFile(e)
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-end gap-3">
                        <button className='btn border' onClick={() => {
                            toggle()
                            setFormData({})
                        }}>Cancel</button>
                        <button className='btn btn-primary' disabled={btnloader} onClick={submitData}> {btnloader ? <div className='mx-2'>
                            <div class="spinner-border text-light spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div> : `${type === "Edit" ? "Update" : "Create"}`}</button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
