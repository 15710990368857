import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import { addIncident, addPrioritytype, addTypeT, getCountForIncidentByProjectId, getPrioritytype, getTypeT, getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { useNavigate } from "react-router";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FaAngleRight } from "react-icons/fa";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import Loader from "../../Components/Common/Loader";
import { uploadDocs } from "../../Api/Admin/AllAPIs";
import {
  getActionsPermission,
  getProjectName,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import { Editor } from "primereact/editor";
import { useSelector } from "react-redux";
import SingleSelect from "../../Components/Common/SingleSelect";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import { MdAdd } from "react-icons/md";
import AddSets from "../../Components/Common/AddSets";
import { Checkbox } from "primereact/checkbox";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import moment from "moment";

export const CreateIncident = () => {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [incidentDate, setIncidentDate] = useState(null);
  const [closeDate, setCloseDate] = useState(new Date())
  const [incidentTime, setIncidentTime] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [timeUnknown, setTimeUnknown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState();
  const [description, setDescription] = useState("");
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [validationTitle, setValidationTitle] = useState(false);
  // const [validationIdentifiedBy, setValidationIdentifiedBy] = useState(false)
  const [validationLocation, setValidationLocation] = useState(false);
  const [validationIncidentDate, setValidationIncidentDate] = useState(false);
  const [validationIncidentTime, setValidationIncidentTime] = useState(false);
  const [validationReviewer, setValidationReviewer] = useState(false);
  const [validationApprover, setValidationApprover] = useState(false);
  const [validationIncidentType, setValidationIncidentType] = useState(false);
  const [validationIncidentID, setValidationIncidentID] = useState(false);
  const [validationPriority, setValidationPriority] = useState(false);
  const [validationUser, setValidationUser] = useState(false);
  const [incidentID, setIncidentID] = useState("");
  const [incidentType, setIncidentType] = useState();
  const [types, setTypes] = useState([]);
  const [addType, setAddType] = useState('')
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [user, setUser] = useState("");
  const [priority, setPriority] = useState("");
  const [priorities, setPriorities] = useState([])
  const [addPriority, setAddPriority] = useState('')
  const [checked, setChecked] = useState(false);
  const [notifyChecked, setNotifyChecked] = useState(false);
  const [loading, setLoading] = useState(false)

  const projectId = localStorage.getItem("projectId");
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;


  let set = useSelector((d) => d.rolesAndPermissionData);
  const isApproverAccess = getActionsPermission("Incident Approver");
  const isReviewerAccess = getActionsPermission("Incident Reviewer");
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [approver, setApprover] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const domain = window.location.origin;
  const generateNextIncident = async () => {
    try {
      let res = await getCountForIncidentByProjectId(projectId)
      console.log(res)
      setIncidentID(res.data.data);
    } catch (Err) {
      console.log(Err)
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    generateNextIncident();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };

  const formatDateTime = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = "" + d.getFullYear();
    let hours = "" + d.getHours();
    let minutes = "" + d.getMinutes();
    let seconds = "" + d.getSeconds();
  
    // Pad single digit values with leading zeros
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;
  
    // Format date and time
    return [day, month,year].join("-") + " " + [hours, minutes, seconds].join(":");
  };

  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };

  const checkValidation = () => {
    let isValid = true;

    // Reset all validation states
    setValidationTitle(false);
    setValidationLocation(false);
    setValidationIncidentDate(false);
    setValidationIncidentTime(false);
    setValidationIncidentType(false);
    setValidationPriority(false);
    setValidationUser(false);
    setValidationReviewer(false);
    setValidationApprover(false);
    setValidationIncidentID(false);

    if (!incidentID) {
      setValidationIncidentID(true);
      isValid = false;
    }
    if (!title.trim()) {
      setValidationTitle(true);
      isValid = false;
    }

    if (!location.trim()) {
      setValidationLocation(true);
      isValid = false;
    }

    if (!incidentDate) {
      setValidationIncidentDate(true);
      isValid = false;
    }

    if (!incidentTime) {
      setValidationIncidentTime(true);
      isValid = false;
    }

    if (!incidentType) {
      setValidationIncidentType(true);
      isValid = false;
    }

    if (!priority) {
      setValidationPriority(true);
      isValid = false;
    }

    if (!user) {
      setValidationUser(true);
      isValid = false;
    }

    // Reviewer validation if applicable
    // if (isReviewerAccess && !reviewer) {
    //   setValidationReviewer(true);
    //   isValid = false;
    // }

    // // Approver validation if applicable
    // if (isApproverAccess && !approver) {
    //   setValidationApprover(true);
    //   isValid = false;
    // }

    if (!isValid) {
      toast.info("Please fill all the fields marked with * as mandatory.");
    }

    return isValid;
  };

  const handleCreate = async () => {
    if (selectedFiles.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (!checkValidation()) {
      return;
    }

    setLoader(true);

    try {
      const now = new Date();

      const formData = new FormData();

      let uploadResponse = {};
      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles) {
          formData.append("upload", file);
        }
        uploadResponse = await uploadDocs(formData);
      }

    
      let email_data = [];

  if (reviewer) {
      let reviewerData = allUsersOfProject.find(e => e._id == reviewer)
       email_data.push({
          name: reviewerData?.first_name,
          to: reviewerData?.email_address,
          tool_number: incidentID,
          tool: "Incident",
          tool_name: title,
          project_name: getProjectName(),
          project_id: "8767",
          subject: `FYI: Reviewer appointed to Incident #${incidentID} ${title}`,
          template_type:  "createdToolEmailToReviewer",
          url: `${domain}/coretools/tasktable`,
          created_by: getUserName()
      })
  }
  // send email to reviewer if selected 
  if (approver) {
      let approverData = allUsersOfProject.find(e => e._id == approver)
      email_data.push({
          name: approverData?.first_name,
          to: approverData?.email_address,
          tool_number: incidentID,
          tool: "Incident",
          tool_name: title,
          project_name: getProjectName(),
          project_id: "8767",
          subject: `FYI: Approver appointed to Incident #${incidentID} ${title}`,
          template_type: "createdToolEmailToApprover",
          url: `${domain}/coretools/tasktable`,
          created_by: getUserName()
      })
  }

//   user?.forEach((userId) => {
//     let currentUser = allUsersOfProject.find((e) => e._id == userId)
//     if (currentUser) {
//         email_data.push({
//             name: currentUser?.first_name,
//             to: currentUser?.email_address,
//             tool_number: incidentID,
//             tool: "Incident",
//             tool_name: title,
//             project_name: getProjectName(),
//             project_id: "8767",
//             template_type: "createdToolEmailToAssociatedUsers",
//             userType: "Incident identified by",
//             url: `${domain}/coretools/tasktable`, 
//             created_by: getUserName()
//         })
//     }
// })
if (notifyChecked)
{
  allUsersOfProject.forEach(user => {
    email_data.push({
        name: user.first_name,
        to: user.email_address,
        tool_number: incidentID,
        tool: "Incident",
        tool_name: title,
        project_name: getProjectName(),
        project_id: "8767",
        location:location,
        priority:priority,
        subject: `FYI:New Inciden created in ${getProjectName()}`,
        template_type: "incidentEmailTOAllUsers",
        userType: "All users",
        url: `${domain}/coretools/tasktable`,
        created_by: getUserName()
    });
});
}

      const payload = {

        title: title,
        incidentID: incidentID,
        location: location,
        priority: priority,
        // incident_closeDate: checked === true ? new Date() : undefined,
        incident_closeDate: checked === true ?  moment(closeDate).format('DD MMM YYYY  hh:mm A') : undefined,
        incident_time: incidentTime,
        incident_date: incidentDate,
        status: reviewer !== null && approver !== null ? 'inreview' : 'draft',
        description: description,
        project_id: projectId,
        attachFile: uploadResponse.data,
        approver_id: approver ? approver : null,
        reviewer_id: reviewer ? reviewer : null,
        incident_identifiedBy: user,
        incident_type: incidentType,
        email_data: email_data,
        notification: notifyChecked,
        creator_email: getUserId(),
        created_by: userName,
        project_name:projectData.project_name,
        project_number: projectData.projectNumber,
      }


      await addIncident(payload);
      console.log(payload, "payload")
      toast.success("Incident Created Successfully!");
      setTitle("");
      setIncidentID('');
      setLocation("");
      setIncidentDate("");
      setIncidentTime("");
      setSelectedFiles([]);
      setUser('');
      setLoader(false);
      navigate("/projectManagement/incident");
    } catch (error) {
      if (error?.response?.data?.message === "Incident number already exists") {
        toast.error(error?.response?.data?.message);
      }
      else {
        console.error("Error updating incident:", error);
        toast.error("Something Wents Wrong, Please Try Again!");
      }
      // console.error("Error creating incident:", error);
      setLoader(false);
    }
  };

  console.log(incidentTime);

  const handleCancel = () => {
    navigate("/projectManagement/incident");
    setTitle("");
    setLocation("");
    setIncidentDate("");
    setIncidentTime("");
    setSelectedFiles([]);
    setIncidentType("");
  };



  const statusCheck = [{ name: "Open" }, { name: "Close" }];

  let navigate = useNavigate();
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const getIncidentPriority = async (projectId) => {
    try {
      const res = await getPrioritytype(projectId)
      const typee = res?.data?.data
      const capitalizedPriority = typee.map(priority => ({
        ...priority,
        name: capitalizeFirstLetter(priority.name)
      }));
      setPriorities(capitalizedPriority)
    }
    catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   getIncidentPriority(projectId)
  // }, [])

  // const submitIncidentPriority = async () => {

  //   try {
  //     const payload = {
  //       name: addPriority,
  //       project_id: projectId
  //     }
  //     await addPrioritytype(payload)
  //     setAddPriority('')
  //     getIncidentPriority(projectId)
  //     setVisible2(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const getIncidentType = async (projectId) => {
    try {
      const res = await getTypeT(projectId)
      const typee = res?.data?.data
      const capitalizedtype = typee.map(type => ({
        ...type,
        name: capitalizeFirstLetter(type.name)
      }));
      setTypes(capitalizedtype)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIncidentType(projectId)
    getIncidentPriority(projectId)
  }, [])

  // const submitIncidentType = async () => {

  //   try {
  //     const payload = {
  //       name: addType,
  //       project_id: projectId
  //     }
  //     await addTypeT(payload)
  //     setAddType('')
  //     getIncidentType(projectId)
  //     setVisible2(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleCreateAddIcon = async (typ) => {

    try {
      if (typ === "type" && !addType) {
        toast.info(
          "Please fill  the  Submittal Category field marked with * are mandatory"
        );
        return;
      }

      if (typ === "priority" && !addPriority) {
        toast.info(
          "Please fill  the  Submittal Package field marked with * are mandatory"
        );
        return;
      }

     

      if (typ === "type") {
        setLoading(true)
        await addTypeT({
          name: addType,
          project_id: projectId
        });
        setAddType('')
        getIncidentType(projectId)
        setVisible(false);
        setLoading(false)
     
      } else if (typ === "priority") {
      setLoading(true)
      await addPrioritytype({
          name: addPriority,
          project_id: projectId
        });
        setAddPriority('')
        setVisible2(false)
        getIncidentPriority(projectId)
        setLoading(false)
      }
      

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" incident">
      <div className="show-data ">
        {loader ? (
          <Loader />
        ) : (
          <div>
            <div className="row m-0 p-0 mb-3">
              <div className="col-md-12    mb-3">
                <span
                  className="open_Rfi_text "
                  onClick={() => navigate("/projectManagement/incident")}
                >
                  {" "}
                  Incident
                </span>
                <span className=" open_Rfi_text"> /</span>
                <span className="createNew_text">Create Incident</span>
              </div>
            </div>

            <h1>INCIDENT</h1>
            <br />

            <div className="row m-0 p-0 mt-2 ">
              <div className="col-md-6 col-12 ">
                <span className=" labelCss"> Incident Title:</span>{" "}
                <span className="text-danger">*</span>
                <TextInput
                  //  className="my-2"
                  className={`my-2 ${validationTitle ? "Validation" : ""}`}
                  onClick={() => setValidationTitle(false)}
                  placeholder="Title Required"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-12 ">
                <span className=" labelCss"> Incident ID:</span>{" "}
                <span className="text-danger">*</span>
                <TextInput
                  //  className="my-2"
                  className={`my-2 ${validationIncidentID ? "Validation" : ""}`}
                  onClick={() => setValidationIncidentID(false)}
                  placeholder="Incident ID"
                  value={incidentID}
                  onChange={(e) => setIncidentID(e.target.value)}
                />
              </div>

            </div>
            <div className="row m-0 p-0 mt-2 ">
              <div className="col-md-6 col-12">
                <span className="py-2 labelCss">Location:</span>{" "}
                <span className="text-danger">*</span>
                {/* <SingleSelect options={[]} label={"Location:"} placeholder={"Location"} /> */}
                <TextInput
                  placeholder="Enter Location"
                  style={{}}
                  className={`mt-1 ${validationLocation ? "Validation" : ""}`}
                  onClick={() => setValidationLocation(false)}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></TextInput>
              </div>

              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label htmlFor="" className=" labelCss">
                    Incident Type: <span className="text-danger">*</span>
                  </label>
                  <div className="card flex justify-content-center  ">
                    <AutoCompleteSelect
                      options={types.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={incidentType}
                      optionLabel="incident_type"
                      onChange={(value) => {setIncidentType(value)
                        setValidationIncidentType(false)
                      }}
                      className={`mt-1 ${validationIncidentType ? "Validation" : ""
                        }`}
                      onClick={() => setValidationIncidentType(false)}
                      placeholder="Select Incident Type"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
            </div>

            <div className="row m-0 p-0 mt-2 ">
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Incident Identified By:{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({ label: item?.first_name, value: item?._id }))}
                      value={user}
                      optionLabel="user"
                      onChange={(value) => {setUser(value)
                        setValidationUser(false)
                      }}
                      className={`mt-1 ${validationUser ? "Validation" : ""}`}
                      onClick={() => setValidationUser(false)}
                      placeholder="Select Incident Identified By"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Priority: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={priorities.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={priority}
                      optionLabel="priority"
                      onChange={(value) => {setPriority(value)
                        setValidationPriority(false)
                      }}
                      className={`mt-1 ${validationPriority ? "Validation" : ""
                        }`}
                      onClick={() => setValidationPriority(false)}
                      placeholder="Select Trade"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible2(true)}
                  />
                </div>
              </div>
            </div>
            <div className="row m-0 p-0 mt-2 ">
              <div className="col-md-6">
                <span className="py-2 labelCss">Incident Date:</span>{" "}
                <span className="text-danger">*</span>
                <input
                  type="date"
                  style={{ height: "50px" }}
                  selected={incidentDate}
                  //  className="p-0 mt-1"
                  className={` mt-1 ${validationIncidentDate ? "Validation" : ""
                    } form-control`}
                  onClick={() => setValidationIncidentDate(false)}
                  placeholder="Event Date"
                  onChange={(e) => setIncidentDate(e.target.value)}
                />
              </div>

              <div className="col-md-6 col-12">
                <span className="labelCss">Incident Time:</span>{" "}
                <span className="text-danger">*</span>
                <input
                  type="time"
                  style={{ height: "50px" }}
                  className={`my-2 ${validationIncidentTime ? "Validation" : ""
                    } form-control`}
                  onClick={() => setValidationIncidentTime(false)}
                  value={incidentTime}
                  placeholder=" Incident Time"
                  onChange={(e) => setIncidentTime(e.target.value)}
                  timeOnly
                />
              </div>
            </div>
            <div className="row m-0 p-0">
              {isReviewerAccess && (
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject?.filter(e => e._id !== getUserId())?.map((item) => {

                      return {
                        label: item?.first_name,
                        value: item?._id,
                      }

                    })}
                    value={reviewer}
                    optionLabel="label"
                    onChange={(value) => {
                      setReviewer(value);
                    }}
                    className={`p-0 ${validationReviewer ? "Validation" : ""}`}
                    onClick={() => setValidationReviewer(false)}

                    disabled={reviewer === getUserId()}
                    placeholder="Select Reviewer"

                  />

                </div>
              )}
              {isApproverAccess && (
                <>
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver:
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject?.filter(e => e._id !== getUserId())?.map((item) => {
                        return {
                          label: item?.first_name,
                          value: item?._id,
                        }

                      })}
                      value={approver}
                      optionLabel="label"
                      onChange={(value) => {
                        setApprover(value);
                      }}
                      className={`p-0 ${validationApprover ? "Validation" : ""}`}
                      onClick={() => setValidationApprover(false)}

                      disabled={approver === getUserId()}
                      placeholder="Select Approver"

                    />
                  </div>
                </>

              )}

            

            </div>

            <div className="row m-0 p-0 mt-2  ">
              <div className="col-md-12">
                <span className="py-2 labelCss ">Incident Feedback:</span>
                <Editor
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  style={{ height: "200px" }}
                  className="mt-1"
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-2">
              <div className="col-md-3 d-flex align-items-center gap-2">
                <label htmlFor="" className="py-2 labelCss">
                  Incident Closed:
                </label>
                <Checkbox
                  style={{ color: "blue" }}
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
              </div>
              {checked === true ? <div className="col-md-3"> <input
                className="form-control"
                style={{ height: "50px" }}
                placeholder=""
                value={formatDateTime(closeDate)}
                onChange={(e) => setCloseDate(e.target.value)}
                disabled
              /></div> : ""}
            </div>
            <div className="row m-0 p-0 mt-2">
              <div className="col-md-3 d-flex align-items-center gap-2">
                <label htmlFor="" className="py-2 labelCss">
                  Notify Project Team:
                </label>
                <Checkbox
                  style={{ color: "blue" }}
                  onChange={(e) => setNotifyChecked(e.checked)}
                  checked={notifyChecked}
                ></Checkbox>
              </div>

            </div>



            <div className="row m-0 p-0 mt-4  ">
              <div className="col-md-12">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={selectedFiles}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                  label={"Incident Attachments"}
                />
              </div>
              {/* <div className="col-12 mt-3">
                        <CustomDragAndDrop
                            value={fileName}
                            setDeleted={setDeletedFiles}
                            onChange={(e) => {
                                console.log(e)
                                setFiles(e)
                            }}
                            label={"Documents"}
                        />
                    </div> */}
            </div>
            <div className="row m-0 p-0">
              <div className="col-12 d-flex justify-content-end mt-3">
                <button
                  className="btn border me-3"
                  onClick={() => navigate("/projectManagement/incident")}
                >
                  Cancel
                </button>
                <PrimaryButton text={"Create"} onClick={handleCreate} />
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <AddSets
       loading={loading}
       setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Incident Type"}
        label={"Incident Type"}
        reqFor={"create"}
        value={addType}
        setValue={setAddType}
        onSubmit={() => handleCreateAddIcon("type")}
      />
      <AddSets
       loading={loading}
       setLoading={setLoading}
        visible={visible2}
        setVisible={setVisible2}
        CategoryName={"Priority"}
        label={"Priority"}
        reqFor={"create"}
        value={addPriority}
        setValue={setAddPriority}
        onSubmit={() => handleCreateAddIcon("priority")}
      />
    </div>
  );
};
