import { Checkbox } from 'primereact/checkbox';
import React,{useState} from 'react'
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ImCross } from "react-icons/im";
import { useNavigate } from 'react-router-dom';


function ProjectSetting() {
    const [selectedProducts, setSelectedProducts] = useState(null);
    const navigate=useNavigate()
    const data = [{
        projectId: 1,
        projectName: 'Jackson Building',
        currentPermission:'Architect/Engineer',
        change:'Change Template'
    }]
    const dataOne = [
        {
        projectId: 1,
        projectName: 'Jackson Building',
        currentPermission:'Architect/Engineer',
        },
        {
            projectId: 1,
            projectName: 'Jackson Building',
            currentPermission:'Architect/Engineer',
            },
            {
                projectId: 1,
                projectName: 'Jackson Building',
                currentPermission:'Architect/Engineer',
                },
                {
                    projectId: 1,
                    projectName: 'Jackson Building',
                    currentPermission:'Architect/Engineer',
                    },
                    {
                        projectId: 1,
                        projectName: 'Jackson Building',
                        currentPermission:'Architect/Engineer',
                        },
                        {
                            projectId: 1,
                            projectName: 'Jackson Building',
                            currentPermission:'Architect/Engineer',
                            },
]
   
  return (
    <>
         <div className="mainContainer main-container">
                <div className="card p-md-4 p-sm-3 p-2 ">
                <div >
                <span className='fw-bold'>PROJECT SETTINGS</span>
            </div>
            <div className='mt-4'>
                <span className='fw-bold'>New Project Settings</span>
            </div>
            <hr/>
            <div className="row  ">
          <div className="col-12">
            <label htmlFor="" className="py-2 labelCss">
              Default permission template when added to projects:<i>Architect/Engineer</i>
            </label>
            <div className='flex gap-3 mt-1 ms-1'>
                <Checkbox/> <span>Add Aimee Archison to all new projects in Procore Certification</span>
            </div>
            <div className='flex gap-3 mt-1 ms-1'>
                <Checkbox/> <span>Add Aimee Archison to create new projects </span>
            </div>
            <hr/>
            <div className='flex justify-content-between'>
                <div className='fw-bold'>Current Project Settings</div>
                <div className='fw-bold' style={{color:'#f97b06'}}>New Feature</div>
            </div>
            <div className='flex justify-content-between'>
                <div >Aimee Archison currently has access to the following projects:</div>
                <div className='fw-bold'>
                <div className="d-flex align-items-center justify-content-center">
                                  <span
                                className="cancelBtn  px-2 ">
                                {" "}
                                Remove All
                            </span>
                                </div>
                </div>
            </div>
         
        </div>
        </div>
       <div className='mt-3'>
       <BorderedTable>
                    <DataTable 
                    value={data}
                      selection={selectedProducts}
                     onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="id" >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="projectId" header="Project Id" style={{ width: '7rem' }} ></Column>
                            <Column field="projectName" header="Project Name" ></Column>
                            <Column field="currentPermission" header="Current Permission Template" ></Column>
                            <Column field="change" header="" ></Column>
                            <Column field="" header="" style={{ width: '8rem' }} body={(rowData)=>(
                                <div className="d-flex align-items-center justify-content-center">
                                  <span
                                className="cancelBtn  px-3 py-2"

                            >
                                {" "}
                                Remove
                            </span>
                                </div>
                            )}></Column>
                        </DataTable>
                    </BorderedTable>
       </div>
       <div className='flex justify-content-between mt-5'>
                <div >Aimee Archison Does Not Belong To:</div>
                <div className='fw-bold'>
                <div className="d-flex align-items-center justify-content-center">
                                  <span
                                className="cancelBtn  px-2 ">
                                {" "}
                                Add All
                            </span>
                                </div>
                </div>
            </div>

            <div className='mt-3'>
       <BorderedTable>
                    <DataTable 
                    value={dataOne}
                      selection={selectedProducts}
                     onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="id" >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="projectId" header="Project Id" style={{ width: '7rem' }} ></Column>
                            <Column field="projectName" header="Project Name" ></Column>
                            <Column field="currentPermission" header="Current Permission Template" ></Column>
                            
                            <Column field="" header="" style={{ width: '8rem' }} body={(rowData)=>(
                                <div className="d-flex align-items-center justify-content-center">
                                  <span
                                className="cancelBtn  px-3 py-2"

                            >
                                {" "}
                                Add
                            </span>
                                </div>
                            )}></Column>
                        </DataTable>
                    </BorderedTable>
       </div>
       <div className="btn_main  mt-4 ">
                <span
                    className="cancelBtn  px-3 py-2"

                >
                    {" "}
                    Cancel
                </span>
                <div onClick={() => navigate("/projectSetting")}>
                    <span className="CreateRfi px-3 py-2"  >
                        Save
                    </span>
                </div>
            </div>



           
        

                </div>
                </div>
    </>
  )
}

export default ProjectSetting