import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import TextInput from "../../Components/Inputs/TextInput";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { Editor } from "primereact/editor";
import { uploadDocs } from "../../Api/Admin/AllAPIs";
import { toast } from "../../utils";

const AddMeetingItem = ({ visible, setVisible, itemIndex, handleAddRow }) => {
  const [title, setTitle] = useState();
  const [priority, setPriority] = useState();
  const [category, setCategory] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState();
  const [description, setDescription] = useState("");
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [Validation, setValidation] = useState({
    title: false,
    priority: false,
    category: false,
  });

  const checkValidation = () => {
    let hasErrors = false;

    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        title: false,
      }));
    }

    if (!priority) {
      setValidation((prevState) => ({
        ...prevState,
        priority: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        priority: false,
      }));
    }

    if (!category) {
      setValidation((prevState) => ({
        ...prevState,
        category: true,
      }));
      hasErrors = true;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        category: false,
      }));
    }

    if (hasErrors) {
      toast.info("Please fill all fields marked with * (required).");
    }

    return hasErrors;
  };

  const prioritys = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  const categorys = [{ name: "Safety" }];

  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };

  const handleFormSubmit = async () => {
    if (checkValidation()) {
      return;
    }

    let storedData = JSON.parse(localStorage.getItem("meetingFormData"));

    if (!Array.isArray(storedData)) {
      storedData = [];
    }

    let templateMeetingAttachment = [];

    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      for (const file of selectedFiles) {
        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);

      templateMeetingAttachment = uploadResponse.data;
    }

    const payload = {
      template_meeting_title: title,
      template_meeting_priority: priority.name,
      template_meeting_category: category.name,
      template_meeting_attachment: templateMeetingAttachment,
      template_meeting_description: description,
    };

    handleAddRow(itemIndex, payload);

    const newData = [...storedData, payload];
    localStorage.setItem("meetingFormData", JSON.stringify(newData));

    setTitle("");
    setPriority("");
    setCategory(null);
    setSelectedFiles("");
    setDescription("");
    setSelectedFiles("")
    setVisible(false);
  };

  return (
    <div className="card flex justify-content-center ">
      <Dialog
        header="Add a Meeting Item"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row ">
          <div className="col-md-6 ">
            <label className="labels py-2">
              Title : <span className="text-danger">*</span>
            </label>

            <div className="card flex justify-content-center  ">
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onClick={() => setValidation({ ...Validation, title: false })}
                className={Validation.title ? "Validation" : ""}
                placeholder="Title"
              />
            </div>
          </div>
          <div className="col-md-6">
            <label className="labels py-2">
              Priority : <span className="text-danger">*</span>
            </label>
            <div className="card flex justify-content-center  ">
              <Dropdown
                value={priority}
                onChange={(e) => setPriority(e.value)}
                options={prioritys}
                optionLabel="name"
                placeholder="Priority "
                onClick={() =>
                  setValidation({ ...Validation, priority: false })
                }
                className={`${
                  Validation.priority ? "Validation" : ""
                } w-full md:w-14rem dropdown_box`}
              />
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="labels py-2">
              Category : <span className="text-danger">*</span>
            </label>
            <div className="card flex justify-content-center  ">
              <Dropdown
                value={category}
                onChange={(e) => setCategory(e.value)}
                options={categorys}
                optionLabel="name"
                placeholder="Select Category "
                onClick={() =>
                  setValidation({ ...Validation, category: false })
                }
                className={`${
                  Validation.category ? "Validation" : ""
                } w-full md:w-14rem dropdown_box`}
              />
            </div>
          </div>
          <div className="col-md-12 py-2">
            <div className="card flex justify-content-center  ">
              <DragAndDrop
                setRemovePhoto={setRemovePhoto}
                value={fileName}
                setFiles={selectedFiles}
                onChange={handleFileInput}
                setValue={setFileName}
                setPreviousFiles={setPreviousFiles}
              />
            </div>
          </div>

          <div className="col-md-12 ">
            <label htmlFor="" className="py-2 labelCss">
              Description:
            </label>
            <div className="card flex justify-content-center  ">
              <Editor
                value={description}
                onTextChange={(e) => setDescription(e.htmlValue)}
                style={{ height: "200px" }}
              />
            </div>
          </div>
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <span className="clear_btn">Clear All</span>
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleFormSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default AddMeetingItem;
