import React, { useState } from "react";
import { PrimaryButton } from "../../../../../Components/Buttons/PrimaryButton";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TabPanel, TabView } from "primereact/tabview";
import { ExportPdfButton } from "../../../../../Components/Buttons/ExportPdfButton";

const Commitment = () => {
  const [activeStep, setActiveStep] = useState(0);
  let navigate = useNavigate();

  const steps = [
    {
      label: "Purchase Order",
      path: "/financialManagement/commitment",
    },
    {
      label: "Subcontractor",
      path: "/financialManagement/commitment/subcontractor",
    },
  ];

  return (
    <div className="commitment_section">
      {/* <div className="d-flex align-items-center justify-content-between">
        <h1>COMMINTMENTS</h1>
        <div className="d-flex gap-2">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <AiOutlineExport />
            Export
            <MdOutlineKeyboardArrowDown />
          </div>
          <div>
           {activeStep == 0 ?  <Link to={"/financialManagement/createPurchaseOrder"}>
              <PrimaryButton addIcon text={"Create Purchase Order"} />
            </Link> :  <Link to={"/financialManagement/createSubcontract"}>
              <PrimaryButton addIcon text={"Create Subcontract"} />
            </Link>}
          </div>
        </div>
      </div> */}

      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">COMMINTMENTS</div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <div className="right d-flex gap-2 align-items-center">
          <ExportPdfButton exportToPDF={""}/>
          </div>
          {activeStep == 0 ?  <Link to={"/financialManagement/createPurchaseOrder"}>
              <PrimaryButton addIcon text={"Create Purchase Order"} />
            </Link> :  <Link to={"/financialManagement/createSubcontract"}>
              <PrimaryButton addIcon text={"Create Subcontract"} />
            </Link>}
        </div>
      </div>


      <div>
        <TabView
          activeIndex={activeStep}
          onTabChange={(e) => {
            setActiveStep(e.index);
            navigate(steps[e.index].path);
          }}
        >
          {steps.map((res, key) => (
            <TabPanel key={key} header={res.label} />
          ))}
        </TabView>
        <Outlet />

        
      </div>
    </div>
  );
};

export default Commitment;
