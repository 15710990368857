import React, { useRef, useState } from "react";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { Column } from "primereact/column";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import "./submittal.css";
import FilterDailog from "./Filter";
import { useNavigate } from "react-router-dom";
import {
  deleteSubmittalById,
  getSubmittalByFilter,
  getSubmittalDataByProjectId,
  updateSubmittalById,
} from "../../Api/Admin/AllAPIs";
import { useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import {
  getProjectCode,
  getProjectId,
  getProjectName,
  getUserData,
  getUserId,
  isApproverOfProject,
  isReviewerOfProject,
  searchDataWithKeys,
  showDateAndTime,
  toast,
} from "../../utils";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { ImCross } from "react-icons/im";
import { Statuscomp } from "../../Components/Common/StatusComp";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import moment from "moment";
import {
  HtmlIntoString,
  HtmlIntoText,
} from "../../Components/Common/HtmlIntoText";
import Pagination from "../../Components/Common/Pagination/Pagination";
import Filter from "./Filter";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";

const Submittals = () => {


  const [visibleDelete, setVisibleDelete] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [submittal, setSubmittal] = useState([]);
  const [search, setSearch] = useState("");
  const [submittalId, setSubmittalId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [originalSubmittal, setOriginalSubmittal] = useState([]);
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [currentStatusRowData, setCurrentStatusRowData] = useState({});
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: "",
  });

  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPageData = 10;

  const [filterData, setFilterData] = useState({
    submittal_submittalType: "",
    submittal_status: "",
    submittal_responsibleContractor: null,
    submittal_finalDueDate: null,
  });
  const [filterActive, setFilterActive] = useState(false);

  const [tempFilterData, setTempFilterData] = useState({});

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            submittal_review_status: "approved",
            reviewer_comment: comment,
            submittal_approval_status: "under_review",
            submittal_status: "close",
            email_data: [
              {
                email: currentStatusRowData?.approver?.email_address,
                type: "Approver",
                rfi_name: currentStatusRowData?.subject,
                project: getProjectName(),
                toolName: "SUBMITTAL",
                name: currentStatusRowData?.approver?.first_name,
              },
            ],
            user_name: getUserData()?.first_name || "",
          };

          await updateSubmittalById(itemId, payload);
        } else {
          let payload = {
            submittal_review_status: "unapproved",
            reviewer_comment: comment,
            submittal_status: "reopen",
            submittal_approval_status: "pending",
            user_name: getUserData()?.first_name || "",
          };
          await updateSubmittalById(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            submittal_approval_status: "approved",
            approver_comment: comment,
            submittal_review_status: "approved",
            user_name: getUserData()?.first_name || "",
          };
          await updateSubmittalById(itemId, payload);
        } else {
          let payload = {
            submittal_review_status: "under_review",
            submittal_approval_status: "unapproved",
            approver_comment: comment,
            user_name: getUserData()?.first_name || "",
          };
          await updateSubmittalById(itemId, payload);
        }
      } else {
        console.log("nonooo");
      }
    } catch (err) {
      console.log(err);
    } finally {
      await fetchData();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }

  async function fetchData() {
    try {
      setSkeletonLoader(true);

      let params = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search,
      };
      let body = { ...tempFilterData };

      const res = await getSubmittalDataByProjectId(
        getProjectId(),
        params,
        body
      );
      const data = res?.data?.data || [];
      const reversedData = data.reverse();
      const sortedData = data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setSearch("");
      console.log(data, "pppppppppppppppppppppppppppppppppa");
      setTotalCount(res?.data?.totalCount || 0);
      setSubmittal(sortedData);
      setAllData(sortedData);
      setOriginalSubmittal(data);
    } catch (error) {
      console.log(error, "error loading data");
    } finally {
      setSkeletonLoader(false);
    }
  }

  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setSubmittalId(id);
  };
  const confirmDelete = async () => {
    setLoader(true);
    let payload ={
      created_by:userName,
    }
    try {
      const res = await deleteSubmittalById(submittalId,payload);
      setLoader(false);
      toast.success("Submittal Deleted Successfully!");
      console.log("data deleted", res);
      setSearch("");
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const formatHourSpent = (hours) => {
    console.log(hours, "check");
    if (!hours || hours === "--") return "N/A";

    const parts = hours.split("-").map(Number);

    if (parts.some(isNaN)) return "Invalid";

    const [min, max] = parts;

    if (parts.length === 1) {
      if (min < 1) {
        return `<1 hour`;
      } else {
        return `${min} hours`;
      }
    } else if (max) {
      if (max >= 10) {
        return `${min} hours ${max} min`;
      } else {
        return `${min} hours ${max} min`;
      }
    } else {
      return `${min} hours`;
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
  }, [tableSortData, tempFilterData]);

  const csvData = submittal.map((item) => ({
    Title: item.submittal_title,
    Type: item.submittal_submittalType,
    Status: item.submittal_status,
    "Responsible Contractor": item.submittal_responsibleContractor
      ?.map((contractor) => contractor.company_name)
      .join(", "),
    "Submittal CostCode": item.submittal_costCode,
    "Specific Section": item.submittal_specSection,
    "Submittal Number": item.submittal_number,
    "Submittal Revision": item.submittal_revision,
    "Submitted By": item.submittal_submitBy,
    "Received Date": moment(item?.submittal_receivedDate).format("DD/MM/YYYY"),
    "Issue Date": moment(item?.submittal_issueDate).format("DD/MM/YYYY"),
    "Created At": moment(item?.submittal_finalDueDate).format("DD/MM/YYYY"),
    "Submittal Package": item.submittal_submittalPackage,
    "Received From": item.submittal_receivedFrom,
    "Submittal Manager": item.submittal_submittalManager
      ?.map((manager) => manager.first_name)
      .join(", "),
    Location: item.submittal_location,
    "Linked Drawings": item.submittal_linkedDrawings,
    "Distribution List": item.submittal_distributionList,
    "WorkFlow's Status": item.workflow_status, // Added
    "Hourly Spent": item?.hours_spent, // Added
    Description: HtmlIntoString({ html: item.submittal_description }),
    Deleted: item.is_deleted ? "Yes" : "No",
    // "Created At": new Date(item.created_at).toLocaleString(),
    "Created At": moment(item?.created_at).format("DD/MM/YYYY"),
  }));

  // Define CSV headers
  // Define CSV headers
  const csvHeaders = [
    { label: "Title", key: "Title" },
    { label: "Type", key: "Type" },
    { label: "Status", key: "Status" },
    { label: "Responsible Contractor", key: "Responsible Contractor" },
    { label: "Submittal CostCode", key: "Submittal CostCode" },
    { label: "Specific Section", key: "Specific Section" },
    { label: "Submittal Number", key: "Submittal Number" },
    { label: "Submittal Revision", key: "Submittal Revision" },
    { label: "Submitted By", key: "Submitted By" },
    { label: "Received Date", key: "Received Date" },
    { label: "Issue Date", key: "Issue Date" },
    { label: "Final Due Date", key: "Final Due Date" },
    { label: "Submittal Package", key: "Submittal Package" },
    { label: "Received From", key: "Received From" },
    { label: "Submittal Manager", key: "Submittal Manager" },
    { label: "Location", key: "Location" },
    { label: "Linked Drawings", key: "Linked Drawings" },
    { label: "Distribution List", key: "Distribution List" },
    { label: "WorkFlow's Status", key: "WorkFlow's Status" }, // Added
    { label: "Hourly Spent", key: "Hourly Spent" }, // Added
    { label: "Description", key: "Description" },
    { label: "Deleted", key: "Deleted" },
    { label: "Created At", key: "Created At" },
  ];

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();

    const imgData = CDC_LOGO_URL;
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, "JPEG", imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${
      getProjectCode() || "-"
    })`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);

    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {},
    });

    // Save the PDF
    doc.save("Submittal.pdf");
  };

  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
  }
  function onFilter() {
    setTempFilterData(filterData);
    setPage(1);
    setFilterActive(true);
  }
  function clearFilter() {
    setFilterActive(false);
    setFilterData({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      dueDate: null,
    });
    setTempFilterData({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      dueDate: null,
    });
  }

  function searchValue(e) {
    setTableSortData((pre) => ({
      ...pre,
      page: 1,
      search: e.target.value,
    }));
  }

  //   Approver and reviewer

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }
    setOpen(false);

    try {
      if (type == "reviewer") {
        let emailData = [];
        let payload = {
          submittal_status: dropdownvalue?.code,
          reviewer_comment: comment,
          project_id: projectId,
          email_data: [],
          id: currentStatusRowData?.ID,
        };

        // creator_email
        if (dropdownvalue?.code == "pendingapproval") {
          emailData = [
            {
              email: currentStatusRowData?.approver?.email_address,
              type: "Approver",
              tool_name: currentStatusRowData?.submittal_title,
              project: getProjectName(),
              toolType: "Submittal",
              name: currentStatusRowData?.approver?.first_name,
              project_id: "8767",
              tool_id: currentStatusRowData?.ID,
              createdAt: moment().format("DD MMM YYYY  hh:mm A"),
              createdBy: userData?.name,
            },
          ];
        }

        emailData.push({
          email: currentStatusRowData?.creator_email?.email_address,
          type: "Project Creator",
          tool_name: currentStatusRowData?.submittal_title,
          project: getProjectName(),
          toolType: "Submittal",
          name: currentStatusRowData?.approver?.first_name,
          project_id: "8767",
          tool_id: currentStatusRowData?.submittal_number,
          createdAt: moment().format("DD MMM YYYY  hh:mm A"),
          createdBy: userData?.name,
        });

        payload.email_data = emailData;

        await updateSubmittalById(itemId, payload);
      } else {
        let emailData = [];
        let payload = {
          submittal_status: dropdownvalue?.code,
          approver_comment: comment,
          project_id: projectId,
          id: currentStatusRowData?.submittal_number,
        };
        if (dropdownvalue?.code == "approved") {
          emailData.push({
            email: currentStatusRowData?.creator_email?.email_address,
            type: "Project Creator",
            tool_name: currentStatusRowData?.submittal_title,
            project: getProjectName(),
            toolType: "Submittal",
            name: currentStatusRowData?.approver?.first_name,
            project_id: "8767",
            tool_id: currentStatusRowData?.submittal_number,
            createdAt: moment().format("DD MMM YYYY  hh:mm A"),
            createdBy: userData?.name,
          });
        }
        payload.email_data = emailData;
        await updateSubmittalById(itemId, payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
      setItemId("");
      setType("");
      // setLoader(false)
      setDropDownValue(null);
    }
  }

  function changeStatusUi(submittal_status) {
    switch (submittal_status) {
      case "draft":
        return (
          <div className="statusOuterD">
            <div className="statusDotD" />
            <div className="StatusD">Draft</div>
          </div>
        );
        break;
      case "inreview":
        return (
          <div className="statusOuterI">
            <div className="statusDotI" />
            <div className="StatusI">In Review</div>
          </div>
        );
        break;
      case "pendingapproval":
        return (
          <div className="statusOuterP">
            <div className="statusDotP" />
            <div className="StatusP">Pending Approval</div>
          </div>
        );
        break;
      case "approved":
        return (
          <div className="statusOuterA">
            <div className="statusDotA" />
            <div className="StatusA">Approved</div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  function changeStatusUi2(status) {
    console.log(status);
    switch (status) {
      case "Not Started":
        return (
          <div className="statusOuterns">
            <div className="statusDotns" />
            <div className="Statusns">Not Started</div>
          </div>
        );
        break;
      case "In Progress":
        return (
          <div className="statusOuterip">
            <div className="statusDotip" />
            <div className="Statusip">In Progress</div>
          </div>
        );
        break;
      case "Completed":
        return (
          <div className="statusOuterc">
            <div className="statusDotc" />
            <div className="Statusc">Completed</div>
          </div>
        );
        break;
      case "Ready for Review":
        return (
          <div className="statusOuterr">
            <div className="statusDotr" />
            <div className="Statusr">Ready for Review</div>
          </div>
        );
        break;
      case "Incomplete":
        return (
          <div className="statusOuterin">
            <div className="statusDotin" />
            <div className="Statusin">Incomplete</div>
          </div>
        );
        break;

      default:
        break;
    }
  }
  async function statusChange(data, id, toolId, comment) {
    try {
      let res = await updateSubmittalById(id, {
        email_data: data.email_data,
        submittal_status: data.status,
        submittal_number: toolId,
        ...comment,
      });
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {/* <ChangeStatusModal
                open={open}
                setOpen={setOpen}
                value={dropdownvalue}
                setValue={setDropDownValue}
                onSubmit={changeStatus}
                setComment={setComment}
                statusRequired={statusRequired}
                setStatusRequired={setStatusRequired}
            /> */}
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        type={type}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />
      {loader ? (
        <Loader />
      ) : (
        <div className="Submittal_section">
          <div className="d-flex flex-sm-row flex-column justify-content-between">
            <div className="page_heading">Submittals</div>
            <div className="right d-flex align-items-center justify-content-end mt-sm-0 mt-3">
              <div className=" d-flex align-items-center">
                <ExportPdfButton
                  exportToPDF={exportToPDF}
                  csvBtn={
                    <CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"submittals.csv"}
                    >
                      CSV
                    </CSVLink>
                  }
                />
              </div>
              <PrimaryButton
                onClick={() => navigate("createsubmittals")}
                addIcon
                text={"Create"}
                className={`ms-2`}
              />
            </div>
          </div>
          <div style={{ height: "0", overflow: "hidden" }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>Documents No</th>
                  <th>Title</th>
                  <th>Type</th>

                  <th>Responsible Contractor</th>
                  <th>Submittal CostCode</th>
                  <th>Submittal Category</th>
                  {/* <th>Submittal Number</th> */}
                  <th>Submittal Revision</th>
                  <th>Submittal Create Date</th>
                  <th>Document Recieve date </th>
                  <th>Document Issue date</th>
                  <th>Final Due Date</th>
                  <th>Submittal Package</th>
                  <th>Received From</th>
                  <th>Submittal Manager</th>
                  <th>Location</th>
                  <th>Linked Drawings</th>
                  <th>Responsible team</th>
                  <th>WorkFlow's Status</th>
                  {/* <th>Hourly Spent</th> */}

                  <th>Description</th>
                  <th>Deleted</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {originalSubmittal?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.submittal_number}</td>
                      <td>{item.submittal_title}</td>
                      <td>{item.submittal_submittalType}</td>
                      {/* <td>{item.submittal_status}</td> */}
                      <td>
                        {item.submittal_responsibleContractor
                          ?.map((contractor) => contractor.company_name)
                          .join(", ")}
                      </td>
                      <td>{item.submittal_costCode}</td>
                      <td>{item.submittal_specSection}</td>
                      <td>{item.submittal_revision}</td>
                      <td>
                        {moment(item?.submittal_submitBy).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {moment(item?.submittal_receivedDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.submittal_issueDate).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {moment(item?.submittal_finalDueDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>

                      <td>{item.submittal_submittalPackage}</td>
                      <td>{item.submittal_receivedFrom}</td>

                      <td>
                        {item.submittal_submittalManager
                          ?.map((manager) => manager.first_name)
                          .join(", ")}
                      </td>
                      <td>{item.submittal_location}</td>
                      <td>{item.submittal_linkedDrawings}</td>
                      <td>{item.submittal_distributionList}</td>
                      <td>{item?.workflow_status}</td>
                      {/* <td>{formatHourSpent(item?.hourSpent)}</td> */}
                      <td>
                        <HtmlIntoText html={item.submittal_description} />
                      </td>
                      <td>{item.is_deleted ? "Yes" : "No"}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
            <div className="searchInput">
              <SearchInput
                value={tableSortData.search}
                onChange={searchValue}
                onCancel={() => {
                  setTableSortData({
                    page: 1,
                    search: "",
                  });
                  fetchData();
                }}
              />
            </div>

            <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
              <Filter
                className="w-100"
                filterData={filterData}
                setFilterData={setFilterData}
                onFilter={onFilter}
              />
              <button
                disabled={!filterActive}
                className="filter_btn w-100"
                onClick={clearFilter}
              >
                Clear Filter
              </button>
            </div>
          </div>

          <br />
          <div style={{ width: "100%", overflow: "auto" }}>
            <div style={{ width: "2400px" }}>
              {skeletonLoader ? (
                <TableSkelton
                  header={[
                    "Documents No",
                    "Title",
                    "Responsible Contractor",
                    "Responsible Team",
                    "Submittal manager",
                    " 	Final Due Date ",
                    "Approval Status",
                    "",
                  ]}
                />
              ) : (
                <BorderedTable>
                  <DataTable value={submittal}>
                    <Column
                      sortable
                      field="submittal_number"
                      header="Documents No"
                      body={(rowData, rowIndex) => (
                        <div>{rowData.submittal_number}</div>
                      )}
                    ></Column>
                    <Column
                      sortable
                      field="workflow_status"
                      header="Workflow Status"
                      body={(rowData) =>
                        changeStatusUi2(rowData?.workflow_status)
                      }
                    ></Column>
                    <Column
                      field="submittal_title"
                      sortable
                      header="Title"
                      body={(rowData) => (
                        <div
                          className=""
                          // onClick={() => navigate(`view/${rowData?._id}`)}
                          style={{ cursor: "pointer" }}
                        >
                          {rowData?.submittal_title}
                        </div>
                      )}
                    ></Column>

                    <Column
                      style={{ maxWidth: "20vw" }}
                      field="submittal_responsibleContractor"
                      sortable
                      header="Responsible Contractor Name"
                      body={(rowData) => {
                        let data =
                          rowData?.submittal_responsibleContractor || [];

                        return (
                          <div>
                            {data?.map((item) => item?.company_name).join(", ")}
                          </div>
                        );
                      }}
                    ></Column>

                    <Column
                      sortable
                      field="submittal_distributionList"
                      header="Responsible Team"
                    ></Column>

                    {/* <Column
                                            field="submittal_submittalManager"
                                            header=" Submittal manager"
                                        ></Column> */}

                    <Column
                      style={{ maxWidth: "20vw" }}
                      field="submittal_submittalManager"
                      sortable
                      header="Submittal Manager"
                      body={(rowData) => {
                        let data = rowData?.submittal_submittalManager || [];

                        return (
                          <div>
                            {data?.map((item) => item.first_name)?.join(", ")}
                          </div>
                        );
                      }}
                    ></Column>

                    <Column
                      field="submittal_finalDueDate"
                      sortable
                      body={(rowData) => {
                        return (
                          <div>
                            {moment(rowData?.submittal_finalDueDate)?.format(
                              "DD-MM-yyyy"
                            )}
                          </div>
                        );
                      }}
                      header=" Final Due Date"
                    ></Column>
                    {/* <Column
                                            field="approve"
                                            header="Approval Status"
                                            body={(rowData) => {
                                                return (
                                                    <>
                                                        <div className={`d-flex align-items-center `}>
                                                            {rowData?.submittal_status === "approved" && (
                                                                <div className="w-100">
                                                                    {changeStatusUi(rowData?.submittal_status)}
                                                                </div>
                                                            )}
                                                            {rowData?.submittal_status === "pendingapproval" && (
                                                                <div
                                                                    className="w-100"
                                                                    title="Pending Approval"
                                                                    onClick={() => {
                                                                        if (getUserId() === rowData?.approver_id) {
                                                                            setOpen(true);
                                                                            setType("approver");
                                                                            setItemId(rowData._id);
                                                                            setCurrentStatusRowData(rowData);
                                                                        }
                                                                    }}
                                                                >
                                                                    {changeStatusUi(rowData?.submittal_status)}
                                                                </div>
                                                            )}
                                                            {rowData?.submittal_status === "draft" &&
                                                                changeStatusUi(rowData?.submittal_status)}
                                                            {rowData?.submittal_status === "inreview" && (
                                                                <div
                                                                    className="w-100"
                                                                    title="Pending review"
                                                                    onClick={() => {
                                                                        // console.log(getUserId())
                                                                        // console.log(rowData?.reviewer_id)
                                                                        // if (userData?.user_type == 'admin' || getUserId() === rowData?.reviewer_id) {
                                                                        if (getUserId() === rowData?.reviewer_id) {
                                                                            setOpen(true);
                                                                            setType("reviewer");
                                                                            setItemId(rowData._id);
                                                                            setCurrentStatusRowData(rowData);
                                                                        }
                                                                    }}
                                                                >
                                                                    {changeStatusUi(rowData?.submittal_status)}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                );
                                            }}
                                            style={{ width: "5rem" }}
                                        ></Column> */}
                    <Column
                      field="submittal_status"
                      sortable
                      headerClassName="text-nowrap"
                      body={(rowData) => {
                        return (
                          <ChangeApprovalStatus
                            status={rowData?.submittal_status}
                            onSubmit={statusChange}
                            toolData={{
                              tool_id: rowData?._id,
                              tool_number: rowData?.submittal_number,
                              tool_name: rowData?.submittal_title,
                              tool: "Submittal",
                              _id: rowData?._id,
                              approver_id: rowData?.approver_id,
                              reviewer_id: rowData?.reviewer_id,
                              reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                              approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                              reviewer_email: `${rowData?.reviewer?.email_address}`,
                              approver_email: `${rowData?.approver?.email_address}`,
                              creator_name:
                                rowData?.creator_email?.[0]?.first_name ||
                                rowData?.creator_email?.[0]?.name,
                              creator_email:
                                rowData?.creator_email?.[0]?.email_address ||
                                rowData?.creator_email?.[0]?.email,
                            }}
                            rowData={rowData}
                          />
                        );
                      }}
                      header="Approval Status"
                    ></Column>
                    <Column
                      header="Action"
                      body={(rowData) => (
                        <div className="d-flex justify-content-center">
                          <Popover
                            options={[
                              "Version History",
                              "Edit",
                              "Delete",
                              "View",
                              <DownloadPdfAndSendEmail
                                heading={"SUBMITTAL"}
                                fileName={"Submittal"}
                                id={rowData?._id}
                                files={rowData?.submittal_file?.map(
                                  (e) => e?.url
                                )}
                              >
                                {console.log(rowData)}
                                <ViewPagesPdfTemplate
                                  header={`#SUBMITTAL ${rowData?.submittal_title}`}
                                  tableData1={[
                                    {
                                      "Responsible Contractor":
                                        rowData?.submittal_responsibleContractor
                                          ?.map(
                                            (contractor) =>
                                              contractor.company_name
                                          )
                                          .join(", ") || "--",
                                    },
                                    {
                                      "Received From":
                                        rowData?.submittal_receivedFrom || "--",
                                    },
                                    
                                    {
                                      "Document Number":
                                        rowData?.submittal_number || "--",
                                    },
                                    {
                                      "Submittal Manager":
                                        rowData?.submittal_submittalManager
                                          ?.map((manager) => manager.first_name)
                                          .join(", "),
                                    },
                                    {
                                      "Submittal Type":
                                        rowData?.submittal_submittalType ||
                                        "--",
                                    },
                                    {
                                      Location:
                                        rowData?.submittal_location || "--",
                                    },
                                    { Title: rowData?.submittal_title || "--" },
                                    {
                                      "Document Version":
                                        rowData?.submittal_revision || "--",
                                    },
                                    {
                                      "Submittal Package":
                                        rowData?.submittal_submittalPackage ||
                                        "--",
                                    },
                                    {
                                      "Hours Spent": rowData?.hourSpent
                                        ? `${
                                            rowData?.hourSpent?.split("-")[0]
                                          } hour ${
                                            rowData?.hourSpent?.split("-")[1]
                                          } Min`
                                        : "--",
                                    },
                                    { "Reviewer": rowData?.reviewer?.first_name|| "--" },
                                    { "Reviewer Comment":rowData?.reviewer_comment || "--" },
                                  ]}
                                  description={{
                                    key: "Specification Description",
                                    value: (
                                      <HtmlIntoText
                                        html={rowData?.submittal_description}
                                      />
                                    ),
                                  }}
                                  tableData2={[
                                    {
                                      "Submittal Create Date":
                                        moment(
                                          rowData?.submittal_submitBy
                                        ).format("DD/MM/YYYY") || "--",
                                    },
                                    {
                                      "Document Issue date:":
                                        moment(
                                          rowData?.submittal_issueDate
                                        ).format("DD/MM/YYYY") || "--",
                                    },
                                    {
                                      "Document Recieve date":
                                        moment(
                                          rowData?.submittal_receivedDate
                                        ).format("DD/MM/YYYY") || "--",
                                    },
                                    {
                                      "Final Due Date":
                                        moment(
                                          rowData?.submittal_finalDueDate
                                        ).format("DD/MM/YYYY") || "--",
                                    },
                                    {
                                      "Cost Code":
                                        rowData?.submittal_costCode || "--",
                                    },
                                    {
                                      "WorkFlow's Status":
                                        rowData?.workflow_status || "--",
                                    },
                                    {
                                      "Linked Drawings":
                                        rowData?.submittal_linkedDrawings ||
                                        "--",
                                    },
                                    {
                                      "Responsible team":
                                        rowData?.submittal_distributionList ||
                                        "--",
                                    },
                                    {
                                      "Submittal Category":
                                        rowData?.submittal_specSection ||
                                        "--",
                                    },
                                    { "Approver": rowData?.approver?.first_name || "--" },
                                    { "Approver Comment":rowData?.approver_comment || "--" },
                                 
                                    {
                                      Description:
                                        rowData?.submittal_description || "--",
                                    },
                                  ]}
                                />
                              </DownloadPdfAndSendEmail>,
                              "Send Via Post",
                            ]}
                            onSelect={(index) => {
                              if (index === 0) {
                                navigate(
                                  `/projectManagement/version-history/submittal/${rowData?.submittal_title}/${rowData?._id}`
                                );
                              } else if (index === 1) {
                                navigate(`${rowData?._id}`);
                              }
                              // else if (index === 2){

                              // }
                              else if (index === 2) {
                                handleDelete(rowData?._id);
                              } else if (index === 3) {
                                navigate(
                                  `view/SUBMITTAL/${rowData?.submittal_title}/${rowData?._id}`
                                );
                              } else if (index === 5) {
                                navigate(
                                  `/projectManagement/addressListing/submittal/${rowData?.submittal_title}/${rowData?._id}`
                                );
                              }
                            }}
                          />
                          {/* <div>
                      <EditButton
                        onClick={() => navigate(`${rowData?._id}`)}
                      />
                    </div>
                    <div onClick={() => handleDelete(rowData?._id)}>
                    <DeleteButton
                                  visible={!!submittalId}
                                  onCancel={() => setSubmittalId(null)}
                                  onConfirm={confirmDelete}
                                  header={"Form"}
                                />
                    </div> */}
                        </div>
                      )}
                    ></Column>
                  </DataTable>
                </BorderedTable>
              )}
            </div>
          </div>
          <br />
          <Pagination
            currentPage={tableSortData.page}
            totalCount={totalCount}
            pageSize={perPageData}
            onPageChange={(e) =>
              setTableSortData((pre) => ({ ...pre, page: e }))
            }
          />
        </div>
      )}

      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setSubmittalId(null)}
        onConfirm={confirmDelete}
        header={"Submittal"}
      />
    </>
  );
};
export default Submittals;
