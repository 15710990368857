import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "./SingleSelect.css";
const SingleSelect = ({ label, placeholder, ...rest }) => {
  return (
    <>
      <div className="flex flex-column ">
        <label className="labelCss">{label}</label>
        <Dropdown  placeholder={placeholder || label} {...rest}   />
      </div>
    </>
  );
};

export default SingleSelect;
