import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useRef, useState } from 'react';
import "./createAddress.css";
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteAddress, getAddressByToolId, getCodeCost, getProject, getProjectAssignData, getProjectHistory } from '../../Api/Admin/AllAPIs';
import moment from 'moment/moment';
import { CreateAddress } from './CreateAddress';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';
import { Checkbox } from "primereact/checkbox";
import { Dialog } from 'primereact/dialog';
import RfiView from '../../Components/openrfis/RfiView';
import { downloadPdfUsingCanvas } from '../../Components/Common/ViewPageComponents/downloadPdfUsingCancas';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { ViewDailyLog } from '../DailyLog/ViewDailyLog';
import { Viewtask } from '../Task/Viewtask';
import ViewTransmittals from '../Transmittals/ViewTransmittals';
import { ViewObservation } from '../Observation/ViewObservation';
import { ViewSpecification } from '../Specifications/ViewSpecification';
import { ViewSubmittal } from '../Submittals/ViewSubmittal';
import { ViewPunch } from '../Punch/ViewPunch';
import { ViewIncident } from '../Incidents/ViewIncident';
import { ViewIssue } from '../CoordinationIssues/ViewIssue';
import { CoordinationIssues } from '../CoordinationIssues/CoordinationIssues';
import { getProjectName } from '../../utils';
import ViewProjectInformation from '../ProjectList/ViewProjectInformation';
import ViewProjectStages from '../ProjectList/ViewProjectStages';
import ViewProjectDirectory from '../ProjectList/ViewProjectDirectory';
import profile from "../../assets/profile.png";
import ViewCostCode from '../ProjectList/ViewCostCode';
import ViewProjectTools from '../ProjectList/ViewProjectTools';
import ViewProjectDrawing from '../ProjectList/ViewProjectDrawing';
import ViewProjectSpecifications from '../ProjectList/ViewProjectSpecifications';
import GridSkeleton from '../../Components/Common/GridSkeleton';
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InspectionsTemplate from '../Inspections/InspectionsTemplate';
import ViewParticularDrawing from '../DrawingFolder/ViewParticularDrawing';
import { ViewMeeting } from '../Meetings/ViewMeeting';

export const AddressListing = ({ useFor }) => {
    let { id, heading } = useParams();


    const [projectData, setProjectData] = useState({});
    const [projectDirectory, setProjectDirectory] = useState([]);
    const [codeData, setCodeData] = useState([]);
    let [allAddres, setAllAddress] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [includeVersionHistory, setIncludeVersionHistory] = useState(false);
    const [version, setVersion] = useState([])
    const [printDialog, setPrintDialog] = useState(false);
    const [pdfGenereationLoader, setPdfGenereationLoader] = useState(false);
    const [pdfAction, setPdfAction] = useState("Print");
    const projectId = localStorage.getItem("projectId");
    let navigate = useNavigate()
    async function getAllAddress() {
        try {
            let res = await getAddressByToolId(projectId);
            console.log(res.data.data, "lllllllllllllllllllllllllllllllllllllllllllll");
            setAllAddress(res.data.data);
            let data = res.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    let rfiPdfView = useRef(null);
    const componentsRefs = useRef([]);

    useState(() => {
        getAllAddress();
    }, [])

    function downloadPdf2() {
        setPrintDialog(true);
    }
    async function handledeleteAddress() {
        try {
            console.log(selectedAddress);
            let res = await deleteAddress({ id: selectedAddress });
            setAllAddress(d => d.filter(dd => !selectedAddress.includes(dd._id)));
        }
        catch (err) {
            console.log(err);
        }
    }



    async function printData() {
        setPdfGenereationLoader(true)
        try {
            const components = [...componentsRefs.current.map(ref => ref.current), rfiPdfView.current]
            console.log(components)

            let pdfBlob = await downloadPdfUsingCanvas(components, [], `${heading?.toLowerCase() !== undefined ? heading?.toLowerCase() : 'Project Data'}.pdf`, pdfAction === "Print")

            if (pdfAction === "Download") {
                setPdfGenereationLoader(false)
                return
            }
            console.log(pdfBlob)
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const iframe = document.createElement('iframe');
            iframe.style.position = 'fixed';
            iframe.style.top = '-9999px';
            iframe.style.left = '-9999px';
            iframe.style.width = '0px';
            iframe.style.height = '0px';
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.onload = function () {
                iframe.contentWindow.print();
            };
            setPdfGenereationLoader(false)
        }
        catch (err) {
            console.log(err)
            setPdfGenereationLoader(false)
        }
    };

    useEffect(() => {
        componentsRefs.current = selectedAddress.map(() => React.createRef());
    }, [selectedAddress]);


    const transformDataBack = (input) => {
        return input.flatMap((data) =>
            data.person.map((person) => ({

                companyName: data.company.company_name === "All Individual" ? "Internal Employees" : data.company.company_name,
                company_id: data.company._id,
                personFirstName: person.user.first_name,
                personLastName: person.user.last_name,
                personCompanyRole: person.user.company_role,
                personName: person.user.first_name + " " + person.user.last_name,
                person_id: person.user._id,
                profile: '',
                role: person.project_role,
            }))
        );
    };

    const getProjectData = async () => {

        try {

            const response = await getProject(localStorage.getItem("projectId"));
            const code = await getCodeCost(localStorage.getItem("projectId"));
            const res = await getProjectAssignData(localStorage.getItem("projectId"));
            const directoryData = res.data.data;
            if (response?.data?.data?.length !== 0) {
                let listData = transformDataBack(directoryData);
                setProjectData(response.data.data[0]);
                console.log(response.data.data[0])
                setProjectDirectory(listData);
                setCodeData(code.data.data);
            }
        }
        catch (err) {
            console.log(err)
        }

    };


    const fetchVersion = async () => {

        try {
            // setSkeletonLoader(true);
            let res = await getProjectHistory(localStorage.getItem("projectId"))

            setVersion(res?.data?.data?.reverse());
            //   setTotalRecords(res?.data?.data?.length() || 0);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            // setSkeletonLoader(false);
        }
    }

    useEffect(() => {
        if (useFor == 'project') {
            getProjectData();
            fetchVersion()
        }
    }, []);


    function renderPrjectView() {
        return (
            <>
                <div className='fw-bold fs-5'>1.Project Information</div>
                <ViewProjectInformation projectData={projectData} />
                <div className='fw-bold fs-5'>2.Project Directory</div>
                <ViewProjectDirectory projectDirectory={projectDirectory} hidePagination={true} />
                <div className='fw-bold fs-5 my-4'>3.Cost Code </div>
                <ViewCostCode costCodeData={codeData} hidePagination={true} />
                <div className='fw-bold fs-5 my-4'>4.Project Tools </div>
                <ViewProjectTools toolData={projectData?.project_tools} hidePagination={true} />
                <div className='fw-bold fs-5 my-4'>5.Project Stages</div>
                <ViewProjectStages stageData={projectData?.project_stages} hidePagination={true} />
                <div className='fw-bold fs-5 my-4'>6.Project Drawings</div>
                <ViewProjectDrawing
                    drawingData={projectData?.project_drawings}
                    hidePagination={true}
                />
                <div className='fw-bold fs-5 my-4'>6.Project Specifications</div>
                <ViewProjectSpecifications
                    specificationData={projectData?.project_specifications}
                    hidePagination={true}
                />
                <div className='fw-bold fs-5 my-4'>7.Project Schedule</div>
                {projectData ? <div className="d-flex mt-3 flex-wrap gap-4">


                    {projectData?.project_schedule?.length !== 0 ? projectData?.project_schedule?.map((res) => (
                        // console.log(res?.fileUrl)
                        <img
                            src={res?.fileUrl}
                            width={"100px"}
                            height={"100px"}
                            alt=""
                        />
                    )) : <div className="ps-3">
                        <h6>No Photos available</h6>
                    </div>
                    }

                </div> : <GridSkeleton cardCount={4} />}

                <div className='mt-5 fw-bold fs-4'>Project Version History</div>

                {includeVersionHistory ? <>
                    <BorderedTable>
                        <DataTable
                            value={version}
                        //   footer={customFooter}
                        >
                            <Column
                                field="#"
                                header="Version"
                                headerStyle={{ width: "4rem", fontWeight: "bold" }}
                                body={(rowData, rowIndex) => (
                                    <div className="d-flex justify-content-center">
                                        {rowIndex?.rowIndex + 1}
                                    </div>
                                )}
                            ></Column>


                            <Column headerStyle={{ fontWeight: "bold" }}
                                field="date"
                                header="Date & time"
                                body={(rowData) => moment(rowData?.date)?.format('DD-MM-YYYY h:mm a')}
                            ></Column>


                            <Column headerStyle={{ fontWeight: "bold" }}
                                field="stageName"
                                header="Change In"
                            // body={keyNameTemplate}

                            />
                            <Column headerStyle={{ fontWeight: "bold" }}
                                field="user_name"
                                header="Changed By"
                            // body={preValTemplate}
                            // style={{margin:"10px 0"}}
                            />

                        </DataTable>
                    </BorderedTable>

                </> : ''}

            </>
        )
    }

    console.log(heading, "addressliotng--- for docmenengsment---")

    function renderToolViewForPrint() {
        switch (heading?.toLowerCase()) {
            case "dailylog":
                return (
                    <>
                        <div className='heading mb-3'>Daily Log Data</div>
                        <ViewDailyLog includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "task":
                return (
                    <>
                        <div className='heading mb-3'>Task Data</div>
                        <Viewtask includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "transmittal":
                return (
                    <>
                        <div className='heading mb-3'>Transmittal Data</div>
                        <ViewTransmittals includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "observation":
                return (
                    <>
                        <div className='heading mb-3'>Observation Data</div>
                        <ViewObservation includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "specification":
                return (
                    <>
                        <div className='heading mb-3'>Specification Data</div>
                        <ViewSpecification includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "submittal":
                return (
                    <>
                        <div className='heading mb-3'>Submittal Data</div>
                        <ViewSubmittal includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "punch":
                return (
                    <>
                        <div className='heading mb-3'>Punch Data</div>
                        <ViewPunch includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "incident":
                return (
                    <>
                        <div className='heading mb-3'>Incident Data</div>
                        <ViewIncident includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "issue":
                return (
                    <>
                        <div className='heading mb-3'>Issue Data</div>
                        <ViewIssue includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "rfi":
                return (
                    <>
                        <div className='heading mb-3'>RFI Data</div>
                        <RfiView includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "inspection":
                return (
                    <>
                        <div className='heading mb-3'>RFI Data</div>
                        <InspectionsTemplate includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;
            case "meeting":
                return (
                    <>
                        <div className='heading mb-3'>RFI Data</div>
                        <ViewMeeting includeVersionHistory={includeVersionHistory} usedAsComponent={true} />
                    </>
                )
                break;

            case "document":
                return (
                    <>
                        <div className='heading mb-3'>Document Data</div>
                        <ViewParticularDrawing includeVersionHistory={includeVersionHistory} usedAsComponent={true} role={"documents"} />
                    </>
                )
                break;
            case "model":
                return (
                    <>
                        <div className='heading mb-3'>Model Data</div>
                        <ViewParticularDrawing includeVersionHistory={includeVersionHistory} usedAsComponent={true} role={"models"} />
                    </>
                )
                break;

            case "photo":
                return (
                    <>
                        <div className='heading mb-3'>Photo Data</div>
                        <ViewParticularDrawing includeVersionHistory={includeVersionHistory} usedAsComponent={true} role={"photos"} />
                    </>
                )
                break;
            case "drawing":
                return (
                    <>
                        <div className='heading mb-3'>Drawing Data</div>
                        <ViewParticularDrawing includeVersionHistory={includeVersionHistory} usedAsComponent={true} role={"drawings"} />
                    </>
                )
                break;



            default:
                break;
        }
    }


    return (
        <div>
            <div className="address_listing_container">
                <div className="between mb-4">
                    <div className='heading'>Address Listing</div>
                    <div className='d-flex gap-3'>

                        <PrimaryButton
                            dialogHeader={"Delete Address"}
                            className={"disableActionBtn"}
                            dialogBody={"Are you sure you want to delete addresses?"}
                            onClick={handledeleteAddress} confirmation text={<i className='pi pi-trash' />} disabled={selectedAddress.length === 0} />
                        <PrimaryButton className={"disableActionBtn"} text={"Print"} onClick={downloadPdf2} disabled={selectedAddress.length === 0} />
                        <CreateAddress getAllAddress={getAllAddress} />
                    </div>
                </div>
                <Accordion multiple activeIndex={[0]}>
                    {allAddres?.map((res, key) => {
                        return (
                            <AccordionTab header={res.name} key={key}
                                headerTemplate={<div className='between w-100'>
                                    <div>
                                        {res.name}
                                    </div>
                                    <div>
                                        <Checkbox
                                            checked={selectedAddress.includes(res._id)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                setSelectedAddress(pre => {
                                                    if (pre.includes(res._id)) {
                                                        return pre.filter(ite => ite !== res._id)
                                                    }
                                                    else {
                                                        return [...pre, res._id]
                                                    }

                                                })
                                            }}
                                        />
                                    </div>
                                </div>}
                            >
                                <div className='row'>
                                    <div className='col-6'>
                                        <div><label>Name :-</label> {res?.name}</div>
                                    </div>
                                    <div className="col-6">
                                        <div><label>Phone Number :-</label>{res?.phone_no}</div>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <div><label>Date :-</label>{moment(res.date).format("DD/MM/YYYY")}</div>
                                    </div>
                                    <div className='mt-2 col-6'><label>Billing Address :-</label>
                                        <HtmlIntoText html={res.billing_address} />
                                    </div>
                                    <div className='mt-2 col-6'><label>Shipping Address :-</label>
                                        <HtmlIntoText html={res.shipping_address} /> </div>
                                    <div className='mt-3'><label>Post Code:-</label>{res.zipcode}</div>
                                </div>
                            </AccordionTab>
                        )
                    })}
                </Accordion>

                <div className='end mt-3'>
                    <button onClick={() => navigate(-1)} className="btn border">Cancel</button>
                </div>

                <div style={{ height: "0px", overflow: "hidden" }}>

                    {allAddres.filter(dd => selectedAddress.includes(dd._id)).map((res, index) => {
                        return (
                            <div ref={componentsRefs.current[index]}>
                                <h1>{getProjectName()}</h1>
                                <h2 className='mt-3'>Tool:- <span style={{ textTransform: "capitalize" }}>{heading?.toLocaleLowerCase()}</span></h2>
                                <br />
                                <div className='row border rounded-2 mt-2 p-3'>
                                    <div className='col-6'>
                                        <div><label>Name :-</label> {res?.name}</div>
                                    </div>
                                    <div className="col-6">
                                        <div><label>Phone Number :-</label>{res?.phone_no}</div>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <div><label>Date :-</label>{moment(res.date).format("DD/MM/YYYY")}</div>
                                    </div>

                                    <div className='mt-2 col-6'><label>Billing Address :-</label>
                                        <HtmlIntoText html={res.billing_address} />
                                    </div>
                                    <div className='mt-2 col-6'><label>Shipping Address :-</label>
                                        <HtmlIntoText html={res.shipping_address} /> </div>
                                    <div className='mt-3'><label>Post Code:-</label>{res.zipcode}</div>
                                </div>
                            </div>
                        )
                    })}

                    <br />
                    <div ref={rfiPdfView}>
                        {useFor == 'tools' ? renderToolViewForPrint() : renderPrjectView()}
                    </div>
                </div>

                {/* <ViewIssue includeVersionHistory={includeVersionHistory} usedAsComponent={true} /> */}

                <Dialog
                    visible={printDialog}
                    header="Print"
                    style={{ width: "30%" }}
                    draggable={false}
                    onHide={() => {
                        setPrintDialog(false)
                        setPdfGenereationLoader(false)
                    }}
                    footer={
                        <div className="end gap-3">
                            <PrimaryButton disabled={pdfGenereationLoader} text={pdfGenereationLoader ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="2" animationDuration=".5s" /> : pdfAction} onClick={printData} />
                        </div>
                    }
                >
                    <div>
                        <div>
                            <Checkbox checked={includeVersionHistory} onChange={(e) => setIncludeVersionHistory(e.target.checked)} /> <b>&nbsp;&nbsp;Include Version History</b>
                        </div>
                        <div className="flex flex-wrap gap-3 mt-3">
                            <div className="flex align-items-center">
                                <RadioButton inputId="ingredient1" name="pdfaction" value="Print" onChange={(e) => setPdfAction(e.value)} checked={pdfAction === 'Print'} />
                                <label htmlFor="ingredient1" className="ml-2"><b>&nbsp;&nbsp;Print</b></label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="ingredient2" name="pdfaction" value="Download" onChange={(e) => setPdfAction(e.value)} checked={pdfAction === 'Download'} />
                                <label htmlFor="ingredient2" className="ml-2"><b>&nbsp;&nbsp;Download</b></label>
                            </div>

                        </div>
                    </div>
                </Dialog>

            </div>
        </div>
    );
};
