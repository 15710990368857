import React, { useState, useEffect } from "react";
import "./projectDirectoryList.css";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { useNavigate } from "react-router-dom";
import {
  deleteUser,
  deleteCompany,
  getCompanyAndPersonData,
} from "../../Api/Admin/AllAPIs";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { getActionsPermission, searchDataWithKeys } from "../../utils";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import Pagination from "../../Components/Common/Pagination/Pagination";


function Contractors({ onSendData, search, setSearch, setUsersCount }) {
  const [visibleDelete, setVisibleDelete] = useState(false);
  let [allData, setAllData] = useState([])

  const [isDeleteAccess, setIsDeleteAccess] = useState(false);
  let deleteAccess = getActionsPermission("Delete Company")
  const [isEditAccess, setIsEditAccess] = useState(false);
  let editAccess = getActionsPermission("Edit Company")

  useEffect(() => {
    setIsDeleteAccess(deleteAccess)
    setIsEditAccess(editAccess)
  }, [deleteAccess])

  const [expandedRows, setExpandedRows] = useState([]);
  // const [search, setSearch] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [getData, setGetData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [deletedUser, setDeletedUser] = useState({
    userType: "",
    userId: ""
  })

  const navigate = useNavigate();


  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  let perPageData = 10;



  const getCompanyAndUser = async () => {
    try {
      setLoader(true)
      setSearch("")
      let body = { type: "contractor" }

      let paginationParams = {
        page: page,
        perPageData: perPageData
      }

      const res = await getCompanyAndPersonData(body, null, paginationParams);
      const resData = res?.data?.data;
      console.log(res?.data, "allllllllllllllll")
      setTotalCount(res?.data?.totalCount || 0)
      setGetData(resData);
      setAllData(resData)
      setLoader(false);
      const companies = resData;
      const persons = resData.flatMap((company) => company.person);

      onSendData(companies.length, persons.length);
    } catch (err) {
      console.log(err);
    }
    finally {
      setLoader(false)
    }
  };

  const confirmDelete = async () => {
    setLoader(true);
    try {
      // await deleteUser(companyId);
      console.log(deletedUser);

      if (deletedUser.userType === "company") {
        let res = await deleteCompany(deletedUser.userId)

        setUsersCount(pre => ({ ...pre, contractor: pre.contractor - 1 }))
        setAllData(pre => pre.filter(d => d._id !== deletedUser.userId))
        setGetData(pre => pre.filter(d => d._id !== deletedUser.userId))

      }
      else if (deletedUser.userType === "user") {
        let dd = await deleteUser(deletedUser.userId)
        getCompanyAndUser()
      }
      console.log("user deleted successfully")
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };


  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.first_name; // Ensure that rowData contains the 'first_name' property
    return (
      <div className="flex align-items-center gap-2">
        <img
          src={`https://primefaces.org/cdn/primereact/images/avatar/xuxuefeng.png`}
          width="32"
        />
        <span>{representative}</span> {/* Display the representative's name */}
      </div>
    );
  };
  const allowExpansion = (rowData) => {
    return rowData.person?.length > 0;
  };

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="border">
        <DataTable
          value={rowData.person}
          className="childrenTable"
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode={"checkbox"}
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column style={{ width: "4rem" }}></Column>
          <Column
            field="#"
            header="Sno."
            body={(rowData, rowIndex) => <div>{rowIndex.rowIndex + 1.1}</div>}
            style={{ width: "4.7rem" }}
          ></Column>
          <Column
            header=""
            body={representativeBodyTemplate}
            sortable
            sortField="first_name" // Ensure the sort field matches the data property name
            style={{ width: "10rem", minWidth: "12.1rem" }}
          ></Column>
          <Column field="business_phone" header="Phone" style={{ width: "8.5rem", minWidth: "8.5rem" }} />
          <Column field="email_address" header="Email" style={{ width: "5rem", minWidth: "5rem" }} />
          <Column field="address" header="Address" style={{ width: "13.3rem", minWidth: "13.3rem" }} />
          {/* <Column field="" header="Staus" style={{ width: "10.4rem", minWidth: "10.4rem" }} /> */}

          <Column

            field=""
            header="Actions"
            body={(rowData) => (
              <div className="d-flex align-items-center gap-2">
                <Popover
                  options={["Edit", "view", "Delete"]}
                  onSelect={(index) => {
                    if (index === 0) navigate(`/userManagement/editUser/${rowData?._id}`, { state: { backUrl: "/projectDirectories" } });
                    else if (index === 1) {
                    } else if (index === 2) {
                      setVisibleDelete(true)
                      setDeletedUser({
                        userType: "user",
                        userId: rowData?._id
                      })
                    };
                  }}
                />
              </div>
            )}
          ></Column>
        </DataTable>
      </div>
    );
  };



  useEffect(() => {

    getCompanyAndUser();
  }, [page]);

  useEffect(() => {
    setGetData(searchDataWithKeys(["company_name", "email_address"], allData, search))
  }, [search])



  return (
    <>

      <div>
        {/* <div className="searchInput">
          <SearchInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setGetData(searchDataWithKeys(["company_name", "email_address"], allData, e.target.value))
            }
            }
            onCancel={() => {
              setSearch("")
              setGetData(allData)
            }}
          />
        </div> */}




        <div>

          <div className="userManagement_table_scroll">
            {loader ? <TableSkelton header={["", "S No.", "Full Name", "Phone", "Email", "Address", "Status", "Actions"]} /> :
              <BorderedTable>
                <DataTable
                  value={getData}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}

                  selectionMode={"checkbox"}
                  dataKey="_id"
                  tableStyle={{ minWidth: "60rem" }}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}

                >
                  <Column expander={allowExpansion} style={{ width: "3rem" }} />
                  {/* <Column
                  selectionMode="multiple"
                  style={{ width: "4rem", minWidth: "4rem" }}
                ></Column> */}
                  <Column
                    style={{ width: "4.7rem" }}
                    field="#"
                    header="S No."
                    body={(rowData, rowIndex) => (
                      <div>{rowIndex.rowIndex + 1}</div>
                    )}
                  ></Column>
                  <Column field="company_name" header="Full Name" style={{ width: "12.1rem", minWidth: "12.2rem" }} />
                  {/* //<Column field="" header="Photo" /> */}
                  <Column field="business_phone" header="Phone" style={{ width: "8.4rem", minWidth: "8.4rem" }} />
                  <Column field="email_address" header="Email" style={{ width: "10rem", minWidth: "10rem" }} />
                  <Column field="address" header="Address" />
                  {/* <Column field="" header="Status" /> */}

                  <Column
                    field=""
                    header="Actions"
                    body={(rowData) => (
                      <div className="d-flex align-items-center gap-2">
                        <Popover
                          options={[isEditAccess ? "Edit" : null, "view", isDeleteAccess ? "Delete" : null]}
                          onSelect={(index) => {
                            console.log(index)

                            if (index === 0)
                              navigate(`/projectDirectories/updateContractor/${rowData?._id}`)
                            else if (index === 1) {
                            } else if (index === 2) {
                              setVisibleDelete(true)
                              setDeletedUser({
                                userType: "company",
                                userId: rowData?._id
                              })
                            };
                          }}
                        />
                      </div>
                    )}
                  ></Column>
                </DataTable>
              </BorderedTable>}
            <br />
          </div>
          <Pagination
            currentPage={page}
            totalCount={totalCount}
            pageSize={perPageData}
            onPageChange={page => setPage(page)}
          />

        </div>
      </div>


      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setCompanyId(null)}
        onConfirm={confirmDelete}
        header={"User"}
      />
    </>
  );
}

export default Contractors;
