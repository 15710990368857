import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import style from "./button.module.css"



export const ExportPdfButton =({exportToPDF, csvLink, className,csvBtn,PassData})=> {
    const [selectedCity, setSelectedCity] = useState(null);
    const [format, setFormat]= useState(null)
    const Data = [
        { name: 'PDF', code: 'pd' },
        { name: csvBtn, code: 'cs' }
      
    
    ];

    return (
        <div className="">
          <div>  <Dropdown className={style.pdf_dropDown} value={format}  options={ PassData ?  PassData : Data} optionLabel="name" 
                placeholder="Export"  
                onChange={(e) => {
          console.log(e.value.name);
          if (e.value.code === "pd") {
            exportToPDF()
          }
        }}
       

                /></div>
        </div>
    )
}