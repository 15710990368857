import React, { useRef, useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import "primeicons/primeicons.css";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import style from "./schedule.module.css";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Calender from "../../Components/Common/Calender";
import ScheduleCalender from "./ScheduleCalender";
import ScheduleGainttChart from "./ScheduleGainttChart";
import ScheduleLookHead from "./ScheduleLookHead";
import { CSVLink } from "react-csv";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { TableSkelton } from "../../Components/Common/TableSkelton";


export default function Schedule() {
  const [products, setProducts] = useState([
    {
      id: 1,
      web: 1,
      title: "Release given for priority submitals",
      resor: "GC",
      sDate: "14/3/2024",
      endate: "22/3/2024",
    },
    {
      id: 2,
      web: 1.3,
      title: "Release given for priority submitals",
      resor: "GC",
      sDate: "14/3/2024",
      endate: "22/3/2024",
    },
    {
      id: 3,
      web: 2.5,
      title: "Release given for priority submitals",
      resor: "GC",
      sDate: "14/3/2024",
      endate: "22/3/2024",
    },
    {
      id: 4,
      web: 3.1,
      title: "Release given for priority submitals",
      resor: "GC",
      sDate: "14/3/2024",
      endate: "22/3/2024",
    },
    {
      id: 5,
      web: 1,
      title: "Release given for priority submitals",
      resor: "Owner",
      sDate: "14/3/2024",
      endate: "22/3/2024",
    },
  ]);
  const [visible, setVisible] = useState(false);
  const [skeletonLoader,setSkeletonLoader] =useState(false)
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [{ name: "All", code: "all" }];


  const csvData = products.map(item => ({
    "WBS": item.web,
    "Title": item.title,
    "Resourcees": item.resor,
    "Start Date": item.sDate,
    "Finish Date": item.endate

  }));

  // Define CSV headers
  const csvHeaders = [
    { label: 'WBS', key: 'WBS' },
    { label: 'Title', key: 'Title' },
    { label: 'Resources', key: 'Resources' },
    { label: 'Start Date', key: 'Start Date' },
    { label: 'Finish Date', key: 'Finish Date' }

  ];

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
      didParseCell: function (data) {
        // if (data.row.index === 0) {
        //   // Scale down font size for header row
          data.cell.styles.fontSize = 2;
        // }
      },
    });
    doc.save('schedule.pdf');
  };


  return (
    <>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        // closable={false}
        draggable={false}
        header="Filter Schedule"
      >
        <div className="row mt-3">
          <div className="col-md-6">
            <p>Summary Tasks:</p>
            <Dropdown
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              options={cities}
              optionLabel="name"
              placeholder="All"
              className="w-100 "
            ></Dropdown>
          </div>
          <div className="col-md-6">
            <p>Resources:</p>
            <Dropdown
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              options={cities}
              optionLabel="name"
              placeholder="All"
              className="w-100 "
            ></Dropdown>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <p>Start Date:</p>
            <Calender className="p-0" placeholder="Start Date" />
          </div>
          <div className="col-md-6">
            <p>End Date:</p>
            <Calender className="p-0" placeholder="End Date" />
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <span className="clearAll me-3">Clear All</span>
            <button
              className="btn btn-Secondary me-3"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </button>
            <PrimaryButton
              text="Apply Filter"
              onClick={() => {
                setVisible(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      <div>
        <div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h1 className="heading d-flex align-items-center ">SCHEDULE</h1>
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center">
              <ExportPdfButton exportToPDF={exportToPDF} 
                  csvBtn={<CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"Schedule.csv"}
                    >
                      CSV
                    </CSVLink>
                    }
                 />

              </div>
              <div className="ms-4">
                <PrimaryButton addIcon text="Create Task" />
              </div>
            </div>
          </div>


          <div style={{height:'0',overflow:'hidden'}}>
            <table ref={tableRef}>
              <thead>
                <tr>
                <th>#</th>
    <th>WBS</th>
    <th>Title</th>
    <th>Resources</th>
    <th>Start Date</th>
    <th>Finish Date</th>
                  
                </tr>
              </thead>
              <tbody>
                {products?.map((item, index) => {
                  return (
                    <tr key={index}>
                    <td>{index + 1}</td>
  <td>{item.web}</td>
  <td>{item.title}</td>
  <td>{item.resor}</td>
  <td>{item.sDate}</td>
  <td>{item.endate}</td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>


          <div className="mt-3"></div>
          <TabView>
            <TabPanel header="List">
              <div className="row mt-3">
                <div className="col-md-3 SearchInputC">
                  <SearchInput />
                </div>
                <div className="col-md-9 d-flex justify-content-end  ">
                  <button
                    className="btn btn-secondary "
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Filter Schedule
                  </button>
                  <span className="clearAll ms-3">Clear All</span>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end mt-4">
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <span>Go To Page 01</span>
                    <i className="fs-6 pi pi-angle-down ms-1" />
                  </div>
                  <div>
                  <></>
                  </div>
                </div>
              </div> */}
              <div className="mt-3">
              { 
              
            
                skeletonLoader ? 
                <TableSkelton
                  header={[
                    "ID",
                    "WBS",
                    "Title",
                    "Resources",
                    "Start Date",
                    "Finish Date",
                    "Progress", 
                  ]}
                />
              : 
              <BorderedTable>
                  <DataTable value={products} paginator rows={5} rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]} >
                    <Column field="id" header="ID"></Column>
                    <Column field="web" header="WBS"></Column>
                    <Column field="title" header="Title"></Column>
                    <Column field="resor" header="Resources"></Column>
                    <Column field="sDate" header="Start Date"></Column>
                    <Column field="endate" header="Finish Date"></Column>
                    <Column
                      field="progress"
                      body={
                        // (rowdata) => (
                        <div className="w-100 d-flex">
                          <ProgressBar
                            value={null}
                            style={{
                              height: "18px",
                              backgroundColor: "#f97b06",
                            }}
                            mode="determinate"
                            className="w-100"
                          />
                          <span className="ms-1"> 100%</span>
                        </div>
                        // )
                      }
                      header="Progress"
                    ></Column>
                  </DataTable>
                </BorderedTable>}
              </div>
            </TabPanel>
            <TabPanel header="Calender">
              <div className="row mt-3 ">
                <div className="col-md-3">
                  <SearchInput />
                </div>
                <div className=" col-md-9 d-flex justify-content-end mb-2 mt-3">
                  <button
                    className="btn btn-secondary "
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Filter Schedule
                  </button>
                  <span className="clearAll d-flex align-items-center ms-3">Clear All</span>
                </div>
              </div>
              <ScheduleCalender />
            </TabPanel>
            <TabPanel header="Gantt">
              <ScheduleGainttChart />
            </TabPanel>
            <TabPanel header="Lookaheads">
              <ScheduleLookHead />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}
