import React, { useState, useEffect } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { useNavigate, Link } from "react-router-dom";


import Popover from "../../Components/Common/Popover";
import { getCompanyAndPersonData } from "../../Api/Admin/AllAPIs";
import { TableSkelton } from "../../Components/Common/TableSkelton";
// import "./projectDirectoryList.css"

function Contractor({ tableData }) {

  const [expandedRows, setExpandedRows] = useState([]);
  const [skeletonLoader,setSkeletonLoader]= useState(true)
  const [search, setSearch] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(null);
  let project_id=localStorage.getItem("projectId");

  const navigate = useNavigate();
  const [getData, setGetData] = useState([ ]);

  const handleGetRow = (e) => {
    setExpandedRows(e.data);
  };

  const representativeBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          src={`https://primefaces.org/cdn/primereact/images/avatar/xuxuefeng.png`}
          width="32"
        />
      </div>
    );
  };
  const allowExpansion = (rowData) => {
    return rowData.person?.length > 0;
  };

  const getCompanyAndUser = async () => {
    try {
      setSkeletonLoader(true);
      let body = { type: "contractor" }
      const res = await getCompanyAndPersonData(body,project_id);
      console.log(res);
      
      const resData = res?.data?.data;
      console.log(resData, "rrrr");
      setGetData(resData);

    
    } catch (err) {
      console.log(err);
    }
    finally{
      setSkeletonLoader(false);
    }
  };
  
  useEffect(() => {
    // fetchCompany()
    getCompanyAndUser();
  }, []);

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="">
        <DataTable
          value={rowData.person}
          className="childrenTable"
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode={"checkbox"}
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column style={{ width: "4rem" }} />
          <Column
            selectionMode="multiple"
            style={{ width: "4rem", minWidth: "4rem" }}
          ></Column>
          <Column
            style={{ width: "4rem" }}
            field="#"
            header=""
            body={representativeBodyTemplate}
          ></Column>
          <Column
            field="first_name"
            header="Full Name"
            style={{ width: "9.33rem" }}
          />
          <Column field="cell_phone" header="Phone" style={{ width: "12rem" }} />
          <Column field="email_address" header="Email" style={{ width: "19.75rem" }} />
          <Column field="role" header="Role" />
          {/* <Column
            field=""
            header="Action"
            body={(rowData, meta) => (
              <div className="flex justify-content-center">
                <Popover
                  options={["Version History", "Edit", "Delete", "View"]}
                  //   onSelect={(index) => {
                  //     if (index === 0)
                  //       navigate(
                  //         `/projectManagement/version-history/RFI/${rowData?.subject}/${rowData?._id}`
                  //       );
                  //     else if (index === 1)
                  //       navigate(`editRfi/${rowData?._id}`);
                  //     else if (index === 2) handleDelete(rowData?._id)
                  //     else if (index === 3) navigate(`view/${rowData?._id}`);
                  //   }}
                />
              </div>
            )}
            style={{ width: "4.5rem", color: "#121212" }}
          ></Column> */}
        </DataTable>
      </div>
    );
  };

  function list() {}

  return (
    <>
      <div>
        <br/>
        {/* <div className="row">
          <div className="col-md-3 SearchInput">
            <SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-9 d-flex justify-content-end mb-3 gap-3">
            <button className="filter_btn">Filter Directory</button>

            <button className={`clear_btn  `}>Clear All</button>
          </div>
        </div> */}

        <div>
      
         {
          skeletonLoader? <TableSkelton header={["","","","Full Name","Phone","Email","Role","Action"]}/> :
          <BorderedTable>
            <DataTable
              value={tableData}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              selectionMode={"checkbox"}
              dataKey="_id"
              tableStyle={{ minWidth: "60rem" }}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
            >
              <Column expander={allowExpansion} style={{ width: "4rem" }} />
              <Column
                selectionMode="multiple"
                style={{ width: "4rem", minWidth: "4rem" }}
              ></Column>
              <Column
                style={{ width: "4rem" }}
                field="#"
                header=""
                body={(rowData, rowIndex) => <div>{rowIndex.rowIndex + 1}</div>}
              ></Column>
              <Column field="company_name" header="Full Name" />
              {/* //<Column field="" header="Photo" /> */}
              <Column field="business_phone" header="Phone" />
              <Column field="email_address" header="Email" />
              <Column field="role" header="Role" />
              {/* <Column
                field=""
                header="Action"
                body={(rowData, meta) => (
                  <div className="flex justify-content-center">
                    <Popover
                      options={["Version History", "Edit", "Delete", "View"]}
                        onSelect={(index) => {
                          if (index === 0)
                            navigate(
                              `/projectManagement/version-history/RFI/${rowData?.subject}/${rowData?._id}`
                            );
                          else if (index === 1)
                            navigate(`editRfi/${rowData?._id}`);
                          // else if (index === 2) handleDelete(rowData?._id)
                          else if (index === 3) navigate(`view/${rowData?._id}`);
                        }}
                    />
                  </div>
                )}
                style={{ width: "4rem", color: "#121212" }}
              ></Column> */}
            </DataTable>
          </BorderedTable>}
        </div>
      </div>

      {/* <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setCompanyId(null)}
        onConfirm={confirmDelete}
        header={"Directory"}
      /> */}
    </>
  );
}

export default Contractor;
