import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import TextInput from "../../Components/Inputs/TextInput";

export default function AddSection({ onSubmit }) {

  let [visible, setVisible] = useState(false)
  let [sectionName, setSectionName] = useState("")

  function formSubmit() {
    onSubmit(sectionName)
    setVisible(false);
    setSectionName("")
  }


  return (
    <div>

      <div
        className="clear_btn gray-lt"
        onClick={() => setVisible(true)}
      >
        <IoMdAdd /> Add Section
      </div>
      <Dialog
        header="Add Section"
        visible={visible}
        style={{ width: "60rem", minWidth: "30rem" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <>

          <div className="row mt-3">
            <div className="col-12">
              <label className="labelCss">Inspection Section</label>
              <TextInput onChange={(e) => setSectionName(e.target.value)} value={sectionName} placeholder="Inspection section" />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <div className="d-flex">
              <button
                type="button"
                className="btn border me-3"
                onClick={() => { setVisible(false) }}
              >
                Cancel
              </button>
              <PrimaryButton
                type="button"
                onClick={formSubmit}
                text={"Add"}
              />
            </div>
          </div>

        </>
      </Dialog>





    </div>
  );
}
