import React, { useState, useEffect } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DeleteButton } from "../../Components/Buttons/DeleteButton";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import style from "./PaintTouchup.css";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { Dropdown } from "bootstrap";
import { MultipleDragAndDrop } from "../../Components/Inputs/MultipleDragAndDrop";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { MdAdd, MdOutlineAttachment } from "react-icons/md";
import Loader from "../../Components/Common/Loader";
import {
  getActionsPermission,
  getProjectName,
  getUserId,
  toast,
} from "../../utils";

import {
  addCategory,
  addTrade,
  getAllCategory,
  getCompanyByProjectId,
  getDrawing,
  getDrawingByProjectId,
  getPunchbyid,
  getTradeByProjectId,
  getUserByProjectId,
  getUsersByCompanyId,
  updatePunch,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { Editor } from "primereact/editor";
import { LoopFileUpload } from "../../Components/Inputs/LoopFileUpload";
import { EditFileUpload } from "./EditFileUpload";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { MultiSelect } from "primereact/multiselect";
import AddSets from "../../Components/Common/AddSets";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import TimeInput from "../../Components/Inputs/TimeSelector";
import moment from "moment";

export const EditPunch = () => {
  const { id } = useParams();
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({});
  // console.log(id);
  const [punchItemName, setPunchItemName] = useState("");
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [punchItemManager, setPunchItemManager] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [types, setTypes] = useState("");
  const [trade, setTrade] = useState("");
  const [description, setDescription] = useState("");
  const [drawing, setDrawing] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [assigneeAttachment, setAssigneeAttachment] = useState([]);
  const [assigneeFiles, setAssigneeFiles] = useState({});
  const [previous, setPrevious] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [attachFile, setAttachFile] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [drawingListing, setDrawingListing] = useState([]);
  const [options, setOptions] = useState([]);
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");

  const [trades, setTrades] = useState([]);
  const [addTradess, setAddTradess] = useState();

  const [drawingVisible, setDrawingVisible] = useState(false);
  const [linkedDrawing, setLinkedDrawing] = useState("");
  const [getDrawingWithProjectId, setGetDrawingWithProjectId] = useState([]);
  const [wfStatus, setWfStatus] = useState("");
  const [users, setUsers] = useState([]);

  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [addCategoryy, setAddCategoryy] = useState();
  const [companies, setCompanies] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [punchListID, setPunchListID] = useState("");
  const [hourSpent, setHourSpent] = useState({
    hours: 0,
    minutes: 0,
  });
  const [personResponsible, setPersonResponsible] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },

    { qty: 1, height: "15rem" },
    { qty: 1 },
    { qty: 1, height: "7rem" },
  ];

  const [validation, setValidation] = useState({
    punchItemName: false,
    person: false,
    punchItemManager: false,
    eventDate: false,
    location: false,
    punchNo: false,
    trade: false,
    category: false,
    description: false,
    // drawing: false,
    hourSpent: false,
  });

  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const projectId = localStorage.getItem("projectId");
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  console.log(userId, userName);
  const isApproverAccess = getActionsPermission("Punch Approver");
  const isReviewerAccess = getActionsPermission("Punch Reviewer");
  const [approver, setApprover] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [loading, setLoading] = useState(false)
  const [oldrfi_review_status, setOldrfi_review_status] = useState("");
  useEffect(() => {
    handleGetDrawing();
  }, []);

  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" }
  ];

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatHourSpent = (hourSpent) => {
    if (!hourSpent) return "--";
    const { hours, minutes } = hourSpent;
    return `${hours || "0"} : ${minutes || "0"}`;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleGetDrawing = async () => {
    try {
      const res = await getDrawingByProjectId(projectId);
      const getListing = res?.data?.data;
      setDrawingListing(getListing);
      // console.log(getListing, "testing");
    } catch (error) {
      console.log(error);
    }
  };
  const checkValidation = () => {
    let isValid = true;
    const updatedValidation = {};

    // Check each required field and update validation state
    if (!punchItemName.trim()) {
      updatedValidation.punchItemName = true;
      isValid = false;
    }
    if (!punchListID) {
      setValidation((prevState) => ({
        ...prevState,
        punchNo: true,
      }));
      isValid = false;
    }

    if (personResponsible.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        person: true,
      }));
      isValid = false;
    }
    if (punchItemManager.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        punchItemManager: true,
      }));
      isValid = false;
    }

    if (!location.trim()) {
      updatedValidation.location = true;
      isValid = false;
    }
    if (!trade?.trim()) {
      updatedValidation.trade = true;
      isValid = false;
    }
    if (!category) {
      updatedValidation.category = true;
      isValid = false;
    }

    if (!description?.trim()) {
      updatedValidation.description = true;
      isValid = false;
    }
   
    if (!hourSpent) {
      updatedValidation.hourSpent = true;
      isValid = false;
    }

    if (!eventDate) {
      updatedValidation.eventDate = true;
      isValid = false;
    }

    // Update validation state
    setValidation((prevState) => ({
      ...prevState,
      ...updatedValidation,
    }));

    if (!isValid) {
      toast.info("Please fill all the mandatory fields marked with *.");
    }

    return isValid;
  };

  const [allData, setAllData] = useState({});
  const handleGetPunch = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getPunchbyid(id);

      const punch = response?.data?.data?.[0];
      console.log(response?.data?.data?.[0])
      setAllData(punch);

      let approverData = punch?.approver?.[0] || {};
      let reviewerData = punch?.reviewer?.[0] || {};
      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null,
      });
      setApprover(approverData._id);
      setReviewer(reviewerData._id);
      setApproverComment(punch?.approver_comment)
      setReviewerComment(punch?.reviewer_comment)
      console.log(punch, "punch");
      setPunchListID(punch.punchID);
      // console.log(punch.PunchItemName);
      setPunchItemName(punch.PunchItemName);
      // console.log("punchItemName", punchItemName);
      setPersonResponsible(punch.person?.map((e) => e._id));
      setResponsibleContractor(punch?.responsibleContractor?.map((e) => e._id));
      setMembersResponsible(punch.MembersResponsible?.map((e) => e._id));
      setStatus(punch.workflow_status );
      setHourSpent(punch?.hourSpent);
      // console.log("status", status);
      setEventDate(new Date(punch.date));
      // setEventTime(new Date(incident.eventTime));
      setLocation(punch.Location);
      // console.log(punch.Location);
      // console.log(location);
      setTypes(punch.types);
      setDrawing(punch.drawing);
      setTypes(punch.Type);

      setOldrfi_review_status(punch?.status);

      setPunchItemManager(punch?.punchItemManager?.map((e) => e._id));
      // console.log(punch);
      setCategory(punch.category?._id);
      setTrade(punch.Trade?._id);
      // console.log(punch.Trade, "ttttttttttttttttttttttt");
      setDescription(punch.discription);
      setDrawing(punch.linkDrawing);
      setPreviousFiles([...punch?.attachFile]);
      console.log("Previous Files", previousFiles);
      setFileName(
        punch.attachFile.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );
      setTableData(punch.assignees);

      setAssigneeAttachment(punch?.assignees);

      let data = {
        value: punch?.responsibleContractor?.map((res) => res?._id),
      };
      handleCompanyChange(data);
      let statuss = punch.status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);
    } catch (error) {
      console.log("Error fetching incident:", error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const handleFileSubmit = (event) => {
    setAttachFile(event);
  };
  useEffect(() => {
    handleGetPunch();
  }, []);
  const deleteAssignee = (ob, index) => {
    // console.log("ob", ob);
    // console.log("index", index);
    // console.log("t", tableData);
    // let desiredObjects = tableData.filter(obj => obj.Comments !==ob.Comments);
    let arr = tableData.splice(index, 1);
    // console.log("splice Array", tableData);
    //console.log("afterTableDta",desiredObjects);
    setTableData((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleAddRow = () => {
    const newRow = {
      AssigneeName: "",
      Comments: "",
      Attachment: [],
      Status: "",
    };

    setTableData((pre) => [...pre, newRow]);

    // Add the new row to the tableData state
    // setTableData((prevData) => [...prevData,{assignees:[...prevData.assignees,newRow]} ]);

    // Add an empty string value for the newly added row to assigneeValues
    // setAssigneeValues((prevValues) => [...prevValues, ""]);
  };
  const changeAssigneeName = (ob, e, index) => {
    // console.log("ob", ob);
    // console.log("table", tableData);
    //ob.AssigneeName = e;
    // console.log("index", index);
    const Key = "AssigneeName";

    let result = tableData.map((item, i) => {
      if (ob.AssigneeName === item.AssigneeName && i === index) {
        return {
          ...item,
          [Key]: e,
        };
      }
      return item;
    });
    // console.log("AssigneeName Result", result);
    setTableData(result);
  };

  const changeAssigneeStatus = (ob, e, index) => {
    // console.log("ob", ob);
    // console.log("table", tableData);
    // console.log("e", e);
    // console.log("index", index);
    //ob.AssigneeName = e;
    const Key = "Status";

    let result = tableData.map((item, i) => {
      if (ob.Status === item.Status && i === index) {
        return {
          ...item,
          [Key]: e,
        };
      }

      return item;
    });
    console.log("AssigneeStatus Result", result);
    setTableData(result);
  };
  // console.log("tableData", tableData);

  const handleUpdatePunch = async () => {
    if (attachFile.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (!checkValidation()) {
      return;
    }

    const files = attachFile || [];
    const formData = new FormData();
    for (const getFile of files) {
      formData.append("upload", getFile);
    }

    let uploadedFiles = [];
    if (files.length > 0) {
      console.log("form Data", formData);
      const uploadFile = await uploadDocs(formData);
      console.log("uploadedFile Response", uploadFile);
      uploadedFiles = uploadFile.data.map((item) => ({
        fileName: item.fileName,
        fileUrl: item.fileUrl,
      }));
    }

    console.log(assigneeAttachment, assigneeFiles, previous, removePhoto);

    // console.log(attachfile);
    for (const key in assigneeFiles) {
      console.log(`Key: ${key}, Value: ${assigneeFiles[key]}`);
      const formData = new FormData();
      let attachfile1 = [];
      for (const data in assigneeFiles[key]) {
        console.log(`file: ${assigneeFiles[key][data].name}`);
        formData.append("upload", assigneeFiles[key][data]);
      }
      // if (assigneeFiles[key].length > 0) {
      const uploadResponse = await uploadDocs(formData);
      attachfile1 = [...uploadResponse?.data, ...previous[key]];
      // }

      if (tableData[key]) {
        tableData[key].Attachment = attachfile1;
        console.log("jadoooo", tableData, tableData[key]);
        setTableData(tableData);
      }
    }

    // console.log(tableData);

    let emailData = [];
    let manager = allUsersOfProject?.filter(res => punchItemManager.includes(res?._id))

    manager?.forEach(res => {
      emailData.push({
        email: res?.email_address,
        type: "Punch List Manager",
        tool_name: punchItemName,
        project: getProjectName(),
        toolType: "Punch List",
        name: res?.first_name,
        subject: `FYI: Task #${punchListID} ${punchItemName} Edited in ${getProjectName()}`,
        projectName: getProjectName(),
        project_id: projectData?.project_code,
        tool_id: punchListID,
        createdAt: moment().format('DD MMM YYYY  hh:mm A'),
        createdBy: userData?.name

      });
    })

    const payload = {
      punchID: punchListID,
      PunchItemName: punchItemName,
      date: eventDate,
      MembersResponsible: membersResponsible,
      status: status,

      // punch_review_status: status == "close" ? "under_review" : "panding",

      status:
        (oldrfi_review_status === "draft" && reviewer && reviewer)?"inreview":oldrfi_review_status,

      punchItemManager: punchItemManager,
      responsibleContractor: responsibleContractor,
      membersResponsible: membersResponsible,
      Location: location,
      person: personResponsible,
      category: category,
      Trade: trade,
      discription: description,
      linkDrawing: drawing,
      attachfile: [...previousFiles, ...uploadedFiles],
      assignees: tableData,
      deleted_file: removePhoto,
      userName: userName,
      project_id: projectId,
      userId: userId,
      reviewer_id: reviewer? reviewer : null,
      approver_id: approver? approver : null,
      email_data: emailData,
      hourSpent: hourSpent,
      workflow_status: status,
      created_by: userName,
      project_name:projectData.project_name,
      project_number: projectData.projectNumber,
    };

    console.log("payload", payload);
    // return;
    try {
      let result = await updatePunch(id, payload);
      console.log("update Punch Result", result);
      // console.log("edit Punch");

      toast.success("Punch Updated successfully!");

      navigate("/projectManagement/punch");
    } catch (error) {
      if(error?.response?.data?.message ==="Punch ID Already Exist"){
        toast.error(error?.response?.data?.message);
      }
      else{
        console.error("Error creating Punch:", error);
        toast.error("Something Wents Wrong, Please Try Again!");
      }
     
    }
  };

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }

  let navigate = useNavigate();

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      console.log("*********** Response:", res);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        console.log(companyNames, "companynames-------");

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id,
        }));

        setCompanies(formattedCompanies);
        console.log(companies, "hhhhhhhhhhhhhhh");
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany();
  }, []);

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);
    console.log(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, {
            company_id: [companyId],
          });
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map((user) => ({
              label: user.name,
              value: user._id,
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(
        new Set(usersFromAllCompanies.map((user) => user.value))
      ).map((id) => {
        return usersFromAllCompanies.find((user) => user.value === id);
      });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  const getPunchCategory = async (projectId) => {
    try {
      const res = await getAllCategory(projectId);
      console.log(res?.data?.data);
      const categoryy = res?.data?.data;
      const capitalizedCategory = categoryy.map(category => ({
        ...category,
        name: capitalizeFirstLetter(category.name)
      }));
      setCategories(capitalizedCategory);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPunchCategory(projectId);
  }, []);

  // const submitPunchCategory = async () => {
  //   try {
  //     const payload = {
  //       name: addCategoryy,
  //       project_id: projectId,
  //     };
  //     await addCategory(payload);
  //     setAddCategoryy("");
  //     getPunchCategory(projectId);
  //     setVisible(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getPunchTrade = async (projectId) => {
    try {
      const res = await getTradeByProjectId(projectId);
      console.log(res?.data?.data);
      const tradee = res?.data?.data;
      const capitalizedTrades = tradee.map(trade => ({
        ...trade,
        name: capitalizeFirstLetter(trade.name)
      }));

      setTrades(capitalizedTrades);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPunchTrade(projectId);
  }, []);
  // const submitTrade = async () => {
  //   try {
  //     const payload = {
  //       name: addTradess,
  //       project_id: projectId,
  //     };
  //     await addTrade(payload);
  //     setAddTradess("");
  //     getPunchTrade(projectId);
  //     setVisible2(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleCreateAddIcon = async (type) => {

    try {
      if (type === "category" && !addCategoryy) {
        toast.info(
          "Please fill  the  Submittal Category field marked with * are mandatory"
        );
        return;
      }

      if (type === "trade" && !addTradess) {
        toast.info(
          "Please fill  the  Submittal Package field marked with * are mandatory"
        );
        return;
      }

     

      if (type === "category") {
        setLoading(true)
        await addCategory({
          name: addCategoryy,
          project_id: projectId
        });
        setAddCategoryy('')
        getPunchCategory(projectId)
        setVisible(false);
        setLoading(false)
     
      } else if (type === "trade") {
      setLoading(true)
        await  addTrade({
          name: addTradess,
          project_id: projectId
        });
        setAddTradess('')
        setVisible2(false)
        getPunchTrade(projectId)
        setLoading(false)
      
      }
      

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={` ${style.createPunch_con}`}>
      <div>
        <div>
          <div className="row mb-3">
            <div className="col-12   px-3 ">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/projectManagement/punch")}
              >
                {" "}
                Punch List
              </span>
              <span className="px-1 open_Rfi_text">
                {" "}
                <FaAngleRight size={15} />{" "}
              </span>
              <span className="createNew_text">Edit Punch List</span>
            </div>
          </div>

          <h1>EDIT PUNCH LIST</h1>
          <br />
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} />
          ) : (
            <div className="row">
              <div className="col-md- col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List Name:<span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Punch Item Name"
                  value={punchItemName}
                  onChange={(e) => setPunchItemName(e.target.value)}
                  className={validation.punchItemName ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, punchItemName: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List ID: <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  placeholder="Punch List ID"
                  value={punchListID}
                  onChange={(e) => setPunchListID(e.target.value)}
                  style={{ width: "100%" }}
                  className={`${
                    validation.punchNo ? "Validation" : ""
                  } form-control common_input`}
                  onClick={() =>
                    setValidation({ ...validation, punchNo: false })
                  }
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Contractor Company(s):{" "}
                </label>

                <MultiSelect
                  value={responsibleContractor}
                  //  onChange={(e) => setResponsibleContractor(e.value)}
                  onChange={handleCompanyChange}
                  options={companies}
                  optionLabel="label"
                  filter
                  placeholder="Company"
                  // maxSelectedLabels={3}
                  style={{ width: "100%" }}
                  className={`p-0 ${validation.contractor ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...validation, contractor: false })
                  }
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Member(s):
                </label>
                <MultiSelect
                  value={membersResponsible}
                  onChange={(e) => setMembersResponsible(e.value)}
                  options={users}
                  optionLabel="label"
                  filter
                  placeholder="Select Member"
                  // maxSelectedLabels={3}
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${
                    validation.membersResponsible ? "Validation" : ""
                  }`}
                  onClick={() =>
                    setValidation({ ...validation, membersResponsible: false })
                  }
                />
              </div>

              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                Responsible Person(s):
                </label>
                <MultiSelect
                  value={personResponsible}
                  onChange={(e) => {setPersonResponsible(e.value)
                    setValidation({ ...validation, person: false })
                  }}
                  options={allUsersOfProject.map((item) => ({
                    label: item?.first_name,
                    value: item?._id,
                  }))}
                  optionLabel="label"
                  filter
                  placeholder="Select Person"
                  // maxSelectedLabels={3}
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${validation.person ? "Validation" : ""
                    }`}
                  onClick={() =>
                    setValidation({ ...validation, person: false })
                  }
                />
              </div>
              {/* <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Status:
                </label>
            
                <SingleSelect
                  options={options}
                  placeholder={"Status"}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={
                    allData.punch_approval_status == "approved" ||
                    allData.punch_review_status == "approved"
                  }
                />
              </div> */}

              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List Manager(s):<span className="text-danger">*</span>
                </label>
               
                <MultiSelect
                  value={punchItemManager}
                  onChange={(e) =>{ setPunchItemManager(e.value)
                    setValidation({ ...validation, punchItemManager: false })
                  }}
                  options={allUsersOfProject.map((item) => ({
                    label: item?.first_name,
                    value: item?._id,
                  }))}
                  optionLabel="label"
                  filter
                  placeholder="Select Person"
                  // maxSelectedLabels={3}
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${
                    validation.punchItemManager ? "Validation" : ""
                  }`}
                  onClick={() =>
                    setValidation({ ...validation, punchItemManager: false })
                  }
                />
              </div>
              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Location:<span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className={validation.location ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, location: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>
              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Due Date:<span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  placeholder="Due Date"
                  value={formatDate(eventDate)}
                  onChange={(e) => setEventDate(e.target.value)}
                  className={`${
                    validation.eventDate ? "Validation" : ""
                  } form-control`}
                  onClick={() =>
                    setValidation({ ...validation, eventDate: false })
                  }
                />
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label className="labels mb-2">Category:</label>{" "}
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center  ">
                    <AutoCompleteSelect
                      options={categories?.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={category}
                      optionLabel="category_name"
                      onChange={(value) => {setCategory(value)
                        setValidation({ ...validation, category: false })
                      }}
                      className={`p-0 text-capitalize ${
                        validation.category ? "Validation" : ""
                      }`}
                      onClick={() =>
                        setValidation({ ...validation, category: false })
                      }
                      placeholder="Select Category"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Hours Spent:<span className="text-danger">*</span>
                </label>
                {console.log(hourSpent, "hourSpent")}
                <TimeInput
                  value={hourSpent}
                  onChange={(e) => {
                    console.log("TimeInput onChange:", e);
                    
                      const {hours, minutes} = e;
                      setHourSpent({ hours, minutes });
                    
                  }}
                  disabled
                />
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Trade: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={trades.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={trade}
                      optionLabel="trade_name"
                      onChange={(value) => {setTrade(value)
                        setValidation({ ...validation, trade: false })
                      }}
                      className={`p-0 text-capitalize ${
                        validation.category ? "Validation" : ""
                      }`}
                      onClick={() =>
                        setValidation({ ...validation, trade: false })
                      }
                      placeholder="Select Trade"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible2(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Workflow Status: <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  value={status}
                  options={statusOptions}
                  placeholder="Workflow Status"
                  onChange={(e) => setStatus(e.target.value)}
                  className={validation.status ? "validation p-0" : "p-0"}
                  onClick={() =>
                    setValidation({ ...validation, status: false })
                  }
                // disabled={
                //   alldata.rfi_approval_status == "approved" ||
                //   alldata.rfi_review_status == "approved"
                // }
                />
              </div>

              <div className="col-md-6 d-flex">
                <div className="maindiv_plusIcon">
                  <label htmlFor="" className="py-2 labelCss">
                    Linked Drawings:
                  </label>
                  <SingleSelect
                    options={drawingListing?.map((item) => ({
                      label: item.drawing_name,
                      value: item.drawing_name,
                    }))}
                    placeholder={"Link Drawing (s)"}
                    value={drawing}
                    onChange={(e) => setDrawing(e.target.value)}
                    // className={validation.drawing ? "Validation" : ""}
                    //   onClick={() =>
                    //     setValidation({ ...validation, drawing: false })
                    //   }
                  />
                </div>
              </div>

              {isReviewerAccess && (
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={reviewer}
                    optionLabel="label"
                    onChange={(value) => {
                      setReviewer(value);
                    }}
                    className={`p-0 }`}
                       
                    disabled={
                      reviewer === getUserId() || allData?.status == "approved"
                    }
                    placeholder="Select Reviewer"
                  />
                </div>
              )}

              {isApproverAccess && (
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={approver}
                    optionLabel="label"
                    onChange={(value) => {
                      setApprover(value);
                    }}
                    className={`p-0 `}
                   
                    placeholder="Select Approver"
                    disabled={approver === getUserId()}
                  />
                </div>
              )}
              <div className="row m-0 p-0">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer Comment:
                  </label>
                  <TextInput
                    placeholder=" Reviewer Comment"
                    value={reviewerComment}
                    onChange={(e) => setReviewerComment(e.target.value)}
                    disabled
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver Comment:
                  </label>
                  <TextInput
                    placeholder=" Approver Comment"
                    value={approverComment}
                    onChange={(e) => setApproverComment(e.target.value)}
                    disabled
                  />
                </div>
              </div>

              <div className="col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Description:
                </label>

                <Editor
                  style={{ height: "200px" }}
                  placeholder={"Description"}
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  className={validation.description ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...validation, description: false })
                  }
                />
              </div>

              <div className="col-12 mt-2">
                <label
                  htmlFor=""
                  className="py-2 labelCss text-decoration-underline"
                ></label>
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setAttachFile}
                  onChange={handleFileSubmit}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                />
              </div>
            </div>
          )}

          {/* <div>
            <hr className="my-4" />
          </div>
          <div className="fw-bold fs-6">Assignee</div>

          {skeletonLoader ? <TableSkelton header={["", "Assignee Name", "Comments", "Attatchment", "Status", ""]} /> :
            <BorderedTable>
              <DataTable value={tableData}>
                <Column field="id" header=""></Column>
                <Column
                  header="Assignee Name"
                  body={(rowData, rowIndex) => {
                    return (
                      <SingleSelect
                        className="flex align_center"
                        style={{ height: "40px" }}
                        options={assigneeUsers}
                        value={rowData.AssigneeName}
                        placeholder="assigneeName"
                        onChange={(e) => {
                          changeAssigneeName(
                            rowData,
                            e.target.value,
                            rowIndex.rowIndex
                          );
                        }}
                      />
                    );
                  }}
                ></Column>
                <Column
                  field="comment"
                  header="Comments"
                  body={(rowData, rowIndex) => (
                    <div>
                      <TextInput
                        onChange={(e) => {
                          const updatedTableData = [...tableData];
                          updatedTableData[rowIndex.rowIndex].Comments =
                            e.target.value;
                          setTableData(updatedTableData);
                        }}
                        value={rowData.Comments}
                      />
                    </div>
                  )}
                />

                
                <Column
                  body={(rowData, meta) => (
                    <div>
                      <EditFileUpload
                        index={meta?.rowIndex}
                        previous={assigneeAttachment}
                        setPrevious={setPrevious}
                        data={assigneeFiles}
                        setData={setAssigneeFiles}
                        removed={setRemovePhoto}
                      />
                    </div>
                  )}
                  header="Attatchment"
                ></Column>
                <Column
                  body={(rowData, rowIndex) => (
                    <div>
                      <SingleSelect
                        className="flex align_center"
                        style={{ height: "40px" }}
                        options={["Unresolved", "Ready for Review"]}
                        value={rowData.Status}
                        placeholder="Select"
                        onChange={(e) => {
                          changeAssigneeStatus(
                            rowData,
                            e.target.value,
                            rowIndex.rowIndex
                          );
                        }}
                      />
                    </div>
                  )}
                  header="Status"
                ></Column>
                <Column
                  body={(rowData, rowIndex) => (
                    <div>
                      <span
                        onClick={() =>
                          deleteAssignee(rowData, rowIndex.rowIndex)
                        }
                      >
                        <DeleteButton />
                      </span>
                    </div>
                  )}
                ></Column>
              </DataTable>
            </BorderedTable>}
          <br />
        
          <div>
            +
            <span
              className="addAssignee_btn ms-1 fw-bold fs-6"
              onClick={handleAddRow}
            >
              Add Assignee
            </span>
          </div>
          <br /> */}
          <div className="d-flex gap-2 d-flex justify-content-end mt-3">
            <button
              className="btn border"
              onClick={() => navigate("/projectManagement/punch")}
            >
              Cancel
            </button>
            <PrimaryButton text={"Update"} onClick={handleUpdatePunch} />
          </div>
        </div>
        <br />
      </div>

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Punch Category"}
        label={"Category"}
        reqFor={"create"}
        value={addCategoryy}
        setValue={setAddCategoryy}
        onSubmit={() => handleCreateAddIcon("category")}
      // onSubmit={submitPunchCategory}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible2}
        setVisible={setVisible2}
        CategoryName={"Punch Trade"}
        label={"Trade"}
        reqFor={"create"}
        value={addTradess}
        setValue={setAddTradess}
        onSubmit={() => handleCreateAddIcon("trade")}
      // onSubmit={submitTrade}
      />
    </div>
  );
};
