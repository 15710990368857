import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Calender from "../../Components/Common/Calender";
import {  getUserByProjectId,getAllObserVationDataById } from "../../Api/Admin/AllAPIs";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";

const Filter = ({ filteredData, setFilteredData, onFilter }) => {

  const [visible, setVisible] = useState(false)
  const projectId = localStorage.getItem("projectId");
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [getObservationData,setGetObserVationData] = useState([])


  const status_options = [
    { value: "open", label: "Open" },
    { value: "close", label: "Close" },
    { value: "reopen", label: "Reopen" },
    { value: "onhold", label: "On Hold" },
  ];
  const ReceivedFrom = [
    {
      entity_id: 1,
      entity_name: "Roy Construction",
    },
    {
      entity_id: 2,
      entity_name: "Jonshon Builders",
    },
    {
      entity_id: 3,
      entity_name: "A&C Constructors",
    },
    {
      entity_id: 4,
      entity_name: "Smith Engineering",
    },
  ];
  const typeOption = ["Environmental", "Health and Safety", "Materials", "Permit Compliance", "QA/QC", "Quality Control", "Three-Phase Quality Control"];
  const tradeOptions = ["Electrical", "Plumbing", "Carpentry", "Concrete", "HVAC (Heating, Ventilation, and Air Conditioning)", "Masonry", "Painting", "Roofing", "Demolition", "Landscaping"];

  function inpChange(e) {
    let { name, value } = e.target;
    setFilteredData(pre => ({ ...pre, [name]: value }))
  }
 
  function applyFilterr() {
    setVisible(false)
    onFilter()
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
    }
    catch (err) {
      console.log(err)
    }
  }

  async function getAllObserverStatusById(){
    try{
      let res = await getAllObserVationDataById(projectId)
      setGetObserVationData(res.data.data)
    }catch(error){
      console.log(error)
    }
  }
  useEffect(() => {
    getAllUsersOfProject()
    getAllObserverStatusById()
  }, [])

  return (
    <div className="card flex justify-content-center ">
      <button style={{width:"fit-content !important"}} className="filter_btn" onClick={() => setVisible(true)}>
        Filter 
      </button>
      <Dialog
        header="Filter Observation"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row">

          
          <div className="col-md-6">
            <label className="labels">Observation Status:</label>
            <div className="card flex justify-content-center mt-2 ">
             
              <Dropdown
  value={filteredData?.observationStatus}
  onChange={(e) => inpChange(e)}
  options={getObservationData.map((res) => ({ label: res.name, value: res._id }))}
  placeholder="All"
  name="observationStatus"
/>
{console.log(filteredData?.observationStatus)}
            </div>
          </div>
          <div className="col-md-6">
            <label className="labels">Observed by:</label>
            <div className="card flex justify-content-center mt-2">
             
              <Dropdown
  value={filteredData?.observedBy}
  onChange={(e) => inpChange(e)}
  options={allUsersOfProject.map((d) => ({ label: d.first_name, value: d._id }))}
  placeholder="All"
  name="observedBy"
/>
{console.log(filteredData?.observedBy)}
            </div>
          </div>
          
          
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>

              <PrimaryButton
                onClick={applyFilterr}
                addIcon
                text={"Apply Filter"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
