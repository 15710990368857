import React, { useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";
import "./portfolio.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { CiSearch } from "react-icons/ci";
import FilterProject from "../FilterProject/FilterProject";
// import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import Filter from "./Filter";
import { SearchInput } from "../Inputs/SearchInput";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import BarChart from "./BarChart";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
// import TopBar from "../TopBar/TopBar";

const Portfolio = () => {
  const [visible, setVisible] = useState(false);
  const [chartData, setChartData] = useState({});
  const [dataLabel, setDataLebel] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});
  const dd = useRef({});
  const navigate = useNavigate();

  // useEffect(() => {
  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue("--text-color");
  //   const textColorSecondary = documentStyle.getPropertyValue(
  //     "--text-color-secondary"
  //   );
  //   const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  //   const data = {
  //     labels: [
  //       "RFIs",
  //       "Submittals",
  //       "Punch Lists",
  //       "Meetings",
  //       "Incidents",
  //       "Tasks",
  //     ],
  //     datasets: [
  //       {
  //         label: "Overdue",
  //         backgroundColor: "#D42620",
  //         borderColor: "#D42620",
  //         data: [100, 50, 100, 150, 100,90],
  //         barThickness: 20,
  //         borderRadius: 10,
  //         barPercentage: 1.5,
  //       },

  //       {
  //         label: "Next 7 Days",
  //         backgroundColor: "#0F973D",
  //         borderColor: "0F973D",
  //         data: [150, 100, 50, 50, 100,110],
  //         barThickness: 20,
  //         borderRadius: 10,
  //         barPercentage: 1.5,
  //       },

  //       {
  //         label: "> 7 Days",
  //         backgroundColor: "#F3A218",
  //         borderColor: "red",
  //         data: [50, 150, 150, 100, 100,100],
  //         barThickness: 20,
  //         borderRadius: 10,
  //         barPercentage: 1,
  //       },
  //     ],
  //   };
  //   const options = {
  //     indexAxis: "y",
  //     maintainAspectRatio: false,
  //     aspectRatio: 0.8,
  //     plugins: {
  //       legend: {
  //         labels: {
  //           boxWidth: 20, // Adjust the width to make it square
  //           boxHeight: 20, // Hide legend labels
  //         },
  //       },
  //       tooltip: { // Add tooltip configuration
  //         callbacks: {
  //           label: function(context) {
  //             var label = context.dataset.label || '';

  //             if (label) {
  //               label += ': ';
  //             }
  //             if (context.parsed.y !== null) {
  //               label += context.parsed.y;
  //             }
  //             return context.raw;
  //           }
  //         }
  //       },
  //       datalabels: { // Add datalabels configuration
  //         anchor: 'end',
  //         align: 'end',
  //         color: '#111',
  //         font: {
  //           weight: 'bold',
  //           color: '#ffffff',
  //           size: 16,
  //         },
  //         formatter: function(value, context) {
  //           return value; // Use the value directly as the label
  //         } // You can customize the formatter as needed
  //       },
  //     }
  //   ,scales: {
  //       x: {
  //         stacked: true,
  //         ticks: {

  //           color: textColorSecondary,
  //           font: {
  //             weight: 500,
  //           },
  //         },
  //         display: false,
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //         },
  //       },
  //       y: {
  //         stacked: true,
  //         ticks: {
  //           color: textColorSecondary,
  //         },
  //         grid: {
  //           color: surfaceBorder,
  //           drawBorder: false,
  //         },
  //       },
  //     },
  //   };
  //   dd.current = options.plugins.datalabels
  //   setDataLebel(options.plugins.datalabels)
  //   setChartData(data);
  //   setChartOptions(options);
  // }, []);

  // useEffect(()=>{
  //   if(chartOptions?.plugins?.datalabels!==undefined){

  //     setDataLebel(chartOptions?.plugins?.datalabels)
  //     console.log(chartOptions.plugins.datalabels)
  //   }
  // },[setChartOptions])

  // useEffect(() => {
  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue("--text-color");
  //   const textColorSecondary = documentStyle.getPropertyValue(
  //     "--text-color-secondary"
  //   );
  //   const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  //   const data = {
  //     labels: ["RFIs", "Submittals"],
  //     datasets: [
  //       {
  //         label: "Overdue",
  //         backgroundColor: "#D42620",
  //         borderColor: "#D42620",
  //         data: [100, 100, 100, 100, 100],
  //         barThickness: 20,
  //         borderRadius: 10,
  //       },

  //       {
  //         label: "Next 7 Days",
  //         backgroundColor: "#0F973D",
  //         borderColor: "0F973D",
  //         data: [100, 100, 100, 100, 100],
  //         barThickness: 20,
  //         borderRadius: 10,
  //       },

  //       {
  //         label: "> 7 Days",
  //         backgroundColor: "#F3A218",
  //         borderColor: "red",
  //         data: [100, 100, 100, 100, 100],
  //         barThickness: 20,
  //         borderRadius: 10,
  //       },
  //     ],
  //   };
  //   const options = {
  //     indexAxis: "y",
  //     maintainAspectRatio: false,
  //     aspectRatio: 1.8,
  //     plugins: {
  //       legend: {
  //         labels: {
  //           fontColor: textColor,
  //           boxWidth: 20, // Adjust the width to make it square
  //           boxHeight: 20,
  //         },

  //       },
  //     },
  //     scales: {
  //       x: {
  //         stacked: true,
  //         ticks: {
  //           color: textColorSecondary,
  //           font: {
  //             weight: 500,
  //           },
  //         },
  //         display: false,
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //         },
  //       },
  //       y: {
  //         stacked: true,
  //         ticks: {
  //           color: textColorSecondary,
  //         },
  //         grid: {
  //           color: surfaceBorder,
  //           drawBorder: false,
  //         },
  //       },
  //     },
  //   };

  //   setChartData2(data);
  //   setChartOptions2(options);
  // }, []);
  // const [visible2, setVisible2] = useState(false);

  // const data = [
  //   {
  //     "#": 111,
  //     Status: "yellow",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "green",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "green",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "yellow",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "yellow",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "red",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "red",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  //   {
  //     "#": 111,
  //     Status: "red",
  //     Subject: "Window",
  //     Responsible: "ipsum works",
  //     Member: "tarina",
  //     Date: "24/11/2023",
  //     Due: "07/12/20024",
  //   },
  // ];
  return (
    <>
    {/* <TopBar/> */}
      <div className="portfoli_main">
      <BarChart
                    className=""
                    series={[
                      {
                        name: "Open",
                        data: [3, 3, 6, 7, 10],
                      },
                      {
                        name: "Close",
                        data: [1, 7, 1,  3, 4],
                      },
                    ]}
                  />
        {/* <div className="mt-3 pt-3 d-flex justify-content-between align-items-center ">
          <div className="heading ps-2">PORTFOLIO</div>
          <PrimaryButton
            onClick={() => {
              navigate("/portfolio/projectform/step-1")
              localStorage.removeItem("projectId")
            }}
            addIcon={true}
            text={"Create Project"}
          />
        </div>
        <div className="mt-4 row">
         
          <div className="col-md-3 SearchInputC">
            <SearchInput />
          </div>
          <div className="col-md-9 col-sm-12 d-flex justify-content-end ">
            <button
              className="me-3 filter_btn ps-2 pe-2"
              onClick={() => setVisible(true)}
            >
              Filter Projects
            </button>
            {visible && <Filter visible={visible} setVisible={setVisible} />}
            <span className="clear_btn">Clear All</span>
          </div>
        </div> */}

        {/* <div></div>
        <div className="mt-5 chart_section">
          <div className="chart_head">
            <span className="chart_heading">CAMPANIL ROW</span>
          </div>
          <div className="campanil_row p-3 d-flex  justify-content-between ">
            <div className="d-flex  w-auto  gap-5 flexCustom" >
              <div className="d-flex flex-column">
                <span className="status"> Project Start Date:</span>
                <span className="status">Project Finish Date:</span>
              </div>
              <div className="d-flex flex-column">
                <span className="check_value">Active</span>
                <span className="check_value">15 November 2023</span>
              </div>
            </div>
            <div className="d-flex w-auto justify-content-between gap-5 flexCustom ">
              <div className="d-flex flex-column ">
                <span className="status pl-3">Status          </span>
                <span className="status">Duration:</span>
              </div>
              <div className="d-flex flex-column ">
                <span className="check_value">112 Days</span>
                <span className="check_value">29 March 2025</span>
              </div>
            </div>
            <div className="d-flex justify-content-between gap-5 w-auto flexCustom">
              <div className="d-flex flex-column ">
                <span className="status">Stage:</span>
                <span className="status">Department:</span>
              </div>
              <div className="d-flex flex-column ">
                <span className="check_value">Course of Construction</span>
                <span className="check_value">Framing, Construction</span>
              </div>
            </div>
            <div></div>
          </div>
          <div>
            <div className=" d-flex" onClick={() => setVisible2(true)}>
            
              <div className="w-100 d-flex  border pt-2">
                <div className="w-100">
                  <BarChart
                    className=""
                    series={[
                      {
                        name: "Overdue",
                        data: [44, 55, 41, 37, 22, 10],
                      },
                      {
                        name: "Next 7 Days",
                        data: [53, 32, 33, 52, 13, 43],
                      },
                      {
                        name: "> 7 Days",
                        data: [12, 17, 11, 9, 15, 11],
                      },
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-center ">
                  <div className=" d-flex flex-column  align-items-center  chart_total_home  ">
                    <div className=" totalOpen">Total open </div>
                    <div className="GapChart">
                      <span className="no_gap">12</span>
                      <span className="no_gap"> 17</span>
                      <span className="no_gap">12</span>
                      <span className="no_gap">17</span>
                      <span className="no_gap">12</span>
                      <span className="no_gap">17</span>
                    </div>
                  </div>
                </div>
          
              </div>

            
          
            </div>
          </div>
        </div>

        <div className="mt-5 chart_section mb-5">
          <div className="chart_head">
            <span className="chart_heading">VORTEX BUSINESS CENTER</span>
          </div>
          <div className="campanil_row p-3 d-flex  justify-content-between ">
            <div className="d-flex   w-auto  justify-content-between  gap-5 flexCustom">
              <div className="d-flex flex-column">
                <span className="status"> Project Start Date:</span>
                <span className="status">Project Finish Date:</span>
              </div>
              <div className="d-flex flex-column">
                <span className="check_value ">Active</span>
                <span className="check_value">15 November 2023</span>
              </div>
            </div>
            <div className="d-flex   w-auto justify-content-between gap-5 flexCustom">
              <div className="d-flex flex-column">
                <span className="status">Status</span>
                <span className="status">Duration:</span>
              </div>
              <div className="d-flex flex-column">
                <span className="check_value">112 Days</span>
                <span className="check_value">29 March 2025</span>
              </div>
            </div>
            <div className="d-flex   w-auto  justify-content-between gap-5 flexCustom">
              <div className="d-flex flex-column">
                <span className="status">Stage:</span>
                <span className="status">Department:</span>
              </div>
              <div className="d-flex flex-column">
                <span className="check_value">Course of Construction</span>
                <span className="check_value">Framing, Construction</span>
              </div>
            </div>
            <div></div>
          </div>
          <div>
          <div className="w-100 d-flex  border pt-2">
                <div className="w-100">
                  <BarChart
                    className=""
                    series={[
                      {
                        name: "Overdue",
                        data: [44, 55, 41, 37, 22, 10],
                      },
                      {
                        name: "Next 7 Days",
                        data: [53, 32, 33, 52, 13, 43],
                      },
                      {
                        name: "> 7 Days",
                        data: [12, 17, 11, 9, 15, 11],
                      },
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-center ">
                  <div className=" d-flex flex-column  align-items-center  chart_total_home  ">
                    <div className=" totalOpen">Total open </div>
                    <div className="GapChart">
                      <span className="no_gap">12</span>
                      <span className="no_gap"> 17</span>
                      <span className="no_gap">12</span>
                      <span className="no_gap">17</span>
                      <span className="no_gap">12</span>
                      <span className="no_gap">17</span>
                    </div>
                  </div>
                </div>
             
              </div>
          </div>
        </div> */}
      </div>
      {/* <Dialog
        draggable={false}
        header="RFIs"
        visible={visible2}
        style={{ width: "60vw", height: "38vw" }}
        onHide={() => setVisible2(false)}
      >
        <div className="card">
          <DataTable value={data} tableStyle={{ minWidth: "50rem" }}>
            <Column
              field="Status"
              header="Status"
              body={(rowData) => (
                <div className={`statusBox ${rowData.Status}`}></div>
              )}
            ></Column>
            <Column field="#" header="#"></Column>
            <Column field="Subject" header="Subject"></Column>
            <Column field="Member" header="Member(s) Responsible"></Column>
            <Column field="Due" header="Due Date"></Column>
          </DataTable>
        </div>
      </Dialog> */}
    </>
  );
};
export default Portfolio;
