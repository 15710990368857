import React, { useState, useRef, useEffect } from "react";
import "./PaintTouchup.css";
import "./CreatePunch.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { getPunch, getPunchbyid } from "../../Api/Admin/AllAPIs";
import axios from "axios";
import fileDownload from "js-file-download";

function PaintTouchup() {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [viewRes, setViewRes] = useState({});
  const [fileName, setFileName] = useState();

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
    console.log(files);
  };

  let navigate = useNavigate();

  useEffect(() => {
    fetchPunch();
  }, []);

  console.log(viewRes);

  const fetchPunch = async () => {
    try {
      const res = await getPunchbyid(id);
      const getAll = res?.data?.data;
      setViewRes(getAll);
    } catch (err) {
      console.error(err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  };


  const handleDownload = async (fileName, fileUrl) => {
    console.log('Downloading file:', fileName, 'from URL:', fileUrl);
    try {
      const response = await axios.get(fileUrl, { responseType: 'blob' });
      console.log('Response:', response);
      fileDownload(response.data, fileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };


 return (
    <>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex flex-column paint-touch">
            <div className="row">
              <div className="col-12 px-3">
                <span
                  className="open_Rfi_text"
                  onClick={() => navigate("/punch")}
                >
                  Punch List
                </span>
                <span className="px-1 open_Rfi_text">
                  <FaAngleRight size={15} />
                </span>
                <span className="createNew_text">{viewRes?.PunchItemName}</span>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between">
                <h1 className="heading">{viewRes?.PunchItemName}</h1>
              </div>
              <div className="pt-2 pb-2">
                <div className="pt-2 pb-2">
                  {viewRes?.status ? (
                    <>
                      {viewRes.status.toLowerCase() === "ready to close" && (
                        <Statuscomp
                          className={"ready_to_close_status"}
                          label={"Ready to close"}
                        />
                      )}
                      {viewRes.status.toLowerCase() === "work required" && (
                        <Statuscomp
                          className={"work_required_status"}
                          label={"Work Required"}
                        />
                      )}
                      {viewRes.status.toLowerCase() !== "ready to close" &&
                        viewRes.status.toLowerCase() !== "work required" && (
                          <Statuscomp
                            className={"draft_status"}
                            label={"Draft"}
                          />
                        )}
                    </>
                  ) : (
                    <Statuscomp className={"draft_status"} label={"Draft"} />
                  )}
                </div>
              </div>
              <div className="table-one">
                <div className="d-flex flex-column left-side">
                  <p>Due Date:</p>
                  <p>Member Responsible:</p>
                </div>
                <div className="d-flex flex-column right-side">
                  <p>{formatDate(viewRes?.Date)}</p>
                  <p>{viewRes?.MembersResponsible}</p>
                </div>
              </div>
            </div>
            <hr className="m-0 p-0" />
            <div className="row">
              <table className="table">
                <tr>
                  <th className="left-side1 pr-0">Punch Item Manager:</th>
                  <th className="right-side1 pl-0">
                    {viewRes?.punchItemManager}
                  </th>
                </tr>
                <tr>
                  <td className="left-side1 pr-0">Location:</td>
                  <td className="right-side1 none pl-0">{viewRes?.Location}</td>
                </tr>
                <tr>
                  <td className="left-side1 pr-0">Type:</td>
                  <td className="right-side1 none pl-0">{viewRes?.Type}</td>
                </tr>
                <tr>
                  <td className="left-side1 pr-0">Trade:</td>
                  <td className="right-side1 pl-0">{viewRes?.Trade}</td>
                </tr>
                <tr>
                  <td className="left-side1 pr-0">Description:</td>
                  <td className="right-side1 pl-0">{viewRes?.Location}</td>
                </tr>
                <tr>
                  <td className="left-side1 pr-0">Linked Drawing(s):</td>
                  <td className="right-side1 pl-0">{viewRes?.linkDrawing}</td>
                </tr>
                  
              </table>
            </div>
            <hr className="m-0 p-0" />
            <div className="row react_table table_bg_none">
              <p className="assignees mt-2 ">Assignees </p>
              <BorderedTable>
                <DataTable
                  showGridlines
                  value={viewRes.assignees}
                  tableStyle={{ minWidth: "50rem", marginTop: "10px" }}
                >
                  <Column field="index" header=""></Column>
                  <Column field="AssigneeName" header=" Assignee Name"></Column>
                  <Column field="Comments" header="Comments"></Column>
                  <Column field="Attachment" header="Attachments"
                  body={(rowData)=>(
                    <div className="d-flex gap-2">{rowData?.Attachment?.map((item)=> (<div>{item?.fileName?.length > 20 ? item?.fileName?.slice(0, 20)+"..." : item?.fileName}</div>))} </div>
                  )}
                  ></Column>
                  <Column field="Status" header="Status"></Column>
                </DataTable>
              </BorderedTable>
            </div>
            <div className="mt-4">
              <h6>Attached Files:</h6>
              <div className="d-flex gap-4 flex-wrap">
                {viewRes?.attachFile?.map((file, index) => (
                  <div key={index} className="box">
                    <div
                      className="fw-bold border border-2 p-2 rounded-3"
                      onClick={() => handleDownload(file.fileName, file.fileUrl)}
                      style={{ cursor: 'pointer' }}
                    >
                      {file.fileName}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default PaintTouchup;
