import React from 'react'

export const Statuscomp = ({ label, className }) => {
    return (
        <div className='flex align-items-center gap-2'>
            <div className={`${className} statusBox`}></div>
            {label ? <div className=''>{label}</div> : null}
        </div>
    )
}
