export function getTimeZoneOptions() {

  const timeZones = Intl.supportedValuesOf("timeZone");


  const timeZoneOptions = timeZones.map((timeZone) => {

    const formatter = new Intl.DateTimeFormat("en", {
      timeZone,
      timeZoneName: "long",
    });

    const timeZoneName = formatter
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;


    return {
      label: timeZoneName,
      value: timeZone,
    };
  });


  return timeZoneOptions;
}
