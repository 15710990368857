import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar"

export const LogingKeeper = ({ role, layer }) => {
  const login = JSON.parse(localStorage.getItem('isLoggedIn'))

  return login ? <>
    {/* <Sidebar layer={layer}> */}
      <Outlet></Outlet>
    {/* </Sidebar> */}
  </>
    :
    <Navigate to='/' />
};


