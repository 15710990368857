import React, { useEffect, useState } from "react";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import { EditButton } from "../../Components/Buttons/EditButton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import Popover from "../../Components/Common/Popover";
import { deleteCompany, getCompanyAndPersonData, getProjectDirectory } from "../../Api/Admin/AllAPIs";
import { useNavigate } from "react-router-dom";
import { getActionsPermission, searchDataWithKeys } from "../../utils";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import Pagination from "../../Components/Common/Pagination/Pagination";

function SubContractors({ search, setSearch, setUsersCount }) {
  const [selectedProducts, setSelectedProducts] = useState("");
  let [subContractors, setSubContractors] = useState([])
  let [loader, setLoader] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [isDeleteAccess, setIsDeleteAccess] = useState(false);
  let deleteAccess = getActionsPermission("Delete Company")
  const [isEditAccess, setIsEditAccess] = useState(false);
  let editAccess = getActionsPermission("Edit Company")

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0)
  const perPageData = 10;
  // const [search, setSearch] = useState("")

  useEffect(() => {
    setIsDeleteAccess(deleteAccess)
    setIsEditAccess(editAccess)

  }, [deleteAccess])
  const [deletedId, setDeletedId] = useState("")

  let [allData, setAllData] = useState([])
  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  async function getCompay() {
    try {
      setLoader(true)
      setSearch("")
      let body = { type: "subcontractor" }
      let paginationParams = {
        page: page,
        perPageData: perPageData
      }
      const res = await getCompanyAndPersonData(body, null, paginationParams);
      console.log(res);
      setAllData(res.data.data)
      setTotalCount(res?.data?.totalCount || 0)
      setSubContractors(res.data.data)

    } catch (err) {
      console.log(err);
    }
    finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    // fetchCompany()
    getCompay();
  }, [page]);

  async function handleDelete() {

  }
  let navigate = useNavigate()

  async function confirmDelete() {
    console.log(deletedId)
    try {
      let res = await deleteCompany(deletedId)

      setAllData(pre => pre.filter(d => d._id !== deletedId))
      setSubContractors(pre => pre.filter(d => d._id !== deletedId))
      setUsersCount(pre => ({ ...pre, subcontractor: pre.subcontractor - 1 }))
      console.log(res)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setSubContractors(searchDataWithKeys(["company_name", "email_address"], allData, search))
  }, [search])

  return (
    <>
      <div>

        {/* <div className="searchInput">
          <SearchInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setSubContractors(searchDataWithKeys(["company_name", "email_address"], allData, e.target.value))

            }}
            onCancel={() => {
              setSearch("")
              setSubContractors(allData)
            }}
          />
        </div> */}


        <div className="userManagement_table_scroll">

          {loader ? <TableSkelton header={["Full Name", "Phone", "Email", "Address", ""]} /> :
            <BorderedTable>
              <DataTable
                value={subContractors}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="company_name" header="Full Name"></Column>
                <Column field="business_phone" header="Phone"></Column>
                <Column field="email_address" header="Email"></Column>
                <Column field="address" header="Address"></Column>
                <Column field="address" header=""
                  body={(rowData) => (
                    <div className="`d-flex align-items-center gap-2">
                      <Popover
                        options={[isEditAccess ? "Edit" : null, "view", isDeleteAccess ? "Delete" : null]}
                        onSelect={(ind) => {
                          if (ind === 0) {
                            navigate(`/projectDirectories/updateContractor/${rowData._id}`)
                          }
                          else if (ind === 2) {
                            setDeletedId(rowData._id);
                            setVisibleDelete(true)
                          }
                        }}
                      />
                    </div>
                  )}
                >
                </Column>
              </DataTable>
            </BorderedTable>}
          <br />
        </div>

        <Pagination
          currentPage={page}
          totalCount={totalCount}
          pageSize={perPageData}
          onPageChange={page => setPage(page)}
        />



        <DeleteDialog
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onCancel={() => setDeletedId(null)}
          onConfirm={confirmDelete}
          header={"User"}
        />

      </div>
    </>
  );
}

export default SubContractors;