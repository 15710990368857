






import React, { useEffect, useState } from "react";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import { EditButton } from "../../Components/Buttons/EditButton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import Popover from "../../Components/Common/Popover";
import { useNavigate } from "react-router-dom";
import { getCompanyAndPersonData, getProjectDirectory } from "../../Api/Admin/AllAPIs";

function Individuals() {
  const [selectedProducts, setSelectedProducts] = useState("");
  let [subContractors, setSubContractors] = useState([])
  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  async function getCompay() {
    try {
      let body = { type: "individual" }
      const res = await getCompanyAndPersonData(body);
      console.log(res);

      setSubContractors(res.data.data)

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    // fetchCompany()
    getCompay();
  }, []);

  async function handleDelete() {

  }
  let navigate = useNavigate()
  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-3 SearchInput">
            <SearchInput
            // value={search}
            // onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-9 d-flex justify-content-end mb-3 gap-3">
            <button className="filter_btn">Filter Directory</button>

            <button className={`clear_btn  `}>Clear All</button>
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center  mb-3 mt-3 ms-3">
          <span className="statusBox green"></span>{" "}
          <span className="plain_text">Active</span>
          <span className="statusBox red"></span>{" "}
          <span className="plain_text">Inactive</span>
        </div>

        <BorderedTable>
          <DataTable
            value={subContractors}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={5}

            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column field="first_name" header="Full Name"></Column>
            <Column field="business_phone" header="Phone"></Column>
            <Column field="email_address" header="Email"></Column>
            <Column field="address" header="Address"></Column>
            <Column field="address" header=""
              body={(rowData) => (
                <div className="`d-flex align-items-center gap-2">
                  <Popover
                    options={["Edit", "view", "Delete"]}
                  />
                </div>
              )}
            >
            </Column>
          </DataTable>
        </BorderedTable>
      </div>
    </>
  );
}

export default Individuals;
