import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable';
import { Statuscomp } from '../../Components/Common/StatusComp';
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName';
import { getIncidentById } from '../../Api/Admin/AllAPIs';
import moment from 'moment';
import { AiOutlineExport } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf';
import { ViewPagesPdfTemplate } from '../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';
import FormSkeleton from '../../Components/Common/FormSkeleton';
import ViewVersion from '../../Components/Common/ViewVersion';
import jsPDF from "jspdf";
import { toPng } from "html-to-image";
import { PdfHeaderAndFooter } from '../../Components/Common/PagesComponents/PdfHeaderAndFooter';
import html2pdf from 'html2pdf.js';


export const ViewIncident = ({ usedAsComponent, includeVersionHistory }) => {
    let [data, setData] = useState({})
    let navigate = useNavigate();
    const [attachedData, setAttachedData] = useState({
        drawing: [],
        files: [],
      });


    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const skeletonData = [
        { qty: 1, height: "2.5rem" },
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },

        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "1.4rem" },
        { qty: 1, height: "5rem" },


    ]

    let { id } = useParams();



    async function getData() {
        try {
            setSkeletonLoader(true);
            let res = await getIncidentById(id);
            setData(res?.data?.data?.[0]);
            console.log(res.data.data,"**********************")
            setAttachedData({
                drawing: [],
                files: [
                  ...res?.data?.data?.attachFile.map((e) => e.fileUrl),
                //   ...res?.data?.data?.documentList
                //     ?.map((e) => e.attachFile.map((d) => d.fileUrl))
                //     .flat(Infinity),
                ],
              });
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setSkeletonLoader(false);
        }
    }
    let pdfRef = useRef()
    function pdfDownload() {
        downloadPdf(pdfRef.current, [{
            heading: "Attached Documents", files: data?.attachFile.map(e => e.url)
        }
        ])
    }

    // const downloadPdf2 = () => {
    //     if (!pdfRef.current) {
    //       console.error("pdfRef is not attached to any element.");
    //       return;
    //     }
    
    //     const content = pdfRef.current;
    //     const array1 = attachedData.files;
    //     const array2 = [];
    
    //     toPng(content, { quality: 1.0 })
    //       .then((dataUrl) => {
    //         const pdf = new jsPDF("p", "mm", "a4");
    //         const imgProps = pdf.getImageProperties(dataUrl);
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
    //         pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
    //         let startY = pdfHeight + 10;
    
    //         const addLinksWithHeading = (heading, links) => {
    //           pdf.setFontSize(14);
    //           pdf.setTextColor(71, 83, 103);
    //           pdf.text(heading, 10, startY);
    //           startY += 10;
    
    //           pdf.setFontSize(12);
    //           pdf.setTextColor(0, 0, 255);
    //           pdf.setFont("helvetica", "normal");
    
    //           links.forEach((link) => {
    //             const text = link;
    //             const textX = 10;
    //             const textWidth = pdf.getTextWidth(text);
    
    //             if (textWidth > pdfWidth - 20) {
    //               const splittedText = pdf.splitTextToSize(text, pdfWidth - 20);
    //               let textY = startY;
    //               splittedText.forEach((line) => {
    //                 pdf.text(line, textX, textY);
    //                 textY += 7;
    //               });
    //               startY = textY;
    //             } else {
    //               pdf.text(text, textX, startY);
    //               startY += 10;
    //             }
    
    //             const linkHeight = 10;
    //             pdf.link(textX, startY - linkHeight, textWidth, linkHeight, {
    //               url: link,
    //             });
    //             startY += 5;
    //           });
    
    //           startY += 5;
    //         };
    
    //         if (array1.length !== 0) {
    //           addLinksWithHeading("Attached Documents", array1);
    //         }
    //         if (array2.length !== 0) {
    //           addLinksWithHeading("Linked Drawings", array2);
    //         }
    
    //         pdf.save("download.pdf");
    //       })
    //       .catch((err) => {
    //         console.error("Error generating PDF", err);
    //       });
    //   };

      function pdfDownload() {
        downloadPdf(pdfRef.current, [
          {
            heading: "Attached Documents",
            files: data?.attachFile.map((e) => e.fileUrl),
          },
        ]);
      }
    useEffect(() => {
        getData()
    }, [])

    async function downloadPdfhandler() {


      // Options for html2pdf
      const opt = {
        margin: [0.1, 0.1], // Margins: [top, left, bottom, right]
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };
  
      // Generate PDF
      await html2pdf().from(pdfRef.current).set(opt).save();
    }

    return (
        <div>
            <div>
                <div className="paint-touch">

                    {
                        skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                            <>
                                <div className="row">
                                    <div className="col-12 py-3 ">
                                        <span
                                            className="open_Rfi_text"
                                            onClick={() => navigate("/projectManagement/incident")}
                                        >
                                            {" "}
                                            Incident
                                        </span>
                                        <span className="px-1 open_Rfi_text"> /</span>
                                        <span className="createNew_text">View Incident </span>
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h1 className="heading">{data?.title} </h1>
                                        <div className="d-flex align-items-center gap-2" onClick={downloadPdfhandler}>
                                        {!usedAsComponent &&
                                            <div
                                                className="d-flex gap-2 align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <AiOutlineExport />
                                                Export
                                                <MdOutlineKeyboardArrowDown />
                                            </div>
                                        }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <ViewPageTable tableData={[

                                                { "IncidentID": data?.incidentID || "" },
                                                { "Incident Title": data?.title || "" },
                                                { "Priority" :  data?.priority?.map(e => e.name) || "--" },
                                                { "Incident Type": data?.incident_type?.map(e =>e.name) || "--" },
                                                { "Incident Idntified By": data?.incident_identifiedBy.map( e => e.first_name) || "--" },
                                                { "Noify Project Team" : data?.notification ? "Yes" : "No"},
                                                { "Reviewer's Comments" : data?.reviewer_comment || "--"}
                                               

                                            ]} />
                                        </div>
                                        <div className="col-6">
                                            <ViewPageTable tableData={[
                                                { "Status" : data?.status},
                                                { "Incident Date": moment(data?.incident_date).format("DD/MM/YY") || "--" },
                                                { "Incident Time": data?.incident_time || "--" },
                                                { "Incident Closed Time": moment(data?.closeDate).format("DD/MM/YY HH:MM") || "--" },
                                                { "Location" : data?.location || "--"},
                                                { "Approver's Comment" : data?.approver_comment || "--"}
                                              
                                            ]} />
                                        </div>
                                    </div>

                                    <div className="view_page_para_head mt-4 mb-2">Incident Feedback:</div>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: data?.description }}
                                    >
                                    </div>

                                    <div className="view_page_para_head mt-4 mb-2">Incident Attachment:</div>
                                    <div className="d-flex flex-wrap gap-3">
                                        {data?.attachFile?.map((res, key) => {
                                            return (
                                                <ViewPageDocumentName docUrl={res?.url} key={key} name={res?.fileName} />
                                            )
                                        })}
                                    </div>
                                </div>
                            </>}

                </div>
                {(!usedAsComponent || includeVersionHistory) && (
                    <>
                         <hr />
                             <ViewVersion isView={true} />
                     <br />
                    </>
                )}
            </div>


            {/* <div style={{ height: "0px", overflow: "hidden" }}>
                <div ref={pdfRef}>
                    <ViewPagesPdfTemplate
                        header={`${data?.title}`}
                        description={{
                            key: "Incident Descriptions",
                            value: <HtmlIntoText html={data?.description} />
                        }}
                        tableData1={[
                            { "Incident Title": data?.title || "" },
                            { "Priority" :  data?.priority?.map(e => e.name) || "--" },
                            { "Incident Type": data?.incident_type?.map(e =>e.name) || "--" },
                            { "Incident Idntified By": data?.incident_identifiedBy?.map( e => e.first_name) || "--" },
                           
                        ]}
                        tableData2={[
                            { "Incident Date": moment(data?.incident_date).format("DD/MM/YY") || "--" },
                            { "Incident Time": data?.incident_time || "--" },
                            { "Incident Closed Time": moment(data?.closeDate).format("DD/MM/YY HH:MM") || "--" },
                            { "Location" : data?.location || "--"},

                        ]}
                    />
                    <ViewVersion isView={true} />

                </div>
            </div> */}

<div style={{ height: "0px", overflow: "hidden" }}>
<div ref={pdfRef} className='p-3 bg-white'>
    <PdfHeaderAndFooter>
      <div className="newRfi_text">INCIDENT</div>
      <br />

      <div>
        {skeletonLoader ? (
          <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
        ) : (
          <div>
            <div className="row">
              <div className="col-12">
              <ViewPageTable tableData={[
                    { "Incident ID": data?.incidentID || "" },
                    { "Incident Titlel": data?.title || "" },
                    { "Priority": data?.priority?.map(e => e.name).join(", ") || "--" },
                    { "Incident Type": data?.incident_type?.map(e => e.name).join(", ") || "--" },
                    { "Incident Identified By": data?.incident_identifiedBy?.map(e => e.first_name).join(", ") || "--" },
                    { "Incident Date": moment(data?.incident_date).format("DD/MM/YY") || "--" },
                    { "Incident Time": data?.incident_time || "--" },
                    { "Incident Closed Time": moment(data?.closeDate).format("DD/MM/YY HH:mm") || "--" },
                    { "Location": data?.location || "--" },
                    // { "Approver": `${data?.approver?.first_name} ${data?.approver?.last_name}` },
                    // { "Reviewer": `${data?.reviewer?.first_name} ${data?.reviewer?.last_name}` },
                    { "Reviewer's Comments" : data?.reviewer_comment || "--"},
                    { "Approver's Comment" : data?.approver_comment || "--"},
                  ]}
                  description={{
                    key: "Incident Feedback",
                    value: <HtmlIntoText html={data?.discription} />,
                  }}
                  // tableData2={[
                  //   { "Incident Date": moment(data?.incident_date).format("DD/MM/YY") || "--" },
                  //   { "Incident Time": data?.incident_time || "--" },
                  //   { "Incident Closed Time": moment(data?.closeDate).format("DD/MM/YY HH:mm") || "--" },
                  //   { "Location": data?.location || "--" },
                  // ]}
                />
              </div>
            </div>

            <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
            <div className="d-flex flex-wrap gap-3 mb-2">
              {data?.attachFile?.map((res, key) => (
                <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
              ))}
            </div>
              <br/>
            <div className="ml-2">
              <ViewVersion isView={true} />
            </div>
            <br />
          </div>
        )}
      </div>
    </PdfHeaderAndFooter>
  </div>
</div>

            {!usedAsComponent &&
            <div className="d-flex justify-content-end mt-2">
                <button className='btn border' onClick={() => navigate("/projectManagement/incident")}>Back</button>
            </div>
}
            <br />
        </div>
    )
}


