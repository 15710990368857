import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import html2pdf from 'html2pdf.js';

function View({ visible, setVisible, res }) {
    const divRef = useRef(null);

    const onButtonClick = async () => {
        const element = divRef.current;

        // Wait for all images inside the element to load
        await waitForImagesToLoad(element);

        // Once all images are loaded, generate and save the PDF
        html2pdf().from(element).save();
    };

    const waitForImagesToLoad = (element) => {
        return new Promise((resolve) => {
            const images = element.getElementsByTagName('img');
            let loadedCount = 0;

            const imageLoaded = () => {
                loadedCount++;
                if (loadedCount === images.length) {
                    resolve();
                }
            };

            Array.from(images).forEach((img) => {
                if (img.complete) {
                    imageLoaded();
                } else {
                    img.addEventListener('load', imageLoaded);
                }
            });
        });
    };

    return (
        <Dialog visible={visible} style={{ width: '50rem' }} draggable={false} onHide={() => setVisible(false)}>
            <div className='p-3' ref={divRef}>
                <div className='flex flex-column mt-1 w-100 gap-1'>
                    <span className='coordinate'>ISSUES</span>
                    <div className='flex between'>
                        <span className='h4 mt-1' style={{ fontWeight: 'bold' }}>
                            Issue - {res?.issue_title}
                        </span>
                        <IoCloudDownloadOutline
                            style={{ fontSize: '30px', cursor: 'pointer' }}
                            onClick={onButtonClick}
                        />
                    </div>
                </div>

                <div className='d-flex align-content-center gap-1 mt-1'>
                    <span style={{ fontWeight: 'bold' }}>Status</span>-{' '}
                    <button className='open ps-3 pe-3 mb-4' style={{ marginTop: '2px' }}>
                        {res?.issue_status}
                    </button>
                </div>

                <div className='w-100'>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex gap-4'>
                            <span className='heading1 flex gap-4 flex-wrap'>
                                {res?.issue_file.map((itemRes) => (
                                    <img key={itemRes.id} src={itemRes.url} alt='Description of the image' className='image' />
                                ))}
                            </span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Description</span>
                            <span className='describe'>{res?.issue_description}</span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Location</span>
                            <span className='describe'>{res?.issue_location}</span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Due Date</span>
                            <span className='describe'>{res?.issue_dueDate}</span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Assignee</span>
                            <span className='describe'>{res?.issue_assignee}</span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Assignee Company</span>
                            <span className='describe'>{res?.issue_assigneeCompany}</span>
                        </div>
                    </div>
                    <div className='row mt-2 pb-2 border-bottom'>
                        <div className='col-12 flex flex-column'>
                            <span className='heading1'>Type</span>
                            <span className='describe'>{res?.issue_type}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default View;
