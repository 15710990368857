import axios from "axios";
import { API, baseUrl } from "../api";

// let token;
// let userData = localStorage.getItem("userData");
// if (userData) {
//   token = JSON.parse(userData).token;
// }
// let config = {
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// };
export const getNotificationData = async (id) => {
  return await axios.get(baseUrl + API.getNotification + id);
};
export const getProjectHistory = async (id) => {
  console.log(id);
  return await axios.get(baseUrl + API.getProjectVersionHistory + id);
};
function addPaginationQueryParams(url, queryParams) {
  let baseurl = url;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    baseurl += `?page=${page}&perPageData=${perPageData}`;
  }
  return baseurl;
}

export const forgotPassword = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.forgotPassword, payload);
};
export const checkUserToeknIsValid = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.checkUserToeknIsValid, payload);
};
export const changePassword = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.changePassword, payload);
};

export const uploadDocs = async (Payload) => {
  try {
    const response = await axios.post(baseUrl + API.uploadDocs, Payload);
    return response.data;
  } catch (error) {
    console.error("Error uploading documents:", error);
    throw error; // Propagate the error back to the caller for handling
  }
};

export const uploadMultipleDocs = async (filesArr) => {
  const formData = new FormData();
  for (let i = 0; i < filesArr.length; i++) {
    formData.append("upload", filesArr[i]);
  }
  return await axios.post(baseUrl + API.uploadDocs, formData);
};

export const updateFileName = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.updateFileName, payload);
};

export const googleLogin = async (payload) => {
  return await axios.post(baseUrl + API.googleApi, payload);
};
export const getProjectNumber = async () => {
  return await axios.get(baseUrl + API.getProjectCount);
};

// Incident

export const addIncident = async (Payload) => {
  return await axios.post(baseUrl + API.CreateIncident, Payload);
};
export const loginUser = async (Payload) => {
  return await axios.post(baseUrl + API.login, Payload);
};
export const resetUserPassword = async (userId, Payload) => {
  return await axios.post(baseUrl + API.resetUserPassword + userId, Payload);
};

export const getIncident = async () => {
  return await axios.get(baseUrl + API.getIncident);
};

export const getIncidentById = async (id) => {
  console.log(id);
  return await axios.get(baseUrl + API.getIncidentById + id);
};
export const getCountForIncidentByProjectId = async (id) => {
  console.log(id);
  return await axios.get(baseUrl + API.getCountForIncidentByProjectId + id);
};
export const updateIncident = async (id, payload) => {
  return await axios.put(baseUrl + API.updateIncident + id, payload);
};

export const deleteIncident = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteIcident + id, payload);
};

export const filterIncident = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.filterIncident, payload);
};
export const getincidentVersionHistory = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.getincidentVersionHistory, payload);
};
export const getIncidentDataByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getIncidentDataByProjectId + id;
  let { page, perPageData, search } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }
  return await axios.post(url, body || null);
};

// Rfi

export const createRfi = async (Payload) => {
  return await axios.post(baseUrl + API.createRfi, Payload);
};
export const getRFICount = async (id) => {
  return await axios.get(baseUrl + API.getRfiCount + id);
};
export const getRfi = async () => {
  return await axios.get(baseUrl + API.getRfi);
};
export const getRfiByProjectId = async (projectId, queryParams, body) => {
  let url = baseUrl + API.getRfiByProjectId + projectId;

  let { page, perPageData, search } = queryParams;

  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }

  return await axios.post(url, body);
};

export const getRfiCount = async () => {
  return await axios.get(baseUrl + API.getRfiCount);
};

export const getRFIVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getRFIVersionHistory, body);
};

export const getRfiById = async (id) => {
  return await axios.get(baseUrl + API.getRfiById + id);
};
export const updateRfi = async (id, payload) => {
  return await axios.put(baseUrl + API.updateRfi + id, payload);
};
export const addResponseInRfi = async (id, payload) => {
  return await axios.put(baseUrl + API.addResponseInRfi + id, payload);
};
export const deleteRfi = async (id, payload) => {
  console.log(id);
  return await axios.post(baseUrl + API.deleteRfi + id, payload);
};
export const filterRfi = async (payload) => {
  return await axios.post(baseUrl + API.filterRfi, payload);
};

export const CreateObservation = async (Payload) => {
  return await axios.post(baseUrl + API.createObservation, Payload);
};

// Submitalas:
export const getSubmittal = async () => {
  return await axios.get(baseUrl + API.getSubmittal);
};

export const getSubmittalDataByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getSubmittalDataByProjectId + id;

  console.log(queryParams, body);
  let { page, perPageData } = queryParams;

  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${queryParams.search}`;
  }
  return await axios.post(url, body);
};

export const addSubmittal = async (Payload) => {
  return await axios.post(baseUrl + API.addSubmittal, Payload);
};
export const deleteSubmittalById = async (id, payload) => {
  console.log(id);
  return await axios.post(baseUrl + API.deleteSubmittalById + id, payload);
};
export const getSubmittalById = async (id) => {
  return await axios.get(baseUrl + API.getSubmittalById + id);
};
export const updateSubmittalById = async (id, payload) => {
  return await axios.put(baseUrl + API.updateSubmittalById + id, payload);
};
export const getSubmittalByFilter = async (payload) => {
  return await axios.post(baseUrl + API.getSubmittalByFilter, payload);
};

export const addSubmittalCategory = async (Payload) => {
  return await axios.post(baseUrl + API.submittalCategory, Payload);
};

export const addRespoSubmittals = async (payload) => {
  return await axios.post(baseUrl + API.submittalRes, payload);
};

export const getAllRespoSubmittalsById = async (id) => {
  return await axios.get(baseUrl + API.getAllSubmitResById + id);
};

export const obserVationAddStatus = async (Payload) => {
  return await axios.post(baseUrl + API.addObservationStatus, Payload);
};

export const getAllObserVationDataById = async (id) => {
  return await axios.get(baseUrl + API.getAllObservationStatusById + id);
};

export const getSubmittalCategory = async (id) => {
  return await axios.get(baseUrl + API.getsubmittalCategory + id);
};

export const getCountForSubmitalByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getSubmitallCount + id);
};

export const addSubmittalPackage = async (Payload) => {
  return await axios.post(baseUrl + API.addPackage, Payload);
};

export const getSubmittalPackage = async (id) => {
  return await axios.get(baseUrl + API.getPackage + id);
};

export const addSubmittalType = async (Payload) => {
  return await axios.post(baseUrl + API.addSubmittalType, Payload);
};

export const getSubmittalType = async (id) => {
  return await axios.get(baseUrl + API.getSubmitallType + id);
};

export const transmittalUpdate = async (id, payload) => {
  return await axios.put(baseUrl + API.updateTransmittals + id, payload);
};

//  Specifications

export const createSpecification = async (Payload) => {
  return await axios.post(baseUrl + API.createSpecifications, Payload);
};
export const createSpecificationInProject = async (Payload) => {
  return await axios.post(
    baseUrl + API.addSpecificationInCreateProject,
    Payload
  );
};
export const getSpecificationByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getSpecificationByProjectId + id);
};

export const getSpecification = async () => {
  return await axios.get(baseUrl + API.getSpecification);
};
export const getSpecificationDataByProjectId = async (
  id,
  queryParams,
  body
) => {
  let url = baseUrl + API.getSpecificationDataByProjectId + id;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}`;
  }
  return await axios.post(url, body);
};

export const getSpecificationById = async (id) => {
  return await axios.get(baseUrl + API.getSpecificationById + id);
};
export const getCountForSpecificationsByProjectId = async (id) => {
  return await axios.get(
    baseUrl + API.getCountForSpecificationsByProjectId + id
  );
};
export const getNameByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getNameByProjectId + id);
};
export const getFormatByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getFormatByProjectId + id);
};
export const getLanguageByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getLanguageByProjectId + id);
};

export const updateSpecification = async (id, payload) => {
  return await axios.put(baseUrl + API.updateSpecification + id, payload);
};

export const filterSpecification = async (payload) => {
  return await axios.post(baseUrl + API.filterSpecification, payload);
};
export const AddSpecificationName = async (payload) => {
  return await axios.post(baseUrl + API.addName, payload);
};
export const AddSpecificationFormat = async (payload) => {
  return await axios.post(baseUrl + API.addFormat, payload);
};
export const AddSpecificationLanguage = async (payload) => {
  return await axios.post(baseUrl + API.addlanguage, payload);
};
// Forms

export const createForms = async (Payload) => {
  return await axios.post(baseUrl + API.createForms, Payload);
};
export const getForms = async () => {
  return await axios.get(baseUrl + API.getForms);
};

export const getFormsById = async (id) => {
  return await axios.get(baseUrl + API.getFormsById + id);
};
export const updateForms = async (id, payload) => {
  return await axios.put(baseUrl + API.updateForms + id, payload);
};
export const deleteForms = async (id) => {
  console.log(id);
  return await axios.delete(baseUrl + API.deleteForms + id);
};
export const filterForms = async (payload) => {
  return await axios.post(baseUrl + API.filterForms, payload);
};
export const getFormByProjectId = async (id, queryParams) => {
  let url = baseUrl + API.getFormByProjectId + id;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}`;
  }
  return await axios.post(url);
};

// photos
export const createPhotos = async (Payload) => {
  console.log(Payload);
  return await axios.post(baseUrl + API.createPhotos, Payload);
};

export const getPhotoDataByProjectId = async (id, queryParams) => {
  let url = baseUrl + API.getPhotoDataByProjectId + id;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}`;
  }
  return await axios.post(url);

  // return await axios.get(baseUrl + API.getPhotoDataByProjectId + id);
};
export const getPhotoDataById = async (id) => {
  return await axios.get(baseUrl + API.getPhotoDataById + id);
};

export const updatePhotos = async (id, payload) => {
  return await axios.put(baseUrl + API.updatePhotos + id, payload);
};

export const getPhotosById = async (id) => {
  return await axios.get(baseUrl + API.getPhotoById + id);
};

export const deletePhotos = async (id) => {
  console.log(id);
  return await axios.delete(baseUrl + API.deletPhotos + id);
};
export const filterPhotos = async (payload) => {
  return await axios.post(baseUrl + API.filterPhotoData, payload);
};
//observation
export const getObservation = async () => {
  return await axios.get(baseUrl + API.getObservation);
};
export const getObservationByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getObservationByProjectId + id;
  let { page, perPageData, search } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }
  return await axios.post(url, body);
};

//Models:
export const addModel = async (Payload) => {
  return await axios.post(baseUrl + API.addModel, Payload);
};
export const getModel = async () => {
  return await axios.get(baseUrl + API.getModel);
};
export const deleteModelById = async (id) => {
  return await axios.delete(baseUrl + API.deleteModelById + id);
};
export const getModelById = async (id) => {
  return await axios.get(baseUrl + API.getModelById + id);
};
export const updateModelById = async (id, payload) => {
  return await axios.put(baseUrl + API.updateModelById + id, payload);
};
export const getModelByFilter = async (payload) => {
  return await axios.post(baseUrl + API.getModelByFilter, payload);
};
export const getModelByProjectId = async (id, queryParams) => {
  let url = baseUrl + API.getModelByProjectId + id;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}`;
  }
  return await axios.post(url);
};

//Punch

export const getPunch = async () => {
  return await axios.get(baseUrl + API.getPunch);
};
export const addPunch = async (payload) => {
  return await axios.post(baseUrl + API.createPunch, payload);
};
export const getPunchbyid = async (id) => {
  return await axios.get(baseUrl + API.getPunchById + id);
};
export const getCountForPunchByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getCountForPunchByProjectId + id);
};
export const updatePunch = async (id, payload) => {
  console.log(id, payload);

  return await axios.put(baseUrl + API.updatePunch + id, payload);
};
export const getPunchByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getPunchByProjectId + id;
  let { page, perPageData, search } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }
  return await axios.post(url, body);
};

export const getpunchVersionHistory = async (payload) => {
  console.log(payload);
  return await axios.post(baseUrl + API.getpunchVersionHistory, payload);
};

export const deletePunch = async (id, payload) => {
  console.log(id);

  return await axios.post(baseUrl + API.deletePunch + id, payload);
};
export const filterPunch = async (payload) => {
  return await axios.post(baseUrl + API.filterPunch, payload);
};
export const getTransmittal = async () => {
  return await axios.get(baseUrl + API.getTransmittal);
};
export const getTransmittalDataByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getTransmittalDataByProjectId + id;
  let { page, perPageData, search } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }
  return await axios.post(url, body);
};
export const getTransmittalUid = async (id) => {
  return await axios.get(baseUrl + API.getAutoIdInTransmittal + id);
};
export const addTransmittal = async (payload) => {
  return await axios.post(baseUrl + API.createTransmittal, payload);
};
export const getTransmittalbyid = async (id) => {
  return await axios.get(baseUrl + API.getTransmittalById + id);
};
export const updateTransmittal = async (id, payload) => {
  console.log(id, payload);

  return await axios.put(baseUrl + API.updateTransmittal + id, payload);
};

export const deleteTransmittal = async (id, payload) => {
  console.log(id);

  return await axios.post(baseUrl + API.deleteTransmittal + id, payload);
};

//issues

export const createIssue = async (Payload) => {
  return await axios.post(baseUrl + API.createIssue, Payload);
};
export const getIssue = async () => {
  return await axios.get(baseUrl + API.getIssue);
};
export const getIssueByProjectId = async (id, queryParams, body) => {
  let url = baseUrl + API.getIssueByProjectId + id;
  let { page, perPageData } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}`;
  }
  return await axios.post(url, body || null);
};

export const getIssueById = async (id) => {
  return await axios.get(baseUrl + API.getIssueById + id);
};
export const updateIssue = async (id, Payload) => {
  console.log(Payload);
  return await axios.put(baseUrl + API.updateIssue + id, Payload);
};
export const deleteIssue = async (id, payload) => {
  console.log(id);
  return await axios.post(baseUrl + API.deleteIssue + id, payload);
};
export const filterIssue = async (Payload) => {
  return await axios.post(baseUrl + API.filterIssue, Payload);
};

// Drawings

export const addDrawingSet = async (Payload) => {
  return await axios.post(baseUrl + API.addDrawingSet, Payload);
};
export const addDrawingCategory = async (Payload) => {
  return await axios.post(baseUrl + API.addDrawingCategory, Payload);
};
export const updateDrawingSetById = async (id, Payload) => {
  return await axios.post(baseUrl + API.updateDrawingSetById + id, Payload);
};
export const updateDrawingCategoryById = async (id, Payload) => {
  return await axios.put(baseUrl + API.updateDrawingCategoryById + id, Payload);
};
export const deleteMultipleDrawing = async (Payload) => {
  return await axios.post(baseUrl + API.deleteMultipleDrawing, Payload);
};
export const getAllDrawingSet = async () => {
  return await axios.get(baseUrl + API.getAllDrawingSet);
};
export const getAllDrawingCategory = async () => {
  return await axios.get(baseUrl + API.getAllDrawingCategory);
};
export const getDrawingByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getDrawingByProjectId + id);
};

export const addDrawings = async (Payload) => {
  return await axios.post(baseUrl + API.createDrawing, Payload);
};
export const getDrawing = async () => {
  return await axios.get(baseUrl + API.getAllDrawings);
};

export const getDrawingById = async (id) => {
  return await axios.get(baseUrl + API.getDrawingsById + id);
};
export const updateDrawings = async (id, Payload) => {
  return await axios.put(baseUrl + API.updateDrawings + id, Payload);
};
export const deleteDrawings = async (id) => {
  console.log(id);
  return await axios.delete(baseUrl + API.deleteDrawings + id);
};

export const filterDrawing = async (Payload) => {
  return await axios.post(baseUrl + API.filterDrawings, Payload);
};

// Observation
export const updateObservation = async (id, payload) => {
  return await axios.put(baseUrl + API.updateObservation + id, payload);
};

export const deleteObservation = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteObservation + id, payload);
};

export const addObservation = async (Payload) => {
  return await axios.post(baseUrl + API.addObservation, Payload);
};
export const filterObservation = async (payload) => {
  return await axios.post(baseUrl + API.filterObservation, payload);
};

export const getObservationById = async (id) => {
  return await axios.get(baseUrl + API.getObservationById + id);
};
export const getObservationUid = async (id) => {
  return await axios.get(baseUrl + API.getAutoIdInObservation + id);
};

//  meeting

export const addMeeting = async (Payload) => {
  return await axios.post(baseUrl + API.createMeetings, Payload);
};
export const getMeetingVersionHistory = async (Payload) => {
  return await axios.post(baseUrl + API.getMeetingVersionHistory, Payload);
};

export const addHeadName = async (Payload) => {
  return await axios.post(baseUrl + API.cerateHeadName, Payload);
};

export const getHeadName = async () => {
  return await axios.get(baseUrl + API.getheadName);
};
export const getMeetings = async () => {
  return await axios.get(baseUrl + API.getAllMeetings);
};

export const getMeetingById = async (id) => {
  return await axios.get(baseUrl + API.getMeetingById + id);
};
export const getMeetingByProjectId = async (id, body) => {

  return await axios.post(baseUrl + API.getMeetingByProjectId + id, body);
};
export const getUserAssignedToProjectById = async (id) => {
  return await axios.get(baseUrl + API.getUserAssignedToProjectById + id);
};
export const updateMeeting = async (id, Payload) => {
  return await axios.put(baseUrl + API.updateMeeting + id, Payload);
};
export const deleteMeetings = async (id) => {
  console.log(id);
  return await axios.delete(baseUrl + API.deletMeeting + id);
};

export const filterMeetings = async (Payload) => {
  return await axios.post(baseUrl + API.filterMeeting, Payload);
};

export const addTemplate = async (Payload) => {
  return await axios.post(baseUrl + API.createTemplate, Payload);
};

export const getTemplate = async () => {
  return await axios.get(baseUrl + API.getTemplate);
};
export const getTemplateById = async (id) => {
  return await axios.get(baseUrl + API.getTemplateById + id);
};
export const updateTemplate = async (id, Payload) => {
  return await axios.put(baseUrl + API.updateTemplate + id, Payload);
};

export const deleteTemplate = async (id) => {
  return await axios.delete(baseUrl + API.deletTemplate + id);
};

//Inspection:
export const addInspectionData = async (Payload) => {
  return await axios.post(baseUrl + API.addInspectionData, Payload);
};
export const getInspectionData = async () => {
  return await axios.get(baseUrl + API.getInspectionData);
};
export const getInspectionDataByProjectId = async (id, body) => {
  return await axios.post(baseUrl + API.getInspectionDataByProjectId + id, body);
};
export const deleteInspectionData = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteInspectionData + id, payload);
};
export const getInspectionDataById = async (id) => {
  return await axios.get(baseUrl + API.getInspectionDataById + id);
};
export const getInspectionTypeByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getInspectionTypeByProjectId + id);
};
export const getInspectionVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getInspectionVersionHistory, body);
};
export const addInspectionType = async (body) => {
  return await axios.post(baseUrl + API.addInspectionType, body);
};
export const updateInspectionData = async (id, payload) => {
  return await axios.put(baseUrl + API.updateInspectionData + id, payload);
};
export const getInspectionByFilter = async (payload) => {
  return await axios.post(baseUrl + API.getInspectionByFilter, payload);
};
export const ProjectCount = async (payload) => {
  return await axios.get(baseUrl + API.ProjectCount, payload);
};
export const getCountForInspectionByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getCountForInspectionByProjectId + id);
};

//  create project  apis

export const createProjectType = async (Payload) => {
  return await axios.post(baseUrl + API.projectType, Payload);
};

export const getProjectType = async () => {
  return await axios.get(baseUrl + API.getProjectType);
};

export const createProjectInformation = async (Payload) => {
  return await axios.post(baseUrl + API.addProjectInformation, Payload);
};
export const createStep2 = async (Payload) => {
  return await axios.post(baseUrl + API.projectStages, Payload);
};

export const getProjectAssignData = async (id) => {
  return await axios.get(baseUrl + API.getTemplateAndRole + id);
};
export const getProject = async (id) => {
  return await axios.get(baseUrl + API.getProjectById + id);
};
export const addProjectTools = async (payload) => {
  return await axios.post(baseUrl + API.projectTools, payload);
};

export const createStep6 = async (Payload) => {
  return await axios.post(baseUrl + API.ProjectDrawings, Payload);
};

export const getProjectDirectory = async () => {
  return await axios.get(baseUrl + API.getProjectDirectory);
};
export const getContractorSubIndCount = async (project_id) => {
  return await axios.get(
    baseUrl +
    API.getContractorSubIndCount +
    (project_id ? "?project_id=" + project_id : "")
  );
};
export const getCompanyAndPersonData = async (
  body,
  project_id,
  paginationParams
) => {
  let url = baseUrl + API.getCompanyAndPersonData;

  if (project_id) {
    url += `?project_id=${project_id}`;
  }

  if (paginationParams) {
    const { page, perPageData } = paginationParams;
    url += `${project_id ? "&" : "?"}page=${page}&perPageData=${perPageData}`;
  }

  return await axios.post(url, body);
};

export const getAllPersonAndCompany = async () => {
  return await axios.get(baseUrl + API.getAllPersonAndCompany);
};

export const addSpecifications = async (payload) => {
  return await axios.post(baseUrl + API.addspecifications, payload);
};

export const addAssignPersonCompany = async (payload, id) => {
  return await axios.post(baseUrl + API.addAssignPersonCompany + id, payload);
};

export const projectSchedule = async (payload) => {
  return await axios.post(baseUrl + API.projectSchedule, payload);
};
export const createProjectVersionHistory = async (payload) => {
  return await axios.post(baseUrl + API.addProjectVersionHistory, payload);
};

export const getAllProjects = async (params, body) => {
  console.log(params, body);
  let queryParams = `${params?.user_id && params?.type
    ? "?user_id=" + params.user_id + "&type=" + params.type
    : ""
    }`;
  queryParams += `${params?.page && params?.perPageData
    ? "&page=" + params.page + "&perPageData=" + params.perPageData
    : ""
    }`;
  queryParams += `${params?.search ? "&search=" + params.search : ""}`;

  return await axios.post(
    `${baseUrl + API.getAllProjects}${queryParams}`,
    body
  );
};
export const getCountOfProjects = async (body) => {
  return await axios.post(
    `${baseUrl + API.getCountOfProjects}`,
    (body && body) || null
  );
};

export const getVersionHistoryById = async (id, payload) => {
  return await axios.post(baseUrl + API.getVersionHistoryById + id, payload);
};

export const assignReviewerApprover = async (id, payload) => {
  return await axios.put(baseUrl + API.assignReviewerApprover + id, payload);
};

export const addProjectDepartmens = async (payload) => {
  return await axios.post(baseUrl + API.addprojectDepartment, payload);
};

export const getProjectDepartmens = async () => {
  return await axios.get(baseUrl + API.getprojectDepartment);
};

export const addUnit = async (payload) => {
  return await axios.post(baseUrl + API.addunit, payload);
};

export const getUnit = async () => {
  return await axios.get(baseUrl + API.getunit);
};

// cost codes api

export const copyAllCodes = async (payload) => {
  return await axios.post(baseUrl + API.copyAllCodes, payload);
};

export const createCostCode = async (payload) => {
  return await axios.post(baseUrl + API.createCostCode, payload);
};

export const getCodeCost = async (id) => {
  return await axios.get(baseUrl + API.getCostCode + id);
};

export const addCodes = async (payload) => {
  return await axios.post(baseUrl + API.addCodes, payload);
};
export const updateCode = async (body) => {
  return await axios.post(baseUrl + API.updateCode, body);
};
export const deleteMany = async (body) => {
  return await axios.post(baseUrl + API.deleteMany, body);
};
export const addDivision = async (body) => {
  return await axios.post(baseUrl + API.addDivision, body);
};
export const getDivisions = async () => {
  return await axios.get(baseUrl + API.getDivisions);
};

//project Directory apis

export const addCompany = async (payload) => {
  return await axios.post(baseUrl + API.addCompany, payload);
};
export const getCompany = async () => {
  return await axios.get(baseUrl + API.getCompany);
};
export const getCompanyById = async (id) => {
  return await axios.get(baseUrl + API.getCompanyById + id);
};
export const updateCompany = async (id, payload) => {
  return await axios.put(baseUrl + API.updateCompany + id, payload);
};
export const deleteCompany = async (id) => {
  return await axios.put(baseUrl + API.deleteCompany + id);
};

export const getCompanyByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getCompanyByProjectId + id);
};
export const getManyUsersByCompanyIds = async (id, body) => {
  return await axios.post(baseUrl + API.getManyUsersByCompanyIds + id, body);
};

export const getUsersByCompanyId = async (id, company) => {
  return await axios.post(`${baseUrl}${API.getUsersByCompanyId}${id}`, company);
};

export const getProjectList = async (id) => {
  return await axios.get(baseUrl + API.getProjectList + id);
};
export const removeProject = async (id) => {
  return await axios.get(baseUrl + API.removeProject + id);
};
export const addProject = async (id) => {
  return await axios.get(baseUrl + API.addProject + id);
};

//user api in project directory
export const addUser = async (payload) => {
  return await axios.post(baseUrl + API.addUser, payload);
};

export const getUser = async (queryParams) => {
  let url = baseUrl + API.getUser;
  return await axios.get(addPaginationQueryParams(url, queryParams));
};

export const getUserByProjectId = async (id) => {
  return await axios.post(baseUrl + API.getUserByProjectId + id);
};
export const getUserById = async (id) => {
  return await axios.get(baseUrl + API.getUserById + id);
};
export const updateUser = async (id, payload) => {
  return await axios.put(baseUrl + API.updateUser + id, payload);
};
export const deleteUser = async (id) => {
  return await axios.delete(baseUrl + API.deleteUser + id);
};
export const verifyEmail = async (id, body) => {
  return await axios.post(baseUrl + API.verifyEmail + id, body);
};

//Documents api
export const createFolder = async (payload) => {
  return await axios.post(baseUrl + API.createFolder, payload);
};
export const getFolders = async (id) => {
  return await axios.get(baseUrl + API.getFolders + id);
};
export const renameFolder = async (id, payload) => {
  return await axios.put(baseUrl + API.renameFolder + id, payload);
};
export const updateFolder = async (id, payload) => {
  return await axios.put(baseUrl + API.updateFolder + id, payload);
};
export const deleteFolder = async (id) => {
  return await axios.delete(baseUrl + API.deleteFolder + id);
};
export const renameFile = async (id, payload) => {
  return await axios.put(baseUrl + API.renameFile + id, payload);
};
export const deleteFile = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteFile + id, payload);
};
export const moveFile = async (id, payload) => {
  return await axios.post(baseUrl + API.moveFile + id, payload);
};

//task apis

export const addtask = async (payload) => {
  return await axios.post(baseUrl + API.addTask, payload);
};
export const getCountForTaskByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getCountForTaskByProjectId + id);
};

export const getalltask = async () => {
  return await axios.get(baseUrl + API.getTask);
};
export const getTaskVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getTaskVersionHistory, body);
};
export const getSpecificationVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getSpecificationVersionHistory, body);
};
export const getIssueVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getIssueVersionHistory, body);
};
export const getSubmittalVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getSubmittalVersionHistory, body);
};

export const getTransmittalVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getTransmittalVersionHistory, body);
};

export const getObservationVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getObservationVersionHistory, body);
};

export const getalldataby_id = async (id, queryParams, body) => {
  let url = baseUrl + API.getdatabyid + id;
  let { page, perPageData, search } = queryParams;
  if (page && perPageData) {
    url += `?page=${page}&perPageData=${perPageData}&search=${search}`;
  }
  return await axios.post(url, body);
};

export const gettaskbyuser = async (id) => {
  return await axios.get(baseUrl + API.gettaskbyid + id);
};
export const gettaskbyid = async (id) => {
  return await axios.get(baseUrl + API.gettaskbyid + id);
};

export const updatetasktable = async (id, payload) => {
  return await axios.put(baseUrl + API.updatetask + id, payload);
};

export const deleteusertask = async (id, payload) => {
  return await axios.post(baseUrl + API.deletetask + id, payload);
};

export const filtertaskdata = async (payload) => {
  return await axios.post(baseUrl + API.filtertask, payload);
};

export const getProjectsByStatus = async (status, body) => {
  return await axios.post(baseUrl + API.getProjectByStatus + status, body);
};

export const overviewData = async (id) => {
  return await axios.get(baseUrl + API.projectOverView + id);
};

export const specificatonDelete = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteSpecificationById + id, payload);
};

export const getallrole = async (queryParams) => {
  return await axios.get(
    addPaginationQueryParams(baseUrl + API.getallrole, queryParams || "")
  );
};

export const addRole = async (body) => {
  return await axios.post(baseUrl + API.addRole, body);
};
export const getPermissionByRole = async (id) => {
  return await axios.get(baseUrl + API.getPermissionByRole + id);
};
export const updatePermissionById = async (id, body) => {
  return await axios.put(baseUrl + API.updatePermissionById + id, body);
};

export const checkUserRoleIntoProject = async (body) => {
  return await axios.post(baseUrl + API.checkUserRoleIntoProject, body);
};
export const getProjectOverviewSummary = async () => {
  return await axios.get(baseUrl + API.getProjectOverviewSummary);
};
export const sendEmailWithAttachment = async (body) => {
  return await axios.post(baseUrl + API.sendEmailWithAttachment, body);
};
export const addAddress = async (body) => {
  return await axios.post(baseUrl + API.addAddress, body);
};

export const getAddressByToolId = async (id) => {
  return await axios.get(baseUrl + API.getAddressByToolId + id);
};
export const deleteAddress = async (body) => {
  return await axios.post(baseUrl + API.deleteAddress, body);
};
export const getProjectCompanyData = async (id) => {
  return await axios.get(baseUrl + API.getProjectCompanyData + id);
};
export const getUserOfCompanyInsideProject = async (id, body) => {
  return await axios.post(
    baseUrl + API.getUserOfCompanyInsideProject + id,
    body
  );
};
export const addDirectCost = async (body) => {
  return await axios.post(baseUrl + API.addDirectCost, body);
};
export const getAllDirectCost = async (queryParams) => {
  let url = baseUrl + API.getAllDirectCost;
  return await axios.get(url);
};

export const getPrimeContractById = async (id) => {
  return await axios.post(baseUrl + API.getPrimeContractById + id);
};
export const getDirectCostById = async (id) => {
  return await axios.post(baseUrl + API.getDirectCostById + id);
};

export const addCategory = async (body) => {
  return await axios.post(baseUrl + API.addCategory, body);
};
export const getAllCategory = async (id) => {
  return await axios.get(baseUrl + API.getAllCategory + id);
};
// daily log apis
export const addDailyLogData = async (body) => {
  return await axios.post(baseUrl + API.addDailyLogData, body);
};
export const getDailyLogDataById = async (id) => {
  return await axios.get(baseUrl + API.getDailyLogDataById + id);
};
export const getAllDailyLogData = async (projectId, query, body) => {
  return await axios.post(
    baseUrl +
    API.getAllDailyLogData +
    projectId +
    `?page=${query.page}&perPageData=${query.perPageData}&search=${query.search}`,
    body
  );
};
export const updateDailyLogData = async (id, body) => {
  return await axios.put(baseUrl + API.updateDailyLogData + id, body);
};
export const deleteDailyLogData = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteDailyLogData + id, payload);
};
export const getdailyLogVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getdailyLogVersionHistory, body);
};
export const getCountForDailyByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getCountForDailyByProjectId + id);
};

export const getTradeByProjectId = async (id) => {
  return await axios.get(baseUrl + API.getTradeByProjectId + id);
};
export const addTrade = async (body) => {
  return await axios.post(baseUrl + API.addTrade, body);
};

//priority
export const addPrioritytype = async (payload) => {
  return await axios.post(baseUrl + API.addType, payload);
};

export const getPrioritytype = async (id) => {
  return await axios.get(baseUrl + API.getType + id);
};
export const getCountIssueId = async (id) => {
  return await axios.get(baseUrl + API.getCountIssueId + id);
};
//type
export const addTypeT = async (payload) => {
  return await axios.post(baseUrl + API.addTypeT, payload);
};

export const getTypeT = async (id) => {
  return await axios.get(baseUrl + API.getTypeT + id);
};

//trade
export const addTradeT = async (payload) => {
  return await axios.post(baseUrl + API.addTradeT, payload);
};

export const getTradeT = async (id) => {
  return await axios.get(baseUrl + API.getTradeT + id);
};

export const deleteTradeT = async (id) => {
  return await axios.delete(baseUrl + API.deleteTradeT + id);
};
export const createGroup = async (body) => {
  return await axios.post(baseUrl + API.createGroup, body);
};
export const getAllEmailGroup = async (body) => {
  return await axios.post(baseUrl + API.getAllEmailGroup, body);
};
export const getGroupById = async (id) => {
  return await axios.get(baseUrl + API.getGroupById + id);
};
export const updateGroup = async (body) => {
  return await axios.put(baseUrl + API.updateGroup, body);
};
export const deleteGroup = async (id) => {
  return await axios.delete(baseUrl + API.deleteGroup + id);
};
export const createTag = async (body) => {
  return await axios.post(baseUrl + API.createTag, body);
};
export const getTag = async (project_id) => {
  return await axios.get(baseUrl + API.getTag + project_id);
};
export const getFromEmailByUser = async (userId) => {
  return await axios.get(baseUrl + API.getFromEmailByUser + userId);
};
export const addEmail = async (body) => {
  return await axios.post(baseUrl + API.addEmail, body);
};
export const getEmail = async (body) => {
  return await axios.post(baseUrl + API.getEmail, body);
};
export const updateEmailById = async (id, body) => {
  return await axios.put(baseUrl + API.updateEmailById + id, body);
};

export const getPickListData = async () => {
  return await axios.post(baseUrl + API.getPicklistData);
};
export const updatePeaklistTemp = async (id, body) => {
  return await axios.put(baseUrl + API.updatePeaklistTemp + id, body);
};

export const deletePhaseData = async (payload) => {
  return await axios.post(baseUrl + API.deletePhase, payload);
};

export const getAllPhase = async () => {
  return await axios.get(baseUrl + API.getAllPhase);
};

export const addPhase = async (payload) => {
  return await axios.post(baseUrl + API.addPhase, payload);
};

export const addCategoryByPhase = async (payload) => {
  return await axios.post(baseUrl + API.addCategory, payload);
};
export const getAllCategoriesByPhase = async (id) => {
  return await axios.get(baseUrl + API.getCategoryByPhase + id);
};

export const addSubCategoryByPhase = async (payload) => {
  return await axios.post(baseUrl + API.addSubCategory, payload);
};
export const getAllSubCategoriesByPhase = async (id) => {
  return await axios.get(baseUrl + API.getSubCategoryByPhase + id);
};

export const addDocType = async (payload) => {
  return await axios.post(baseUrl + API.addDocType, payload);
};
export const getAllDocType = async (id, role) => {
  return await axios.get(
    baseUrl + API.getDocTypeByPhase + id + "?role=" + role
  );
};

export const createPickListitem = async (payload) => {
  return await axios.post(baseUrl + API.addPicklistData, payload);
};
export const checkForTemplateUpdate = async (id) => {
  return await axios.get(baseUrl + API.checkForTemplateUpdate + id);
};

// file management----

export const getFileVersionHistory = async (body) => {
  return await axios.post(baseUrl + API.getFileVersionHistory, body);
};

export const createFile = async (payload) => {
  return await axios.post(baseUrl + API.createFile, payload);
};

export const updateFile = async (id, payload) => {
  return await axios.put(baseUrl + API.updateFile + id, payload);
};

export const getAllInfo = async (payload) => {
  return await axios.post(baseUrl + API.getAllInfo, payload);
};

export const getAllFiles = async (id, role, payload) => {
  return await axios.post(
    baseUrl + API.getAllFiles + id + "?role=" + role,
    payload
  );
};

export const oneFile = async (id) => {
  return await axios.get(baseUrl + API.getOneFile + id);
};

export const delFile = async (id, payload) => {
  return await axios.post(baseUrl + API.deleteFile + id, payload);
};

export const getDocTemplate = async (id) => {
  return await axios.get(baseUrl + API.getDocTemplate + id);
};

export const getGlobalData = async (payload) => {
  return await axios.post(baseUrl + API.importGlobalData, payload);
};

export const getCategories = async () => {
  return await axios.get(baseUrl + API.getCategories);
};

export const getSubCategories = async () => {
  return await axios.get(baseUrl + API.getSubCategories);
};

export const getDocTypes = async () => {
  return await axios.get(baseUrl + API.getDocTypes);
};


export const addHashTag = async (payload) => {
  return await axios.post(baseUrl + API.addHashTag, payload);
};

export const getAllTags = async () => {
  return await axios.get(baseUrl + API.getTagList);
};