import React from "react";
import Carousel from 'react-bootstrap/Carousel';

function AdaptiveHeight({overViewGetData}) {

    return (

        <>
    
    
            <Carousel interval={2000} slide={true} controls={false} indicators={false}>
                    {overViewGetData?.map((res,index)=>{return(
                <Carousel.Item key={index}>

                        <>
                        <img className="d-block w-100" src={res?.fileUrl} alt="First slide" /></>
                       

                </Carousel.Item>
                   ) })}
               
            </Carousel>


        </>
    );
}

export default AdaptiveHeight;
