import React, { useEffect, useRef, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdOutlineAttachment } from "react-icons/md";
import { FileUpload } from "primereact/fileupload";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import style from "./forms.module.css";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";

import { useNavigate, useParams } from "react-router-dom";
import { getFormsById, getUserByProjectId, updateForms, uploadDocs } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectName, getUserId, toast } from "../../utils";
import Loader from "../../Components/Common/Loader";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { useSelector } from "react-redux";

export const EditForm = () => {
  // const [selectedCity, setSelectedCity] = useState([]);
  // const [text, setText] = useState("");


  const isApproverAccess = getActionsPermission("Form Approver")
  const isReviewerAccess = getActionsPermission("Form Reviewer")
  const [approver, setApprover] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({})
  let set = useSelector(d => d.rolesAndPermissionData)
  const [oldform_review_status, setOldform_review_status] = useState("")

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [validationName, setValidationName] = useState(false);
  const [validationDate, setValidationDate] = useState(false);
  const [allData, setAllData] = useState({})
  const [status, setStatus] = useState(null);
  const [options, setOptions] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [

    { qty: 2 },

    { qty: 1, height: "12rem" },
    { qty: 1 },
    { qty: 1, height: "7rem" },

  ]
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const navigate = useNavigate();

  const { id } = useParams();

  const fileInputRef = useRef(null);
  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };
  const checkValidation = () => {
    if (!name.trim()) {
      setValidationName(true);
    }
    if (!dateCreated) {
      setValidationDate(true);
    }
    if (name.trim() === "" || !dateCreated) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
    // console.log(files);
  };

  // console.log(dateCreated,"ggg")

  const handleGetFormsById = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getFormsById(id);
      console.log(res);
      const formsData = res?.data?.data?.[0];


      let approverData = formsData?.approver?.[0] || {}
      let reviewerData = formsData?.reviewer?.[0] || {}

      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null
      })

      setApprover(approverData);
      setReviewer(reviewerData);

      setOldform_review_status(formsData?.form_review_status)

      setAllData(formsData)
      setName(formsData.forms_name);
      setDescription(formsData.forms_description);
      setDateCreated(new Date(formsData.forms_date_created));
      setFileName(
        formsData.forms_file.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );
      setPreviousFile([...formsData?.forms_file]);
      setStatus(
        formsData?.status
      );
      let statuss = formsData.status;
      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);
    } catch (err) {
      console.error(err);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }

  const handleUpdate = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }

    if (checkValidation()) {
      return;
    }
    const formData = new FormData();
    for (const file of fileUpload) {
      formData.append("upload", file);
    }
    // const uploadResponse = await uploadDocs(formData);
    // // console.log(uploadResponse.data,'response');
    // const fileData = uploadResponse.data.map((item) => ( {
    //   fileName : item.fileName,
    //   url : item.fileUrl,
    // }))
    setLoader(true);
    let fileData = [];
    if (fileUpload.length != 0) {
      const uploadResponse = await uploadDocs(formData);
      fileData = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        url: item.fileUrl,
      }));
    }



    let emailData = [];

    if (oldApproverReviewwerData?.reviewer !== reviewer._id) {
      emailData.push({
        email: reviewer.email_address,
        type: "Reviewer",
        rfi_name: name,
        project: getProjectName(),
        toolName: "RFI",
        name: reviewer?.first_name
      })
    }



    const payload = {
      forms_name: name,
      forms_description: description,
      forms_date_created:
        dateCreated !== null ? dateCreated.toLocaleString() : "",
      forms_file: [...previousFile, ...fileData],
      deleted_file: removePhoto,
      status: status,
      form_review_status: oldform_review_status === "pending" ? status?.toLowerCase() === "close" ? "under_review" : "pending" : oldform_review_status,
      reviewer_id: reviewer._id,
      approver_id: approver._id,
      email_data: emailData,


    };

    try {
      const res = await updateForms(id, payload);
      setLoader(true);
      toast.success("Form Updated Successfully!");
      navigate("/projectManagement/forms");
    } catch (err) {
      console.error(err);
      toast.success("Forms Created successfully!");
    }
    setLoader(false);
  };

  useEffect(() => {
    handleGetFormsById();
  }, []);
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
      console.log(allUsers, "================================================")
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllUsersOfProject()
  }, [])
  return (
    <>
      <div className="main-container">
        <div>
          <div className="row m-0 p-0">
            <div className="col-12 py-3  px-3 ">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/projectManagement/forms")}
              >
                {" "}
                Forms
              </span>
              <span className=" open_Rfi_text">
                {" "}
                <FaAngleRight size={15} />{" "}
              </span>
              <span className="createNew_text"> Edit Form </span>
            </div>
          </div>

          {/* Top Create New Rfi End */}

          <div className="w-100  mt-4">
            <div className="col-12 px-1">
              <span className="newRfi_text"> Edit Form  </span>
            </div>

            <>

              {/*  New section End */}

              {/* ----------------alret------------- */}

              <div className="mt-4 w-100 ">
                <div className="w-100 border py-3">
                  <div className="col-12">
                    <div className="d-flex     align-items-center   gap-3">
                      <IoIosInformationCircle
                        size={45}
                        style={{ color: "#F97B06" }}
                      />
                      <div className="  d-flex flex-column">
                        <span className={style.alret_text}>
                          {" "}
                          1. Select a fillable PDF
                        </span>

                        <span className={style.alret_text}>
                          {" "}
                          2. Click "Attach File" or drag and drop file into{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                skeletonLoader ?
                  <FormSkeleton data={skeletonData} mt={"mt-4"} />
                  :
                  <>
                    <div className="row m-0 p-0 mt-3">
                      <div className="col-md-6">
                        <label htmlFor="" className="py-2 labelCss">
                          Form Name: <span className="text-danger">*</span>
                        </label>
                        <TextInput
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className={validationName ? "Validation" : ""}
                          onClick={() => setValidationName(false)}
                        >
                          {" "}
                        </TextInput>
                      </div>
                      {/* {reqFor === "edit" ? ( */}
                      <div className="col-md-6">
                        <label htmlFor="" className="py-2 labelCss">
                          Status: <span className="text-danger">*</span>
                        </label>
                        <SingleSelect
                          placeholder="Status"
                          options={options}
                          optionLabel="label"

                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          // className={validation.status ? "Validation" : ""}
                          disabled={
                            allData.form_review_status == "approved" ||
                              allData.form_approval_status == "approved"
                              ? true
                              : false
                          }
                        />
                      </div>
                      {/* ) : (
                  ""
                )} */}
                    </div>


                    <div className="row m-0 p-0">
                      {isApproverAccess &&
                        <div className="col-md-6 col-12">
                          <label htmlFor="" className="py-2 labelCss">
                            Approver:
                          </label>
                          <SingleSelect
                            placeholder={approver._id === getUserId() ? approver?.first_name : "Approver"}
                            // className={Validation.approver ? "Validation" : ""}
                            disabled={approver._id === getUserId()}
                            options={allUsersOfProject.filter(e => e._id !== getUserId())}
                            optionLabel={"first_name"}
                            value={allUsersOfProject.find(dd => dd._id == approver?._id)}
                            onChange={(e) => {
                              setApprover(e.target.value);
                            }}
                          />
                        </div>
                      }
                      {isReviewerAccess &&
                        <div className="col-md-6 col-12">
                          <label htmlFor="" className="py-2 labelCss">
                            Reviewer:
                          </label>
                          <SingleSelect
                            // className={Validation.reviewer ? "Validation" : ""}
                            disabled={reviewer._id === getUserId()}
                            placeholder={reviewer._id === getUserId() ? reviewer?.first_name : "Reviewer"}
                            options={allUsersOfProject.filter(e => e._id !== getUserId())}
                            optionLabel={"first_name"}
                            value={allUsersOfProject.find(dd => dd._id == reviewer?._id)}

                            onChange={(e) => {
                              setReviewer(e.target.value);
                            }}
                          />
                        </div>}
                    </div>









                    {/*  calender section start */}
                   
                      <div className="col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Description:
                        </label>
                        <Editor
                          style={{ height: "200px" }}
                          value={description}
                          onTextChange={(e) => setDescription(e.htmlValue)}
                        />
                      </div>
                 

                    {/* calender start  */}
                    <div className="w-100 mt-3  gap-0">
                      <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Date Created: <span className="text-danger">*</span>
                        </label>
                        {/* <Calender
                          placeholder="Date Created"
                          value={dateCreated}
                          selected={dateCreated}
                          onChange={(e) => setDateCreated(e.value)}
                          className={validationDate ? "Validation" : ""}
                          onClick={() => setValidationDate(false)}
                        /> */}
                         <input
                         type="date"
                         style={{height : "50px"}}
                          placeholder="Date Created"
                          value={formatDate(dateCreated)}
                          selected={dateCreated}
                          onChange={(e) => setDateCreated(e.value)}
                          className={validationDate ? "Validation" : "form-control"}
                          onClick={() => setValidationDate(false)}
                        />
                      </div>
                    </div>

                    <div className="w-100 mt-4 css_com">
                      <div className="col-12">
                        <DragAndDrop
                          setRemovePhoto={setRemovePhoto}
                          value={fileName}
                          setFiles={setFileUpload}
                          onChange={handleFileSubmit}
                          setValue={setFileName}
                          setPreviousFiles={setPreviousFile}
                        />
                      </div>
                    </div>
                  </>}

              <div className="d-flex justify-content-end mt-3">
                <span
                  className="cancelBtn px-3 py-2"
                  onClick={() => navigate("/projectManagement/forms")}
                >
                  {" "}
                  Cancel
                </span>
                &nbsp;&nbsp;
                <span className="CreateRfi px-3 py-2" onClick={handleUpdate}>
                  Update
                </span>
              </div>
            </>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};
