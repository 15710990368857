import React, { useState } from "react";
import { SearchInput } from "../../../../../Components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import Popover from "../../../../../Components/Common/Popover";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

const PurchaseOrder = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const demodata = [
    {
      id: "1000",
      code: "f230fh0g3",
      contract_company: "contract_company Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      contract_company: "contract_company Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      contract_company: "contract_company Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      contract_company: "contract_company Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      contract_company: "contract_company Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
  ];

  const footerGroup = (
    <ColumnGroup>
        <Row>
            <Column footer="Grand Total:" colSpan={2} footerStyle={{ textAlign: 'center' }}  />
            <Column footer={''} />
            <Column footer={""} />
            <Column footer={""} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={"$ 0.00"} />
            <Column footer={(rowData, meta) => (
                <div className="flex justify-content-center">
                    <Popover
                        options={["Edit", "delete"]}
                        onSelect={(index, event) => {
                            if (index === 0)
                                // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                              console.log(index);
                        }}
                    />
                </div>
            )} />
        </Row>
    </ColumnGroup>
);
  return (
    <div className="purchaseOrder_section mt-2">
      <div className="d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput

          // value={search}
          // onChange={searchData}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <button className="filter_btn w-100">Filter Contracts</button>
          <button className="filter_btn w-100">Clear All</button>
        </div>
      </div>

      <div className="direct_cost_general_table_scroll mt-3" >
        <DataTable
        value={demodata}
        scrollable
          scrollHeight="flex"
        style={{minWidth: "100rem"}}
        footerColumnGroup={footerGroup}
        >
          <Column
            field="id"
            header="#"
            body={(rowData, rowIndex) => <div>{rowIndex.rowIndex + 1}</div>}
          ></Column>
          <Column header="Title" field="Title" />
          <Column header="Contract Company" field="contract_company" 
           body={(rowData, rowIndex) => (
            <span
              style={{
                cursor: "pointer",
                // color: "blue",
                // textDecoration: "underline",
              }}
              onClick={() => navigate("/financialManagement/commitment/ViewPurchaseOrder/generalDetail")}
            >
              {rowData.contract_company}
            </span>
          )}/>
          <Column header="Staus" field="Status" />
          <Column header="Executed" field="Executed" />
          {/* <Column header="Paid Date" body={(rowData) => moment(rowData?.paid_date).format("DD/MM/YYYY")} /> */}
          <Column header="Orignal Contract Amount" field="Original_Contract_Amount" />
          <Column header="Approval Change Order" field="Approved_Change_Orders" />
          <Column header="Revised Contract Order" field="Revised_Contract_Amount" />
          <Column header="Pending Cost Changes" field="Pending_Cost_Changes" />
          <Column header="Draft Change Orders" field="Draft_Change_Orders" />
          <Column header="Invoiced" field="Invoiced" />
          <Column header="Payment Recieved" field="payment_Received" />
         

          <Column
            header="Actions"
            body={(rowData, meta) => (
              <div className="flex justify-content-center">
                <Popover
                  options={["Edit"]}
                  onSelect={(index, event) => {
                    if (index === 0)
                      navigate(
                        `/financialManagement/directCost/edit/${rowData._id}`
                      );
                  }}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
     
    </div>
  );
};

export default PurchaseOrder;
