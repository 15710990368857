import React, { useEffect, useState } from 'react'
import { BorderedTable } from '../../Components/Tables/BorderedTable'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getRfi, getRfiCount } from '../../Api/Admin/AllAPIs';
import Chart from 'react-apexcharts';
import Loader from '../../Components/Common/Loader';

export const ViewRfi = () => {
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    let [rfiData, setRfiData] = useState([])
    let [loader, setLoader] = useState(true)
    let [chartData, setChartData] = useState({})

    useEffect(() => {
        getData()
    }, []);

    async function getData() {
        try {
            setLoader(true)
            let res = await getRfiCount()
            let data = res.data.data
            if (data.length > 0) {
                let chrtData = {}
                data.forEach((res) => {
                    chrtData[res._id] = res.count
                })
                setChartData(chrtData)
                setRfiData(data.map(d => d.data).flat().map(res => ({ ...res, headName: res.status, responses: "Please see attached and revised dreawing showing where new mounting points will be for relocated lighting fixture position", assignees: "Pat Project Manager", rfiManager: "Elton Electrical Sub PM" })))
            }

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    };


    // const expandAll = () => {
    //     let _expandedRows = {};

    //     products.forEach((p) => (_expandedRows[`${p.id}`] = true));

    //     setExpandedRows(_expandedRows);
    // };
    const handleGetRow = (e) => {
        setExpandedRows(e.data);
    };

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="vertical-align-middle ml-2 font-bold line-height-3">
                    {data?.headName}
                </span>
            </React.Fragment>
        );
    };


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };



    var options = {
        series: [{
            data: [chartData?.close || 0, chartData?.reopen || 0, chartData?.open || 0]
        }],
        chart: {
            type: 'bar',
            // events: {
            //     click: function (chart, w, e) {
            //     }
            // }
        },
        colors: ["#1B88C6", "#1B88C6", "#1B88C6"],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: ["Closed", "Reopen ", "Open"],
        }
    };






    return (
        <>
            {loader ?
                <Loader /> :
                <div className="mainContainer main-container">
                    <div className="card p-md-4 p-sm-3 p-2 ">


                        <Chart
                            options={options}
                            series={options.series}
                            type="bar"
                            height="450"
                        />

                        <br />


                        <BorderedTable>
                            <DataTable
                                value={rfiData}
                                rowGroupMode="subheader"
                                groupRowsBy="headName"
                                sortMode="multiple"
                                sortField="headName"
                                sortOrder={1}
                                expandableRowGroups
                                expandedRows={expandedRows}
                                onRowToggle={handleGetRow}
                                rowGroupHeaderTemplate={headerTemplate}
                                tableStyle={{ minWidth: "50rem" }}
                                selection={selectedProducts}
                                onSelectionChange={(e) => {
                                    console.log(e.value)
                                    setSelectedProducts(e.value)
                                }}
                                dataKey="id">

                                <Column field="" header="Status"></Column>
                                <Column field="group" header="Group"></Column>
                                <Column field="rfi" header="RFI"></Column>
                                <Column field="subject" header="Subject"></Column>
                                <Column field="assignees" header="Assignees"></Column>
                                <Column field="rfiManager" header="RFI Manager"></Column>
                                <Column field="responses" header="Responses"></Column>

                            </DataTable>
                        </BorderedTable>

                    </div>
                </div >}
        </>
    )
}
