import React, { useEffect, useState } from 'react'
import SingleSelect from '../../Components/Common/SingleSelect';
import { InputNumber } from 'primereact/inputnumber';
import { DragAndDrop } from '../../Components/Inputs/DragAndDrop';
import TextInput from '../../Components/Inputs/TextInput';
import { useNavigate, useParams } from 'react-router-dom';
import { Country, State, City } from "country-state-city";
import { addCompany, getCompany, getCompanyById, uploadDocs } from '../../Api/Admin/AllAPIs';
import { toast } from '../../utils';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';


function AddGeneralInfo({ setActiveTabIndex, formType }) {
  let isSubContractor = formType === "subContractor";
  let [userType, setUserType] = useState("contractor")
  const data = [{ name: "John" }, { name: "Samuel" }];
  const [createLoader, setCreateLoader] = useState(false)
  const navigate = useNavigate()
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [abbreviatedName, setAbbreviatedName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [tags, setTags] = useState("");
  const [laborUnion, setLaborUnion] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [entityType, setEntityType] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [fileUpload2, setFileUpload2] = useState([]);
  const [fileName2, setFileName2] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previousFile2, setPreviousFile2] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  let [password, setPassword] = useState("")


  const [idStore, setIdStore] = useState();
  const [loader, setLoader] = useState(false);
  const [Validation, setValidation] = useState({
    companyName: false,
    abbreviatedName: false,
    address: false,
    fax: false,
    selectedCountry: false,
    selectedState: false,
    selectedCity: false,
    email: false,
    website: false,
    zip: false,
    phone: false,
    laborUnion: false,
    licenseNumber: false,
    entityType: false,
    tags: false,
  })

  let paramsId = useParams()

  const checkValidation = () => {
    if (!companyName.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        companyName: true,
      }));
    }
    if (!abbreviatedName.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        abbreviatedName: true,
      }));
    }
    if (!address.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        address: true,
      }));
    }
    if (!fax.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        fax: true,
      }));
    }
    if (!selectedCountry) {

      setValidation((prevState) => ({
        ...prevState,
        selectedCountry: true,
      }));
    }
    // if (!selectedState) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     selectedState: true,
    //   }));
    // }
    // if (!selectedCity) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     selectedCity: true,
    //   }));
    // }

    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
    if (!regex.test(email)) {
      setValidation((prevState) => ({
        ...prevState,
        email: true,
      }));
    }




    if (!website.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        website: true,
      }));
    }
    if (!zip) {
      setValidation((prevState) => ({
        ...prevState,
        zip: true,
      }));
    }
    if (!phone) {
      setValidation((prevState) => ({
        ...prevState,
        phone: true,
      }));
    }
    if (!laborUnion.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        laborUnion: true,
      }));
    }
    if (!licenseNumber.trim()) {

      setValidation((prevState) => ({
        ...prevState,
        licenseNumber: true,
      }));
    }
    if (!entityType.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        entityType: true,
      }));
    }
    if (!tags.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        tags: true,
      }));
    }

    if (
      !companyName.trim() ||
      !abbreviatedName.trim() ||
      !address.trim() ||
      !fax.trim() ||
      !selectedCountry ||
      // !selectedState ||
      // !selectedCity ||
      !regex.test(email) ||
      !website.trim() ||
      !zip ||
      !phone ||
      !laborUnion.trim() ||
      !licenseNumber.trim() ||
      !entityType.trim() ||
      !tags.trim()

    ) {
      toast.info("Please fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };



  const getStates = (countryName, countryIsoCode, defult, citys) => {
    console.log(countryName);
    setSelectedState("");
    setSelectedCity("");
    const states = State.getStatesOfCountry(countryIsoCode);
    console.log(states)
    setStates(states);

    if (defult) {
      console.log(citys);
      let dataState = states.filter((e) => e.name === defult)[0];
      setSelectedState(dataState);
      getCities(dataState, citys);
    }
  };

  useEffect(() => {
    const companyIdFromLocalStorage = localStorage.getItem("companyId");
    if (companyIdFromLocalStorage) {
      setIdStore(companyIdFromLocalStorage);
      console.log(idStore)
    }
  }, []);

  const getCities = (state, citys) => {
    let isoCode = state?.isoCode;
    let cCode = state?.countryCode;

    setSelectedCity("");

    const cities = City.getCitiesOfState(cCode, isoCode);
    console.log(cities, "yyy");
    setCities(cities);
    if (citys) {
      setSelectedCity(cities.filter((e) => e.name === citys)[0]);
    }
  };
  const handleFileInput1 = (event) => {
    setSelectedFiles(event);
    setFileUpload(event);
  };

  const handleFileInput2 = (event) => {
    setSelectedFiles2(event);
  };


  const handleCompany = async () => {

    try {
      setLoader(true)

      if (fileUpload.length > 1) {

        toast.info('You can add only 1 logo');
        return
      }

      if (checkValidation()) {
        return;
      }

      // if (isSubContractor && password == "") {
      //   toast.info('Please Enter Password');
      //   return
      // }
      setCreateLoader(true)
      let uploadedFiles = [];
      console.log(selectedFiles, "selectedFiles");
      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles) {
          formData.append("upload", file);
        }
        const uploadResponse = await uploadDocs(formData);


        console.log(uploadResponse.data, "uploadedFiles");

        uploadedFiles = [...uploadedFiles, ...uploadResponse.data];
      }

      let uploadedFiles2 = [];
      if (selectedFiles2.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles2) {
          formData.append("upload", file);
        }
        const uploadResponse = await uploadDocs(formData);
        uploadedFiles2 = [...uploadedFiles2, ...uploadResponse.data];
      }
      const payload = {

        company_name: companyName,
        abbreviated_name: abbreviatedName,
        business_phone: phone,
        address: address,
        business_fax: fax,
        city: selectedCity?.name || selectedCity,
        email_address: email,
        conunty: selectedCountry?.name,
        state: selectedState?.name,
        website: website,
        zip: zip,

        tags_keywords: tags,

        logo: [...previousFile, ...uploadedFiles],
        attachments: [...previousFile2, ...uploadedFiles2],
        license_number: licenseNumber,
        entity_type: entityType,
        labor_union: laborUnion,
        company_type: userType
      }


      const res = await addCompany(payload)
      toast.success("Company Added Successfully!");


      // if (formType === "subContractor") {
      navigate("/projectDirectories")
      // }

      const companyId = res?.data?.data?._id
      localStorage.setItem("companyId", companyId)

      setActiveTabIndex(1)
      // navigate("/project")

      setCompanyName("");
      setAbbreviatedName("");
      setAddress("");
      setFax("");
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity("");
      setEmail("");
      setWebsite("");
      setZip("");

      setTags("");
      setPhone("");
      setLaborUnion("");
      setLicenseNumber("");
      setEntityType("");

      setFileUpload("");
      setFileUpload2("")


    }
    catch (err) {
      console.log(err)
      toast.error("Something Wents Wrong, Please Try Again!");
    }
    finally {
      setCreateLoader(false)
      setLoader(false);
    }
  }
  return (
    <>

      <div className=''>
        <div >

        </div>

        {/*  New section End */}

        <div className='twoColumnFormGridContainer mt-4'>

          <div>
            <label htmlFor="" className="labelCss">
              Company Type:
            </label>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <RadioButton inputId="userType2" name="userType" value="contractor" onChange={(e) => setUserType(e.value)} checked={userType === 'contractor'} />
                &nbsp;
                <label htmlFor="userType2" className="ml-2">Contractor</label>
              </div>
              <div className="flex align-items-center">
                <RadioButton inputId="userType1" name="userType" value="subcontractor" onChange={(e) => setUserType(e.value)} checked={userType === 'subcontractor'} />
                &nbsp;
                <label htmlFor="userType1" className="ml-2">Sub Contractor</label>
              </div>
            </div>
          </div>
          <div></div>

          <div>
            <label htmlFor="" className="labelCss">
              Company Name: <span className="text-danger">*</span>
            </label>

            <TextInput placeholder="Company Name"
              value={companyName}
              onChange={(e) => { setCompanyName(e.target.value) }}
              className={Validation.companyName ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, companyName: false })
              }
            >

            </TextInput>
          </div>
          <div>
            <label htmlFor="" className="labelCss">
              Abbreviated Name: <span className="text-danger">*</span>
            </label>
            <div className="">
              <TextInput placeholder=" Abbreviated Name"
                value={abbreviatedName}
                onChange={(e) => { setAbbreviatedName(e.target.value) }}
                className={Validation.abbreviatedName ? "Validation" : ""}
                onClick={() =>
                  setValidation({ ...Validation, abbreviatedName: false })
                }
              >

              </TextInput>
            </div>

          </div>




          <div>
            <label htmlFor="" className="labelCss">
              Address: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Address"
              value={address}
              onChange={(e) => { setAddress(e.target.value) }}
              className={Validation.address ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, address: false })
              }

            >
              {" "}
            </TextInput>
          </div>
          <div>
            <label htmlFor="" className="labelCss">
              Business Fax: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Business Fax"
              value={fax}
              onChange={(e) => { setFax(e.target.value) }}
              className={Validation.fax ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, fax: false })
              }

            >
              {" "}
            </TextInput>
          </div>




          <div>
            <label htmlFor="" className="labelCss">
              Country: <span className="text-danger">*</span>
            </label>
            <SingleSelect placeholder="Country"
              options={countries.map((country) => ({
                label: country.name,
                value: country,
              }))}

              value={selectedCountry}
              onChange={(e) => {
                const selectedCountry = e.value;
                setSelectedCountry(e.target.value);
                getStates(selectedCountry.name, selectedCountry.isoCode);
              }}
              className={Validation.selectedCountry ? "Validation p-0" : "p-0"}
              onClick={() =>
                setValidation({ ...Validation, selectedCountry: false })
              }
              filter={true}
              filterInputAutoFocus={true}
            />
          </div>
          <div>
            <label htmlFor="" className="labelCss">
              State: <span className="text-danger">*</span>
            </label>
            <SingleSelect placeholder="State"
              options={Array.from(states)?.map((state) => ({
                label: state.name,
                value: state,
              }))}
              name="state"
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
                getCities(e.target.value);
              }}
              className={Validation.selectedState ? "Validation p-0" : "p-0"}
              onClick={() =>
                setValidation({ ...Validation, selectedState: false })
              }
              filter={true}
              filterInputAutoFocus={true}
            />
          </div>

          <div>
            <label htmlFor="" className="labelCss">
              City:
            </label>
            <SingleSelect placeholder="City"
              options={cities.map((city) => ({
                label: city.name,
                value: city.name,
              }))}
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className={Validation.selectedCity ? "Validation p-0" : "p-0"}
              onClick={() =>
                setValidation({ ...Validation, selectedCity: false })
              }
              filter={true}
              filterInputAutoFocus={true}
            />
          </div>

          <div>
            <label htmlFor="" className="labelCss">
              Labor Union: <span className="text-danger">*</span>
            </label>
            <div className="card">
              <TextInput
                placeholder="Labor Union"
                value={laborUnion}
                onChange={(e) => { setLaborUnion(e.target.value) }}
                className={Validation.laborUnion ? "Validation" : ""}
                onClick={() =>
                  setValidation({ ...Validation, laborUnion: false })
                }

              >
                {" "}
              </TextInput>
            </div>
          </div>




          <div>
            <label htmlFor="" className="labelCss">
              Website: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Website"
              value={website}
              onChange={(e) => { setWebsite(e.target.value) }}
              className={Validation.website ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, website: false })
              }

            >
              {" "}
            </TextInput>
          </div>
          <div>
            <label htmlFor="" className="labelCss">
              ZIP: <span className="text-danger">*</span>
            </label>
            <div className="d-flex" >
              <input
                type="text"
                inputId="integeronly"
                placeholder="Zip"
                value={zip}
                // max={5}
                // useGrouping={false}
                style={{ height: "50px" }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setZip(newValue)

                }}
                className={`${Validation.zip ? "Validation" : ""} form-control`}
                onClick={() =>
                  setValidation({ ...Validation, zip: false })
                }
              />
            </div>

          </div>
          <div>
            <label htmlFor="" className="labelCss">
              Business Phone: <span className="text-danger">*</span>
            </label>
            <div className="card">
              <input
                type='number'
                inputId="integeronly"
                placeholder="Business Phone"
                value={phone}
                // max={10}
                // useGrouping={false}

                onChange={(e) => {
                  const newValue = e.target.value;
                  let numRegex = /^[0-9]+$/;
                  if ((newValue === '' || numRegex.test(newValue))) {
                    setPhone(newValue)
                  }
                }}
                style={{ width: "100%", height: "50px" }}
                className={`${Validation.phone ? "Validation" : ""} form-control`}
                onClick={() =>
                  setValidation({ ...Validation, phone: false })
                } />

            </div>
          </div>

          <div>
            <label htmlFor="" className="labelCss">
              Email Address: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Email Address"
              value={email}

              onChange={(e) => { setEmail(e.target.value) }}
              className={Validation.email ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, email: false })
              }

            >
              {" "}
            </TextInput>
          </div>



          <div>
            <label htmlFor="" className="labelCss">
              License Number: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="License Number"
              value={licenseNumber}
              onChange={(e) => { setLicenseNumber(e.target.value) }}
              className={Validation.licenseNumber ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, licenseNumber: false })
              }

            >
              {" "}
            </TextInput>
          </div>
          <div>
            <label htmlFor="" className="labelCss">
              Entity Type: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Entity Type"
              value={entityType}
              onChange={(e) => { setEntityType(e.target.value) }}

              className={Validation.entityType ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, entityType: false })
              }
            >
              {" "}
            </TextInput>
          </div>



          <div>
            <label htmlFor="" className="labelCss">
              Tags/Keywords: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder="Tags/Keywords"
              value={tags}
              onChange={(e) => { setTags(e.target.value) }}
              className={Validation.tags ? "Validation" : ""}
              onClick={() =>
                setValidation({ ...Validation, tags: false })
              }

            >

            </TextInput>
          </div>

          <div>
            <DragAndDrop
              setRemovePhoto={setRemovePhoto}
              value={fileName}
              setFiles={setFileUpload}
              onChange={handleFileInput1}
              setValue={setFileName}
              setPreviousFiles={setPreviousFile}
              label={"Company Logo"}

            />
          </div>



        </div>

        <div className="rowcss_com mt-3">
          <div className="col-md-12">
            <DragAndDrop
              setRemovePhoto={setRemovePhoto}
              value={fileName2}
              setFiles={setFileUpload2}
              onChange={handleFileInput2}
              setValue={setFileName2}
              setPreviousFiles={setPreviousFile2}
            />
          </div>
        </div>

        <div className="btn_main  mt-4 ">
          <span
            className="cancelBtn  px-2 py-2"
            onClick={() => { navigate("/projectDirectories") }}
          >
            {" "}
            Cancel
          </span>
          <div >
            <PrimaryButton onClick={handleCompany} text={createLoader ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" /> : "Submit"} />
          </div>
        </div>
      </div>
      <br />
    </>
  )
}

export default AddGeneralInfo